import {NgModule} from '@angular/core';
import {PusherService} from './pusher/pusher.service';
import {CommonModule} from '@angular/common';
import {PhoneInputModule} from './phone-input/phone-input.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PhoneInputModule
  ],
  providers: [
    PusherService
  ]
})
export class HlCommonModule {
}
