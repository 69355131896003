// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    aguilar: false,
    coreURL: 'https://api.watergate.dev',
    pusherKey: '71c900acecab6a63cee7',
    incidentChangePusherEvent: 'incident-change',
    incidentCreationPusherEvent: 'incident-creation',
    telemetryPusherEvent: 'smart-valve-flow-event',
    stateChangePusherEvent: 'thing-state-change',
    googleAnalyticsTrackingCode: '',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
