import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import * as _ from 'lodash';
import {
    IncidentDetailsResponse,
    IncidentIndex,
    IncidentsStatusCount,
    IncidentState,
    IncidentType,
    MostRecentIncident
} from '../../models/incident';
import {Pageable} from '../../models/Pageable';
import {ApiConfig} from './ApiConfig';
import {OptionalHttpParams} from './thing.resource';

@Injectable()
export class IncidentResource {

    constructor(private http: HttpClient) {
    }

    public getIncidentIndex(propertyId: string, indexParams?: IncidentIndexFilters): Observable<Pageable<IncidentIndex>> {
        const url = `${ApiConfig.CORE_URL}/properties/${propertyId}/incidents`;
        let params: any = indexParams;
        if (params.state) {
            params = _.omit(params, 'state');
            params['state[]'] = indexParams.state || null;
        }
        return this.http.get<Pageable<IncidentIndex>>(url, {params});
    }

    public getAllIncidents(params?: OptionalHttpParams): Observable<Pageable<IncidentIndex>> {
        const url = `${ApiConfig.CORE_URL}/incidents`;

        return this.http.get<Pageable<IncidentIndex>>(url, {params});
    }

    public getIncidentsCount(propertyId: string): Observable<IncidentsStatusCount> {
        const url = `${ApiConfig.CORE_URL}/properties/${propertyId}/incidents/status_count`;

        return this.http.get<IncidentsStatusCount>(url);
    }

    public findById(propertyId: string, incidentId: string): Observable<IncidentDetailsResponse> {
        const url = `${ApiConfig.CORE_URL}/properties/${propertyId}/incidents/${incidentId}`;
        return this.http.get<IncidentDetailsResponse>(url);
    }


    public executeAction(propertyId: string,
                         incidentId: string,
                         action: string): Observable<IncidentDetailsResponse> {
        const url = `${ApiConfig.CORE_URL}/properties/${propertyId}/incidents/${incidentId}/action`;
        return this.http.put<IncidentDetailsResponse>(url, {action});
    }

    public snooze(propertyId: string,
                  incidentId: string,
                  snoozedUntil: string): Observable<IncidentDetailsResponse> {
        const url = `${ApiConfig.CORE_URL}/properties/${propertyId}/incidents/${incidentId}/action`;
        return this.http.put<IncidentDetailsResponse>(url, {action: 'snooze', snoozed_until: snoozedUntil});
    }

    public theMostRecentClosedIncidents(propertyId: string): Observable<MostRecentIncident[]> {
        const url = `${ApiConfig.CORE_URL}/properties/${propertyId}/the_most_recent_closed_incidents`;
        return this.http.get<MostRecentIncident[]>(url);
    }

    public getCopiesByType(type: string): Observable<{ title: string; description: string }> {
        const url = `${ApiConfig.CORE_URL}/incidents/copy?type=${type}`;
        return this.http.get<{ title: string; description: string }>(url);
    }
}

export interface IncidentIndexFilters {
    // initialising, contacting, resolved, waiting_for_user, dismissed;
    state?: IncidentState[];
    seen?: boolean;
    open?: boolean;
    confirmed?: boolean;
    type?: IncidentType;
    severity?: string;
    escalation_level?: number;
    page?: number;
    status?: 'active' | 'snoozed' | 'closed';
    page_size?: number;
}
