import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
    name: 'utcLocal',
})
export class UtcToLocalPipe implements PipeTransform {

    constructor() {

    }

    transform(value: number | Date, args: { format: string; timezone: string } = {format: '', timezone: 'Europe/London'}) {
        return moment.utc(value).tz(args.timezone).format(args.format);
    }
}
