import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApiConfig} from './ApiConfig';
import {ThingEventsResponse, ThingResponse} from '../../models/thing';
import {TotalUsage, TotalUsageParams} from '../../models/total.usage';
import {CalendarPressureTests, PressureTestsResponse} from '../../models/pressure-test';
import {AggregatedStats} from '../../models/aggregated-stats';

@Injectable()
export class ThingResource {

    constructor(private http: HttpClient) {
    }

    public changeThingState(thingId: string, newState: string): Observable<ThingResponse> {
        const url = `${ApiConfig.CORE_URL}/things/${thingId}/state_change`;
        const data = {
            state: newState,

        };
        return this.http.post<ThingResponse>(url, data);
    }

    public getThing(thingId: string): Observable<ThingResponse> {
        const url = `${ApiConfig.CORE_URL}/things/${thingId}`;

        return this.http.get<ThingResponse>(url);
    }

    public getTotalUsage(thingId: string, query: TotalUsageParams): Observable<TotalUsage> {
        const url = `${ApiConfig.CORE_URL}/things/${thingId}/total_usage`;
        const params = query as OptionalHttpParams;

        return this.http.get<TotalUsage>(url, {params});
    }

    public getPropertyPressureTests(thingId: string, params?: any): Observable<PressureTestsResponse> {
        return this.http.get<PressureTestsResponse>(ApiConfig.CORE_URL + '/things/' + thingId + '/pressure_tests', {params});
    }

    public getCalendarPressureTests(thingId: string, params: {
        from: string;
        to: string;
    }): Observable<CalendarPressureTests> {
        return this.http.get<CalendarPressureTests>(ApiConfig.CORE_URL + '/things/' + thingId + '/pressure_tests_calendar', {params});
    }

    public getEvents(thingId: string, params?: OptionalHttpParams): Observable<ThingEventsResponse> {
        return this.http.get<ThingEventsResponse>(ApiConfig.CORE_URL + '/things/' + thingId + '/events', {params});
    }

    public getAggregatedStats(thingId: string, from: number, to: number): Observable<AggregatedStats[]> {
        const url = `${ApiConfig.CORE_URL}/things/${thingId}/aggregated_stats?from=${from}&to=${to}`;
        return this.http.get<AggregatedStats[]>(url);
    }

}

export interface OptionalHttpParams {
    [param: string]: string | string[];
}
