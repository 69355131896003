<h2 mat-dialog-title
    class="text-center">Edit account</h2>
<mat-dialog-content class="overflow-visible">
  <form [formGroup]="userForm">
    <mat-form-field class="w-100">
      <mat-label>First name</mat-label>
      <input matInput
             formControlName="first_name"/>
      <mat-error>Please enter first name</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100">
      <mat-label>Last name</mat-label>
      <input matInput
             formControlName="last_name"/>
      <mat-error>Please enter last name</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100">
      <mat-label>E-mail</mat-label>
      <input matInput
             type="email"
             formControlName="email"/>
      <mat-error>Please enter valid e-mail</mat-error>
    </mat-form-field>

    <mat-form-field class="w-100">
      <app-phone-input   formControlName="phone"></app-phone-input>
      <mat-error>Please enter valid phone number</mat-error>
    </mat-form-field>
    <mat-error *ngIf="apiError">{{apiError}}</mat-error>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-ripple
          class="alert-cancel-bt btn btn-outline-secondary mr-3"
          mat-dialog-close><span>Cancel</span></button>
  <button mat-ripple
          class="btn btn-primary"
          (click)="save()">
    <span>Save</span>
  </button>
</mat-dialog-actions>
