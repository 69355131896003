import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {tableSettings} from 'src/app/models/table-settings';
import {Property, PropertyPaginatedResponse} from '../../models/property';
import {OptionalHttpParams} from '../../providers/resources/thing.resource';
import {PropertyService} from '../../providers/services/property.service';
import {TableComponent} from '../parts/table/table.component';
import * as _ from 'lodash';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
    selector: 'app-properties',
    templateUrl: './properties.component.html',
    styleUrls: ['./properties.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertiesComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild(TableComponent) table: TableComponent;
    @ViewChild('addressColumn') addressColumn: TemplateRef<any>;
    @ViewChild('actionsColumn') actionsColumn: TemplateRef<any>;
    @ViewChild('systemColumn') systemColumn: TemplateRef<any>;
    @ViewChild('statusColumn') statusColumn: TemplateRef<any>;
    public rows: Property[];
    public columns = [];
    public search: string;
    public tableSettings = _.cloneDeep(tableSettings);

    private searchSubj = new Subject<void>();

    constructor(private propertyService: PropertyService,
                private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.searchSubj
            .pipe(debounceTime(300))
            .subscribe(() => {
                this.fetchPage();
            });
    }

    ngOnDestroy() {
        if (this.searchSubj) {
            this.searchSubj.unsubscribe();
        }
    }

    public ngAfterViewInit(): void {

        this.columns = [
            {prop: 'name', name: 'Name', flexGrow: 1, sortable: false},
            {name: 'System status', flexGrow: 1, minWidth: 150, sortable: false, cellTemplate: this.statusColumn},
            {
                cellTemplate: this.actionsColumn,
                name: 'Actions',
                maxWidth: 180,
                minWidth: 180,
                flexGrow: 0.5,
                sortable: false
            }
        ];
        this.fetchPage();
    }

    public printSystemStatus(row: Property): string {
        const hubsNumber = _.size(_.flatMapDeep(row.spaces, (s) => s.hubs));
        return hubsNumber > 0 ? `System has ${hubsNumber} hub${hubsNumber > 1 ? 's' : ''}` : 'System has no hubs';
    }

    public fetchPage(params?: OptionalHttpParams): void {
        if (!this.table) {
            return;
        }
        if (this.search) {
            if (!params) {
                params = {};
            }
            params['search'] = this.search;
        }
        this.propertyService.getPropertiesListPaginated(params)
            .subscribe((response: PropertyPaginatedResponse) => {
                    this.table.setPageParams({
                        page_number: response.page_number,
                        page_size: response.page_size,
                        total_entries: response.total_entries,
                        total_pages: response.total_pages
                    });
                    this.rows = response.data;
                    this.table.isLoading = false;
                    this.cdRef.detectChanges();
                }
            );
    }

    /**
     * Triggers fetchPage with debounce
     */
    public triggerSearch(): void {
        this.searchSubj.next();
    }

    /**
     * Clears the search field and triggers a search.
     *
     * @function clearSearch
     * @returns
     */
    public clearSearch(): void {
        this.search = '';
        this.triggerSearch();
    }
}
