<div class="card">
	<div class="card-header">
		<!-- Title -->
		<h4 class="card-header-title">
			Notification settings <span *ngIf="showBack && selectedProperty">({{selectedProperty.name}})</span>
		</h4>
		<button class="ml-auto d-block btn btn-outline-primary btn-sm"
				mat-ripple
				*ngIf="selectedProperty"
				(click)="openEditNotificationDialog();">
			<i class="fe fe-edit-2"></i> Edit
		</button>
	</div>
	<div class="card-body">
		<ng-container *ngIf="!selectedProperty; else hasProperty">
			<h4>Select property</h4>
			<app-properties-list (propertySelect)="selectProperty($event)"></app-properties-list>
		</ng-container>
		<ng-template #hasProperty>
			<button (click)="clearPropertySelection()"
					*ngIf="showBack"
					class="btn btn-outline-secondary btn-sm btn-block mb-3">
				<i class="fe fe-chevron-left"></i> Back
			</button>
			<table class="table table-striped"
				   *ngIf="getNotificationSettingsStream() | async as notificationSettings">
				<tr *ngFor="let prop of boolProperties">
					<td>{{prop.name}}:</td>
					<td>
						<span [ngClass]="{'text-success': notificationSettings[prop.key],'text-warning':!notificationSettings[prop.key]}">{{notificationSettings[prop.key] ? 'On' : 'Off' }}</span>
					</td>
				</tr>
				<tr>
					<td>High volume threshold:</td>
					<td>
						{{notificationSettings.high_volume_threshold_litres}} l
					</td>
				</tr>
				<tr>
					<td>Long flow delay:</td>
					<td>
						{{notificationSettings.long_flow_notification_delay_mins}} min
					</td>
				</tr>
			</table>
		</ng-template>
	</div>
</div>
