import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Property} from '../../../models/property';
import {PropertyService} from '../../../providers/services/property.service';
import {NotificationSettings} from '../../../models/notification-settings';
import {BehaviorSubject, Observable} from 'rxjs';
import * as _ from 'lodash';
import {take} from 'rxjs/operators';
import {EditNotificationSettingsDialogComponent} from '../../parts/edit-notification-settings-dialog/edit-notification-settings-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {PropertySettings} from '../../../models/property.settings';
import {EditPropertySettingsDialogComponent} from '../../parts/edit-property-settings-dialog/edit-property-settings-dialog.component';
import * as moment from 'moment-timezone';
import {incidentsBoolProperties} from '../../incidents/config';

@Component({
    selector: 'app-notification-settings-page',
    templateUrl: './notification-settings-page.component.html',
    styleUrls: ['./notification-settings-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationSettingsPageComponent implements OnInit {

    @Input() selectedProperty: Property;
    public propertiesList = [];
    public selectedProperties = [];
    public allProperties = [];
    public boolProperties = incidentsBoolProperties;
    private notificationSettingsSubject: BehaviorSubject<NotificationSettings> = new BehaviorSubject(null);
    private propertySettingsSubject: BehaviorSubject<PropertySettings> = new BehaviorSubject(null);

    constructor(private cdRef: ChangeDetectorRef,
                private matDialog: MatDialog,
                private propertyService: PropertyService) {
    }

    ngOnInit(): void {
        if (this.selectedProperty) {
            this.refreshAllData();
        }
    }

    public selectProperty(property: Property[]): void {
        this.selectedProperty = property[0];
        this.selectedProperties = property;
        this.refreshAllData();
    }

    public getNotificationSettingsStream(): Observable<NotificationSettings> {
        return this.notificationSettingsSubject.asObservable();
    }

    public getPropertySettingsStream(): Observable<PropertySettings> {
        return this.propertySettingsSubject.asObservable();
    }

    public clearPropertySelection(): void {
        this.selectedProperty = null;
        this.notificationSettingsSubject.next(null);
        this.propertySettingsSubject.next(null);
        this.selectedProperties = [];
        this.cdRef.detectChanges();
    }

    public openEditNotificationDialog(): void {
        const ref = this.matDialog.open(EditNotificationSettingsDialogComponent, {
            data: {
                notificationSettings: this.notificationSettingsSubject.getValue(),
                propertyData: this.selectedProperties,
                boolProperties: this.boolProperties
            },
            width: '100%',
            maxWidth: '500px',
            panelClass: ['mx-auto', 'overflow-visible'],
        });
        ref.afterClosed()
            .pipe(take(1))
            .subscribe((response: boolean) => {
                if (response) {
                    this.refreshAllData();
                    this.cdRef.detectChanges();
                }
            });
    }

    public openEditSettingsDialog(mode: string): void {
        const ref = this.matDialog.open(EditPropertySettingsDialogComponent, {
            data: {
                propertySettings: this.propertySettingsSubject.getValue(),
                propertyData: this.selectedProperties,
                mode
            },
            width: '100%',
            maxWidth: '500px',
            panelClass: ['mx-auto', 'overflow-visible'],
        });
        ref.afterClosed()
            .pipe(take(1))
            .subscribe((response: boolean) => {
                if (response) {
                    this.refreshAllData();
                    this.cdRef.detectChanges();
                }
            });
    }

    public getPropertiesList(propertiesList: Property[]): void {
        this.allProperties = propertiesList;
    }

    formatTime(pressure_tests_schedule: string) {
        return moment.utc(pressure_tests_schedule, 'H:mm:ss').tz(this.propertySettingsSubject.value.timezone).format('H:mm');
    }

    public selectAllProperties(): void {
        this.selectedProperties = [...this.allProperties];
        this.selectedProperty = this.selectedProperties[0];
        this.cdRef.detectChanges();
        this.refreshAllData();
    }

    private fetchNotificationSettings(): void {
        this.propertyService.getNotificationSettings(this.selectedProperty.id)
            .subscribe((settings) => {
                this.notificationSettingsSubject.next(_.cloneDeep(settings));
                this.cdRef.detectChanges();
            });
    }

    private fetchPropertySettings(): void {
        this.propertyService.getSettings(this.selectedProperty.id, true)
            .subscribe((settings) => {
                this.propertySettingsSubject.next(_.cloneDeep(settings));
                this.cdRef.detectChanges();
            });
    }

    private refreshAllData() {
        if (!this.selectedProperty) {
            return;
        }
        this.fetchNotificationSettings();
        this.fetchPropertySettings();
    }

}
