<div class="c-widget-frame {{class}}">
    <div class="c-widget-frame__heading" *ngIf="title" (click)="toggle()">
        <div class="row align-items-center">
            <div class="col">
                <h2 class="c-widget-frame__title">{{ title }}</h2>
            </div>
            <div class="col-auto pr-0" *ngIf="value">
                <span class="c-widget-frame__value">{{ value }}</span>
                <span class="c-widget-frame__unit" *ngIf="unit">{{ unit }}</span>
            </div>
            <div class="col-auto" *ngIf="link">
                <a [routerLink]="link.path" class="c-widget-frame__link">{{ link.label }}</a>
            </div>
        </div>
    </div>
    <div class="c-widget-frame__image" *ngIf="image">
        <img [src]="image" alt=""/>
    </div>
    <div class="c-widget-frame__content" *ngIf="isOpen">
        <ng-content></ng-content>
    </div>
</div>
