import * as PusherUMD from 'pusher-js';
import Pusher, {Channel} from 'pusher-js';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, Observable, Subscription, timer} from 'rxjs';
import {skipWhile} from 'rxjs/operators';
import * as _ from 'lodash';
import {PusherChannelOptions} from './pusherChannelOptions';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GeneralPusherService {
    // @ts-ignore
    public pusher: Pusher = new PusherUMD(environment.pusherKey, {
        cluster: 'eu',
        encrypted: true,
    });
    protected channel: BehaviorSubject<Channel> = new BehaviorSubject<Channel>(null);
    protected propertyId: string;
    protected readonly CHANNEL_PREFIX = 'properties@';

    private unsubscribeSub: Subscription;
    private pusherTimeout = 5 * 60 * 1000; // 5 minutes

    constructor() {

        // TODO: should be reworked to be more angular way
        document.addEventListener('visibilitychange', () => {
            if (!this.propertyId) {
                return;
            }
            if (document.hidden) {
                this.handlePusherTimeout();
            } else {
                this.initPropertyChannel(this.propertyId);
            }
        });
    }

    public getChannelStream(): Observable<Channel> {
        return this.channel
            .asObservable()
            .pipe(skipWhile(res => res === null));
    }

    public initPropertyChannel(propertyId: string) {
        if (this.unsubscribeSub) {
            this.unsubscribeSub.unsubscribe();
        }
        this.unsubscribeSub = timer(this.pusherTimeout)
            .subscribe(() => this.handlePusherTimeout());

        this.propertyId = propertyId;
        this.channel.next(this.pusher.subscribe(this.CHANNEL_PREFIX + propertyId));
    }

    public destroyEvents() {
        this.unBindAll();
        if (this.channel.value) {
            this.pusher.unsubscribe(this.channel.value.name);
        }
        this.channel.complete();
    }

    public getChannel(): Channel {
        return this.channel.value;
    }

    public unBindEvent(event: string): void {
        if (!_.isNil(this.channel.value)) {
            this.channel.value.unbind(event);
        }
    }

    public unBindEvents(events: string[]): void {
        _.forEach(events, (event) => {
            this.unBindEvent(event);
        });
    }

    public bindEvents(bindOptions: PusherChannelOptions[]): void {
        _.forEach(bindOptions, (option) => {
            this.channel.value.bind(option.event, option.handler);
        });
    }

    protected unBindAll(): void {
        if (this.channel.value) {
            this.channel.value.unbind_all();
        }
    }

    private handlePusherTimeout() {
        this.destroyEvents();
    }
}
