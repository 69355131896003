import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApiConfig} from './ApiConfig';
import {OptionalHttpParams} from './thing.resource';
import {ApplianceCategoriesResponse} from 'src/app/models/appliance';

@Injectable()
export class ApplianceResource {

  constructor(private http: HttpClient) {
  }

  public getCategories(thingId: string, params?: OptionalHttpParams): Observable<ApplianceCategoriesResponse> {
    return this.http.get<ApplianceCategoriesResponse>(`${ApiConfig.CORE_URL}/things/${thingId}/appliance_categories`, {params});
  }

  public getAllCategories(): Observable<ApplianceCategoriesResponse> {
    return this.http.get<ApplianceCategoriesResponse>(`${ApiConfig.CORE_URL}/appliance_categories`);
  }

}
