<span [ngSwitch]="batteryLevel"
      matTooltip="{{getBatteryLevel().label}}"
      [ngClass]="getBatteryLevel().css">
			<ng-template [ngSwitchCase]="'low'">
			<svg xmlns="http://www.w3.org/2000/svg"
				 width="16"
				 height="16"
				 fill="currentColor"
				 class="bi bi-battery"
				 viewBox="0 0 16 16">
  <path d="M0 6a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6zm2-1a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H2zm14 3a1.5 1.5 0 0 1-1.5 1.5v-3A1.5 1.5 0 0 1 16 8z"/>
</svg>
			</ng-template>
			<ng-template [ngSwitchCase]="'mid'">
				<svg xmlns="http://www.w3.org/2000/svg"
					 width="16"
					 height="16"
					 fill="currentColor"
					 class="bi bi-battery-half"
					 viewBox="0 0 16 16">
  <path d="M2 6h5v4H2V6z"/>
  <path d="M2 4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H2zm10 1a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h10zm4 3a1.5 1.5 0 0 1-1.5 1.5v-3A1.5 1.5 0 0 1 16 8z"/>
</svg>
			</ng-template>
			<ng-template [ngSwitchCase]="'high'">
			<svg xmlns="http://www.w3.org/2000/svg"
				 width="16"
				 height="16"
				 fill="currentColor"
				 class="bi bi-battery-full"
				 viewBox="0 0 16 16">
  <path d="M2 6h10v4H2V6z"/>
  <path d="M2 4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H2zm10 1a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h10zm4 3a1.5 1.5 0 0 1-1.5 1.5v-3A1.5 1.5 0 0 1 16 8z"/>
</svg>
			</ng-template>
			<ng-template [ngSwitchCase]="'external_power_supply'">
				<svg xmlns="http://www.w3.org/2000/svg"
					 width="16"
					 height="16"
					 fill="currentColor"
					 class="bi bi-battery-charging"
					 viewBox="0 0 16 16">
  <path d="M9.585 2.568a.5.5 0 0 1 .226.58L8.677 6.832h1.99a.5.5 0 0 1 .364.843l-5.334 5.667a.5.5 0 0 1-.842-.49L5.99 9.167H4a.5.5 0 0 1-.364-.843l5.333-5.667a.5.5 0 0 1 .616-.09z"/>
  <path d="M2 4h4.332l-.94 1H2a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h2.38l-.308 1H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2z"/>
  <path d="M2 6h2.45L2.908 7.639A1.5 1.5 0 0 0 3.313 10H2V6zm8.595-2-.308 1H12a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H9.276l-.942 1H12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.405z"/>
  <path d="M12 10h-1.783l1.542-1.639c.097-.103.178-.218.241-.34V10zm0-3.354V6h-.646a1.5 1.5 0 0 1 .646.646zM16 8a1.5 1.5 0 0 1-1.5 1.5v-3A1.5 1.5 0 0 1 16 8z"/>
</svg>
			</ng-template>
		</span>
