import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {finalize, map, skipWhile, tap} from 'rxjs/operators';
import {ApplianceCategory} from 'src/app/models/appliance_category';
import {ApplianceResource} from '../resources/appliance.resource';
import {OptionalHttpParams} from '../resources/thing.resource';
import {ApplianceDemoResource} from '../demo-resources/appliance.demo.resource';

@Injectable()
export class ApplianceCategoryService {
  private readonly STORAGE_KEY = 'applianceCategories';
  private categoriesLoaded = false;
  private loadedCategoriesSubject: BehaviorSubject<ApplianceCategory[]> = new BehaviorSubject(null);
  private isLoadingCategories = false;
  private resource: ApplianceResource | ApplianceDemoResource;

  constructor(private readonly applianceResource: ApplianceResource,
              private readonly applianceDemoResource: ApplianceDemoResource) {
    this.resource = applianceResource;

  }


  public toggleDemo(state: boolean): void {
    this.resource = state ? this.applianceDemoResource : this.applianceResource;
  }


  public getCategories(thingId: string, params?: OptionalHttpParams): Observable<ApplianceCategory[]> {
    if (!this.categoriesLoaded || params) {
      return this.resource.getCategories(thingId, params)
        .pipe(
          map((res) => res.appliance_categories),
          tap((res) => localStorage.setItem(this.STORAGE_KEY, JSON.stringify(res))),
          finalize(() => this.categoriesLoaded = true)
        );
    } else {
      return of(JSON.parse(localStorage.getItem(this.STORAGE_KEY)));
    }
  }

  public getAllCategories(forceLoad = false): Observable<ApplianceCategory[]> {
    if (!this.loadedCategoriesSubject.getValue() && !this.isLoadingCategories || forceLoad) {
      this.isLoadingCategories = true;
      this.resource.getAllCategories()
        .pipe(
          map((res) => res.appliance_categories),
          finalize(() => this.isLoadingCategories = false)
        )
        .subscribe((res) => {
          this.loadedCategoriesSubject.next(res);
          this.loadedCategoriesSubject.complete();
        });
    }

    const cats = this.loadedCategoriesSubject.getValue();
    if (!forceLoad && cats) {
      return of(cats);
    } else {
      return this.loadedCategoriesSubject.asObservable()
        .pipe(skipWhile((res) => res === null));
    }
  }

}
