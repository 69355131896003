<ng-container *ngIf="incidents.length; else noIncidents">

	<mat-list>
		<mat-list-item
				*ngFor="let incident of incidents; trackBy: trackByFn">
			<div tappable
				 class=" incident-item">
				<div class="container-fluid">
					<div class="row align-items-center  py-2 mb-2">
						<div class="col-2 px-0">
							<div class="row">
								<div class="col-auto mx-auto">
									<ng-container *ngIf="incident.state==='processing'; else notProcessing">
										<mat-spinner></mat-spinner>
									</ng-container>
									<ng-template #notProcessing>
										<div class="col-auto px-0">
											<ng-container *ngIf="incident.state !== 'snoozed'; else snoozed">
												<img src="../../../../assets/images/incidents-icons/{{ recentTypes[incident.type]?.icon }}"
													 class="incident-icon"
													 alt=""/>
											</ng-container>
											<ng-template #snoozed>
												<div class="snooze_icon dnd-mode p-2">
													<i class="icon-notifications"></i>
												</div>
											</ng-template>
										</div>
									</ng-template>
								</div>
							</div>
							<div class="row"
								 *ngIf="incident.type === 'water_leak'">
								<div class="col-12 incident-level-label text-center">
									{{incident.severity | uppercase}}
								</div>
							</div>
						</div>
						<div class="col-8">
							<p class="incident-name m-0 text-wrap"
							   matLine>{{incident.title}}</p>
							<p class="incident-payload m-0"
							   *ngIf="incident.payload">
                                <span [ngSwitch]="incident.type"
									  class="font-weight-normal">
                                    <ng-container *ngSwitchCase="'high_volume_alert'">
                                       <span matLine>Volume: {{ incident.payload.volume | liters}}
										   <span class="l-font">l</span></span>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'long_flow_alert'">
										<span matLine>Duration: {{ formatDuration(incident.payload.duration)}}
											min</span>
                                    </ng-container>
                                </span>
							</p>
							<p class="incident-time m-0"
							   matLine>
								{{readableTime(incident.detected_at)}}</p>
						</div>
						<div class="col-2">
							<button mat-button
									(click)="goToDetails(incident.id)">Details
							</button>
						</div>
					</div>
				</div>

			</div>
		</mat-list-item>
	</mat-list>
	<button mat-button
			*ngIf="showNextPage"
			class="btn btn-primary"
			(click)="loadNextPage()">Next page
	</button>

</ng-container>
<ng-template #noIncidents>
	<div class="row h-100 blue-gradient-bg mx-0">
		<div class="col-10 m-auto text-center">
			<h2 class="mt-4">Hurray!</h2>
			<p class="p-0">There are no incidents in this list</p>
		</div>
	</div>
</ng-template>
