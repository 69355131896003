import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RegexMatcher} from '../../../common/regex/RegexMatcher';
import {Router} from '@angular/router';
import * as _ from 'lodash';
import {AuthenticationService} from '../../../providers/services/authenthication.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordComponent implements OnInit {
    public password_reset = false;
    public error = '';
    public passwordForm: FormGroup;

    constructor(private authService: AuthenticationService,
                private fb: FormBuilder,
                private cdRef: ChangeDetectorRef,
                private router: Router) {

    }

    ngOnInit(): void {
        this.buildForm();
    }

    public onSubmit(): void {
        if (!this.passwordForm.invalid) {
            this.authService.requestPasswordReset(this.passwordForm.get('email').value).subscribe(
                    (res: any) => {
                        this.password_reset = true;
                        this.cdRef.detectChanges();
                    },
                    (err) => {
                        this.error = _.get(err, 'error.message', err);
                        this.cdRef.detectChanges();
                    }
            );
        } else {
            this.error = 'Provided input is incorrect';
            this.cdRef.detectChanges();
        }
    }

    public goToLogin(): void {
        this.router.navigate(['auth']);
    }

    private buildForm() {
        this.passwordForm = this.fb.group({
            email: ['', Validators.compose([Validators.required, Validators.pattern(RegexMatcher.EMAIL_REGEX)])],
        });
    }
}
