<app-widget-frame title="Average water temperature" [value]="getAverageTemp()"
                  [unit]="unit"
                  >
    <app-line-chart [warnValue]="freezeWaterTempAlert"
                    [seriesData]="seriesData"
                    [range]="range"
                    unit="°C"
                    [chartXMax]="chartXMax"
                    [chartXMin]="chartXMin"
                    [labels]="labels"></app-line-chart>
</app-widget-frame>
