import {AuthGuardService} from '../providers/services/auth-guard.service';
import {DashboardComponent} from './dashboard/dashboard.component';
import {PropertyComponent} from './property/property.component';
import {PropertiesComponent} from './properties/properties.component';
import {PressureTestsComponent} from './pressure-tests/pressure-tests.component';
import {ThingsSummaryComponent} from './things-summary/things-summary.component';
import {SettingsComponent} from './settings/settings.component';
import {IncidentsPageComponent} from './incidents/incidents-page/incidents-page.component';
import {IncidentPageComponent} from './incidents/incident-page/incident-page.component';
import {StatsComponent} from './stats/stats.component';

export const ModulesRoutes = [
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    },
    {
        path: '',
        canActivate: [AuthGuardService],
        children: [
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full',
            },
            {
                path: 'dashboard',
                component: DashboardComponent
            },
            {
                path: 'property',
                redirectTo: 'dashboard',
                pathMatch: 'full',
            },
            {
                path: 'property/:propertyId',
                component: PropertyComponent
            },
            {
                path: 'properties',
                component: PropertiesComponent
            },
            {
                path: 'stats',
                component: StatsComponent
            },
            {
                path: 'incidents',
                component: IncidentsPageComponent
            },
            {
                path: 'incident/:propertyId/:incidentId',
                component: IncidentPageComponent
            },
            {
                path: 'sonics',
                component: ThingsSummaryComponent
            },
            {
                path: 'pressure-tests',
                component: PressureTestsComponent
            },
            {
                path: 'settings',
                component: SettingsComponent
            }
        ]
    }
];
