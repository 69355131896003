<nav class="navbar navbar-vertical fixed-left navbar-expand-xl navbar-light"
     id="sidebar">
    <div class="container-fluid">
        <!-- Toggler -->
        <button aria-controls="sidebarCollapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
                class="navbar-toggler order-1"
                data-target="#sidebarCollapse"
                data-toggle="collapse"
                (click)="menuOpened=!menuOpened"
                type="button">
            <span class="navbar-toggler-icon"></span>
        </button>
        <!-- Brand -->
        <a
            [ngClass]="!platform === true ? 'navbar-brand' : 'navbar-brand-aquilar'"
            class="navbar-brand mb-5 order-2"
            routerLink="/">
            <img alt="..."
                 class="navbar-brand-img logo mx-lg-auto ml-auto"
                 [src]="logo">
        </a>

        <span class="version order-4 order-xl-last">{{ version }}</span>

        <!-- Collapse -->
        <div class="collapse navbar-collapse order-xl-3 order-5"
             [ngClass]="{'show': menuOpened}"
             id="sidebarCollapse">
            <!-- Navigation -->
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link"
                       routerLinkActive="active"
                       routerLink="/dashboard">
                        <i class="fe fe-command"></i> Dashboard
                    </a>
                </li>
            </ul>
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link"
                       routerLinkActive="active"
                       routerLink="/properties">
                        <i class="fe fe-home"></i> Properties
                    </a>
                </li>
            </ul>
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link"
                       routerLinkActive="active"
                       routerLink="/incidents">
                        <i class="fe fe-alert-triangle"></i> Incidents
                    </a>
                </li>
            </ul>
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link"
                       routerLinkActive="active"
                       routerLink="/sonics">
                        <i class="fe fe-droplet"></i> Devices
                    </a>
                </li>
            </ul>
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link"
                       routerLinkActive="active"
                       routerLink="/pressure-tests">
                        <i class="fe fe-activity"></i> Pressure tests
                    </a>
                </li>
            </ul>
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link"
                       routerLinkActive="active"
                       routerLink="/stats">
                        <i class="fe fe-bar-chart"></i> Stats
                    </a>
                </li>
            </ul>
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link"
                       routerLinkActive="active"
                       routerLink="/settings">
                        <i class="fe fe-settings"></i> Settings
                    </a>
                </li>
            </ul>
            <div class="mt-auto"></div>
            <div class="navbar-user d-none d-md-flex"
                 id="sidebarUser">
                <button (click)="signOut()"
                        class="btn btn-link">
                    <i class="fe fe-log-out"></i>
                    Logout
                </button>
            </div>
        </div> <!-- / .navbar-collapse -->
    </div>
</nav>
<ng-content></ng-content>
<div *ngIf="demoMode"
     class="demo-mode-ribbon">
    <div class="demo-mode-ribbon-inner">
        SANDBOX
    </div>
</div>
