<div class="card-body">
    <app-table
        [rows]="rows"
        [externalPaging]="true"
        [externalSorting]="true"
        [columns]="columns"
        [tableSettings]="tableSettings"
        [selectionEnabled]="false"
        (fetchPage)="fetchPage($event)"
    >
    </app-table>

    <ng-template
        #titleColumn
        ngx-datatable-cell-template
        let-row="row"
        let-value="value"
    >
        <img
            src="../../../assets/images/incidents-icons/{{
                incidentsTypes[row.type].icon
            }}"
            class="incident-icon"
            alt=""
        />
        {{ value }}
    </ng-template>
    <ng-template
        #statusColumn
        ngx-datatable-cell-template
        let-row="row"
        let-value="value"
    >
        <span
            class="badge badge-warning"
            [ngClass]="{
                'badge-warning': value === 'active',
                'badge-light': value === 'closed'
            }"
        >{{ value }}</span
        >
    </ng-template>
    <ng-template
        #severityColumn
        ngx-datatable-cell-template
        let-row="row"
        let-value="value"
    >
        <div class="row align-items-center">
            <div class="col-auto">
                <div
                    class="state-indicator"
                    [ngClass]="[incidentColor[value]]"
                ></div>
            </div>
            <div class="col p-0">
                {{ value }}
            </div>
        </div>
    </ng-template>
    <ng-template #additionalColumn ngx-datatable-cell-template let-incident="row">
        <div *ngIf="checkIfHasPayload(incident.payload)">
            <div [ngSwitch]="incident.type" class="font-weight-normal">
                <ng-container *ngSwitchCase="'low_battery_level'">
                    <div>
                        <span class="label">Battery </span>
                        <strong
                            class="value">{{ incident.payload.battery / 1000 }}</strong>
                        <strong class="l-font">V</strong><br/>
                    </div>
                    <div>
                        <span class="label">Motor on </span>
                        <strong
                            class="value">{{ incident.payload.average_battery_when_motor_on / 1000 }}</strong>
                        <strong class="l-font">V</strong><br/>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'low_water_temperature'">
                    <div>
                        <span class="label">Water temperature </span>
                        <strong
                            class="value">{{ incident.payload.water_temp | number : '1.1-1' }}</strong>
                        <strong class="l-font">&deg;C</strong><br/>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'health_check_failed'">

                </ng-container>
                <ng-container *ngSwitchCase="'pressure_test_failed'">
                    <div>
                        <span class="label">Average pressure </span>
                        <strong
                            class="value">{{ incident.payload.avg_pressure / 1000 | number: '1.2-2' }}</strong>
                        <strong class="l-font">bar</strong><br/>
                    </div>
                    <div>
                        <span class="label">Pressure change </span>
                        <strong
                            class="value">
                            <ng-container *ngIf="incident.payload.percentage_tendency>0">+</ng-container>
                            {{ incident.payload.percentage_tendency | number: '1.0-0' }}</strong>
                        <strong
                            class="l-font">%</strong><br/>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'advanced_valve_misalignment'">
                    <ng-container *ngIf="incident.payload.battery_level">
                        <div>
                            <span class="label">Power status </span>
                            <span class="value">
                                        <app-battery-level
                                            [batteryLevel]="incident.payload.battery_level"></app-battery-level>
                                    </span>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'backup_battery_usage'">
                    <ng-container *ngIf="incident.payload.battery_level">
                        <div>
                            <span class="label">Power status </span>
                            <span class="value">
                                        <app-battery-level
                                            [batteryLevel]="incident.payload.battery_level"></app-battery-level>
                                    </span>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'long_flow_alert'">
                    <ng-container *ngTemplateOutlet="leakDetails"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'high_volume_alert'">
                    <ng-container *ngTemplateOutlet="leakDetails"></ng-container>

                </ng-container>
                <ng-template #leakDetails>
                    <div>
                        <span class="value">{{ incident.payload.volume | liters }}</span>
                        <span class="l-font">l</span><br/>
                        <span class="label">Volume</span>
                    </div>
                    <div>
                        <span class="value">{{ formatDuration(incident.payload.duration) }}</span> <span
                        class="l-font">min</span><br/>
                        <span class="label">Duration</span>
                    </div>
                    <div>
                        <span class="value">{{ incident.payload.max | liters }}</span>
                        <span class="l-font">l/min</span><br/>
                        <span class="label">Max flow</span>
                    </div>
                </ng-template>


            </div>
        </div>
    </ng-template>
    <ng-template
        #actionsColumn
        ngx-datatable-cell-template
        let-row="row"
        let-value="value">
        <a (click)="openSingleIncident( row)"
           class="btn btn-primary btn-md px-5 ml-auto"
        >Open</a>
    </ng-template>
</div>
