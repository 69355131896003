import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../providers/services/authenthication.service';
import {DemoService} from '../../../providers/services/demo.service';
import * as data from '../../../../../package.json';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-layout-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {

    public logo = !environment.aguilar ? 'assets/images/logo.svg' : 'assets/images/aquilar-logo.jpeg';
    public platform = environment.aguilar;
    public version;
    public menuOpened = false;
    public demoMode = false;

    constructor(private authService: AuthenticationService,
                private demoService: DemoService) {
    }

    ngOnInit(): void {
        this.demoMode = this.demoService.demoMode;
        const appInfo = data;
        this.version = appInfo.version;
    }

    public signOut(): void {
        this.authService.signOut();
    }
}
