import {Injectable} from '@angular/core';
import {HeroEvents} from './pusher.config';
import {GeneralPusherService} from './general.pusher.service';
import {
    PusherIncidentCreationMessage,
    PusherIncidentСhangeMessage,
    PusherStateChangeMessage,
    PusherTelemetryMessage
} from '../../models/pusher';
import Pusher from 'pusher-js';

@Injectable()
export class PusherService extends GeneralPusherService {
    constructor() {
        super();
    }

    public onValveFlow(callback: (message: PusherTelemetryMessage) => any) {
        this.channel.value.bind(HeroEvents.valveFlow, callback);
    }

    public onThingState(callback: (message: PusherStateChangeMessage) => any) {
        this.channel.value.bind(HeroEvents.stateChange, callback);
    }

    public onIncidentCreate(callback: (message: PusherIncidentCreationMessage) => any) {
        this.channel.value.bind(HeroEvents.incidentCreation, callback);
    }

    public onLifecycel(callback: (message: Pusher) => any) {
        this.channel.value.bind(HeroEvents.lifecycle, callback);
    }

    public onIncidentChange(callback: (message: PusherIncidentСhangeMessage) => any) {
        this.channel.value.bind(HeroEvents.incidentChange, callback);
    }


}
