<app-layout-auth>
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-12 col-md-5 col-xl-4 my-5">

				<!-- Heading -->
				<h1 class="display-4 text-center mb-3">
					Password reset
				</h1>
				<ng-container
						*ngIf="!password_reset; else passwordSent">
					<!-- Subheading -->
					<p class="text-muted text-center mb-5">
						Enter your email to get a password reset link.
					</p>
					<div *ngIf="error !== ''"
						 class="alert alert-danger"
						 role="alert">
						{{error}}
					</div>
					<!-- Form -->
					<form [formGroup]="passwordForm">

						<!-- Email address -->
						<div class="form-group">

							<!-- Label -->
							<label>Email Address</label>

							<!-- Input -->
							<input class="form-control"
								   formControlName="email"
								   placeholder="name@address.com"
								   type="email">

						</div>

						<!-- Submit -->
						<button (click)="onSubmit()"
								class="btn btn-lg btn-block btn-primary mb-3">
							Reset Password
						</button>

						<!-- Link -->
						<div class="text-center">
							<small class="text-muted text-center">
								Remember your password? <a routerLink="/auth">Log in</a>.
							</small>
						</div>


					</form>
				</ng-container>
				<ng-template #passwordSent>
					<div class="row">
						<div class="col-12 text-center">
							<p>
								Password reset instruction has been sent to your mailbox
							</p>
							<button class="btn btn-primary px-4"
									routerLink="/auth"
									type="button">
								Login using your new password
							</button>
						</div>
					</div>
				</ng-template>
			</div>
		</div> <!-- / .row -->
	</div> <!-- / .container -->
</app-layout-auth>
