import {ChangeDetectionStrategy, Component, OnDestroy} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-snooze-modal',
    templateUrl: './snooze-modal.component.html',
    styleUrls: ['./snooze-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnoozeModalComponent implements OnDestroy {

    public dndOptions = [
        {
            label: '1 hour',
            value: 60
        },
        {
            label: '2 hours',
            value: 120
        },
        {
            label: '5 hours',
            value: 300
        },
    ];
    public dndTime = this.dndOptions[0].value;
    private backButtonSub: Subscription;

    constructor(private dialogRef: MatDialogRef<SnoozeModalComponent>) {
    }

    public ngOnDestroy(): void {
        if (this.backButtonSub) {
            this.backButtonSub.unsubscribe();
        }
    }

    public confirm(): void {
        this.dialogRef.close(this.dndTime);
    }
}
