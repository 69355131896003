import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ProvidersModule} from './providers/providers.module';
import {ModulesModule} from './modules/modules.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthenticationService} from './providers/services/authenthication.service';
import {HlCommonModule} from './common/hl-common.module';
import {PipesModule} from './pipes/pipes.module';
import {FormsModule} from '@angular/forms';
import {PartsModule} from './modules/parts/parts.module';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {ChartsModule} from 'ng2-charts';
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from 'ngx-google-analytics';
import {environment} from '../environments/environment';
import {NgxEchartsModule} from 'ngx-echarts';
import {CustomDateAdapter} from './providers/custom/custom.date.adapter';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ProvidersModule,
        HlCommonModule,
        PipesModule,
        FormsModule,
        PartsModule,
        NgxMaterialTimepickerModule,
        ModulesModule,
        MatSnackBarModule,
        BrowserAnimationsModule,
        MatNativeDateModule,
        ChartsModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts')
        }),
        NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsTrackingCode),
        NgxGoogleAnalyticsRouterModule

    ],
    providers: [
        {provide: DateAdapter, useClass: CustomDateAdapter},
        {provide: MAT_DATE_LOCALE, useValue: 'en-GB'}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(authService: AuthenticationService) {
        authService.checkToken();
    }
}
