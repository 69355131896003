import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {NotificationSettings} from '../../../models/notification-settings';
import {PropertyService} from '../../../providers/services/property.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {forkJoin} from 'rxjs';
import {Property} from 'src/app/models/property';
import * as _ from 'lodash';
import {predefinedHighVolumeOptions, predefinedLongFlowOptions} from '../../settings/settings.config';

@Component({
    selector: 'app-edit-notification-settings-dialog',
    templateUrl: './edit-notification-settings-dialog.component.html',
    styleUrls: ['./edit-notification-settings-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditNotificationSettingsDialogComponent implements OnInit {

    public predefinedHighVolumeOptions = predefinedHighVolumeOptions;
    public predefinedLongFlowOptions = predefinedLongFlowOptions;
    public notificationSettings: NotificationSettings;
    public boolProperties: [{ name: string; key: string }];

    constructor(private cdRef: ChangeDetectorRef,
                @Inject(MAT_DIALOG_DATA) private data: {
                    notificationSettings: NotificationSettings;
                    propertyData: Property[];
                    boolProperties: [{ name: string; key: string }];
                },
                private dialogRef: MatDialogRef<EditNotificationSettingsDialogComponent>,
                private propertyService: PropertyService) {
    }

    ngOnInit(): void {
        this.notificationSettings = this.data.notificationSettings;
        this.boolProperties = this.data.boolProperties;
    }

    public save(): void {
        this.updateNotificationSettings();
    }

    public close(refresh = false): void {
        this.dialogRef.close(refresh);
    }

    public restoreToDefault() {

        for (const property of this.data.propertyData) {
            this.propertyService.restoreDefaultNotificationSettings(property.id)
                .subscribe((res) => {
                    this.notificationSettings = res;
                    this.close(true);
                });
        }
    }

    private updateNotificationSettings(): void {
        const observableList = [];
        for (const property of this.data.propertyData) {
            const notificationSettings = _.clone(this.data.notificationSettings);
            _.update(notificationSettings, 'property_id', () => property.id);

            observableList.push(
                this.propertyService.updateNotificationSettings(property.id, notificationSettings)
            );
        }
        forkJoin({
            ...observableList
        }).subscribe(
            () => {
                this.close(true);
            },
        );

    }

}
