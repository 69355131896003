import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PropertyService} from '../../providers/services/property.service';
import {PropertySettings} from '../../models/property.settings';
import {Property} from '../../models/property';
import {Thing, ThingsResponse} from '../../models/thing';
import * as _ from 'lodash';

@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyComponent implements OnInit {

  public propertySettings: PropertySettings;
  public property: Property;
  public thing: Thing;
  public propertyId: string;

  constructor(private route: ActivatedRoute,
              private cdRef: ChangeDetectorRef,
              private propertyService: PropertyService) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.propertyId = params.propertyId;
      this.fetchProperty();
      this.loadSettings();
    });

  }

  private loadSettings(): void {
    this.propertyService.getSettings(this.propertyId)
      .subscribe((settings) => {
        this.propertySettings = settings;
        this.cdRef.detectChanges();
      });
  }

  private fetchProperty() {
    this.propertyService.getProperty(this.propertyId)
      .subscribe((property) => {
        this.property = property.property;
        this.fetchThings();
      });
  }

  private fetchThings(): void {
    this.propertyService.getThingsByPropertyId(this.propertyId)
      .subscribe((thingsResponse: ThingsResponse) => {
        this.thing = _.head(thingsResponse.things);
        this.cdRef.detectChanges();
      });
  }

}
