import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ThingState } from 'src/app/models/pusher';
import { Thing } from 'src/app/models/thing';

@Injectable({
  providedIn: 'root'
})
export class ValveStateService {

    public success = ['open', 'closed'];
    public danger = ['manual_movement_after_open', 'manual_movement_after_closed','failed_to_open', 'failed_to_close','manual_movement'];
    public secondary = ['opening', 'closing', 'requested_open', 'requested_closed', null];
  constructor() { }

  public returnValveState(thing: Thing, shorStrings = false): string {
        switch (thing.state) {
            case 'open':
                return 'Open';
            case 'closed':
                return 'Closed';
            case 'requested_open':
                return 'Requested open';
            case 'requested_closed':
                return 'Requested close';
            case 'opening':
                return 'Opening valve';
            case 'closing':
                return 'Closing valve';
            case 'manual_movement_after_open':
                return 'Valve not fully closed';
            case 'manual_movement_after_closed':
                return 'Valve not fully opened';
            case 'failed_to_close':
                if(!shorStrings){
                    if(thing.battery_level === 'low'){
                        return 'Batteries too low to close the valve';
                    }
                    return `Sonic didn't close`;
                }
                if(thing.battery_level === 'low'){
                    return 'Batteries too low';
                }
                return `Sonic didn't close`;
            case 'failed_to_open':
                if(!shorStrings){
                    if(thing.battery_level === 'low'){
                        return 'Batteries too low to open the valve';
                    }
                    return `Sonic didn't open`;
                }
                if(thing.battery_level === 'low'){
                    return 'Batteries too low ';
                }
                return `Sonic didn't open`;
            case 'manual_movement':
                if(!shorStrings){
                    return 'Valve position unknown. Actuate to realign';
                }
                return 'Valve position unknown';
        }
        return 'Unknown';
    }

    public stateDanger(state: ThingState): boolean {
        return this.danger.includes(state);
    }

    public stateSucces(state: ThingState): boolean {
        return this.success.includes(state);
    }

    public stateSecondary(state: ThingState): boolean {
        return this.secondary.includes(state);
    }

    public showQuestionMark(state: ThingState): boolean {
        return this.danger.includes(state);
    }

    public tooltipText(thing: Thing): string{
        switch (thing.state) {
            case 'manual_movement_after_open':
                return 'Movement of Sonic’s valve was detected but Sonic was not fully closed. Did you want to close the valve?';
            case 'manual_movement_after_closed':
                return 'Movement of Sonic’s valve was detected but Sonic was not fully opened. Did you want to open the valve?';
            case 'failed_to_close':
                if(thing.battery_level === 'low'){
                    return 'Your Sonic had insufficient battery levels to close it’s valve. Please replace its batteries, plug the Sonic into mains power and try again. In an emergency, you can open Sonic with the manual handle.';
                }
                return `Sonic encountered an error when closing its valve. Our data suggests that this could be a mechanical fault. Please, try to close the valve from the app again. Or, contact support.
                 In an emergency, you can close Sonic with the manual handle.`;
            case 'failed_to_open':
                if(thing.battery_level === 'low'){
                    return 'Your Sonic had insufficient battery levels to open its valve. Please replace its batteries, plug the Sonic into mains power and try again. In an emergency, you can open Sonic with the manual handle.';
                }
                return `Sonic encountered an error when opening its valve. Our data suggests that this could be a mechanical fault. Please try to open the valve from the app again. Or, contact support.
                 In an emergency, you can open Sonic with the manual handle.`;
            case 'manual_movement':
                return 'Sonic could not verify the position of its valve. Please open or close the valve to resolve this.';
        }
    }
}

