<h2 mat-dialog-title
	class="text-center"
>Snooze incident</h2>
<mat-dialog-content>

	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				<p>Temporarily snooze incident? This is useful when you need time to investigate.</p>
			</div>
			<div class="col-10 mx-auto">
				<mat-form-field class="w-100">
					<mat-select class="w-100"
								[(ngModel)]="dndTime">
						<mat-option *ngFor="let option of dndOptions"
									[value]="option.value">
							{{ option.label }}
						</mat-option>
					</mat-select>
				</mat-form-field>

			</div>
		</div>
	</div>

</mat-dialog-content>
<mat-dialog-actions>
	<button mat-button
			class="alert-cancel-bt"
			mat-dialog-close><span>Cancel</span></button>
	<button mat-button
			(click)="confirm()">
		<span>Yes</span>
	</button>
</mat-dialog-actions>
