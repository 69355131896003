import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ConfirmDialogData} from '../../../models/confirm-dialog';
import {take} from 'rxjs/operators';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDialogComponent implements OnInit {

    private snackBarConfig = {
        duration: 1500
    };

    constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
                private snackBar: MatSnackBar,
                @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {
    }

    ngOnInit(): void {
        this.dialogRef.backdropClick()
                .pipe(take(1))
                .subscribe((event) => {
                    event.preventDefault();
                    this.noAction();
                });
    }

    public noAction(): void {
        if (this.data.noAction) {
            this.data.noAction();
        }
    }

    public yesAction(): void {
        if (this.data.yesAction) {
            this.data.yesAction();
        }
        if (this.data.snackBarMessage) {
            this.snackBarConfig = Object.assign(this.snackBarConfig, this.data.snackBarConfig);
            this.snackBar.open(this.data.snackBarMessage, null, this.snackBarConfig);
        }
    }
}
