import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './login/login.component';
import {LayoutsModule} from '../layouts/layouts.module';
import {RouterModule} from '@angular/router';
import {routes} from './auth.routing';
import {ReactiveFormsModule} from '@angular/forms';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';


@NgModule({
    declarations: [
        LoginComponent,
        ForgotPasswordComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        LayoutsModule,
        ReactiveFormsModule
    ]
})
export class AuthModule {
}
