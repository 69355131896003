import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LoginResponse} from '../../models/login.response';
import {ApiConfig, gatewayUrl} from './ApiConfig';
import {ValidateTokenResponse} from '../../models/validateToken.response';
import {EmailExistsResponse, User, UserProfile, UserResponse} from '../../models/user';

@Injectable()
export class UserResource {

    constructor(private http: HttpClient) {
    }

    public signInUser(email: string, password: string): Observable<LoginResponse> {
        const url = `${ApiConfig.USER_URL}/auth/sign_in`;

        return this.http.post<LoginResponse>(url, {
            email,
            password,
        });
    }

    public signInUserWithFacebook(accessToken: string): Observable<LoginResponse> {
        const url = `${gatewayUrl}/oauth/facebook/finish`;

        return this.http.post<LoginResponse>(url, {access_token: accessToken});
    }


    public signInUserWithApple(firstName: string, lastName: string, jwt: string, userIdentity: string): Observable<LoginResponse> {
        const url = `${gatewayUrl}/oauth/apple/finish`;

        return this.http.post<LoginResponse>(url, {first_name: firstName, last_name: lastName, jwt, user_identity: userIdentity});
    }

    public signInUserWithGoogle(accessToken: string): Observable<LoginResponse> {
        const url = `${gatewayUrl}/oauth/google/finish`;

        return this.http.post<LoginResponse>(url, {access_token: accessToken});
    }

    public signOutUser(): Observable<any> {
        const url = `${ApiConfig.USER_URL}/auth/sign_out`;

        return this.http.delete(url);
    }


    public validateToken(): Observable<ValidateTokenResponse> {
        const url = `${ApiConfig.USER_URL}/auth/validate_token`;

        return this.http.get<ValidateTokenResponse>(url);
    }

    public updateUser(userId: string, payload: UserProfile): Observable<User> {
        const url = `${ApiConfig.USER_URL}/customer_app/users/${userId}`;
        return this.http.put<User>(url, {user: payload});
    }

    public refreshUserToken(oldToken = null): Observable<any> {
        const url = `${ApiConfig.USER_URL}/auth/refresh_token`;
        return this.http.put(url, null);
    }

    public signUpUser(email: string, password: string, firstName: string, lastName: string, phone: string): Observable<UserResponse> {
        const url = `${ApiConfig.CUSTOMER_URL}/register`;

        const data = {
            user: {
                email,
                password,
                first_name: firstName,
                last_name: lastName,
                phone,
            },
        };

        return this.http.post<UserResponse>(url, data);
    }

    public activateUser(code: string): Observable<UserResponse> {
        const url = `${ApiConfig.CUSTOMER_URL}/activate`;

        const data = {
            activation_code: code,
        };

        return this.http.put<UserResponse>(url, data);
    }

    public requestResendActivationEmail(email: string): Observable<User> {
        const url = `${ApiConfig.CUSTOMER_URL}/resend_activation_email`;

        const data = {
            email,
        };

        return this.http.post<User>(url, data);
    }

    public checkIfEmailExists(email: string): Observable<EmailExistsResponse> {
        const url = `${ApiConfig.CUSTOMER_URL}/email_exists?email=${email}`;

        return this.http.get<EmailExistsResponse>(url);
    }

    public requestCancelAccount(): Observable<string> {
        const url = `${ApiConfig.CORE_URL}/users/cancel_account`;

        return this.http.post<string>(url, null);
    }
}
