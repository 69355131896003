import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {PusherService} from '../../../common/pusher/pusher.service';
import {
    BatteryPayload,
    HealthCheckPayload,
    IncidentAction,
    IncidentDetails,
    IncidentUsagePayload
} from '../../../models/incident';
import {IncidentsService} from '../../../providers/services/incidents.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {recentTypes} from '../config';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
// @ts-ignore
import * as momentDurationFormatSetup from 'moment-duration-format';
import {TelemetryMessage} from '../../../models/pusher';
import {SnoozeModalComponent} from '../snooze-modal/snooze-modal.component';
import {ConfirmDialogComponent} from '../../parts/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-incident-details-dialog',
    templateUrl: './incident-details-dialog.component.html',
    styleUrls: ['./incident-details-dialog.component.scss'],
})
export class IncidentDetailsDialogComponent implements OnInit, OnDestroy {

    public recentTypes = recentTypes;
    public incidentDetailsStream: Observable<IncidentDetails>;
    private pusherAlreadyInitialised = true;

    constructor(private pusherService: PusherService,
                private matDialog: MatDialog,
                private cdRef: ChangeDetectorRef,
                @Inject(MAT_DIALOG_DATA) private data: { propertyId: string; incidentId: string; timezone: string },
                private dialogRef: MatDialogRef<IncidentDetailsDialogComponent>,
                private incidentService: IncidentsService) {
    }

    ngOnInit() {
        momentDurationFormatSetup(moment);
        if (!this.pusherService.getChannel()) {
            this.pusherAlreadyInitialised = false;
            this.pusherService.initPropertyChannel(this.data.propertyId);
        }
        this.fetchDetails();
        this.pusherService.onIncidentChange((message) => {
            if (this.data.incidentId === message.message.incident_id) {
                this.fetchDetails();
            }
        });
    }

    public ngOnDestroy(): void {
        if (!this.pusherAlreadyInitialised) {
            this.pusherService.destroyEvents();
        }
    }

    public convertDate(detected_at: string): string {
        const timezone = this.data.timezone || 'Europe/London';
        return moment.tz(detected_at, timezone).format('ll HH:mm');
    }

    public checkIfHasPayload(payload: TelemetryMessage | IncidentUsagePayload | HealthCheckPayload | BatteryPayload): boolean {
        return !(!payload || _.isEmpty(payload));
    }

    public formatDuration(duration: number): string {
        return moment.duration(duration, 'millisecond').format('m');
    }

    public sortActionButtons(buttonActions: IncidentAction[]): IncidentAction[] {
        return _.sortBy(buttonActions, [(o) => o.button_class.indexOf('btn-primary') >= 0 ? 0 : 1]);
    }

    public handleAction(action: IncidentAction): void {
        if (action.name === 'snooze') {
            this.showSnoozeModal();
        } else if (action.confirmation_required) {

            this.matDialog.open(ConfirmDialogComponent, {
                data: {
                    title: action.confirmation_required.title,
                    description: action.confirmation_required.body,
                    yesClass: ['btn-danger'],
                    yesAction: () => {
                        this.executeIncidentAction(action);
                    },
                    noAction: () => {
                    }
                }
            });
        } else {
            this.executeIncidentAction(action);
        }
    }

    private executeIncidentAction(action: IncidentAction) {
        this.incidentDetailsStream = this.incidentService.executeAction(this.data.propertyId, this.data.incidentId, action.name);
        this.cdRef.detectChanges();
    }

    private fetchDetails(): void {
        this.incidentDetailsStream = this.incidentService.getDetails(this.data.propertyId, this.data.incidentId);
    }

    private showSnoozeModal(): void {
        const ref = this.matDialog.open(SnoozeModalComponent, {
            data: {},
            width: '100%',
            maxWidth: '80vw',
            panelClass: 'mx-auto',
        });
        ref.afterClosed().subscribe((res: number) => {
            if (res) {
                const snoozedUntil = res > 0 ? moment().utc().add(res, 'm').format('YYYY-MM-DD HH:mm:ss') : null;

                this.incidentService.snooze(this.data.propertyId, this.data.incidentId, snoozedUntil)
                        .subscribe(() => {
                            this.fetchDetails();
                            this.cdRef.detectChanges();
                        });
            }
        });
    }
}
