import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit} from '@angular/core';
import {EChartsOption} from 'echarts';
import {chartDefaultOptions} from '../chart-defaults';
import * as _ from 'lodash';
import {StatsRange} from '../../../../models/aggregated-stats';
import {StatsService} from '../../../../providers/services/stats.service';

@Component({
    selector: 'app-water-usage-widget',
    templateUrl: './water-usage-widget.component.html',
    styleUrls: ['./water-usage-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WaterUsageWidget implements OnInit, OnChanges {

    @Input() series1Data: number[];
    @Input() series2Data: number[];
    @Input() labels: string[];
    @Input() limit: number;
    @Input() range: StatsRange;

    public chartOptions: EChartsOption;
    public sum: number;
    public minYAxisSize = 10;

    constructor(private cdRef: ChangeDetectorRef,
                private statsService: StatsService) {
    }

    ngOnInit() {
        this.setupOptions();
    }

    ngOnChanges() {
        this.setupOptions();
    }

    public getUsageLimit(): number {
        switch (this.range.value) {
            case 'week':
            case 'month':
                return this.limit * this.series1Data.length;
            case 'year':
                return this.limit * 365;
            case 'day':
            default:
                return this.limit;

        }
    }
    private setupOptions() {
        const tooltipBg = '#191A19';

        this.sum = _.sum(_.filter(this.series1Data, item => !_.isNaN(item)));

        const series = this.getSeries();
        this.chartOptions = _.merge(_.cloneDeep(chartDefaultOptions), {
            xAxis: {
                data: this.labels,
                // silent: false,
                axisLabel: {
                    color: '#A2A1A1',
                    formatter: (item, index) => this.statsService.formatXaxisLabel(item, index, this.series1Data.length)
                },
                boundaryGap: ['0%', '0%'],
                axisLine: {
                    show: false
                },
            },
            yAxis: {
                axisLabel: {
                    color: '#A2A1A1',
                    formatter: (item) => this.statsService.formatUsage(item),
                },
                minInterval: 5,
                max: _.max(this.series1Data) > this.minYAxisSize ? null : this.minYAxisSize
            },
            series,
            grid: {
                left: '5%',
                top: '5%',
                right: '15%',
                bottom: '10%',
            },
            tooltip: {
                trigger: 'axis',
                borderWidth: 0,
                backgroundColor: tooltipBg,
                textStyle: {
                    color: '#D9D8D8'
                },
                formatter: (item) => this.statsService.formatTooltipContent(item[1], 'l', this.range)
            }
        });
        this.cdRef.detectChanges();
    }


    private getSeries() {
        const series = [];
        let gap = '0';

        if (this.series2Data) {
            gap = '-100%';
            series.push({
                name: 'bar',
                type: 'bar',
                barMaxWidth: 15,
                barMinHeight: 7,
                data: this.series2Data,
                animationDelay: idx => idx * 10,
                itemStyle: {
                    borderRadius: [15, 15, 0, 0],
                    borderColor: '#D9D8D8',
                    color: 'transparent'
                },

            });
        }

        series.push({
            barGap: gap,
            name: 'bar',
            type: 'bar',
            data: this.series1Data,
            color: '#5D8DC0',
            barMaxWidth: 15,
            // barMinHeight: 5,
            animationDelay: idx => idx * 10,
            itemStyle: {
                borderRadius: [15, 15, 0, 0],
            },
        });

        return series;
    }

}
