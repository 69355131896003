import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Property, PropertyPaginatedResponse} from '../../models/property';
import * as _ from 'lodash';
import {TableComponent} from '../parts/table/table.component';
import {PropertyService} from '../../providers/services/property.service';
import {OptionalHttpParams} from '../../providers/resources/thing.resource';
import {tableSettings} from 'src/app/models/table-settings';
import {debounceTime, switchMap} from 'rxjs/operators';
import {forkJoin, Subject} from 'rxjs';
import {Thing, ThingsResponse} from '../../models/thing';

@Component({
    selector: 'app-things-summary',
    templateUrl: './things-summary.component.html',
    styleUrls: ['./things-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThingsSummaryComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild(TableComponent) table: TableComponent;
    @ViewChild('waterFlowColumn') waterFlowColumn: TemplateRef<any>;
    @ViewChild('actionsColumn') actionsColumn: TemplateRef<any>;
    @ViewChild('batteryColumn') batteryColumn: TemplateRef<any>;
    @ViewChild('wifiColumn') wifiColumn: TemplateRef<any>;
    @ViewChild('radioColumn') radioColumn: TemplateRef<any>;
    @ViewChild('stateColumn') stateColumn: TemplateRef<any>;

    public rows: Thing[];
    public columns = [];
    public tableSettings = _.cloneDeep(tableSettings);
    public search: string;

    private searchSubj = new Subject<void>();
    private properties: Property[];

    constructor(private propertyService: PropertyService,
                private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.searchSubj
            .pipe(debounceTime(300))
            .subscribe(() => {
                this.fetchPage();
            });
    }

    ngOnDestroy() {
        if (this.searchSubj) {
            this.searchSubj.unsubscribe();
        }
    }


    public ngAfterViewInit(): void {

        this.columns = [
            {prop: 'property_name', name: 'Property', flexGrow: 1.5, sortable: true, minWidth: 200},
            {prop: 'cached_telemetry.water_temp', name: 'Temp (°C)', flexGrow: 1, sortable: true, minWidth: 80},
            {prop: 'cached_telemetry.pressure', name: 'Pressure (mbar)', flexGrow: 1, sortable: true, minWidth: 80},
            {
                prop: 'cached_telemetry.water_flow',
                name: 'Water flow (l)',
                flexGrow: 1,
                sortable: true,
                cellTemplate: this.waterFlowColumn,
                minWidth: 80
            },
            {
                prop: 'battery_level',
                name: 'Battery',
                flexGrow: 1,
                cellTemplate: this.batteryColumn,
                sortable: false,
                minWidth: 80
            },
            {name: 'WiFi', flexGrow: 0.5, cellTemplate: this.wifiColumn, sortable: false, minWidth: 80},
            {name: 'Sonic radio', flexGrow: 0.8, cellTemplate: this.radioColumn, sortable: false, minWidth: 80},
            {name: 'Valve state', flexGrow: 1.5, cellTemplate: this.stateColumn, sortable: false, minWidth: 80},

            {
                cellTemplate: this.actionsColumn,
                name: 'Actions',
                sortable: false,
                maxWidth: 180,
                minWidth: 180,
                flexGrow: 0.5
            }
        ];
        this.fetchPage();
    }

    public fetchPage(params?: OptionalHttpParams): void {
        if (!this.table) {
            return;
        }
        if (this.search) {
            if (!params) {
                params = {};
            }
            params['search'] = this.search;
        }

        this.propertyService.getPropertiesListPaginated(params)
            .pipe(switchMap((response: PropertyPaginatedResponse) => {
                const thingSubs = [];
                this.properties = response.data;
                this.table.setPageParams({
                    page_number: response.page_number,
                    page_size: response.page_size,
                    total_entries: response.total_entries,
                    total_pages: response.total_pages
                });
                _.forEach(response.data, (property) => {
                    thingSubs.push(this.propertyService.getThingsByPropertyId(property.id));
                });
                return forkJoin(thingSubs);
            }))
            .subscribe((response: ThingsResponse[]) => {
                    this.rows = [];
                    _.forEach(response, (item, index) => {
                        _.forEach(item.things, (thing) => {
                            _.set(thing, 'property_name', this.properties[index].name);
                        });
                        this.rows.push(..._.filter(item.things, (thing) => thing.type !== 'siryn'));
                    });
                    this.table.isLoading = false;
                    this.cdRef.detectChanges();
                }
            );
    }


    /**
     * Triggers fetchPage with debounce
     */
    public triggerSearch(): void {
        this.searchSubj.next();
    }

    /**
     * Clears the search field and triggers a search.
     *
     * @function clearSearch
     * @returns
     */
    public clearSearch(): void {
        this.search = '';
        this.triggerSearch();
    }
}
