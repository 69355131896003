import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PropertyService} from '../../providers/services/property.service';
import {Thing, ThingsResponse} from '../../models/thing';
import * as _ from 'lodash';
import {HubService} from 'src/app/providers/services/hub.service';
import {Hub} from '../../models/hub';
import {ThingService} from '../../providers/services/thing.service';
import {PusherService} from '../../common/pusher/pusher.service';
import {OngoingEvent, PusherStateChangeMessage, PusherTelemetryMessage, StateChangeMessage, TelemetryMessage} from '../../models/pusher';
import {ConfirmDialogComponent} from '../parts/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {PropertySettings} from '../../models/property.settings';
import {TotalUsage} from '../../models/total.usage';
import {BehaviorSubject, interval, Observable, Subscription, timer} from 'rxjs';
import * as moment from 'moment-timezone';
import {WaterFlowTelemetryEvent} from '../../models/telemetry.event';
import {DemoService} from '../../providers/services/demo.service';
import {ThingDemoResource} from '../../providers/demo-resources/thing.demo.resource';
import {ValveStateService} from '../../providers/services/valve-state.service';

@Component({
    selector: 'app-thing',
    templateUrl: './thing.component.html',
    styleUrls: ['./thing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThingComponent implements OnInit, OnDestroy {

    @Input() propertyId: string;
    public thing: Thing;
    public currentState: string;
    public propertySettings: PropertySettings;
    public currentConnectionState = '';
    public totalUsageStream: Observable<TotalUsage>;
    public yearUsageStream: Observable<TotalUsage>;
    public waterIsFlowing = false;
    public isOngoingEvent = false;
    public lastOngoingEvent: OngoingEvent;
    public ongoingEventDuration: number;
    private durationSub: Subscription;
    private lastOngoingTimeoutSub: Subscription;
    private pusherInitialised = false;
    private lastInfluxTime = 0;
    private waterFlowTelemetrySubject = new BehaviorSubject<WaterFlowTelemetryEvent>(null);

    constructor(private propertyService: PropertyService,
                private hubService: HubService,
                private matDialog: MatDialog,
                private demoService: DemoService,
                private thingDemoResource: ThingDemoResource,
                private thingService: ThingService,
                private pusherService: PusherService,
                private cdRef: ChangeDetectorRef,
                private valveStateService: ValveStateService) {
    }

    ngOnInit(): void {

        this.fetchThings();

        this.initPusher();
    }

    public ngOnDestroy(): void {
        this.pusherService.destroyEvents();
    }

    public stopDurationCount(): void {
        if (this.durationSub) {
            this.durationSub.unsubscribe();
        }
    }

    public waterFlowTelemetryChange(): Observable<WaterFlowTelemetryEvent> {
        return this.waterFlowTelemetrySubject.asObservable();
    }

    public getWifiStrength(thing: Thing | Hub): { label: string; css: string } {
        return this.thingService.getWifiStrength(thing);
    }

    public getBatteryLevel(thing: Thing): { label: string; css: string } {
        return this.thingService.getBatteryLevel(thing.battery_level);
    }

    public getThingConnectionStatus(thing: Thing): string {
        return this.thingService.getThingConnectionStatus(thing);
    }

    public beforeChangeState(action: string, thingId: string): void {

        this.matDialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Valve state',
                description: `Are you sure you want to ${action==='open' ? 'open':'close'} the valve?`,
                yesClass: ['btn-danger'],
                yesAction: () => {
                    this.changeState(action, thingId);
                },
                noAction: () => {
                }
            }
        });
    }

    public changeState(action: string, thingId: string): void {

        this.thingService.changeThingState(thingId, action==='open' ? 'open':'closed')
            .subscribe((res) => {
                this.currentState = res.thing.state;
                this.fetchThings();
                this.cdRef.detectChanges();
            });
    }

    public returnValveState(thing: Thing): string {
        return this.valveStateService.returnValveState(thing);
    }

    public buttonsLayout(thing: Thing): string {
        const manualStates = ['manual_movement_after_closed', 'manual_movement', 'manual_movement_after_open'];
        const faileStates = ['failed_to_open', 'failed_to_close'];

        switch (true) {
            case (manualStates.includes(thing.state)):
                return 'manualState';
            case (faileStates.includes(thing.state)):
                if(thing.battery_level === 'low'){
                    return 'failedWithLowBattery';
                    }
                    return 'failedState';
            default:
                return 'defaultState';
        }
    }

    public getTooltipText(thing: Thing): string {
        return this.valveStateService.tooltipText(thing);
    }

    public showQuestionMark(thing: Thing): boolean {
        return this.valveStateService.showQuestionMark(thing.state);
    }

    public contactSupport(){
        window.open('https://help.watergate.ai/','_blank');
    }

    private stopLastOngoingTimeout(): void {
        if (this.lastOngoingTimeoutSub) {
            this.lastOngoingTimeoutSub.unsubscribe();
        }
    }

    private initPusher(): void {
        if (!this.pusherInitialised) {
            const id = this.propertyId;

            if (!id) {
                throw new Error('No Selected property at this point');
            }
            this.pusherService.destroyEvents();
            this.pusherService.initPropertyChannel(id);
            this.pusherInitialised = true;
            this.bindPusherEvents();
        }
    }

    private loadSettings(): void {
        this.propertyService.getSettings(this.propertyId)
            .subscribe((settings) => {
                this.propertySettings = settings;
                this.setTotalUsage(this.thing.id);
                this.cdRef.detectChanges();
            });
    }

    private startOngoingDurationCount(): void {
        this.stopDurationCount();
        this.durationSub = interval(1000)
            .subscribe(() => {
                this.ongoingEventDuration += 1000;
                this.cdRef.detectChanges();
            });
    }

    private bindPusherEvents(): void {
        if (this.demoService.demoMode) {
            this.thingDemoResource.thingStateEvent
                .subscribe((message: PusherStateChangeMessage) => this.handleStateChangeMessage(message.message, this.thing));
        } else if (this.pusherInitialised) {
            this.pusherService.onThingState(
                (message: PusherStateChangeMessage) => {
                    if (message.message.device_id===this.thing.id) {
                        this.handleStateChangeMessage(message.message, this.thing);
                    }
                },
            );
            this.pusherService.onValveFlow(
                (message: PusherTelemetryMessage) => {
                    if (message.message.device_id===this.thing.id && this.lastInfluxTime < message.message.influx_time) {
                        this.lastInfluxTime = message.message.influx_time;
                        this.handleTelemetryMessage(message.message, this.thing, message.ongoing_event);
                    }
                },
            );

        }
    }

    private handleTelemetryMessage(response: TelemetryMessage, thing: Thing, ongoing_event: OngoingEvent = null): void {
        const message = _.cloneDeep(response);
        if (message.water_flow > 0 && message.water_flow < 30) {
            return;
        }
        if (message.water_flow > 0) {
            this.waterIsFlowing = true;
            if (!this.isOngoingEvent && ongoing_event) {
                this.lastOngoingEvent = ongoing_event;
                this.ongoingEventDuration = ongoing_event.duration;
                this.startOngoingDurationCount();
                this.isOngoingEvent = true;

            }
        } else {
            this.waterIsFlowing = false;
            if (this.isOngoingEvent) {
                this.showLastOngoing();
                this.resetOngoing();
            }
        }
        message.water_flow *= 0.001;
        if (ongoing_event) {
            ongoing_event.volume *= 0.001;
        }
        this.waterFlowTelemetrySubject.next({message, thing, ongoing_event});
        this.cdRef.detectChanges();
    }

    private resetOngoing(): void {

        this.isOngoingEvent = false;
        this.ongoingEventDuration = null;
        this.stopDurationCount();
        this.cdRef.detectChanges();
    }

    private showLastOngoing(): void {
        this.stopLastOngoingTimeout();
        this.lastOngoingTimeoutSub = timer(3000)
            .subscribe(() => {
                this.stopLastOngoingTimeout();
                this.lastOngoingEvent = null;
                this.cdRef.detectChanges();
            });
    }

    private handleStateChangeMessage(message: StateChangeMessage, thing: Thing): void {
        const connectionState = _.get(message, 'connection_state');
        if (this.currentConnectionState!==connectionState) {
            this.currentConnectionState = connectionState;
            this.updateCurrentThingConnection(thing);
        }
        const state = _.get(message, 'state');
        if (state!==this.currentState) {
            this.currentState = state;
            this.fetchThing(thing.id);
            this.cdRef.detectChanges();
        }
    }

    private setTotalUsage(thingId: string) {

        const settings = this.propertySettings;
        if (settings) {
            const timezone = settings.timezone;
            const todayStart = moment().tz(timezone).startOf('day').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
            const currentEnd = moment().tz(timezone).endOf('day').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
            const period = {range_from: todayStart, range_to: currentEnd};

            this.totalUsageStream = this.thingService.totalUsage(thingId, period);

            const yearFrom = moment().tz(timezone).endOf('day').subtract(1, 'years').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
            const yearPeriod = {range_from: yearFrom, range_to: currentEnd};
            this.yearUsageStream = this.thingService.totalUsage(thingId, yearPeriod);

        }
    }

    private updateCurrentThingConnection(thing: Thing): void {
        this.propertyService.getThingsByPropertyId(this.propertyId)
            .subscribe((thingResponse) => {
                const currentThing = _.find(thingResponse.things, {id: thing.id});
                this.currentState = currentThing.state;
                this.currentConnectionState = currentThing.radio_connection;
                this.cdRef.detectChanges();
            });
    }

    private fetchThing(id: string): void {
        this.thingService.getThing(id)
            .subscribe((thing) => {
                this.thing = thing.thing;
                this.cdRef.detectChanges();
            });
    }

    private fetchThings(): void {
        this.propertyService.getThingsByPropertyId(this.propertyId)
            .subscribe((thingsResponse: ThingsResponse) => {
                this.thing = _.head(thingsResponse.things);
                this.currentState = this.thing.state;
                this.currentConnectionState = this.thing.radio_connection;
                this.loadSettings();
                this.cdRef.detectChanges();
            });
    }
}
