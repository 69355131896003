import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild
} from '@angular/core';
import {PropertyService} from '../../../providers/services/property.service';
import {Property} from '../../../models/property';
import * as _ from 'lodash';

import 'leaflet';
import 'leaflet.markercluster';
import {Router} from '@angular/router';
import {OpenStreetMapProvider} from 'leaflet-geosearch';
import {from} from 'rxjs';
import {LatLng, LatLngBoundsLiteral} from 'leaflet';
import {catchError, skipWhile} from 'rxjs/operators';

const L = window['L'];


@Component({
    selector: 'app-properties-widget',
    templateUrl: './properties-widget.component.html',
    styleUrls: ['./properties-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertiesWidgetComponent implements OnInit, AfterViewInit {

    @ViewChild('mapDiv') mapContainer;

    @Input() incidentsPerProperty = [];
    public properties: Property[];
    public latitude = 52.913946;
    public longitude = -3.95022;
    private map: L.Map;
    private markersLayer = new L.MarkerClusterGroup();
    private tilesUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    private mapOptions: L.MapOptions = {
        zoomControl: false,
        minZoom: 2, // 11,
        maxZoom: 18,
    };
    private provider = new OpenStreetMapProvider();
    private markers = [];

    constructor(private propertyService: PropertyService,
                private router: Router,
                private cdRef: ChangeDetectorRef) {
    }

    public ngAfterViewInit(): void {
        this.initMap();
    }

    ngOnInit(): void {
    }

    public processProperties(properties: Property[]): void {
        this.properties = properties;
        this.map.invalidateSize();

        const markersPositions = [];

        this.properties.forEach((property) => {
            if (property.lat && property.lng) {

                const markerPosition = this.createMarker(property.lat.toString(), property.lng.toString(), property);
                markersPositions.push(markerPosition);
            } else {
                const tempProperty = property;
                const query = `${property.country ? property.country:''} ${property.city ? property.city:''} ${property.address_1 ? property.address_1:''}`;
                from(this.provider.search({query}))
                    .pipe(
                        skipWhile(res => _.size(res)===0),
                        catchError(e => {
                                throw e;
                            }
                        ))
                    .subscribe({
                        next: (res) => {
                            const responseItem = res[0];
                            const markerPosition = this.createMarker(responseItem.raw.lat, responseItem.raw.lon, tempProperty);
                            markersPositions.push(markerPosition);
                            this.updateMapBounds(markersPositions);
                        },
                        error: (error: Error) => {

                        }
                    });
            }
        });
        this.updateMapBounds(markersPositions);
    }


    public goToProperty(id): void {
        this.router.navigate([`property/${id}`]);
    }

    private updateMapBounds(markersPositions: LatLngBoundsLiteral): void {

        const bounds = new L.LatLngBounds(markersPositions);
        this.map.fitBounds(bounds.pad(0.5));
        this.cdRef.detectChanges();
    }


    private createMarker(lat: string, lng: string, property: Property): LatLng {
        const activeIncidents = _.filter(this.incidentsPerProperty[property.id], (item) => item.status==='active');
        const activeIncidentsCount = _.size(activeIncidents);

        const myIcon = L.divIcon({
            className: 'map-marker',
            iconSize: [32, 32],
            popupAnchor: [0, -20],
            html: '<img class="map-marker-image" src="assets/images/map-marker.svg"/>' +
                (activeIncidentsCount > 0 ? `<span class="badge badge-danger">${_.size(activeIncidents)}</span>`:'')
        });
        const markerItem = L.marker([+lat, +lng], {icon: myIcon});

        markerItem.on('click', () => this.goToProperty(property.id));
        markerItem.bindTooltip(`<b>${property.name}</b><br/>${property.city ? property.city:''}
${property.country ? property.country:''}<br/>${property.address_1 ? property.address_1:''}<br/>
<br/>Active incidents: ${activeIncidentsCount}`);

        this.markers.push(markerItem);
        this.markersLayer.addLayer(markerItem);
        return markerItem.getLatLng();
    }

    private initMap(): void {
        this.map = new L.Map(this.mapContainer.nativeElement, this.mapOptions)
            .setView([this.latitude, this.longitude], 11);
        L.tileLayer(this.tilesUrl, {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            maxZoom: 19
        }).addTo(this.map);

        this.markersLayer.addTo(this.map);

        this.map.on('click', () => {
            this.cdRef.detectChanges();
        });

        this.cdRef.detectChanges();
    }

}
