import {ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {PressureMeasurement, PressureTest} from '../../models/pressure-test';

import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import {ChartDataSets} from 'chart.js';

@Component({
    selector: 'app-pressure-test-details',
    templateUrl: './pressure-test-details.component.html',
    styleUrls: ['./pressure-test-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PressureTestDetailsComponent implements OnInit {
    @Input() pressureTest: PressureTest;
    @Input() timezone: string;


    public timeFormat = 'HH:mm';

    public gradient: CanvasGradient;


    public lineChartColors: any;
    public chartLoadingProgress = 0;
    public minValue = 3000;

    public minValueOffset = 10;
    // TODO: Move to configs
    public chartOptions = {
        scaleShowVerticalLines: false,
        elements: {
            point: {
                radius: 0,
            },
        },
        scales: {
            xAxes: [{
                id: 'x-axis-1',
                gridLines: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    display: false,
                    drawBorder: false,
                },
            }, {
                id: 'x-axis-2',
                type: 'linear',
                position: 'bottom',
                display: false,
                ticks: {
                    min: 0,
                    max: 1,
                    step: 1,
                },
                gridLines: {
                    display: false,
                    drawBorder: false,
                },
            }],
            yAxes: [{
                offset: true,
                gridLines: {
                    display: false,
                },
                ticks: {
                    padding: -50,
                    fontSize: 14,
                    fontColor: 'rgba(207,207,207,1)',
                    maxTicksLimit: 8,
                    suggestedMin: this.minValue,
                    z: 2,
                },
            }],
        },
    };

    public chartDataSets: ChartDataSets[] = [];

    private chartWrapper: ElementRef;
    private allowedDecay = 0.1;

    @ViewChild('chartWrapper') set content(content: ElementRef) {
        this.chartWrapper = content;
    }

    ngOnInit(): void {
        this.allowedDecay = this.pressureTest.tendency_eligible_for_fail * -0.01;
        if (_.size(this.pressureTest.measurements)) {
            this.initChart();
        }
    }

    public isNaN(val: any): boolean {
        return isNaN(val);
    }

    public utcToPropertyLocalTime(time?: string | Date, format?: string): string {
        return moment.utc(time).tz(this.timezone).format(format ? format:this.timeFormat);
    }

    public mapValues(values: PressureMeasurement[]): number[] {
        return values.map((val) => val.pressure);
    }

    public getDecayLineData() {
        const startValue = this.pressureTest.measurements[0].pressure;
        return [{x: 0, y: startValue}, {x: 1, y: (1 - this.allowedDecay) * startValue}];
    }

    public mapLabels(values: PressureMeasurement[]): string[] {
        return values.map((val) => moment(val.probed_at).format('HH:mm:ss'));
    }

    private initChart() {

        this.chartDataSets = [
            {
                data: this.getDecayLineData(),
                fill: false,
                pointRadius: 0,
                lineTension: 0,
                borderDash: [10, 10],
                xAxisID: 'x-axis-2',
                borderWidth: 2,
            },
            {
                data: this.mapValues(this.pressureTest.measurements),
                label: 'Check Up',
                xAxisID: 'x-axis-1',
            },
        ];


        this.minValue = _.minBy(this.pressureTest.measurements, val => val.pressure).pressure - this.minValueOffset;
        this.chartOptions.scales.yAxes[0].ticks.suggestedMin = this.minValue;

        this.setChartGradient();

        this.lineChartColors = [
            {
                borderColor: '#E9645A',
                backgroundColor: 'rgba(255,255,255,0)',
            },
            {
                borderColor: '#57AADE',
                backgroundColor: this.gradient,
            },
        ];
    }


    private setChartGradient(): void {

        const canvas = document.createElement('canvas');
        canvas.width = 500;
        canvas.height = 400;
        this.gradient = canvas.getContext('2d').createLinearGradient(0, 0, 0, 400);
        this.gradient.addColorStop(0, 'rgba(207,237,255,1)');
        this.gradient.addColorStop(0.5, 'rgba(255,255,255,0)');

        canvas.remove();
    }


}
