import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {IncidentsService} from './incidents.service';
import {PropertyService} from './property.service';
import {ThingService} from './thing.service';
import {ApplianceCategoryService} from './appliance-category.service';

@Injectable({
  providedIn: 'root'
})
export class DemoService {

  public demoMode = false; // ! DO NOT SET THIS TO TRUE ON START

  constructor(private userService: UserService,
              private propertyService: PropertyService,
              private thingService: ThingService,
              private applianceService: ApplianceCategoryService,
              private incidentsService: IncidentsService) {
  }

  public toggleDemo(state: boolean): void {
    this.demoMode = state;
    this.userService.toggleDemo(state);
    this.incidentsService.toggleDemo(state);
    this.propertyService.toggleDemo(state);
    this.thingService.toggleDemo(state);
    this.applianceService.toggleDemo(state);
  }

}
