export const propertiesAguilar = [
    {
        active: false,
        address_1: '23 Templedene Avenue',
        address_2: null,
        address_3: null,
        city: 'Staines',
        country: 'United Kingdom',
        id: 'aef65e22-apt-1-property',
        lat: 51.4237569,
        lng: -0.4920096,
        name: 'Main home',
        postcode: 'TW18 1LH',
        spaces: [{
            appliance_categories: [],
            appliances: [],
            default: true,
            hubs: [{
                id: '45c28e92-0415-48f3-8076-d695ba190010',
                name: null,
                online: false,
                type: 'hub',
                wifi_rssi: null
            }],
            id: 'f3d00b5f-d0cd-4d14-a3ed-a412824d0223',
            name: 'Room for entire home'
        }, {
            appliance_categories: [{
                icon_url: 'irrigation',
                id: 300,
                name: 'Irrigation System',
                parent_id: null
            }],
            appliances: [{
                appliance_category_icon: 'irrigation',
                appliance_category_id: 300,
                appliance_category_name: 'Irrigation System',
                brand: null,
                id: '8d480356-40e6-47ad-a03a-bd961a3238c8',
                model: null,
                name: null,
                space_id: '3c394021-102f-4544-9faf-0e6d2f1fc1b4'
            }],
            default: false,
            hubs: [],
            id: '3c394021-102f-4544-9faf-0e6d2f1fc1b4',
            name: 'Custom'
        }],
        uprn: null
    },
    {
        active: false,
        address_1: '10 George Street',
        address_2: null,
        address_3: null,
        city: 'London',
        country: 'United Kingdom',
        created_at: '2021-05-10T08:59:48.829088Z',
        id: '1aa197d0-apt-2-property',
        lat: 51.5021546,
        lng: -0.0153349,
        name: 'London flat',
        postcode: 'E14 9QG',
        spaces: [
            {
                appliance_categories: [],
                appliances: [],
                default: true,
                hubs: [
                    {
                        id: '1aa197d0-1f9a-4f0d-9c44-757e3b090010',
                        name: null,
                        online: true,
                        type: 'hub',
                        wifi_rssi: -44
                    }
                ],
                id: 'c16dfb11-df1d-4656-8044-b0005d830f60',
                name: 'Room for entire home'
            },
            {
                appliance_categories: [
                    {
                        icon_url: 'tap',
                        id: 100,
                        name: 'Tap',
                        parent: null,
                        parent_id: null
                    },
                    {
                        icon_url: 'w-machine',
                        id: 200,
                        name: 'Washing Machine',
                        parent: null,
                        parent_id: null
                    },
                    {
                        icon_url: 'dishwasher',
                        id: 210,
                        name: 'Dishwasher',
                        parent: null,
                        parent_id: null
                    },
                    {
                        icon_url: 'fridge',
                        id: 220,
                        name: 'Refrigerator',
                        parent: null,
                        parent_id: null
                    }
                ],
                appliances: [
                    {
                        appliance_category_icon: 'tap',
                        appliance_category_id: 100,
                        appliance_category_name: 'Tap',
                        brand: null,
                        id: 'a58442b9-cdb8-45a9-8b09-0b82ad10d989',
                        model: null,
                        name: 'Kitchen Sink',
                        property_id: '1aa197d0-apt-2-property',
                        space_id: '1ca4b5db-f3e7-4853-8560-234c310df5a5',
                        space_name: 'Kitchen'
                    },
                    {
                        appliance_category_icon: 'dishwasher',
                        appliance_category_id: 210,
                        appliance_category_name: 'Dishwasher',
                        brand: null,
                        id: '773e117a-49b6-4548-be0f-3d38aec1e865',
                        model: null,
                        name: null,
                        property_id: '1aa197d0-apt-2-property',
                        space_id: '1ca4b5db-f3e7-4853-8560-234c310df5a5',
                        space_name: 'Kitchen'
                    },
                    {
                        appliance_category_icon: 'w-machine',
                        appliance_category_id: 200,
                        appliance_category_name: 'Washing Machine',
                        brand: null,
                        id: '991e5df3-757e-45aa-bd9a-911264d5b507',
                        model: null,
                        name: null,
                        property_id: '1aa197d0-apt-2-property',
                        space_id: '1ca4b5db-f3e7-4853-8560-234c310df5a5',
                        space_name: 'Kitchen'
                    },
                    {
                        appliance_category_icon: 'fridge',
                        appliance_category_id: 220,
                        appliance_category_name: 'Refrigerator',
                        brand: null,
                        id: '9578124d-ac55-4be2-b9eb-f2757b7f16b6',
                        model: null,
                        name: 'Fridge',
                        property_id: '1aa197d0-apt-2-property',
                        space_id: '1ca4b5db-f3e7-4853-8560-234c310df5a5',
                        space_name: 'Kitchen'
                    }
                ],
                default: false,
                hubs: [],
                id: '1ca4b5db-f3e7-4853-8560-234c310df5a5',
                name: 'Kitchen'
            }
        ],
        uprn: null
    },
    {
        active: false,
        address_1: 'Częstochowska 38/52 lok.106/107',
        address_2: null,
        address_3: null,
        city: 'Łódź',
        country: 'Poland',
        created_at: '2021-05-10T08:59:48.829088Z',
        id: 'f7b44f5c-apt-3-property',
        lat: 51.7506531,
        lng: 19.481256,
        name: 'Summer residence',
        postcode: '93-121',
        spaces: [
            {
                appliance_categories: [],
                appliances: [],
                default: true,
                hubs: [
                    {
                        id: '1aa197d0-1f9a-4f0d-9c44-757e3b090010',
                        name: null,
                        online: true,
                        type: 'hub',
                        wifi_rssi: -44
                    }
                ],
                id: 'c16dfb11-df1d-4656-8044-b0005d830f60',
                name: 'Room for entire home'
            },
            {
                appliance_categories: [
                    {
                        icon_url: 'tap',
                        id: 100,
                        name: 'Tap',
                        parent: null,
                        parent_id: null
                    },
                    {
                        icon_url: 'w-machine',
                        id: 200,
                        name: 'Washing Machine',
                        parent: null,
                        parent_id: null
                    },
                    {
                        icon_url: 'dishwasher',
                        id: 210,
                        name: 'Dishwasher',
                        parent: null,
                        parent_id: null
                    },
                    {
                        icon_url: 'fridge',
                        id: 220,
                        name: 'Refrigerator',
                        parent: null,
                        parent_id: null
                    }
                ],
                appliances: [
                    {
                        appliance_category_icon: 'tap',
                        appliance_category_id: 100,
                        appliance_category_name: 'Tap',
                        brand: null,
                        id: 'a58442b9-cdb8-45a9-8b09-0b82ad10d989',
                        model: null,
                        name: 'Kitchen Sink',
                        property_id: 'f7b44f5c-apt-3-property',
                        space_id: '1ca4b5db-f3e7-4853-8560-234c310df5a5',
                        space_name: 'Kitchen'
                    },
                    {
                        appliance_category_icon: 'dishwasher',
                        appliance_category_id: 210,
                        appliance_category_name: 'Dishwasher',
                        brand: null,
                        id: '773e117a-49b6-4548-be0f-3d38aec1e865',
                        model: null,
                        name: null,
                        property_id: 'f7b44f5c-apt-3-property',
                        space_id: '1ca4b5db-f3e7-4853-8560-234c310df5a5',
                        space_name: 'Kitchen'
                    },
                    {
                        appliance_category_icon: 'w-machine',
                        appliance_category_id: 200,
                        appliance_category_name: 'Washing Machine',
                        brand: null,
                        id: '991e5df3-757e-45aa-bd9a-911264d5b507',
                        model: null,
                        name: null,
                        property_id: 'f7b44f5c-apt-3-property',
                        space_id: '1ca4b5db-f3e7-4853-8560-234c310df5a5',
                        space_name: 'Kitchen'
                    },
                    {
                        appliance_category_icon: 'fridge',
                        appliance_category_id: 220,
                        appliance_category_name: 'Refrigerator',
                        brand: null,
                        id: '9578124d-ac55-4be2-b9eb-f2757b7f16b6',
                        model: null,
                        name: 'Fridge',
                        property_id: 'f7b44f5c-apt-3-property',
                        space_id: '1ca4b5db-f3e7-4853-8560-234c310df5a5',
                        space_name: 'Kitchen'
                    }
                ],
                default: false,
                hubs: [],
                id: '1ca4b5db-f3e7-4853-8560-234c310df5a5',
                name: 'Kitchen'
            }
        ],
        uprn: null
    }
    ,
    {
        active: true,
        address_1: 'Whitney Avenue 54',
        address_2: null,
        address_3: null,
        uprn: null,
        city: 'Chase',
        country: 'United Kingdom',
        id: 'aef65e22-apt-4-property',
        lat: 52.86,
        lng: -0.55,
        name: 'Balooba',
        postcode: 'WT44 1LH',
        spaces: [
            {
                appliance_categories: [],
                appliances: [],
                default: true,
                hubs: [
                    {
                        id: '1644df18-d3a9-42ab-a9ee-35506a973419',
                        name: null,
                        online: true,
                        type: 'hub',
                        wifi_rssi: null
                    }
                ],
                id: '77f906c4-48e4-4f19-9e5e-4043cdc39d6e',
                name: 'Room for entire home'
            }
        ],
        createdAt: '2011-02-16T20:54:32.587Z'
    },
    {
        active: true,
        address_1: 'Nixon Court 27',
        address_2: null,
        address_3: null,
        uprn: null,
        city: 'Reinerton',
        country: 'United Kingdom',
        id: 'aef65e22-apt-5-property',
        lat: 51.31,
        lng: 0.03,
        name: 'Satiance',
        postcode: 'SW10 1LH',
        spaces: [
            {
                appliance_categories: [],
                appliances: [],
                default: true,
                hubs: [
                    {
                        id: 'f3919452-8117-44ba-b4be-63bfc0995571',
                        name: null,
                        online: true,
                        type: 'hub',
                        wifi_rssi: null
                    }
                ],
                id: '4fad8033-08f9-4f0c-bf7f-479afc15fb57',
                name: 'Room for entire home'
            }
        ],
        createdAt: '2014-12-30T06:15:14.340Z'
    },
    {
        active: true,
        address_1: 'Doscher Street 66',
        address_2: null,
        address_3: null,
        uprn: null,
        city: 'Munjor',
        country: 'United Kingdom',
        id: 'aef65e22-apt-6-property',
        lat: 52.57,
        lng: -1.68,
        name: 'Paprikut',
        postcode: 'TT31 1LH',
        spaces: [
            {
                appliance_categories: [],
                appliances: [],
                default: true,
                hubs: [
                    {
                        id: '612148ae-e1f2-4713-b233-2e69b199dbb8',
                        name: null,
                        online: true,
                        type: 'hub',
                        wifi_rssi: null
                    }
                ],
                id: '2a2b2f4c-a95f-4c69-af70-e091211e7f25',
                name: 'Room for entire home'
            }
        ],
        createdAt: '2013-12-25T03:03:26.895Z'
    },
    {
        active: true,
        address_1: 'Banker Street 69',
        address_2: null,
        address_3: null,
        uprn: null,
        city: 'Caroleen',
        country: 'United Kingdom',
        id: 'aef65e22-apt-7-property',
        lat: 53.24,
        lng: -2.11,
        name: 'Neurocell',
        postcode: 'TT55 1LH',
        spaces: [
            {
                appliance_categories: [],
                appliances: [],
                default: true,
                hubs: [
                    {
                        id: 'c1d5c0ed-58d3-4a66-b33e-18906e154a38',
                        name: null,
                        online: true,
                        type: 'hub',
                        wifi_rssi: null
                    }
                ],
                id: '70561874-4810-4b94-86be-f3fa958c607d',
                name: 'Room for entire home'
            }
        ],
        createdAt: '2011-09-29T14:33:09.295Z'
    },
    {
        active: true,
        address_1: 'Herbert Street 43',
        address_2: null,
        address_3: null,
        uprn: null,
        city: 'Slovan',
        country: 'United Kingdom',
        id: 'aef65e22-apt-8-property',
        lat: 52.58,
        lng: -0.81,
        name: 'Centuria',
        postcode: 'WT89 1LH',
        spaces: [
            {
                appliance_categories: [],
                appliances: [],
                default: true,
                hubs: [
                    {
                        id: 'a75e0373-b4df-4722-a61d-4d8edc302ba7',
                        name: null,
                        online: true,
                        type: 'hub',
                        wifi_rssi: null
                    }
                ],
                id: '1c8f7325-26ae-4ff5-a6ca-3023b55add5b',
                name: 'Room for entire home'
            }
        ],
        createdAt: '2010-07-14T22:44:00.565Z'
    },
    {
        active: true,
        address_1: 'School Lane 70',
        address_2: null,
        address_3: null,
        uprn: null,
        city: 'Belva',
        country: 'United Kingdom',
        id: 'aef65e22-apt-9-property',
        lat: 53.01,
        lng: -1.07,
        name: 'Aquafire',
        postcode: 'WW60 1LH',
        spaces: [
            {
                appliance_categories: [],
                appliances: [],
                default: true,
                hubs: [
                    {
                        id: '879c9bc6-e35f-4c4e-8460-5b86f02c7dc9',
                        name: null,
                        online: true,
                        type: 'hub',
                        wifi_rssi: null
                    }
                ],
                id: 'de4c4537-dee0-44ee-a8c1-8f4b8765e52e',
                name: 'Room for entire home'
            }
        ],
        createdAt: '2011-11-22T23:05:12.575Z'
    },
    {
        active: true,
        address_1: 'Times Placez 55',
        address_2: null,
        address_3: null,
        uprn: null,
        city: 'Enlow',
        country: 'United Kingdom',
        id: 'aef65e22-apt-10-property',
        lat: 51.29,
        lng: -1.74,
        name: 'Asimiline',
        postcode: 'ST55 1LH',
        spaces: [
            {
                appliance_categories: [],
                appliances: [],
                default: true,
                hubs: [
                    {
                        id: '3289c3c8-7fe0-4d0e-bc48-c509d0cf0a98',
                        name: null,
                        online: true,
                        type: 'hub',
                        wifi_rssi: null
                    }
                ],
                id: '5f4b2398-0c6a-4310-8bce-9476eeda8ab3',
                name: 'Room for entire home'
            }
        ],
        createdAt: '2010-07-26T20:07:06.933Z'
    },
    {
        active: true,
        address_1: 'Bedford Avenue 78',
        address_2: null,
        address_3: null,
        uprn: null,
        city: 'Grapeview',
        country: 'United Kingdom',
        id: 'aef65e22-apt-11-property',
        lat: 52.67,
        lng: -0.92,
        name: 'Zoxy',
        postcode: 'TT89 1LH',
        spaces: [
            {
                appliance_categories: [],
                appliances: [],
                default: true,
                hubs: [
                    {
                        id: '0738bfca-d6c2-479b-a285-b34f10b18a63',
                        name: null,
                        online: true,
                        type: 'hub',
                        wifi_rssi: null
                    }
                ],
                id: '63b16b66-541e-48d8-8269-9d416c18fd1e',
                name: 'Room for entire home'
            }
        ],
        createdAt: '2011-09-11T18:37:59.857Z'
    },
    {
        active: true,
        address_1: 'Cook Street 98',
        address_2: null,
        address_3: null,
        uprn: null,
        city: 'Tecolotito',
        country: 'United Kingdom',
        id: 'aef65e22-apt-12-property',
        lat: 51.32,
        lng: 0.28,
        name: 'Zaphire',
        postcode: 'WT31 1LH',
        spaces: [
            {
                appliance_categories: [],
                appliances: [],
                default: true,
                hubs: [
                    {
                        id: '6ae65b34-7bed-4923-98e4-ae6494315c22',
                        name: null,
                        online: true,
                        type: 'hub',
                        wifi_rssi: null
                    }
                ],
                id: '1f9e14bf-b8df-491a-9014-62813ca4b062',
                name: 'Room for entire home'
            }
        ],
        createdAt: '2013-09-05T01:30:11.021Z'
    },
    {
        active: true,
        address_1: 'Balfour Place 96',
        address_2: null,
        address_3: null,
        uprn: null,
        city: 'Hoehne',
        country: 'United Kingdom',
        id: 'aef65e22-apt-13-property',
        lat: 51.23,
        lng: -0.95,
        name: 'Twiggery',
        postcode: 'WS92 1LH',
        spaces: [
            {
                appliance_categories: [],
                appliances: [],
                default: true,
                hubs: [
                    {
                        id: 'c9196f8e-4ed8-45f2-9786-f7a0bfdc3d68',
                        name: null,
                        online: true,
                        type: 'hub',
                        wifi_rssi: null
                    }
                ],
                id: '99802726-6784-487b-876f-6455a6dca570',
                name: 'Room for entire home'
            }
        ],
        createdAt: '2014-08-12T23:47:18.956Z'
    },
    {
        active: true,
        address_1: 'Campus Place 86',
        address_2: null,
        address_3: null,
        uprn: null,
        city: 'Deltaville',
        country: 'United Kingdom',
        id: 'aef65e22-apt-14-property',
        lat: 51.05,
        lng: -2.03,
        name: 'Geeknet',
        postcode: 'WT82 1LH',
        spaces: [
            {
                appliance_categories: [],
                appliances: [],
                default: true,
                hubs: [
                    {
                        id: '607e9aad-6be3-47b9-9c12-456c64ae4215',
                        name: null,
                        online: true,
                        type: 'hub',
                        wifi_rssi: null
                    }
                ],
                id: '92e6fe4f-af73-48f3-bb1c-37000a675f6a',
                name: 'Room for entire home'
            }
        ],
        createdAt: '2010-03-28T12:21:07.561Z'
    },
    {
        active: true,
        address_1: 'Louise Terrace 53',
        address_2: null,
        address_3: null,
        uprn: null,
        city: 'Collins',
        country: 'United Kingdom',
        id: 'aef65e22-apt-15-property',
        lat: 52.14,
        lng: -1.21,
        name: 'Extragen',
        postcode: 'ST51 1LH',
        spaces: [
            {
                appliance_categories: [],
                appliances: [],
                default: true,
                hubs: [
                    {
                        id: 'fd802e6b-18c1-4650-a96e-9b78cd7fa10e',
                        name: null,
                        online: true,
                        type: 'hub',
                        wifi_rssi: null
                    }
                ],
                id: 'cc22885f-264b-487d-8c31-8f539ea13b61',
                name: 'Room for entire home'
            }
        ],
        createdAt: '2012-05-25T13:15:24.349Z'
    },
    {
        active: true,
        address_1: 'Java Street 46',
        address_2: null,
        address_3: null,
        uprn: null,
        city: 'Stewartville',
        country: 'United Kingdom',
        id: 'aef65e22-apt-16-property',
        lat: 52.58,
        lng: -1.06,
        name: 'Primordia',
        postcode: 'SS62 1LH',
        spaces: [
            {
                appliance_categories: [],
                appliances: [],
                default: true,
                hubs: [
                    {
                        id: '9dbde3f2-c719-4cf1-a626-f2ca4f72d927',
                        name: null,
                        online: true,
                        type: 'hub',
                        wifi_rssi: null
                    }
                ],
                id: '3ceef5cf-6b0a-4a5a-8d70-1064ad819d42',
                name: 'Room for entire home'
            }
        ],
        createdAt: '2010-06-30T11:05:14.407Z'
    },
    {
        active: true,
        address_1: 'Hyman Court 20',
        address_2: null,
        address_3: null,
        uprn: null,
        city: 'Movico',
        country: 'United Kingdom',
        id: 'aef65e22-apt-17-property',
        lat: 52.65,
        lng: -0.82,
        name: 'Kyagoro',
        postcode: 'WS78 1LH',
        spaces: [
            {
                appliance_categories: [],
                appliances: [],
                default: true,
                hubs: [
                    {
                        id: '04353ccf-8b83-4803-8853-333c65d9a60c',
                        name: null,
                        online: true,
                        type: 'hub',
                        wifi_rssi: null
                    }
                ],
                id: '8df5d0a6-177d-4c1f-9989-8a04fa0b3b35',
                name: 'Room for entire home'
            }
        ],
        createdAt: '2012-09-07T02:35:26.947Z'
    },
    {
        active: true,
        address_1: 'Gotham Avenue 24',
        address_2: null,
        address_3: null,
        uprn: null,
        city: 'Wadsworth',
        country: 'United Kingdom',
        id: 'aef65e22-apt-18-property',
        lat: 52.32,
        lng: -1.89,
        name: 'Zilencio',
        postcode: 'SW68 1LH',
        spaces: [
            {
                appliance_categories: [],
                appliances: [],
                default: true,
                hubs: [
                    {
                        id: '4e279d9d-da8d-455a-8a28-0670f459dcd2',
                        name: null,
                        online: true,
                        type: 'hub',
                        wifi_rssi: null
                    }
                ],
                id: 'a626e84a-db27-4c14-a1b4-f7bfae00d035',
                name: 'Room for entire home'
            }
        ],
        createdAt: '2013-06-03T20:49:13.046Z'
    },
    {
        active: true,
        address_1: 'Indiana Place 68',
        address_2: null,
        address_3: null,
        uprn: null,
        city: 'Kidder',
        country: 'United Kingdom',
        id: 'aef65e22-apt-19-property',
        lat: 51.52,
        lng: -1.53,
        name: 'Xeronk',
        postcode: 'WW52 1LH',
        spaces: [
            {
                appliance_categories: [],
                appliances: [],
                default: true,
                hubs: [
                    {
                        id: '32d5d7d4-49a2-466d-ac6c-fcc43f106a35',
                        name: null,
                        online: true,
                        type: 'hub',
                        wifi_rssi: null
                    }
                ],
                id: '8da35dd2-0ff4-44ea-9c27-e947091a7513',
                name: 'Room for entire home'
            }
        ],
        createdAt: '2013-07-14T21:38:24.699Z'
    },
    {
        active: true,
        address_1: 'Drew Street 96',
        address_2: null,
        address_3: null,
        uprn: null,
        city: 'Hobucken',
        country: 'United Kingdom',
        id: 'aef65e22-apt-20-property',
        lat: 51.43,
        lng: -0.01,
        name: 'Namebox',
        postcode: 'WS26 1LH',
        spaces: [
            {
                appliance_categories: [],
                appliances: [],
                default: true,
                hubs: [
                    {
                        id: 'ea723886-8d4a-4767-9a6d-aecb13047437',
                        name: null,
                        online: true,
                        type: 'hub',
                        wifi_rssi: null
                    }
                ],
                id: '606b5f58-ba4c-4ce0-974a-0a8d66cb4c89',
                name: 'Room for entire home'
            }
        ],
        createdAt: '2012-02-11T13:41:25.948Z'
    },
    {
        active: true,
        address_1: 'Woods Place 14',
        address_2: null,
        address_3: null,
        uprn: null,
        city: 'Freelandville',
        country: 'United Kingdom',
        id: 'aef65e22-apt-21-property',
        lat: 52.83,
        lng: 0.08,
        name: 'Callflex',
        postcode: 'WW74 1LH',
        spaces: [
            {
                appliance_categories: [],
                appliances: [],
                default: true,
                hubs: [
                    {
                        id: '766823ac-868a-46c4-898b-c37fe3c0e9f9',
                        name: null,
                        online: true,
                        type: 'hub',
                        wifi_rssi: null
                    }
                ],
                id: '4feadb85-78f3-4f9f-a69b-8a60b3a47d16',
                name: 'Room for entire home'
            }
        ],
        createdAt: '2013-10-17T04:10:41.330Z'
    },
    {
        active: true,
        address_1: 'Mill Street 73',
        address_2: null,
        address_3: null,
        uprn: null,
        city: 'Whitewater',
        country: 'United Kingdom',
        id: 'aef65e22-apt-22-property',
        lat: 53.06,
        lng: -2.17,
        name: 'Fangold',
        postcode: 'SW77 1LH',
        spaces: [
            {
                appliance_categories: [],
                appliances: [],
                default: true,
                hubs: [
                    {
                        id: 'cb3e4986-60ca-4fb1-b533-5fa677ac822c',
                        name: null,
                        online: true,
                        type: 'hub',
                        wifi_rssi: null
                    }
                ],
                id: '752c6ed5-7fdc-4103-8a79-9aa6911667da',
                name: 'Room for entire home'
            }
        ],
        createdAt: '2011-04-03T14:17:05.622Z'
    }
];


export const propertySettingsAguilar = {
    'aef65e22-apt-1-property': {
        adults_count: 2,
        appliance_logging: false,
        auto_shut_off: true,
        children_count: 0,
        currency: 'pounds',
        dont_disturb_mode: false,
        dont_disturb_mode_until: null,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        mode: 'home',
        pets: false,
        pressure_tests_enabled: true,
        pressure_tests_schedule: '03:00:00',
        timezone: 'Europe/London',
        water_cost_per_litre: 0.003
    },
    '1aa197d0-apt-2-property': {
        adults_count: 2,
        appliance_logging: false,
        auto_shut_off: true,
        children_count: 0,
        currency: 'pounds',
        dont_disturb_mode: false,
        dont_disturb_mode_until: null,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        mode: 'home',
        pets: false,
        pressure_tests_enabled: true,
        pressure_tests_schedule: '03:00:00',
        timezone: 'Europe/London',
        water_cost_per_litre: 0.003
    },
    'f7b44f5c-apt-3-property': {
        adults_count: 2,
        appliance_logging: false,
        auto_shut_off: true,
        children_count: 0,
        currency: 'pounds',
        dont_disturb_mode: false,
        dont_disturb_mode_until: null,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        mode: 'home',
        pets: false,
        pressure_tests_enabled: true,
        pressure_tests_schedule: '03:00:00',
        timezone: 'Europe/London',
        water_cost_per_litre: 0.003
    },
    'aef65e22-apt-4-property': {
        mode: 'home',
        pets: false,
        currency: 'pounds',
        timezone: 'Europe/London',
        adults_count: 2,
        auto_shut_off: true,
        children_count: 2,
        appliance_logging: false,
        dont_disturb_mode: false,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        water_cost_per_litre: 0.003,
        pressure_tests_enabled: true,
        dont_disturb_mode_until: null,
        pressure_tests_schedule: '03:00:00'
    },
    'aef65e22-apt-5-property': {
        mode: 'home',
        pets: false,
        currency: 'pounds',
        timezone: 'Europe/London',
        adults_count: 4,
        auto_shut_off: true,
        children_count: 1,
        appliance_logging: false,
        dont_disturb_mode: false,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        water_cost_per_litre: 0.003,
        pressure_tests_enabled: true,
        dont_disturb_mode_until: null,
        pressure_tests_schedule: '03:00:00'
    },
    'aef65e22-apt-6-property': {
        mode: 'home',
        pets: false,
        currency: 'pounds',
        timezone: 'Europe/London',
        adults_count: 4,
        auto_shut_off: true,
        children_count: 3,
        appliance_logging: false,
        dont_disturb_mode: false,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        water_cost_per_litre: 0.003,
        pressure_tests_enabled: true,
        dont_disturb_mode_until: null,
        pressure_tests_schedule: '03:00:00'
    },
    'aef65e22-apt-7-property': {
        mode: 'home',
        pets: false,
        currency: 'pounds',
        timezone: 'Europe/London',
        adults_count: 2,
        auto_shut_off: true,
        children_count: 1,
        appliance_logging: false,
        dont_disturb_mode: false,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        water_cost_per_litre: 0.003,
        pressure_tests_enabled: true,
        dont_disturb_mode_until: null,
        pressure_tests_schedule: '03:00:00'
    },
    'aef65e22-apt-8-property': {
        mode: 'home',
        pets: false,
        currency: 'pounds',
        timezone: 'Europe/London',
        adults_count: 4,
        auto_shut_off: true,
        children_count: 2,
        appliance_logging: false,
        dont_disturb_mode: false,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        water_cost_per_litre: 0.003,
        pressure_tests_enabled: true,
        dont_disturb_mode_until: null,
        pressure_tests_schedule: '03:00:00'
    },
    'aef65e22-apt-9-property': {
        mode: 'home',
        pets: false,
        currency: 'pounds',
        timezone: 'Europe/London',
        adults_count: 3,
        auto_shut_off: true,
        children_count: 1,
        appliance_logging: false,
        dont_disturb_mode: false,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        water_cost_per_litre: 0.003,
        pressure_tests_enabled: true,
        dont_disturb_mode_until: null,
        pressure_tests_schedule: '03:00:00'
    },
    'aef65e22-apt-10-property': {
        mode: 'home',
        pets: false,
        currency: 'pounds',
        timezone: 'Europe/London',
        adults_count: 3,
        auto_shut_off: true,
        children_count: 2,
        appliance_logging: false,
        dont_disturb_mode: false,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        water_cost_per_litre: 0.003,
        pressure_tests_enabled: true,
        dont_disturb_mode_until: null,
        pressure_tests_schedule: '03:00:00'
    },
    'aef65e22-apt-11-property': {
        mode: 'home',
        pets: false,
        currency: 'pounds',
        timezone: 'Europe/London',
        adults_count: 4,
        auto_shut_off: true,
        children_count: 1,
        appliance_logging: false,
        dont_disturb_mode: false,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        water_cost_per_litre: 0.003,
        pressure_tests_enabled: true,
        dont_disturb_mode_until: null,
        pressure_tests_schedule: '03:00:00'
    },
    'aef65e22-apt-12-property': {
        mode: 'home',
        pets: false,
        currency: 'pounds',
        timezone: 'Europe/London',
        adults_count: 3,
        auto_shut_off: true,
        children_count: 2,
        appliance_logging: false,
        dont_disturb_mode: false,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        water_cost_per_litre: 0.003,
        pressure_tests_enabled: true,
        dont_disturb_mode_until: null,
        pressure_tests_schedule: '03:00:00'
    },
    'aef65e22-apt-13-property': {
        mode: 'home',
        pets: false,
        currency: 'pounds',
        timezone: 'Europe/London',
        adults_count: 4,
        auto_shut_off: true,
        children_count: 0,
        appliance_logging: false,
        dont_disturb_mode: false,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        water_cost_per_litre: 0.003,
        pressure_tests_enabled: true,
        dont_disturb_mode_until: null,
        pressure_tests_schedule: '03:00:00'
    },
    'aef65e22-apt-14-property': {
        mode: 'home',
        pets: false,
        currency: 'pounds',
        timezone: 'Europe/London',
        adults_count: 4,
        auto_shut_off: true,
        children_count: 1,
        appliance_logging: false,
        dont_disturb_mode: false,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        water_cost_per_litre: 0.003,
        pressure_tests_enabled: true,
        dont_disturb_mode_until: null,
        pressure_tests_schedule: '03:00:00'
    },
    'aef65e22-apt-15-property': {
        mode: 'home',
        pets: false,
        currency: 'pounds',
        timezone: 'Europe/London',
        adults_count: 3,
        auto_shut_off: true,
        children_count: 3,
        appliance_logging: false,
        dont_disturb_mode: false,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        water_cost_per_litre: 0.003,
        pressure_tests_enabled: true,
        dont_disturb_mode_until: null,
        pressure_tests_schedule: '03:00:00'
    },
    'aef65e22-apt-16-property': {
        mode: 'home',
        pets: false,
        currency: 'pounds',
        timezone: 'Europe/London',
        adults_count: 2,
        auto_shut_off: true,
        children_count: 0,
        appliance_logging: false,
        dont_disturb_mode: false,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        water_cost_per_litre: 0.003,
        pressure_tests_enabled: true,
        dont_disturb_mode_until: null,
        pressure_tests_schedule: '03:00:00'
    },
    'aef65e22-apt-17-property': {
        mode: 'home',
        pets: false,
        currency: 'pounds',
        timezone: 'Europe/London',
        adults_count: 2,
        auto_shut_off: true,
        children_count: 3,
        appliance_logging: false,
        dont_disturb_mode: false,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        water_cost_per_litre: 0.003,
        pressure_tests_enabled: true,
        dont_disturb_mode_until: null,
        pressure_tests_schedule: '03:00:00'
    },
    'aef65e22-apt-18-property': {
        mode: 'home',
        pets: false,
        currency: 'pounds',
        timezone: 'Europe/London',
        adults_count: 2,
        auto_shut_off: true,
        children_count: 3,
        appliance_logging: false,
        dont_disturb_mode: false,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        water_cost_per_litre: 0.003,
        pressure_tests_enabled: true,
        dont_disturb_mode_until: null,
        pressure_tests_schedule: '03:00:00'
    },
    'aef65e22-apt-19-property': {
        mode: 'home',
        pets: false,
        currency: 'pounds',
        timezone: 'Europe/London',
        adults_count: 3,
        auto_shut_off: true,
        children_count: 2,
        appliance_logging: false,
        dont_disturb_mode: false,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        water_cost_per_litre: 0.003,
        pressure_tests_enabled: true,
        dont_disturb_mode_until: null,
        pressure_tests_schedule: '03:00:00'
    },
    'aef65e22-apt-20-property': {
        mode: 'home',
        pets: false,
        currency: 'pounds',
        timezone: 'Europe/London',
        adults_count: 4,
        auto_shut_off: true,
        children_count: 2,
        appliance_logging: false,
        dont_disturb_mode: false,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        water_cost_per_litre: 0.003,
        pressure_tests_enabled: true,
        dont_disturb_mode_until: null,
        pressure_tests_schedule: '03:00:00'
    },
    'aef65e22-apt-21-property': {
        mode: 'home',
        pets: false,
        currency: 'pounds',
        timezone: 'Europe/London',
        adults_count: 4,
        auto_shut_off: true,
        children_count: 3,
        appliance_logging: false,
        dont_disturb_mode: false,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        water_cost_per_litre: 0.003,
        pressure_tests_enabled: true,
        dont_disturb_mode_until: null,
        pressure_tests_schedule: '03:00:00'
    },
    'aef65e22-apt-22-property': {
        mode: 'home',
        pets: false,
        currency: 'pounds',
        timezone: 'Europe/London',
        adults_count: 4,
        auto_shut_off: true,
        children_count: 2,
        appliance_logging: false,
        dont_disturb_mode: false,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        water_cost_per_litre: 0.003,
        pressure_tests_enabled: true,
        dont_disturb_mode_until: null,
        pressure_tests_schedule: '03:00:00'
    }
};

export const notificationSettingsAguilar = {
    'aef65e22-apt-1-property': {
        cloud_disconnection: true,
        device_handle_moved: true,
        health_check_failed: true,
        high_volume_threshold_litres: 400,
        long_flow_notification_delay_mins: 60,
        low_battery_level: true,
        low_water_temperature: true,
        pressure_test_failed: true,
        pressure_test_skipped: true,
        radio_disconnection: true
    },
    '1aa197d0-apt-2-property': {
        cloud_disconnection: true,
        device_handle_moved: true,
        health_check_failed: true,
        high_volume_threshold_litres: 300,
        long_flow_notification_delay_mins: 60,
        low_battery_level: true,
        low_water_temperature: true,
        pressure_test_failed: true,
        pressure_test_skipped: true,
        radio_disconnection: true
    },
    'f7b44f5c-apt-3-property': {
        cloud_disconnection: true,
        device_handle_moved: true,
        health_check_failed: true,
        high_volume_threshold_litres: 300,
        long_flow_notification_delay_mins: 60,
        low_battery_level: true,
        low_water_temperature: true,
        pressure_test_failed: true,
        pressure_test_skipped: true,
        radio_disconnection: true
    },
    'aef65e22-apt-4-property': {
        cloud_disconnection: true,
        device_handle_moved: true,
        health_check_failed: true,
        high_volume_threshold_litres: 300,
        long_flow_notification_delay_mins: 60,
        low_battery_level: true,
        low_water_temperature: true,
        pressure_test_failed: true,
        pressure_test_skipped: true,
        radio_disconnection: true
    },
    'aef65e22-apt-5-property': {
        cloud_disconnection: true,
        device_handle_moved: true,
        health_check_failed: true,
        high_volume_threshold_litres: 300,
        long_flow_notification_delay_mins: 60,
        low_battery_level: true,
        low_water_temperature: true,
        pressure_test_failed: true,
        pressure_test_skipped: true,
        radio_disconnection: true
    },
    'aef65e22-apt-6-property': {
        cloud_disconnection: true,
        device_handle_moved: true,
        health_check_failed: true,
        high_volume_threshold_litres: 300,
        long_flow_notification_delay_mins: 60,
        low_battery_level: true,
        low_water_temperature: true,
        pressure_test_failed: true,
        pressure_test_skipped: false,
        radio_disconnection: true
    },
    'aef65e22-apt-7-property': {
        cloud_disconnection: true,
        device_handle_moved: true,
        health_check_failed: true,
        high_volume_threshold_litres: 300,
        long_flow_notification_delay_mins: 60,
        low_battery_level: true,
        low_water_temperature: true,
        pressure_test_failed: true,
        pressure_test_skipped: true,
        radio_disconnection: true
    },
    'aef65e22-apt-8-property': {
        cloud_disconnection: true,
        device_handle_moved: true,
        health_check_failed: true,
        high_volume_threshold_litres: 300,
        long_flow_notification_delay_mins: 60,
        low_battery_level: true,
        low_water_temperature: true,
        pressure_test_failed: true,
        pressure_test_skipped: true,
        radio_disconnection: true
    },
    'aef65e22-apt-9-property': {
        cloud_disconnection: true,
        device_handle_moved: true,
        health_check_failed: false,
        high_volume_threshold_litres: 300,
        long_flow_notification_delay_mins: 60,
        low_battery_level: true,
        low_water_temperature: true,
        pressure_test_failed: true,
        pressure_test_skipped: true,
        radio_disconnection: true
    },
    'aef65e22-apt-10-property': {
        cloud_disconnection: true,
        device_handle_moved: true,
        health_check_failed: true,
        high_volume_threshold_litres: 300,
        long_flow_notification_delay_mins: 60,
        low_battery_level: true,
        low_water_temperature: true,
        pressure_test_failed: true,
        pressure_test_skipped: true,
        radio_disconnection: true
    },
    'aef65e22-apt-11-property': {
        cloud_disconnection: true,
        device_handle_moved: true,
        health_check_failed: true,
        high_volume_threshold_litres: 300,
        long_flow_notification_delay_mins: 60,
        low_battery_level: false,
        pressure_test_failed: true,
        pressure_test_skipped: true,
        radio_disconnection: true
    },
    'aef65e22-apt-12-property': {
        cloud_disconnection: true,
        device_handle_moved: true,
        health_check_failed: false,
        high_volume_threshold_litres: 300,
        long_flow_notification_delay_mins: 60,
        low_battery_level: true,
        low_water_temperature: true,
        pressure_test_failed: false,
        pressure_test_skipped: true,
        radio_disconnection: false
    },
    'aef65e22-apt-13-property': {
        cloud_disconnection: true,
        device_handle_moved: true,
        health_check_failed: true,
        high_volume_threshold_litres: 300,
        long_flow_notification_delay_mins: 60,
        low_battery_level: true,
        low_water_temperature: true,
        pressure_test_failed: true,
        pressure_test_skipped: true,
        radio_disconnection: true
    },
    'aef65e22-apt-14-property': {
        cloud_disconnection: false,
        device_handle_moved: false,
        health_check_failed: false,
        high_volume_threshold_litres: 300,
        long_flow_notification_delay_mins: 60,
        low_battery_level: true,
        low_water_temperature: true,
        pressure_test_failed: true,
        pressure_test_skipped: false,
        radio_disconnection: true
    },
    'aef65e22-apt-15-property': {
        cloud_disconnection: true,
        device_handle_moved: true,
        health_check_failed: true,
        high_volume_threshold_litres: 300,
        long_flow_notification_delay_mins: 60,
        low_battery_level: true,
        low_water_temperature: true,
        pressure_test_failed: true,
        pressure_test_skipped: true,
        radio_disconnection: true
    },
    'aef65e22-apt-16-property': {
        cloud_disconnection: true,
        device_handle_moved: true,
        health_check_failed: true,
        high_volume_threshold_litres: 300,
        long_flow_notification_delay_mins: 60,
        low_battery_level: true,
        low_water_temperature: true,
        pressure_test_failed: true,
        pressure_test_skipped: true,
        radio_disconnection: true
    },
    'aef65e22-apt-17-property': {
        cloud_disconnection: true,
        device_handle_moved: false,
        health_check_failed: true,
        high_volume_threshold_litres: 300,
        long_flow_notification_delay_mins: 60,
        low_battery_level: true,
        low_water_temperature: true,
        pressure_test_failed: true,
        pressure_test_skipped: true,
        radio_disconnection: true
    },
    'aef65e22-apt-18-property': {
        cloud_disconnection: true,
        device_handle_moved: false,
        health_check_failed: true,
        high_volume_threshold_litres: 300,
        long_flow_notification_delay_mins: 60,
        low_battery_level: true,
        low_water_temperature: true,
        pressure_test_failed: true,
        pressure_test_skipped: true,
        radio_disconnection: true
    },
    'aef65e22-apt-19-property': {
        cloud_disconnection: false,
        device_handle_moved: true,
        health_check_failed: false,
        high_volume_threshold_litres: 300,
        long_flow_notification_delay_mins: 60,
        low_battery_level: true,
        low_water_temperature: true,
        pressure_test_failed: true,
        pressure_test_skipped: true,
        radio_disconnection: true
    },
    'aef65e22-apt-20-property': {
        cloud_disconnection: true,
        device_handle_moved: false,
        health_check_failed: false,
        high_volume_threshold_litres: 300,
        long_flow_notification_delay_mins: 60,
        low_battery_level: true,
        low_water_temperature: true,
        pressure_test_failed: false,
        pressure_test_skipped: false,
        radio_disconnection: false
    },
    'aef65e22-apt-21-property': {
        cloud_disconnection: true,
        device_handle_moved: true,
        health_check_failed: false,
        high_volume_threshold_litres: 300,
        long_flow_notification_delay_mins: 60,
        low_battery_level: true,
        low_water_temperature: true,
        pressure_test_failed: false,
        pressure_test_skipped: true,
        radio_disconnection: false
    }
};
