<div class="d-flex flex-column">
	<mat-dialog-title mat-dialog-title
					  class="mb-2">{{data.title}}</mat-dialog-title>
	<mat-dialog-content class="my-3">{{ data.description}}</mat-dialog-content>
	<mat-dialog-actions class="mb-0">
		<button mat-button
				class="btn mr-2"
				mat-dialog-close
				[ngClass]="data.noClass"
				(click)="noAction()">{{ data.noLabel || 'Cancel'}}
		</button>
		<button mat-button
				class="btn mr-2"
				mat-dialog-close
				[ngClass]="data.yesClass"
				(click)="yesAction()">{{ data.yesLabel || 'Confirm'}}
		</button>
	</mat-dialog-actions>
</div>
