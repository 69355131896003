import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {IncidentIndexFilters, IncidentResource} from '../resources/incident.resource';
import {IncidentDetails, IncidentIndex, IncidentsStatusCount, MostRecentIncident} from '../../models/incident';
import {Pageable} from '../../models/Pageable';
import {OptionalHttpParams} from '../resources/thing.resource';
import {IncidentDemoResource} from '../demo-resources/data/incident.demo.resource';

@Injectable()
export class IncidentsService {

    private resource: IncidentResource | IncidentDemoResource;

    constructor(private incidentResource: IncidentResource,
                private incidentDemoResource: IncidentDemoResource) {
        this.resource = this.incidentResource;
    }

    public toggleDemo(state: boolean): void {
        this.resource = state ? this.incidentDemoResource : this.incidentResource;
    }

    public getAllIncidents(indexParams?: OptionalHttpParams): Observable<Pageable<IncidentIndex>> {
        return this.resource.getAllIncidents(indexParams);
    }


    public getIndex(propertyId: string, params?: IncidentIndexFilters): Observable<Pageable<IncidentIndex>> {
        return this.resource.getIncidentIndex(propertyId, params);
    }

    public getDetails(propertyId: string, incidentId: string): Observable<IncidentDetails> {
        return this.resource.findById(propertyId, incidentId)
                .pipe(map((res) => res.incident));
    }

    public getIncidentByStatus(propertyId: string,
                               pageableRequest?: { page: number; page_size: number },
                               status: 'active' | 'closed' = 'active',
                               type?: string): Observable<Pageable<IncidentIndex>> {
        const params = {status};
        if (type) {
            params['type'] = type;
        }
        if (pageableRequest) {
            params['page'] = pageableRequest.page;
            params['page_size'] = pageableRequest.page_size;
        }
        return this.getIndex(propertyId, params);
    }

    public getIncidentsCount(propertyId: string): Observable<IncidentsStatusCount> {
        return this.resource.getIncidentsCount(propertyId);
    }

    public theMostRecentClosedIncidents(propertyId: string): Observable<MostRecentIncident[]> {
        return this.resource.theMostRecentClosedIncidents(propertyId);
    }

    public executeAction(propertyId: string,
                         incidentId: string,
                         action: string): Observable<IncidentDetails> {
        return this.resource.executeAction(propertyId, incidentId, action)
                .pipe(map((res) => res.incident));
    }

    public snooze(propertyId: string,
                  incidentId: string,
                  snoozedUntil: string): Observable<IncidentDetails> {
        return this.resource.snooze(propertyId, incidentId, snoozedUntil)
                .pipe(map((res) => res.incident));
    }

    public getOpenIncidentsCount(propertyId: string): Observable<number> {
        return this.getIncidentsCount(propertyId)
                .pipe(map((res) => res.active));
    }

    public getCopiesByType(type: string): Observable<{ title: string; description: string }> {
        return this.resource.getCopiesByType(type);
    }
}
