import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserResource} from './resources/user.resource';
import {UserService} from './services/user.service';
import {PasswordResource} from './resources/password.resources';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthInterceptorService} from './interceptors/auth-interceptor.service';
import {AuthGuardService} from './services/auth-guard.service';
import {AuthenticationService} from './services/authenthication.service';
import {PropertyService} from './services/property.service';
import {PropertyResource} from './resources/property.resource';
import {HubService} from './services/hub.service';
import {ThingService} from './services/thing.service';
import {ThingResource} from './resources/thing.resource';
import {IncidentResource} from './resources/incident.resource';
import {IncidentsService} from './services/incidents.service';
import {DemoService} from './services/demo.service';
import {UserDemoResource} from './demo-resources/user.demo.resource';
import {PropertyDemoResource} from './demo-resources/property.demo.resource';
import {IncidentDemoResource} from './demo-resources/data/incident.demo.resource';
import {ThingDemoResource} from './demo-resources/thing.demo.resource';
import {ApplianceCategoryService} from './services/appliance-category.service';
import {ApplianceResource} from './resources/appliance.resource';
import {ApplianceDemoResource} from './demo-resources/appliance.demo.resource';
import {StatsService} from './services/stats.service';
import { ValveStateService } from './services/valve-state.service';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        HttpClientModule
    ],
    providers: [
        AuthGuardService,
        AuthenticationService,
        PropertyService,
        PropertyResource,
        IncidentResource,
        IncidentsService,
        UserResource,
        HubService,
        UserService,
        ThingService,
        ThingResource,
        PasswordResource,
        DemoService,
        UserDemoResource,
        PropertyDemoResource,
        IncidentDemoResource,
        ThingDemoResource,
        ApplianceCategoryService,
        ApplianceResource,
        ApplianceDemoResource,
        StatsService,
        ValveStateService,
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true},
    ]
})
export class ProvidersModule {
}
