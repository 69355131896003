import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import {Property} from '../../../models/property';
import {PropertySettings} from '../../../models/property.settings';
import {forkJoin} from 'rxjs';
import {PropertyService} from '../../../providers/services/property.service';
import {NgxMaterialTimepickerTheme} from 'ngx-material-timepicker';

@Component({
    selector: 'app-edit-property-settings-dialog',
    templateUrl: './edit-property-settings-dialog.component.html',
    styleUrls: ['./edit-property-settings-dialog.component.scss']
})
export class EditPropertySettingsDialogComponent implements OnInit {

    public timeOfPressureTests: string;
    public timePickerTheme: NgxMaterialTimepickerTheme = {
        container: {
            buttonColor: '#2C7BE5'
        },
        dial: {
            dialBackgroundColor: '#2C7BE5',
        },
        clockFace: {
            clockHandColor: '#2C7BE5',
        }
    };

    constructor(@Inject(MAT_DIALOG_DATA) public data: {
                    propertySettings: PropertySettings;
                    propertyData: Property[];
                    mode: string;
                }, private dialogRef: MatDialogRef<EditPropertySettingsDialogComponent>,
                private propertyService: PropertyService) {
    }

    ngOnInit(): void {
        this.timeOfPressureTests = moment.utc(this.data.propertySettings.pressure_tests_schedule, 'H:mm:ss').tz(this.data.propertySettings.timezone).format('H:mm');
    }

    public save(): void {
        this.updatePropertySettings();
    }

    public updateTime(event: string): void {
        _.set(this.data.propertySettings, 'pressure_tests_schedule', moment.tz(event, 'h:mm A', this.data.propertySettings.timezone).utc().format(moment.HTML5_FMT.TIME_MS));
    }

    public close(refresh = false): void {
        this.dialogRef.close(refresh);
    }

    private updatePropertySettings(): void {
        const observableList = [];
        for (const property of this.data.propertyData) {
            const propertySettings = _.clone(this.data.propertySettings);
            observableList.push(
                this.propertyService.updateSettings(property.id, propertySettings)
            );
        }
        forkJoin({
            ...observableList
        }).subscribe(
            () => {
                this.close(true);
            },
        );

    }

}
