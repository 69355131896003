import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PropertiesResponse, PropertyPaginatedResponse, PropertyResponse} from '../../models/property';
import {ApiConfig} from './ApiConfig';
import {ThingsResponse} from '../../models/thing';
import {PropertySettings} from '../../models/property.settings';
import {NotificationSettings} from '../../models/notification-settings';
import {OptionalHttpParams} from './thing.resource';
import * as _ from 'lodash';

@Injectable()
export class PropertyResource {

    constructor(private http: HttpClient) {
    }

    public getPropertiesList(): Observable<PropertiesResponse> {
        const url = `${ApiConfig.CORE_URL}/properties`;


        return this.http.get<PropertiesResponse>(url);
    }

    public getPropertiesListPaginated(params?: OptionalHttpParams): Observable<PropertyPaginatedResponse> {
        const url = `${ApiConfig.APE_URL}/properties`;
        _.unset(params, 'order_by');
        _.unset(params, 'order_strategy');
        _.unset(params, 'page_size');
        return this.http.get<PropertyPaginatedResponse>(url, {params});
    }

    public getThingsByPropertyId(propertyId: string): Observable<ThingsResponse> {
        const url = `${ApiConfig.CORE_URL}/properties/${propertyId}/things`;

        return this.http.get<ThingsResponse>(url);
    }

    public getSettings(propertyId: string): Observable<PropertySettings> {
        const url = `${ApiConfig.CORE_URL}/properties/${propertyId}/settings`;

        return this.http.get<PropertySettings>(url);
    }

    public updateSettings(propertyId: string, payload: PropertySettings): Observable<PropertySettings> {
        const url = `${ApiConfig.CORE_URL}/properties/${propertyId}/update_settings`;
        const data = {
            settings: payload,
        };

        return this.http.put<PropertySettings>(url, data);
    }

    public restoreDefaultNotificationSettings(propertyId: string): Observable<NotificationSettings> {
        const url = `${ApiConfig.CORE_URL}/properties/${propertyId}/restore_default_notification_settings`;
        return this.http.put<NotificationSettings>(url, {});
    }

    public getProperty(propertyId: string): Observable<PropertyResponse> {
        const url = `${ApiConfig.CORE_URL}/properties/${propertyId}`;

        return this.http.get<PropertyResponse>(url);
    }

    public getNotificationSettings(propertyId: string): Observable<NotificationSettings> {
        const url = `${ApiConfig.CORE_URL}/properties/${propertyId}/notification_settings`;

        return this.http.get<NotificationSettings>(url);
    }

    public updateNotificationSettings(propertyId: string, payload: NotificationSettings): Observable<NotificationSettings> {
        const url = `${ApiConfig.CORE_URL}/properties/${propertyId}/update_notification_settings`;
        const data = {
            settings: payload,
        };

        return this.http.put<NotificationSettings>(url, data);
    }
}
