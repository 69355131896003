<app-widget-frame title="Average water pressure"
                  [value]="getAveragePressure()"
                  [unit]="unit"
                  >
    <app-line-chart [seriesData]="seriesData"
                    [range]="range"
                    [unit]="unit"
                    [chartXMax]="chartXMax"
                    [chartXMin]="chartXMin"
                    [yLabelMinInterval]="1"
                    [labels]="labels"></app-line-chart>
</app-widget-frame>
