<a class="nav-link"
   role="button"
   aria-expanded="true"
   (click)="opened = !opened"
   aria-controls="sidebarDashboards">
	<i class="fe"
	   [ngClass]="iconsName"></i> {{menuName}}
</a>
<div class="collapse"
	 [ngClass]="{'show':opened || active }"
	 id="sidebarDashboards">
	<ng-content></ng-content>
</div>
