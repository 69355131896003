<h2 mat-dialog-title
    class="text-center">Incident details</h2>
<mat-dialog-content>
    <ng-container *ngIf="incidentDetailsStream | async as incident">
        <div class="container-fluid">
            <div class="row">
                <div class="col-2 col-lg-1">
                    <ng-container *ngIf="incident.state !== 'snoozed'; else snoozed">
                        <img src="../../../../assets/images/incidents-icons/{{ recentTypes[incident.type]?.icon }}"
                             class="incident-icon"
                             alt=""/>
                    </ng-container>
                    <ng-template #snoozed>
                        <div class="snooze_icon dnd-mode p-2">
                            <i class="icon-notifications"></i>
                        </div>
                    </ng-template>
                </div>
                <div class="col-11 col-lg-10">
                    <h3 class="incident-name m-0">{{ incident.title }}</h3>
                    <p class="incident-time m-0">{{ convertDate(incident.detected_at) }}</p>
                </div>
            </div>
            <div class="row pt-3">
                <div class="col-12  mark-down-content ">
                    <div class="incident-payload pb-4 mb-4"
                         [ngSwitch]="incident.type"
                         *ngIf="checkIfHasPayload(incident.payload)">
                        <ng-container *ngSwitchCase="'low_battery_level'">
                            <ng-container *ngIf="incident.payload.power_supply">
                                <div>
                                    <span class="value">{{ incident.payload.power_supply.battery / 1000 }}</span>
                                    <span class="l-font">V</span><br/>
                                    <span class="label">Battery</span>
                                </div>
                                <div>
                                    <span
                                        class="value">{{ incident.payload.power_supply.average_battery_when_motor_on / 1000 }}</span>
                                    <span class="l-font">V</span><br/>
                                    <span class="label">Motor on</span>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'advanced_valve_misalignment'">
                            <ng-container *ngIf="incident.payload.battery_level">
                                <div>
                                    <span class="value">
                                        <app-battery-level
                                            [batteryLevel]="incident.payload.battery_level"></app-battery-level>
                                    </span><br>
                                    <span class="label">Power status</span>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'backup_battery_usage'">
                            <ng-container *ngIf="incident.payload.battery_level">
                                <div>
                                    <span class="value">
                                        <app-battery-level
                                            [batteryLevel]="incident.payload.battery_level"></app-battery-level>
                                    </span><br>
                                    <span class="label">Power status</span>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'low_water_temperature'">
                            <div>
                                <span class="value">{{ incident.payload.water_temp | number : '1.1-1' }}</span>
                                <span class="l-font">&deg;C</span>
                                <span class="label">Water temperature</span>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'health_check_failed'">

                        </ng-container>
                        <ng-container *ngSwitchCase="'pressure_test_failed'">
                            <div>
                                <span class="label">Average pressure </span>
                                <strong
                                    class="value">{{ incident.payload.avg_pressure / 1000 | number: '1.2-2' }}</strong>
                                <strong class="l-font">bar</strong><br/>
                            </div>
                            <div>
                                <span class="label">Pressure change </span>
                                <strong
                                    class="value">
                                    <ng-container *ngIf="incident.payload.percentage_tendency>0">+</ng-container>
                                    {{ incident.payload.percentage_tendency | number: '1.0-0' }}</strong>
                                <strong
                                    class="l-font">%</strong><br/>
                            </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'long_flow_alert'">
                            <ng-container *ngTemplateOutlet="leakDetails"></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'high_volume_alert'">
                            <ng-container *ngTemplateOutlet="leakDetails"></ng-container>

                        </ng-container>
                        <ng-template #leakDetails>
                            <div>
                                <span class="value">{{ incident.payload.volume | liters }}</span>
                                <span class="l-font">l</span><br/>
                                <span class="label">Volume</span>
                            </div>
                            <div>
                                <span class="value">{{ formatDuration(incident.payload.duration) }}</span> <span
                                class="l-font">min</span><br/>
                                <span class="label">Duration</span>
                            </div>
                            <div>
                                <span class="value">{{ incident.payload.max | liters }}</span>
                                <span class="l-font">l/min</span><br/>
                                <span class="label">Max flow</span>
                            </div>
                        </ng-template>


                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 mark-down-content "
                     [innerHTML]="incident.description | markdown"></div>
            </div>
            <div class="row pb-5 pt-4 d-flex justify-content-center">
                <ng-container *ngIf="incident.state === 'processing'; else notProcessing">
                    <div class="col-auto mx-auto mb-4 text-center">
                        <mat-spinner></mat-spinner>
                        <br/>
                        <p>Processing</p>
                    </div>
                </ng-container>
                <ng-template #notProcessing>
                    <div class="col-8 mx-auto mb-4"
                         *ngFor="let action of sortActionButtons(incident.possible_actions)">
                        <button [class]="'btn btn-incident btn-block ' + action.button_class"
                                mat-button
                                (click)="handleAction(action)">
                            {{ action.title }}
                            <img [src]="action.button_icon"
                                 *ngIf="action.button_icon"
                                 [alt]="action.title"/>
                        </button>
                    </div>
                </ng-template>
            </div>
        </div>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button
            class="alert-cancel-bt"
            mat-dialog-close><span>Close</span></button>
</mat-dialog-actions>
