<!-- List group -->
<div class="input-group mb-3">
    <input [(ngModel)]="searchPhrase"
           class="form-control"
           placeholder="Search..."
           (ngModelChange)="handleInput($event)">
    <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" (click)="resetList()">Reset</button>
    </div>
</div>


<div class="list-group list-group-flush c-properties-list"
     *ngIf="filteredProperties">
	<div class="list-group-item"
         *ngFor="let property of filteredProperties">
		<button (click)="propertyClicked(property)"
				[ngClass]="{'btn-secondary text-white':  includeCheck(property)}"
				class="btn btn-outline-secondary w-100">
			{{ property.name }}
		</button>
	</div>
</div>
