<h2 mat-dialog-title
    class="text-center">Edit property settings</h2>
<mat-dialog-content class="overflow-visible">
    <ng-container *ngIf="data.mode==='pressure';else shutoff">

        <div>
            <label>
                <mat-slide-toggle color="primary"
                                  class="mr-3"
                                  [(ngModel)]="data.propertySettings.pressure_tests_enabled"></mat-slide-toggle>
                Pressure tests enabled
            </label>
        </div>
        <div>
            <label>
                <input [(ngModel)]="timeOfPressureTests" placeholder="24hr format" aria-label="24hr format"
                       [ngxTimepicker]="fullTime" [format]="24"
                       class="form-control"
                       readonly>
                <ngx-material-timepicker [theme]="timePickerTheme"
                                         (timeSet)="updateTime($event)"
                                         #fullTime></ngx-material-timepicker>
            </label>
        </div>
    </ng-container>
    <ng-template #shutoff>
        <div>
            <label>
                <mat-slide-toggle color="primary"
                                  class="mr-3"
                                  [(ngModel)]="data.propertySettings.auto_shut_off"></mat-slide-toggle>
                Auto shut-off
            </label>
        </div>
    </ng-template>


</mat-dialog-content>
<mat-dialog-actions>
    <button mat-ripple
            class="alert-cancel-bt btn btn-outline-secondary mr-3"
            mat-dialog-close><span>Cancel</span></button>
    <button mat-ripple
            class="btn btn-primary"
            (click)="save()">
        <span>Save</span>
    </button>
</mat-dialog-actions>
