import {environment} from '../../../environments/environment';

export const gatewayUrl = environment.coreURL;

export const ApiConfig = {
    CORE_URL: gatewayUrl + '/iot-core/customer_app',
    APE_URL: gatewayUrl + '/ape/v1',
    USER_URL: gatewayUrl + '/user-service',
    CUSTOMER_URL: gatewayUrl + '/iot-core-public/customers',
    PASSWORDS_URL: gatewayUrl + '/iot-core-public/passwords',
};
