import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Thing} from '../../../models/thing';
import {ThingService} from '../../../providers/services/thing.service';

@Component({
    selector: 'app-sonic-connection-status',
    templateUrl: './sonic-connection-status.component.html',
    styleUrls: ['./sonic-connection-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SonicConnectionStatusComponent {

    @Input() thing: Thing;

    constructor(private thingService: ThingService) {
    }

    public getThingConnectionStatus(thing: Thing): string {
        return this.thingService.getThingConnectionStatus(thing);
    }

}
