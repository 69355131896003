<!-- Activity -->
<div class=" ">
	<div class="card">
		<div class="card-header">
			<!-- Title -->
			<h4 class="card-header-title">
				Properties
			</h4>
		</div>
		<div class="card-body py-0 pr-xl-0">
			<div class="row">
				<div class="col-xl-4">
					<app-properties-list (propertiesLoaded)="processProperties($event)"
										 (propertySelect)="goToProperty($event[0].id)"></app-properties-list>
				</div>
				<div class="col-xl-8">
					<div id="map"
						 [ngClass]="{'show':properties}"
						 #mapDiv></div>

				</div>
			</div>
		</div>
	</div>
</div>
