import {EventEmitter, Injectable} from '@angular/core';
import {Observable, of, timer} from 'rxjs';
import {Thing, ThingEventsResponse, ThingResponse} from '../../models/thing';
import {TotalUsage, TotalUsageParams} from '../../models/total.usage';
import {CalendarPressureTests, PressureTest, PressureTestsResponse} from '../../models/pressure-test';
import {delay} from 'rxjs/operators';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import {PropertyDemoResource} from './property.demo.resource';
import {PusherStateChangeMessage, ThingState} from '../../models/pusher';
// @ts-ignore
import {default as eventsData} from './data/events.json';
import {default as eventsDataAguilar} from './aquilarData/events.json';
import { totalUsages, pressureTestsCalendars, pressureTestPassedMeasurements, pressureTestNotPassedMeasurements, lastPressure } from './data/pressure.fixture';
import { totalUsagesAguilar, pressureTestsCalendarsAguilar, pressureTestPassedMeasurementsAguilar, pressureTestNotPassedMeasurementsAguilar } from './aquilarData/pressure.fixture';
import {environment} from '../../../environments/environment';
@Injectable()
export class ThingDemoResource {

  public thingStateEvent: EventEmitter<PusherStateChangeMessage> = new EventEmitter();

  eventsData = !environment.aguilar ? eventsData : eventsDataAguilar;
  private lastPressure = lastPressure;
  private pressureTestNotPassedMeasurements = !environment.aguilar  ? pressureTestNotPassedMeasurements : pressureTestNotPassedMeasurementsAguilar;
  private pressureTestPassedMeasurements = !environment.aguilar ? pressureTestPassedMeasurements : pressureTestPassedMeasurementsAguilar;
  private pressureTestsCalendars = !environment.aguilar ? pressureTestsCalendars : pressureTestsCalendarsAguilar;
  private totalUsages = !environment.aguilar ? totalUsages : totalUsagesAguilar;

  constructor(private propertyDemoResource: PropertyDemoResource) {
    this.generatePressureTests();
  }

  public changeThingState(thingId: string, newState: string): Observable<ThingResponse> {
    const thing = this.findMockedThing(thingId);
    if (thing.state === 'open' && newState === 'closed') {
      thing.state = 'requested_closed';
      this.simulateValveState(thing, newState);

    } else if (thing.state === 'closed' && newState === 'open') {
      thing.state = 'requested_open';
      this.simulateValveState(thing, newState);
    }
    return of({thing}).pipe(delay(200));
  }

  public getThing(thingId: string): Observable<ThingResponse> {
    const thing = this.findMockedThing(thingId);
    const response: ThingResponse = {
      thing
    };
    return of(response).pipe(delay(200));
  }

  public getTotalUsage(thingId: string, query: TotalUsageParams): Observable<TotalUsage> {
    let usage = _.get(this.totalUsages, thingId).year;
    if (moment(query.range_from).format('YYYY-MM-DD') === moment(query.range_to).subtract(12, 'hour').format('YYYY-MM-DD')) {
      usage = _.get(this.totalUsages, thingId).day;
    }
    return of({
      currency: 'pounds',
      data_by_appliance_category: {100: {duration: 12067897, num: 10, water_consumption: 436414}},
      data_by_appliance_category_local: {100: {duration: 12067897, num: 9, water_consumption: 436414}},
      estimated_cost: 8.344567076522004,
      estimated_cost_local: 8.344567076522003,
      total_duration: 20918169.568,
      total_duration_local: 20918169.568,
      total_usage: 2781522.358840668,
      total_usage_local: usage,
      water_cost_per_litre: 0.003
    }).pipe(delay(200));
  }

  public getEvents(thingId: string, params?: OptionalHttpParams): Observable<ThingEventsResponse> {


    const maxDate = moment(+_.get(params, 'to'));

    // return empty for future dates
    if (maxDate.isAfter(moment().endOf('day'))) {
      return of({events: [], last_evaluated_key: null});
    }
    // return all if date is in past
    if (!maxDate || !moment().isSame(maxDate, 'day')) {
      return of(eventsData);
    }
    // filter if date is today till current hour
    const eventsFiltered = [];
    _.forEach(eventsData.events, (item) => {
      if (this.minutesOfDay(moment(item.event_start)) <= this.minutesOfDay(moment())) {
        eventsFiltered.push(item);
      }
    });

    return of({last_evaluated_key: null, events: eventsFiltered});
  }

  public getPropertyPressureTests(thingId: string, params?: any): Observable<PressureTestsResponse> {
    const response: PressureTestsResponse = {
      pressure_tests: [_.get(_.get(this.pressureTestsCalendars, thingId), params.day)]
    };
    return of(response).pipe(delay(200));
  }

  public getCalendarPressureTests(thingId: string, params: { from: string; to: string }): Observable<CalendarPressureTests> {
    const filteredTests = {};
    _.forEach(_.get(this.pressureTestsCalendars, thingId), (test, key) => {
      const testStart = moment(test.started_at).valueOf();
      if (testStart > moment(params.from).valueOf() && testStart < moment(params.to).valueOf()) {
        _.set(filteredTests, key, test);
      }
    });
    return of(filteredTests).pipe(delay(200));
  }

  private findMockedThing(thingId: string): Thing {
    return _.find(_.flatMap(this.propertyDemoResource.things), (item) => item.id === thingId);
  }

  private simulateValveState(thing: Thing, newState: string) {
    let middleState: string;
    switch (newState) {
      case 'open':
        middleState = 'opening';
        break;
      case 'closed':
        middleState = 'closing';
        break;
    }
    if (thing.cloud_connection && thing.radio_connection === 'connected') {

      timer(2000)
        .subscribe(() => {
          this.thingStateEvent.emit({
            message: {
              device_id: thing.id,
              state: middleState as ThingState
            }
          });
          thing.state = middleState as ThingState;
        });
      timer(6000)
        .subscribe(() => {
          this.thingStateEvent.emit({
            message: {
              device_id: thing.id,
              state: newState as ThingState
            }
          });
          thing.state = newState as ThingState;
        });
    }
  }

  private generatePressureTests() {
    _.forEach(_.flatMap(this.propertyDemoResource.things), (thing: Thing) => {
      this.generatePressureTestsForThing(thing.id);
    });
  }

  private minutesOfDay(m): number {
    return m.minutes() + m.hours() * 60;
  }

  private generatePressureTestsForThing(thingId: string) {
    const today = moment();
    const startOfMonth = today.clone().startOf('month');
    const startOfPreviousMonth = startOfMonth.clone().subtract(1, 'months');
    const difference = today.diff(startOfPreviousMonth, 'days');
    for (let i = 0; i <= difference; i++) {
      const testDate = startOfPreviousMonth.clone().add(i, 'days');
      const pressureTest = {
        avg_pressure: 3243.25,
        created_at: testDate.format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
        id: Math.random().toString(36),
        measurements: this.pressureTestPassedMeasurements,
        passed: true,
        percentage_tendency: -0.1539408866995074,
        started_at: testDate.add(10, 'minutes').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
        finished_at: testDate.add(20, 'minutes').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
        state: 'finished',
        status: 'okay',
        tendency_eligible_for_fail: -15,
        thing_id: thingId
      };
      if (thingId === 'aef65e22-apt-1-thing' && testDate.clone().startOf('day').valueOf() === today.clone().startOf('day').subtract(1, 'days').valueOf()) {
        _.set(pressureTest, 'passed', false);
        _.set(pressureTest, 'avg_pressure', 1813.85);
        _.set(pressureTest, 'measurements', this.pressureTestNotPassedMeasurements);
      }

      if (thingId === 'f7b44f5c-apt-3-thing' && testDate.clone().startOf('day').valueOf() === today.clone().startOf('day').subtract(1, 'days').valueOf()) {
        _.set(pressureTest, 'passed', false);
        _.set(pressureTest, 'status', 'abandon_water_run');
        _.set(pressureTest, 'avg_pressure', 2774.5);
        _.set(pressureTest, 'measurements', [{
          pressure: 3088,
          probe_state: 'in_progress',
          probed_at: 1634020822717,
          state: 'okay'
        }, {pressure: 3088, probe_state: 'in_progress', probed_at: 1634020854400, state: 'okay'}, {
          pressure: 3088,
          probe_state: 'in_progress',
          probed_at: 1634020886084,
          state: 'okay'
        }, {pressure: 1834, probe_state: 'in_progress', probed_at: 1634020888128, state: 'abandon_water_run'}]);
      }

      _.set(this.pressureTestsCalendars, `${thingId}.${testDate.format('YYYY-MM-DD')}`, pressureTest);
    }

  }
}

export interface OptionalHttpParams {
  [param: string]: string | string[];
}
