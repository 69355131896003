import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {PropertyResource} from '../resources/property.resource';
import {PropertiesResponse, PropertyPaginatedResponse, PropertyResponse} from '../../models/property';
import {PropertySettings} from '../../models/property.settings';
import {ThingsResponse} from '../../models/thing';
import {tap} from 'rxjs/operators';
import * as _ from 'lodash';
import {NotificationSettings} from '../../models/notification-settings';
import {PropertyDemoResource} from '../demo-resources/property.demo.resource';
import {OptionalHttpParams} from '../resources/thing.resource';

@Injectable({
    providedIn: 'root',
})
export class PropertyService {
    private resource: PropertyResource | PropertyDemoResource;
    private propertiesSettings: PropertiesSettings = {};

    private propertiesCached = false;
    private PROPERTIES_CACHE_KEY = 'properties-cached';

    constructor(private propertyResource: PropertyResource,
                private propertyDemoResource: PropertyDemoResource) {
        this.resource = this.propertyResource;
    }

    public toggleDemo(state: boolean): void {
        this.resource = state ? this.propertyDemoResource : this.propertyResource;
    }

    public getProperty(id: string): Observable<PropertyResponse> {
        return this.resource.getProperty(id);
    }

    public getPropertiesListPaginated(params?: OptionalHttpParams): Observable<PropertyPaginatedResponse> {
        return this.propertyResource.getPropertiesListPaginated(params);
    }
    public getPropertiesList(forceReload = false): Observable<PropertiesResponse> {
        if (this.propertiesCached && forceReload === false) {
            return of(JSON.parse(localStorage.getItem(this.PROPERTIES_CACHE_KEY)));
        }
        return this.resource.getPropertiesList()
            .pipe(tap(properties => {
                this.propertiesCached = true;
                localStorage.setItem(this.PROPERTIES_CACHE_KEY, JSON.stringify(properties));
            }));
    }

    public getSettings(propertyId: string, force = false): Observable<PropertySettings> {
        const settings = _.get(this.propertiesSettings, propertyId);
        if (settings && !force) {
            return of(settings);
        } else {
            return this.resource.getSettings(propertyId)
                .pipe(tap((response) => {
                    _.set(this.propertiesSettings, propertyId, response);
                }));
        }
    }

    public updateSettings(propertyId: string, payload: PropertySettings): Observable<PropertySettings> {
        return this.propertyResource.updateSettings(propertyId, payload);
    }

    public getThingsByPropertyId(propertyId: string): Observable<ThingsResponse> {
        return this.resource.getThingsByPropertyId(propertyId);
    }

    public getNotificationSettings(propertyId: string): Observable<NotificationSettings> {
        return this.resource.getNotificationSettings(propertyId);
    }

    public restoreDefaultNotificationSettings(propertyId: string): Observable<NotificationSettings> {
        return this.propertyResource.restoreDefaultNotificationSettings(propertyId);
    }

    public updateNotificationSettings(propertyId: string, payload: NotificationSettings): Observable<NotificationSettings> {
        return this.resource.updateNotificationSettings(propertyId, payload);
    }
}

export interface PropertiesSettings {
    [param: string]: PropertySettings;
}
