import {Component, Inject, OnInit} from '@angular/core';
import {predefinedHighVolumeOptions, predefinedLongFlowOptions} from '../../settings/settings.config';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PropertyObj} from '../../../models/property';
import {PropertyService} from '../../../providers/services/property.service';
import * as _ from 'lodash';
import {MatSnackBar} from '@angular/material/snack-bar';
import {forkJoin} from 'rxjs';
import * as moment from 'moment-timezone';
import {NgxMaterialTimepickerTheme} from 'ngx-material-timepicker';

@Component({
    selector: 'app-mass-edit-dialog',
    templateUrl: './mass-edit-dialog.component.html',
    styleUrls: ['./mass-edit-dialog.component.scss']
})
export class MassEditDialogComponent implements OnInit {

    public timeOfPressureTests: string;
    public predefinedHighVolumeOptions = predefinedHighVolumeOptions;
    public predefinedLongFlowOptions = predefinedLongFlowOptions;
    public value: any;
    public timePickerTheme: NgxMaterialTimepickerTheme = {
        container: {
            buttonColor: '#2C7BE5'
        },
        dial: {
            dialBackgroundColor: '#2C7BE5',
        },
        clockFace: {
            clockHandColor: '#2C7BE5',
        }
    };

    constructor(@Inject(MAT_DIALOG_DATA) public data: {
                    propertyData: PropertyObj[];
                    field: string;
                    mode: string;
                    label: string;
                },
                private snackBar: MatSnackBar,
                private dialogRef: MatDialogRef<MassEditDialogComponent>,
                private propertyService: PropertyService) {
    }

    ngOnInit(): void {
        if (this.data.field==='pressure_tests_schedule') {
            this.timeOfPressureTests = '3:00';
        }
    }

    public updateTime(event: string): void {
        this.value = moment.utc(event, 'h:mm A').format(moment.HTML5_FMT.TIME_MS);
    }

    public getNumberOfSelectedProperties(): number {
        return _.size(_.filter(this.data.propertyData, (item) => item.isChecked));
    }

    public save(): void {
        const subs = [];
        _.forEach(_.filter(this.data.propertyData, (item) => item.isChecked), (propertyObj) => {
            _.set(propertyObj, `${this.data.mode}.${this.data.field}`, this.value);
            if (this.data.mode==='notification') {
                subs.push(this.propertyService.updateNotificationSettings(propertyObj.property.id, _.get(propertyObj, this.data.mode)));
            } else {
                subs.push(this.propertyService.updateSettings(propertyObj.property.id, _.get(propertyObj, this.data.mode)));
            }

        });
        forkJoin(subs)
            .subscribe(() => {
                this.snackBar.open('Data saved', null, {
                    verticalPosition: 'top',
                    horizontalPosition: 'right',
                    duration: 5000
                });
                this.close(true);
            });
    }

    public close(refresh = false): void {
        this.dialogRef.close(refresh);
    }

}
