export const pressureTestNotPassedMeasurementsAguilar =
[{
  pressure: 3133,
  probe_state: 'in_progress',
  probed_at: 1631159974889,
  state: 'okay'
}, {pressure: 2855, probe_state: 'in_progress', probed_at: 1631160006576, state: 'okay'}, {
  pressure: 2678,
  probe_state: 'in_progress',
  probed_at: 1631160038302,
  state: 'okay'
}, {pressure: 2515, probe_state: 'in_progress', probed_at: 1631160070016, state: 'okay'}, {
  pressure: 2364,
  probe_state: 'in_progress',
  probed_at: 1631160101701,
  state: 'okay'
}, {pressure: 2223, probe_state: 'in_progress', probed_at: 1631160133404, state: 'okay'}, {
  pressure: 2092,
  probe_state: 'in_progress',
  probed_at: 1631160165087,
  state: 'okay'
}, {pressure: 1969, probe_state: 'in_progress', probed_at: 1631160196796, state: 'okay'}, {
  pressure: 1855,
  probe_state: 'in_progress',
  probed_at: 1631160228480,
  state: 'okay'
}, {pressure: 1747, probe_state: 'in_progress', probed_at: 1631160260186, state: 'okay'}, {
  pressure: 1647,
  probe_state: 'in_progress',
  probed_at: 1631160291872,
  state: 'okay'
}, {pressure: 1553, probe_state: 'in_progress', probed_at: 1631160323574, state: 'okay'}, {
  pressure: 1465,
  probe_state: 'in_progress',
  probed_at: 1631160355261,
  state: 'okay'
}, {pressure: 1382, probe_state: 'in_progress', probed_at: 1631160386963, state: 'okay'}, {
  pressure: 1305,
  probe_state: 'in_progress',
  probed_at: 1631160418651,
  state: 'okay'
}, {pressure: 1231, probe_state: 'in_progress', probed_at: 1631160450352, state: 'okay'}, {
  pressure: 1161,
  probe_state: 'in_progress',
  probed_at: 1631160482037,
  state: 'okay'
}, {pressure: 1095, probe_state: 'in_progress', probed_at: 1631160513742, state: 'okay'}, {
  pressure: 1033,
  probe_state: 'in_progress',
  probed_at: 1631160545426,
  state: 'okay'
}, {pressure: 974, probe_state: 'in_progress', probed_at: 1631160577132, state: 'okay'}];

export const pressureTestPassedMeasurementsAguilar = [{
pressure: 3248,
probe_state: 'in_progress',
probed_at: 1631851168245,
state: 'okay'
}, {
pressure: 3243,
probe_state: 'in_progress',
probed_at: 1631851199928,
state: 'okay'
}, {pressure: 3243, probe_state: 'in_progress', probed_at: 1631851231633, state: 'okay'}, {
pressure: 3243,
probe_state: 'in_progress',
probed_at: 1631851263316,
state: 'okay'
}, {pressure: 3243, probe_state: 'in_progress', probed_at: 1631851295023, state: 'okay'}, {
pressure: 3243,
probe_state: 'in_progress',
probed_at: 1631851326727,
state: 'okay'
}, {pressure: 3243, probe_state: 'in_progress', probed_at: 1631851358412, state: 'okay'}, {
pressure: 3243,
probe_state: 'in_progress',
probed_at: 1631851390115,
state: 'okay'
}, {pressure: 3243, probe_state: 'in_progress', probed_at: 1631851421799, state: 'okay'}, {
pressure: 3243,
probe_state: 'in_progress',
probed_at: 1631851453505,
state: 'okay'
}, {pressure: 3243, probe_state: 'in_progress', probed_at: 1631851485188, state: 'okay'}, {
pressure: 3243,
probe_state: 'in_progress',
probed_at: 1631851516893,
state: 'okay'
}, {pressure: 3243, probe_state: 'in_progress', probed_at: 1631851548577, state: 'okay'}, {
pressure: 3243,
probe_state: 'in_progress',
probed_at: 1631851580282,
state: 'okay'
}, {pressure: 3243, probe_state: 'in_progress', probed_at: 1631851611965, state: 'okay'}, {
pressure: 3243,
probe_state: 'in_progress',
probed_at: 1631851643671,
state: 'okay'
}, {pressure: 3243, probe_state: 'in_progress', probed_at: 1631851675355, state: 'okay'}, {
pressure: 3243,
probe_state: 'in_progress',
probed_at: 1631851707062,
state: 'okay'
}, {pressure: 3243, probe_state: 'in_progress', probed_at: 1631851738747, state: 'okay'}, {
pressure: 3243,
probe_state: 'in_progress',
probed_at: 1631851770452,
state: 'okay'
}];

export const totalUsagesAguilar = {
    'aef65e22-apt-1-thing': {
      day: 165755,
      year: 142121000
    },
    '1aa197d0-apt-2-thing': {
      day: 1,
      year: 217121000
    },
    'f7b44f5c-apt-3-thing': {
      day: 214412,
      year: 512125000
    },
    'aef65e22-apt-4-thing': {
      day: 114412,
      year: 432125000
    },
    'aef65e22-apt-5-thing': {
      day: 64412,
      year: 312325000
    },
    'aef65e22-apt-6-thing': {
      day: 187412,
      year: 562525000
    },
    'aef65e22-apt-7-thing': {
      day: 89412,
      year: 374525000
    },
    'aef65e22-apt-8-thing': {
      day: 214412,
      year: 512125000
    },
    'aef65e22-apt-9-thing': {
      day: 78412,
      year: 963425000
    },
    'aef65e22-apt-10-thing': {
      day: 385412,
      year: 246725000
    },
    'aef65e22-apt-11-thing': {
      day: 285412,
      year: 312525000
    },
    'aef65e22-apt-12-thing': {
      day: 114412,
      year: 513525000
    },
    'aef65e22-apt-13-thing': {
      day: 14412,
      year: 112125000
    },
    'aef65e22-apt-14-thing': {
      day: 982412,
      year: 1012125000
    },
    'aef65e22-apt-15-thing': {
      day: 254412,
      year: 412155000
    },
    'aef65e22-apt-16-thing': {
      day: 114412,
      year: 522125000
    },
    'aef65e22-apt-17-thing': {
      day: 654412,
      year: 874125000
    },
    'aef65e22-apt-18-thing': {
      day: 212412,
      year: 522125000
    },
    'aef65e22-apt-19-thing': {
      day: 333412,
      year: 465125000
    },
    'aef65e22-apt-20-thing': {
      day: 211412,
      year: 522125000
    },
    'aef65e22-apt-21-thing': {
      day: 255412,
      year: 412125000
    },
    'aef65e22-apt-22-thing': {
      day: 334412,
      year: 124125000
    }
  };
  
  
  export const pressureTestsCalendarsAguilar = {
  
    'aef65e22-apt-1-thing': {
      '2021-10-08': {}
    },
    '1aa197d0-apt-2-thing': {
      '2021-10-09': {}
    },
    'f7b44f5c-apt-3-thing': {
      '2021-10-20': {},
    }
  };
  
