import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppComponent} from './app/app.component';
import {AuthComponent} from './auth/auth.component';
import {RouterModule} from '@angular/router';
import {ExpandableMenuComponent} from './parts/expandable-menu/expandable-menu.component';


@NgModule({
    declarations: [AppComponent, AuthComponent, ExpandableMenuComponent],
    exports: [
        AppComponent,
        AuthComponent
    ],
    imports: [
        CommonModule,
        RouterModule
    ]
})
export class LayoutsModule {
}
