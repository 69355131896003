<app-layout-app>
    <div class="main-content">
        <!-- HEADER -->
        <div class="header pb-5">
            <div class="container-fluid">
                <!-- Body -->
                <div class="header-body">
                    <div class="row align-items-end">
                        <div class="col">
                            <h1 class="m-0 py-4">
                                Settings
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid ">
            <div class="row">
                <div class="col-lg-12">

                    <div class="card">
                        <div class="card-header">
                            <span>Account info</span>
                            <button class="ml-auto d-block btn btn-outline-primary btn-sm"
                                    mat-ripple
                                    (click)="openUserEditDialog();">
                                <i class="fe fe-edit-2"></i> Edit
                            </button>
                        </div>
                        <div class="card-body account-card"
                             *ngIf="user">
                            <dl>
                                <dt>First name</dt>
                                <dd>{{ user.first_name }}</dd>
                            </dl>
                            <dl>
                                <dt>Last name</dt>
                                <dd>{{ user.last_name }}</dd>
                            </dl>
                            <dl>
                                <dt>E-mail</dt>
                                <dd>{{ user.email }}</dd>
                            </dl>
                            <dl>
                                <dt>Phone</dt>
                                <dd>{{ user.phone }}</dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <!-- Title -->
                            <h4 class="card-header-title">
                                Property settings
                            </h4>
                        </div>
                        <div class="card-body">
                            <mat-tab-group mat-align-tabs="center"
                                           [selectedIndex]="0">
                                <mat-tab label="Standard">
                                    <ng-template matTabContent>
                                        <div class="tab-inner">
                                            <app-notification-settings-page></app-notification-settings-page>
                                        </div>
                                    </ng-template>
                                </mat-tab>
                                <mat-tab label="Advanced">
                                    <ng-template matTabContent>
                                        <div class="tab-inner">
                                            <app-settings-table></app-settings-table>
                                        </div>
                                    </ng-template>
                                </mat-tab>
                            </mat-tab-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-layout-app>
