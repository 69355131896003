import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PhoneInputComponent} from './phone-input.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';


@NgModule({
    declarations: [
        PhoneInputComponent
    ],
    exports: [
        PhoneInputComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
    ]
})
export class PhoneInputModule {
}
