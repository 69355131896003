import {
    AfterViewInit,
    Component,
    ElementRef,
    HostBinding,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    NgControl,
    ValidationErrors,
    Validator
} from '@angular/forms';
import intlTelInput from 'intl-tel-input';
import {Subject, Subscription, timer} from 'rxjs';
import {MatFormFieldControl} from '@angular/material/form-field';

class MyTel {
    constructor(public area: string, public exchange: string, public subscriber: string) {
    }
}


@Component({
    selector: 'app-phone-input',
    templateUrl: './phone-input.component.html',
    styleUrls: ['./phone-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PhoneInputComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: PhoneInputComponent
        },
        {
            provide: MatFormFieldControl,
            useExisting: PhoneInputComponent
        }
    ]
})
export class PhoneInputComponent implements OnInit, ControlValueAccessor, Validator, AfterViewInit, OnDestroy, MatFormFieldControl<MyTel> {


    static nextId = 0;
    @ViewChild('telInput') telInput: ElementRef;
    @Input() cssClass: string;
    @HostBinding('attr.aria-describedby') describedBy = '';
    @HostBinding() id = `phone-input-${PhoneInputComponent.nextId++}`;
    public _value: string;
    public _placeholder: string;
    public touched = false;
    public disabled = false;
    public focused = false;
    public required = false;
    public stateChanges = new Subject<void>();
    public controlType = 'phoneinput';
    public ngControl: NgControl;

    public errorState = false;
    private internationalPhoneInput: any;

    private debounceTime = 300;

    private debounceSub: Subscription;

    constructor(public injector: Injector) {
    }

    /* eslint-disable @typescript-eslint/member-ordering */

    @Input() get placeholder() {
        return this._placeholder;
    }

    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    get empty() {
        if (!this.internationalPhoneInput) {
            return false;
        }
        return !this.internationalPhoneInput.getNumber();
    }

    @HostBinding('class.floating')
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    get value(): any {
        return this._value;
    }

    set value(value) {
        this._value = value;
        this.setNumberValue(this._value);
        this.onChange(value);
        this.stateChanges.next();
    }

    /* eslint-enable @typescript-eslint/member-ordering */


    setDescribedByIds(ids: string[]) {
        this.describedBy = ids.join(' ');
    }

    ngOnInit() {
        this.ngControl = this.injector.get(NgControl);
        if (this.ngControl!=null) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngAfterViewInit(): void {
        this.internationalPhoneInput = intlTelInput(this.telInput.nativeElement, {
            preferredCountries: ['gb', 'pl', 'us'],
            separateDialCode: false
        });
        this.telInput.nativeElement.addEventListener('countrychange', () => {
            this.onPhoneChange();
        });
    }

    ngOnDestroy(): void {
        this.destroyDebounceSub();
        this.stateChanges.complete();
    }

    onChange = (phone: string) => {
    };

    onTouched = () => {
    };

    onFocus = () => {
        this.telInput.nativeElement.focus();
    };

    onContainerClick(event: MouseEvent) {
        if ((event.target as Element).tagName.toLowerCase()!=='div') {
            this.telInput.nativeElement.focus();
        }
    }

    public writeValue(phone: string): void {
        this.setNumberValue(phone);
        this.stateChanges.next();
    }

    public registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: any): void {
        this.onTouched = onTouched;
    }

    public markAsTouched(): void {
        if (this.touched) {
            return;
        }
        this.onTouched();
        this.touched = true;
    }

    public setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    public validate(control: AbstractControl): ValidationErrors | null {

        if (this.internationalPhoneInput && !this.internationalPhoneInput.isValidNumber()) {
            this.errorState = true;
            this.stateChanges.next();
            return {
                invalidPhone: true
            };
        } else {
            this.errorState = false;
        }
        this.stateChanges.next();
        return null;
    }

    public onPhoneChange(): void {
        this.destroyDebounceSub();
        this.debounceSub = timer(this.debounceTime)
            .subscribe(() => this.onChange(this.internationalPhoneInput.getNumber()));

    }

    private destroyDebounceSub(): void {
        if (this.debounceSub) {
            this.debounceSub.unsubscribe();
        }
    }

    private setNumberValue(number: string): void {
        if (!number) {
            return;
        }
        if (this.internationalPhoneInput) {
            this.internationalPhoneInput.setNumber(number);
        } else {
            timer(40)
                .subscribe(() => this.setNumberValue(number));
        }
    }
}
