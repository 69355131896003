<app-layout-app>
	<div class="main-content">
		<!-- HEADER -->
		<div class="header pb-5">
			<div class="container-fluid">
				<!-- Body -->
				<div class="header-body">
					<div class="row align-items-end">
						<div class="col">
							<h1 class="m-0 py-4">
								Dashboard
							</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid ">

			<div class="row">
                <div class="col-12 col-lg-4 col-xl">

					<!-- Card -->
					<div class="card">
						<div class="card-body cursor-pointer"
							 mat-ripple
							 routerLink="/properties">
							<div class="row align-items-center">
								<div class="col">

									<!-- Title -->
									<h6 class="text-uppercase text-muted mb-2">
										Number of properties
									</h6>

									<!-- Heading -->
									<span *ngIf="properties"
										  class="h2 mb-0">
                            {{properties.length}}
                        </span>

								</div>
								<div class="col-auto">

									<!-- Icon -->
									<span class="h2 fe fe-home text-muted mb-0"></span>

								</div>
							</div> <!-- / .row -->
						</div>
					</div>

				</div>

                <div class="col-12 col-lg-4 col-xl">

					<!-- Card -->
					<div class="card">
						<div class="card-body  cursor-pointer"
							 mat-ripple
							 routerLink="/sonics">
							<div class="row align-items-center">
								<div class="col">

									<!-- Title -->
									<h6 class="text-uppercase text-muted mb-2">
										Devices
									</h6>
                                    <ng-container *ngIf="properties">
                                        <!-- Heading -->
                                        <span class="h2 mb-0">{{ getNumberOfAllHubs(properties) }}</span>

                                        <!-- Badge -->
                                        <span
                                            class="badge badge-soft-success mt-n2 ml-3">connected {{ getNumberOfConnectedHubs(properties) }}</span>
                                    </ng-container>
								</div>
								<div class="col-auto">

									<!-- Icon -->
									<span class="h2 fe fe-droplet text-muted mb-0"></span>

								</div>
							</div> <!-- / .row -->
						</div>
					</div>

				</div>
                <div class="col-12 col-lg-4 col-xl">

					<!-- Card -->
					<div class="card">
						<div class="card-body cursor-pointer"
							 mat-ripple
							 routerLink="/incidents">
							<div class="row align-items-center">
								<div class="col">

									<!-- Title -->
									<h6 class="text-uppercase text-muted mb-2">
										Active incidents
									</h6>
									<ng-container *ngIf="numberOfIncidents">
										<!-- Heading -->
										<span class="h2 mb-0">{{ numberOfIncidents['active']}}</span>
									</ng-container>
								</div>
								<div class="col-auto">

									<!-- Icon -->
									<span class="h2 fe fe-alert-triangle  mb-0"
										  [ngClass]="{'text-danger':numberOfIncidents['active']>0,'text-muted':numberOfIncidents['active']===0}"></span>

								</div>
							</div> <!-- / .row -->
						</div>
					</div>
				</div>
			</div>

        </div>
	</div>
</app-layout-app>
