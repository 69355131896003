import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Thing} from '../../../models/thing';
import {Hub} from '../../../models/hub';
import {ThingService} from '../../../providers/services/thing.service';

@Component({
    selector: 'app-wifi-strength',
    templateUrl: './wifi-strength.component.html',
    styleUrls: ['./wifi-strength.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WifiStrengthComponent {

    @Input() thing: Thing | Hub;

    constructor(private thingService: ThingService) {
    }

    public getWifiStrength(thing: Thing | Hub): { label: string; css: string } {
        return this.thingService.getWifiStrength(thing);
    }

}
