<app-layout-app>
    <div class="main-content">
        <!-- HEADER -->
        <div class="header pb-5">
            <div class="container-fluid">
                <!-- Body -->
                <div class="header-body">
                    <div class="row align-items-end">
                        <div class="col">
                            <h1 class="m-0 py-4">Incidents</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12" *ngIf="thingsInProperties">
                    <div class="card">
                        <div class="card-body">

                            <mat-tab-group mat-align-tabs="center"
                                           [selectedIndex]="0"
                                           #mainTabGroup>
                                <mat-tab label='Active'>
                                    <ng-template matTabContent>
                                        <div class="tab-inner">

                                            <app-incidents-table
                                                [columns]="columns"
                                                [incidentColor]="incidentColor"
                                                [incidentsTypes]="incidentsTypes"
                                                [title]="'Active incidents'"
                                                [incidentType]="'active'"
                                            ></app-incidents-table>
                                        </div>
                                    </ng-template>
                                </mat-tab>
                                <mat-tab label='Closed'>
                                    <ng-template matTabContent>
                                        <div class="tab-inner">
                                            <app-incidents-table
                                                [columns]="columns"
                                                [incidentColor]="incidentColor"
                                                [incidentsTypes]="incidentsTypes"
                                                [title]="'Closed incidents'"
                                                [incidentType]="'closed'"
                                            ></app-incidents-table>
                                        </div>
                                    </ng-template>
                                </mat-tab>
                            </mat-tab-group>


                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</app-layout-app>
