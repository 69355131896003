<ng-container *ngIf="thing">
  <div class="row">
    <div class="col-10 col-lg-6 mx-auto py-5">
      <img src="assets/images/sonic-image.svg"
           alt=""/>
    </div>
    <div class="col-lg-6">
      <ng-container *ngIf="waterFlowTelemetryChange() | async as telemetry; else notFlowing">
        <ng-container *ngIf="telemetry.message.water_flow>0; else notFlowing">

          Ongoing event:
          <div class="water-flowing-data">
            <div class="flow">
              Flow: <strong><span class="value">{{telemetry.message.water_flow | number: '1.1-1'}}</span>&nbsp;<span
              class="unit">l/min</span>
            </strong>
            </div>
            <div class="time"
                 *ngIf="telemetry.ongoing_event; else noOngoing"><span class="value">
							Duration: <strong><app-humanize-duration [duration]="ongoingEventDuration"
                                                       [durationDisplayThreshold]="0"
                                                       language="shortEn"></app-humanize-duration></strong></span>
            </div>
            <div class="volume"
                 *ngIf="telemetry.ongoing_event">
              Volume:
              <strong><span class="value">{{telemetry.ongoing_event.volume | number: '1.1-1'}}</span>&nbsp;<span
                class="unit">l</span></strong><br/>

            </div>
          </div>
          <ng-template #noOngoing>
            <div class="w-100 h-100 d-flex   ongoing-loader">
              <p class="mb-0">
                <mat-spinner [diameter]="23"></mat-spinner>
              </p>
            </div>
          </ng-template>
        </ng-container>
      </ng-container>
      <ng-template #notFlowing>
        <div class="water-not-flowing-data">

          <ng-container *ngIf="lastOngoingEvent;">

            <div class="water-flowing-data last-ongoing-event">
              <p class="m-0">Last event</p>

              <div class="volume">
                <span class="value">{{lastOngoingEvent.volume | number: '1.1-1'}}</span>&nbsp;<span
                class="unit">l</span><br/>
                <span class="label">event use</span>
              </div>
              <div class="time"><span class="value"><app-humanize-duration [duration]="lastOngoingEvent.duration"
                                                                           [durationDisplayThreshold]="0"
                                                                           language="shortEn"></app-humanize-duration></span>
              </div>
            </div>

          </ng-container>

        </div>
      </ng-template>
    </div>
  </div>
  <dl>
    <dt>Name</dt>
    <dd>{{thing.name}}</dd>
  </dl>
    <dl>
        <dt>Type</dt>
        <dd>
            <ng-container [ngSwitch]="thing.type">
                <ng-container *ngSwitchCase="'sonic_wifi'">Sonic Wi-Fi</ng-container>
                <ng-container *ngSwitchCase="'sonic_pro'">Sonic Pro</ng-container>
                <ng-container *ngSwitchCase="'smart_valve'">Sonic Pro</ng-container>
                <ng-container *ngSwitchCase="'siryn'">Tile</ng-container>
            </ng-container>
        </dd>
    </dl>
  <dl>
    <dt>Water is</dt>
    <dd>
      <div class="row align-items-center">
        <div class="col-auto">
          {{waterIsFlowing ? "Flowing" : "Not flowing"}}

        </div>
        <div class="col col-lg-5">
          <mat-progress-bar *ngIf="waterIsFlowing"
                            mode="indeterminate"></mat-progress-bar>
        </div>
      </div>
    </dd>
  </dl>
  <dl>
    <dt>
      Water used today
    </dt>
    <dd>
      <ng-container *ngIf="totalUsageStream | async as total; else totalLoading">
        <p class="total-value total-today mb-0">
          <span class="value">{{((total.total_usage_local || total.total_usage) / 1000) |  number : '1.1-1'}}</span>&nbsp;
          <span class="l-font unit">l</span><br/>
        </p>
      </ng-container>
      <ng-template #totalLoading>
        <mat-spinner [diameter]="22"></mat-spinner>
      </ng-template>
    </dd>
  </dl>
  <dl>
    <dt>
      This year usage
    </dt>
    <dd>
      <ng-container *ngIf="yearUsageStream | async as total; else totalLoading">
        <p class="total-value total-year mb-0">
          <span class="value">{{((total.total_usage_local || total.total_usage) / 1000) |  number : '1.0-0'}}</span>&nbsp;
          <span class="l-font unit">l</span><br/>
        </p>
      </ng-container>
    </dd>
  </dl>
  <dl>
    <dt>Battery</dt>
    <dd>
        <app-battery-level [batteryLevel]="thing.battery_level"></app-battery-level>
      {{getBatteryLevel(thing).label}}</dd>
  </dl>
  <dl *ngIf="thing.type !== 'sonic_wifi'">
    <dt>Radio</dt>
    <dd>
      <app-sonic-connection-status [thing]="thing"></app-sonic-connection-status>
      {{ getThingConnectionStatus(thing) }}
    </dd>
  </dl>
  <dl>
    <dt>WiFi</dt>
    <dd>
      <app-wifi-strength [thing]="thing"></app-wifi-strength>
      {{ getWifiStrength(thing).label }}</dd>
  </dl>
  <dl>
    <dt>Valve state</dt>
    <dd class="state-text"
        [matTooltip]="getTooltipText(thing)"
        [matTooltipPosition]="'below'"
        matTooltipClass="tooltip">
            {{returnValveState(thing)}} <mat-icon *ngIf="showQuestionMark(thing)">help_outline</mat-icon></dd>
    <dd>
        <span [ngSwitch]="buttonsLayout(thing)">
        <div *ngSwitchCase="'manualState'" class="flex-conteiner">
            <button class="btn btn-primary"
                    mat-button
                    (click)="beforeChangeState('open',thing.id)">
            <span>Open</span>
            </button>
            <button class="btn btn-primary"
                    mat-button
                    (click)="beforeChangeState('close',thing.id)">
            <ng-container [ngSwitch]="currentState">
                    <span
                    >Close</span>
            </ng-container>
            </button>
        </div>
        <div *ngSwitchCase="'failedState'">
            <button class="btn btn-primary single-button"
                mat-button
                (click)="contactSupport()">
                <span>Contact support</span>
            </button>
        </div>
        <div *ngSwitchCase="'failedWithLowBattery'" class="flex-conteiner-column">
            <button class="btn btn-primary"
                    mat-button
                    (click)="beforeChangeState('close',thing.id)">
            <span>Batteries replaced - close valve</span>
            </button>
            <button class="btn btn-primary"
                    mat-button
                    (click)="beforeChangeState('open',thing.id)">
            <ng-container [ngSwitch]="currentState">
                    <span>Batteries replaced - open valve</span>
            </ng-container>
            </button>
        </div>
        <div *ngSwitchCase="'defaultState'">
            <button class="btn btn-primary single-button"
                mat-button
                (click)="beforeChangeState(currentState !== 'open'?'open':'close',thing.id)"
                [disabled]="currentState !== 'open' && currentState!=='closed'">
            <ng-container [ngSwitch]="currentState">
                <span *ngSwitchCase="'open'">Close valve</span>
                <span *ngSwitchCase="'closed'">Open valve</span>
                <span *ngSwitchCase="'requested_open'">Requested open</span>
                <span *ngSwitchCase="'opening'">Opening valve</span>
                <span *ngSwitchCase="'requested_closed'">Requested close</span>
                <span *ngSwitchCase="'closing'">Closing valve</span>
            </ng-container>
            </button>
        </div>
        </span>
    </dd>
  </dl>
</ng-container>
