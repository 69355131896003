import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PropertyService} from '../../../providers/services/property.service';
import {Property} from '../../../models/property';
import * as _ from 'lodash';

@Component({
    selector: 'app-properties-list',
    templateUrl: './properties-list.component.html',
    styleUrls: ['./properties-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertiesListComponent implements OnInit {

    @Input() autoSelectFirstProperty = false;
    @Input() selectedProperties = [];
    @Input() multiPropertySelectDisabled = true;
    @Output() propertySelect = new EventEmitter<Property[]>();
    @Output() propertiesLoaded = new EventEmitter<Property[]>();
    public properties: Property[];
    public filteredProperties: Property[];
    public searchPhrase: string;

    constructor(private propertyService: PropertyService,
                private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {

        this.fetchProperties();
    }


    /**
     * Resets the list of properties and the search phrase.
     *
     * @return
     */
    resetList(): void {
        this.filteredProperties = this.properties;
        this.searchPhrase = null;
    }

    /**
     * Handles input from searchbar
     *
     * @param event
     */
    public handleInput(event: string) {
        this.filterProperties(event);
        this.cdRef.detectChanges();
    }

    public propertyClicked(property: Property): void {
        if (this.multiPropertySelectDisabled) {
            this.selectedProperties = [property];
            this.propertySelect.emit(this.selectedProperties);
            return;
        }
        this.toggleProperty(property);
        this.propertySelect.emit(this.selectedProperties);
    }

    public includeCheck(property: Property): boolean {
        return this.selectedProperties.includes(property);
    }

    public toggleProperty(property: Property): void {
        if (this.selectedProperties.includes(property)) {
            _.remove(this.selectedProperties, (n) => n.id === property.id);
            return;
        }
        this.selectedProperties.push(property);
    }

    private fetchProperties(): void {
        this.propertyService.getPropertiesList()
            .subscribe((response) => {
                this.properties = _.sortBy(response.properties, (item) => item.name.toLowerCase());
                this.propertiesLoaded.emit(this.properties);
                if (this.autoSelectFirstProperty && this.properties.length) {
                    this.propertyClicked(this.properties[0]);
                }
                this.filterProperties(this.searchPhrase);
                this.cdRef.detectChanges();
            });
    }

    /**
     * Filters the properties list
     *
     * @param search
     * @private
     */
    private filterProperties(search: string = '') {
        const query = search.toLowerCase();
        this.filteredProperties = this.properties
            .filter((property) => this.matchesQuery(property, query));

    }

    /**
     * Perform properties list filtering
     *
     * @param property
     * @param query
     * @private
     */
    private matchesQuery(property: Property, query) {
        // Convert query to lower case once
        const lowerCaseQuery = query.toLowerCase();

        // List the properties you want to check
        const propertiesToCheck = ['name', 'city', 'address_1', 'address_2', 'address_3', 'country', 'postcode'];

        // Check if any property matches the query
        return propertiesToCheck.some(prop => property[prop] && property[prop].toLowerCase().indexOf(lowerCaseQuery) > -1);
    }

}
