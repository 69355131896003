import {AfterViewInit, ChangeDetectorRef, Component, Input, TemplateRef, ViewChild} from '@angular/core';
import {OptionalHttpParams} from '../../../../providers/resources/thing.resource';
import {IncidentsService} from 'src/app/providers/services/incidents.service';
import {TableComponent} from '../../../parts/table/table.component';
import {tableSettings} from 'src/app/models/table-settings';
import {BatteryPayload, HealthCheckPayload, IncidentIndex, IncidentUsagePayload} from '../../../../models/incident';
import {incidentColor, recentTypes} from '../../config';
import {Router} from '@angular/router';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import {TelemetryMessage} from '../../../../models/pusher';

@Component({
    selector: 'app-incidents-table',
    templateUrl: './incidents-table.component.html',
    styleUrls: ['./incidents-table.component.scss']
})
export class IncidentsTableComponent implements AfterViewInit {
    @ViewChild(TableComponent) table: TableComponent;
    @ViewChild('titleColumn') titleColumn: TemplateRef<any>;
    @ViewChild('statusColumn') statusColumn: TemplateRef<any>;
    @ViewChild('severityColumn') severityColumn: TemplateRef<any>;
    @ViewChild('additionalColumn') additionalColumn: TemplateRef<any>;
    @ViewChild('actionsColumn') actionsColumn: TemplateRef<any>;

    @Input() columns = [];
    @Input() incidentColor = incidentColor;
    @Input() incidentsTypes = recentTypes;
    @Input() title: string;
    @Input() incidentType: string;

    public tableSettings = _.cloneDeep(tableSettings);
    public rows: IncidentIndex[];

    constructor(private cdRef: ChangeDetectorRef,
                private router: Router,
                private incidentsService: IncidentsService) {
    }

    public ngAfterViewInit(): void {

        this.columns = [
            {cellTemplate: this.titleColumn, prop: 'title', name: 'Title', flexGrow: 1, sortable: false},
            {cellTemplate: this.statusColumn, prop: 'status', name: 'Status', flexGrow: 0.3, sortable: false},
            {prop: 'property_name', name: 'Property', flexGrow: 0.7, sortable: false},
            {
                cellTemplate: this.severityColumn,
                prop: 'severity',
                name: 'Severity score',
                flexGrow: 0.5,
                sortable: false
            },
            {cellTemplate: this.additionalColumn, name: 'Incident details', flexGrow: 0.8, sortable: false},
            {
                cellTemplate: this.table.dateColumn,
                prop: 'detected_at',
                name: 'Detected at',
                flexGrow: 0.8,
                sortable: false
            },
            {
                cellTemplate: this.actionsColumn,
                name: '', maxWidth: 180, minWidth: 180, flexGrow: 0.5,
                sortable: false
            },
        ];
    }

    public checkIfHasPayload(payload: TelemetryMessage | IncidentUsagePayload | HealthCheckPayload | BatteryPayload): boolean {
        return !(!payload || _.isEmpty(payload));
    }

    public fetchPage(params?: OptionalHttpParams): void {

        Object.assign(params, {status: this.incidentType});
        this.incidentsService.getAllIncidents(params)
            .subscribe((response) => {
                    this.table.setPageParams({
                        page_number: response.page_number,
                        page_size: response.page_size,
                        total_entries: response.total_entries,
                        total_pages: response.total_pages
                    });
                    this.rows = response.content;
                    this.table.isLoading = false;
                    this.cdRef.detectChanges();
                }
            );
    }

    public formatDuration(duration: number): string {
        return moment.duration(duration, 'millisecond').format('m');
    }

    public openSingleIncident(incident: IncidentIndex): void {
        const propertyId = incident.property_id;
        if (!propertyId) {
            return;
        }
        this.router.navigateByUrl(`/incident/${propertyId}/${incident.id}`);
    }
}
