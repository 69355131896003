import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PropertyService} from '../../providers/services/property.service';
import {Property} from '../../models/property';
import {Thing, ThingsResponse} from '../../models/thing';
import * as _ from 'lodash';
import {BehaviorSubject, EMPTY, Observable, Subscription, timer} from 'rxjs';
import {ThingService} from '../../providers/services/thing.service';
import {concatMap, share} from 'rxjs/operators';
import {CalendarPressureTests, PressureTest} from '../../models/pressure-test';
import * as moment from 'moment-timezone';
import {MatCalendar, MatCalendarCellCssClasses} from '@angular/material/datepicker';

@Component({
    selector: 'app-pressure-tests',
    templateUrl: './pressure-tests.component.html',
    styleUrls: ['./pressure-tests.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PressureTestsComponent implements OnInit, OnDestroy {

    @ViewChild(MatCalendar) calendar: MatCalendar<any>;

    public calendarMinDate: Date;
    public timezone: string;
    public selectedProperty: string;
    public thing: Thing;
    public maxDate = new Date();
    public currentPressureTest: PressureTest;
    public notThing: boolean;
    private timerSub: Subscription;
    private currentPeriod: Date;
    private calendarChangeSub: Subscription;
    private calendarSubject: BehaviorSubject<CalendarPressureTests> = new BehaviorSubject(null);
    private isCalendarLoading = false;

    constructor(private propertyService: PropertyService,
                private thingService: ThingService,
                private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
    }

    public ngOnDestroy(): void {
        this.cleanCalendarSubs();
        if (this.timerSub) {
            this.timerSub.unsubscribe();
        }
    }


    public selectProperty(property: Property[]) {
        this.selectedProperty = property[0].id;
        this.currentPressureTest = null;
        this.calendarSubject.next(null);
        this.loadSettings();
        this.fetchThings();
    }

    public getDateClass(pressureTests) {
        return (date: Date): MatCalendarCellCssClasses => {
            const formatDate = moment(date).format('YYYY-MM-DD');
            const test = _.get(pressureTests, formatDate);
            if (test) {
                const cssPayload = {passed: test.passed};
                _.set(cssPayload, test.state, true);
                _.set(cssPayload, test.status, true);
                return cssPayload;
            }
        };
    }

    public readCalendarStream() {
        return this.calendarSubject.asObservable().pipe(share());
    }

    public onCalendarDateSelected(date: Date) {
        this.currentPressureTest = null;
        this.thingService
            .getPropertyPressureTests(this.thing.id, {day: moment(date).format('YYYY-MM-DD'), last: true})
            .subscribe((pressureTests) => {
                this.currentPressureTest = _.head(pressureTests.pressure_tests);
                this.cdRef.detectChanges();
            });

    }

    private loadSettings(): void {
        this.propertyService.getSettings(this.selectedProperty)
            .subscribe((settings) => {
                this.timezone = settings.timezone;
                this.cdRef.detectChanges();
            });
    }

    private fetchThings(): void {
        this.propertyService.getThingsByPropertyId(this.selectedProperty)
            .subscribe((thingsResponse: ThingsResponse) => {
                this.thing = _.head(thingsResponse.things);

                if (this.thing) {
                    this.notThing = false;
                    this.calendarMinDate = this.thing.created_at as Date;
                    this.cleanCalendarSubs();
                    this.loadLastPressureTest(this.thing);
                } else {
                    this.notThing = true;
                }
                if(!this.currentPressureTest && this.thing) {
                    this.onCalendarDateSelected(new Date());
                }
                this.cdRef.detectChanges();
            });
    }


    private cleanCalendarSubs() {
        if (this.calendarChangeSub) {
            this.calendarChangeSub.unsubscribe();
        }
    }

    private loadLastPressureTest(thing: Thing): void {


        this.isCalendarLoading = true;
        this.thingService.getPropertyPressureTests(thing.id, {last: true})
            .pipe(concatMap((response) => {
                const res = response.pressure_tests;
                if (res) {
                    this.cdRef.detectChanges();
                    return this.getPressureTests(thing);
                } else {
                    this.cdRef.detectChanges();
                    return EMPTY;

                }
            }))
            .subscribe((res) => {
                this.handlePressureTestsResponse(res);
            });
    }

    private handlePressureTestsResponse(res: CalendarPressureTests) {
        this.calendarSubject.next(res);
        if (this.timerSub) {
            this.timerSub.unsubscribe();
        }
        this.timerSub = timer(300)
            .subscribe(() => {
                if (this.calendar) {
                    if (!moment(this.currentPeriod).isSame(this.calendar.activeDate, 'month')) {
                        this.calendar.activeDate = moment(this.currentPeriod).toDate();
                    }

                    this.calendar.updateTodaysDate();
                }
                this.cdRef.detectChanges();
                this.isCalendarLoading = false;

                if (this.calendar || this.calendarChangeSub) {
                    const calendarCmp = this.calendar;
                    if (this.calendarChangeSub) {
                        this.calendarChangeSub.unsubscribe();
                    }
                    this.calendarChangeSub = this.calendar.stateChanges
                        .subscribe(() => {
                            if (!moment(this.currentPeriod).isSame(calendarCmp.activeDate, 'day') && !this.isCalendarLoading) {
                                this.currentPeriod = calendarCmp.activeDate;
                                this.calendarChangeSub.unsubscribe();
                                this.getPressureTests(this.thing, true)
                                    .subscribe((tests) => {
                                        this.handlePressureTestsResponse(tests);
                                    });
                                this.cdRef.detectChanges();
                            }

                            if (this.currentPeriod!==calendarCmp.activeDate && !this.isCalendarLoading) {
                            }
                        });
                }
            });

    }

    private getPressureTests(thing: Thing, newLoader = false): Observable<CalendarPressureTests> {

        if (moment(this.calendarMinDate) > moment(this.currentPeriod)) {
            this.currentPeriod = this.calendarMinDate;
        }
        if (this.calendar && !this.currentPeriod) {
            this.currentPeriod = this.calendar.activeDate;
        }
        const {monthStart, monthEnd} = this.formatDate(this.currentPeriod);

        return this.thingService
            .getPressureCalendar(thing.id, {from: monthStart, to: monthEnd});
    }

    private formatDate(date: Date = new Date()) {
        const monthStart = moment(date)
            .startOf('month').format('YYYY-MM-DD HH:mm:ss');
        const monthEnd = moment(date)
            .endOf('month')
            .format('YYYY-MM-DD HH:mm:ss');
        return {monthStart, monthEnd};
    }
}
