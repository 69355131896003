import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

import * as humanizeDuration from 'humanize-duration';

@Component({
    selector: 'app-humanize-duration',
    templateUrl: './humanize-duration.component.html',
    styleUrls: ['./humanize-duration.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HumanizeDurationComponent implements OnInit {
    @Input() durationDisplayThreshold = 5000;
    @Input() duration: number;
    @Input() language = 'en';

    constructor() {
    }

    ngOnInit() {
    }

    public formattedDuration(duration: number): string {

        const str = humanizeDuration(duration, {
            language: this.language,
            largest: 2,
            round: true,
            delimiter: ' ',
            fallbacks: ['en'],
            languages: {
                shortEn: {
                    y: () => 'y',
                    mo: () => 'mo',
                    w: () => 'w',
                    d: () => 'd',
                    h: () => 'h',
                    m: () => 'm',
                    s: () => 's',
                    ms: () => 'ms',
                },
            },
        });

        return str.replace(/([A-Za-z'<>/]+)/g, '<span class="unit">$1</span>');
    }
}
