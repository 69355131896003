import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import * as moment from 'moment';
// @ts-ignore
import * as momentDurationFormatSetup from 'moment-duration-format';
import * as _ from 'lodash';
import {Incident, IncidentIndex} from '../../../models/incident';
import {Pageable, PageableRequestParams} from '../../../models/Pageable';
import {recentTypes} from '../config';
import {MatDialog} from '@angular/material/dialog';
import {IncidentDetailsDialogComponent} from '../incident-details/incident-details-dialog.component';

@Component({
    selector: 'app-incidents-list',
    templateUrl: './incidents-list.component.html',
    styleUrls: ['./incidents-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncidentsListComponent implements OnInit, OnChanges {
    @Input() incidentIndex: Pageable<IncidentIndex>;
    @Output() nextPage: EventEmitter<PageableRequestParams> = new EventEmitter();
    @Output() refresh: EventEmitter<void> = new EventEmitter();
    @Input() propertyId: string;
    @Input() timezone: string;

    public incidents: IncidentIndex[];
    public scrollDisabled = false;
    public recentTypes = recentTypes;
    public showNextPage = false;

    constructor(private matDialog: MatDialog) {
    }

    ngOnChanges(changes): void {
        const current = _.get(changes, 'incidentIndex.currentValue');
        const last = _.get(changes, 'incidentIndex.previousValue');

        if (current.page_number > _.get(last, 'page_number')) {
            this.incidents.push(...this.incidentIndex.content);
        } else if (last && !_.isEqualWith(current.content, last.content)) {
            this.incidents = [...current.content];
        }
    }

    ngOnInit() {
        momentDurationFormatSetup(moment);
        this.incidents = [...this.incidentIndex.content];
        this.checkNext();
    }

    // used for NgFor optimization
    public trackByFn(index, incident: Incident): string {
        return incident.id;
    }

    public readableTime(dateTime): string {
        return moment(dateTime).fromNow();
    }

    public goToDetails(incidentId: string) {
        const ref = this.matDialog.open(IncidentDetailsDialogComponent, {
            data: {
                propertyId: this.propertyId,
                incidentId,
                timezone: this.timezone
            },
            width: '100%',
            maxWidth: '700px',
            panelClass: 'mx-auto',
        });
    }

    public loadNextPage() {
        this.checkNext();
        this.nextPage.emit({
            page: this.incidentIndex.page_number + 1,
            page_size: this.incidentIndex.page_size
        });
        if (this.incidents.length === this.incidentIndex.total_entries) {
            this.scrollDisabled = true;
        }
    }

    public formatDuration(duration: number): string {
        return moment.duration(duration, 'millisecond').format('m');
    }

    private checkNext(): void {
        this.showNextPage = this.incidentIndex.total_entries > this.incidentIndex.page_size * this.incidentIndex.page_number;
    }
}
