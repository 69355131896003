export const properties = [
    {
        active: false,
        address_1: '23 Templedene Avenue',
        address_2: null,
        address_3: null,
        city: 'Staines',
        country: 'United Kingdom',
        id: 'aef65e22-apt-1-property',
        lat: 51.4237569,
        lng: -0.4920096,
        name: 'Main home',
        postcode: 'TW18 1LH',
        spaces: [{
            appliance_categories: [],
            appliances: [],
            default: true,
            hubs: [{
                id: '45c28e92-0415-48f3-8076-d695ba190010',
                name: null,
                online: false,
                type: 'hub',
                wifi_rssi: null
            }],
            id: 'f3d00b5f-d0cd-4d14-a3ed-a412824d0223',
            name: 'Room for entire home'
        }, {
            appliance_categories: [{
                icon_url: 'irrigation',
                id: 300,
                name: 'Irrigation System',
                parent_id: null
            }],
            appliances: [{
                appliance_category_icon: 'irrigation',
                appliance_category_id: 300,
                appliance_category_name: 'Irrigation System',
                brand: null,
                id: '8d480356-40e6-47ad-a03a-bd961a3238c8',
                model: null,
                name: null,
                space_id: '3c394021-102f-4544-9faf-0e6d2f1fc1b4'
            }],
            default: false,
            hubs: [],
            id: '3c394021-102f-4544-9faf-0e6d2f1fc1b4',
            name: 'Custom'
        }],
        uprn: null
    }, {
        active: false,
        address_1: '10 George Street',
        address_2: null,
        address_3: null,
        city: 'London',
        country: 'United Kingdom',
        created_at: '2021-05-10T08:59:48.829088Z',
        id: '1aa197d0-apt-2-property',
        lat: 51.5021546,
        lng: -0.0153349,
        name: 'London flat',
        postcode: 'E14 9QG',
        spaces: [
            {
                appliance_categories: [],
                appliances: [],
                default: true,
                hubs: [
                    {
                        id: '1aa197d0-1f9a-4f0d-9c44-757e3b090010',
                        name: null,
                        online: true,
                        type: 'hub',
                        wifi_rssi: -44
                    }
                ],
                id: 'c16dfb11-df1d-4656-8044-b0005d830f60',
                name: 'Room for entire home'
            },
            {
                appliance_categories: [
                    {
                        icon_url: 'tap',
                        id: 100,
                        name: 'Tap',
                        parent: null,
                        parent_id: null
                    },
                    {
                        icon_url: 'w-machine',
                        id: 200,
                        name: 'Washing Machine',
                        parent: null,
                        parent_id: null
                    },
                    {
                        icon_url: 'dishwasher',
                        id: 210,
                        name: 'Dishwasher',
                        parent: null,
                        parent_id: null
                    },
                    {
                        icon_url: 'fridge',
                        id: 220,
                        name: 'Refrigerator',
                        parent: null,
                        parent_id: null
                    }
                ],
                appliances: [
                    {
                        appliance_category_icon: 'tap',
                        appliance_category_id: 100,
                        appliance_category_name: 'Tap',
                        brand: null,
                        id: 'a58442b9-cdb8-45a9-8b09-0b82ad10d989',
                        model: null,
                        name: 'Kitchen Sink',
                        property_id: '1aa197d0-apt-2-property',
                        space_id: '1ca4b5db-f3e7-4853-8560-234c310df5a5',
                        space_name: 'Kitchen'
                    },
                    {
                        appliance_category_icon: 'dishwasher',
                        appliance_category_id: 210,
                        appliance_category_name: 'Dishwasher',
                        brand: null,
                        id: '773e117a-49b6-4548-be0f-3d38aec1e865',
                        model: null,
                        name: null,
                        property_id: '1aa197d0-apt-2-property',
                        space_id: '1ca4b5db-f3e7-4853-8560-234c310df5a5',
                        space_name: 'Kitchen'
                    },
                    {
                        appliance_category_icon: 'w-machine',
                        appliance_category_id: 200,
                        appliance_category_name: 'Washing Machine',
                        brand: null,
                        id: '991e5df3-757e-45aa-bd9a-911264d5b507',
                        model: null,
                        name: null,
                        property_id: '1aa197d0-apt-2-property',
                        space_id: '1ca4b5db-f3e7-4853-8560-234c310df5a5',
                        space_name: 'Kitchen'
                    },
                    {
                        appliance_category_icon: 'fridge',
                        appliance_category_id: 220,
                        appliance_category_name: 'Refrigerator',
                        brand: null,
                        id: '9578124d-ac55-4be2-b9eb-f2757b7f16b6',
                        model: null,
                        name: 'Fridge',
                        property_id: '1aa197d0-apt-2-property',
                        space_id: '1ca4b5db-f3e7-4853-8560-234c310df5a5',
                        space_name: 'Kitchen'
                    }
                ],
                default: false,
                hubs: [],
                id: '1ca4b5db-f3e7-4853-8560-234c310df5a5',
                name: 'Kitchen'
            }
        ],
        uprn: null
    }, {
        active: true,
        address_1: '11 George Street',
        address_2: null,
        address_3: null,
        city: 'London',
        country: 'United Kingdom',
        created_at: '2021-05-10T09:02:48.829088Z',
        id: 'z7b79fsc-apt-4-property',
        lat: 51.5021546,
        lng: -0.0153349,
        name: 'Second London flat',
        postcode: 'E14 91G',
        spaces: [
            {
                appliance_categories: [],
                appliances: [],
                default: true,
                hubs: [
                    {
                        id: '1aa197d0-1f9a-4f0d-9c44-757e3b090011',
                        name: null,
                        online: true,
                        type: 'hub',
                        wifi_rssi: -44
                    }
                ],
                id: 'c16dfb11-df1d-4656-8044-b0005d830f61',
                name: 'Main room in home'
            },
            {
                appliance_categories: [
                    {
                        icon_url: 'tap',
                        id: 100,
                        name: 'Tap',
                        parent: null,
                        parent_id: null
                    },
                    {
                        icon_url: 'w-machine',
                        id: 200,
                        name: 'Washing Machine',
                        parent: null,
                        parent_id: null
                    },
                    {
                        icon_url: 'fridge',
                        id: 220,
                        name: 'Refrigerator',
                        parent: null,
                        parent_id: null
                    }
                ],
                appliances: [
                    {
                        appliance_category_icon: 'tap',
                        appliance_category_id: 100,
                        appliance_category_name: 'Tap',
                        brand: null,
                        id: 'a58442b9-cdb8-45a9-8b09-0b82ad10d989',
                        model: null,
                        name: 'Kitchen Sink',
                        property_id: 'z7b79fsc-apt-4-property',
                        space_id: '1ca4b5db-f3e7-4853-8560-234c310df5a6',
                        space_name: 'Kitchen'
                    },
                    {
                        appliance_category_icon: 'w-machine',
                        appliance_category_id: 200,
                        appliance_category_name: 'Washing Machine',
                        brand: null,
                        id: '991e5df3-757e-45aa-bd9a-911264d5b507',
                        model: null,
                        name: null,
                        property_id: 'z7b79fsc-apt-4-property',
                        space_id: '1ca4b5db-f3e7-4853-8560-234c310df5a6',
                        space_name: 'Kitchen'
                    },
                    {
                        appliance_category_icon: 'fridge',
                        appliance_category_id: 220,
                        appliance_category_name: 'Refrigerator',
                        brand: null,
                        id: '9578124d-ac55-4be2-b9eb-f2757b7f16b6',
                        model: null,
                        name: 'Fridge',
                        property_id: 'z7b79fsc-apt-4-property',
                        space_id: '1ca4b5db-f3e7-4853-8560-234c310df5a6',
                        space_name: 'Kitchen'
                    }
                ],
                default: false,
                hubs: [],
                id: '1ca4b5db-f3e7-4853-8560-234c310df5a6',
                name: 'Main room'
            }
        ],
        uprn: null
    }, {
        active: true,
        address_1: '12 George Street',
        address_2: null,
        address_3: null,
        city: 'London',
        country: 'United Kingdom',
        created_at: '2021-05-11T09:02:48.829088Z',
        id: 'f7b44f5c-apt-5-property',
        lat: 51.5021546,
        lng: -0.0153349,
        name: 'Third London flat',
        postcode: 'E14 91G',
        spaces: [
            {
                appliance_categories: [],
                appliances: [],
                default: true,
                hubs: [
                    {
                        id: '1aa197d0-1f9a-4f0d-9c44-757e3b090012',
                        name: null,
                        online: true,
                        type: 'hub',
                        wifi_rssi: -44
                    }
                ],
                id: 'c16dfb11-df1d-4656-8044-b0005d830f62',
                name: 'Main room'
            },
            {
                appliance_categories: [
                    {
                        icon_url: 'tap',
                        id: 100,
                        name: 'Tap',
                        parent: null,
                        parent_id: null
                    },
                    {
                        icon_url: 'w-machine',
                        id: 200,
                        name: 'Washing Machine',
                        parent: null,
                        parent_id: null
                    },
                    {
                        icon_url: 'fridge',
                        id: 220,
                        name: 'Refrigerator',
                        parent: null,
                        parent_id: null
                    },
                    {
                        icon_url: 'dishwasher',
                        id: 210,
                        name: 'Dishwasher',
                        parent: null,
                        parent_id: null
                    }
                ],
                appliances: [
                    {
                        appliance_category_icon: 'tap',
                        appliance_category_id: 100,
                        appliance_category_name: 'Tap',
                        brand: null,
                        id: 'a58442b9-cdb8-45a9-8b09-0b82ad10d989',
                        model: null,
                        name: 'Kitchen Sink',
                        property_id: 'f7b44f5c-apt-5-property',
                        space_id: '1ca4b5db-f3e7-4853-8560-234c310df5a7',
                        space_name: 'Kitchen'
                    },
                    {
                        appliance_category_icon: 'w-machine',
                        appliance_category_id: 200,
                        appliance_category_name: 'Washing Machine',
                        brand: null,
                        id: '991e5df3-757e-45aa-bd9a-911264d5b507',
                        model: null,
                        name: null,
                        property_id: 'f7b44f5c-apt-5-property',
                        space_id: '1ca4b5db-f3e7-4853-8560-234c310df5a7',
                        space_name: 'Kitchen'
                    },
                    {
                        appliance_category_icon: 'fridge',
                        appliance_category_id: 220,
                        appliance_category_name: 'Refrigerator',
                        brand: null,
                        id: '9578124d-ac55-4be2-b9eb-f2757b7f16b6',
                        model: null,
                        name: 'Fridge',
                        property_id: 'f7b44f5c-apt-5-property',
                        space_id: '1ca4b5db-f3e7-4853-8560-234c310df5a7',
                        space_name: 'Kitchen'
                    }
                ],
                default: false,
                hubs: [],
                id: '1ca4b5db-f3e7-4853-8560-234c310df5a7',
                name: 'Main room'
            }
        ],
        uprn: null
    }, {
        active: true,
        address_1: '13 George Street',
        address_2: null,
        address_3: null,
        city: 'London',
        country: 'United Kingdom',
        created_at: '2021-05-11T09:22:48.829088Z',
        id: 'f7b44f5c-apt-6-property',
        lat: 51.5021546,
        lng: -0.0153349,
        name: 'Fourth London flat',
        postcode: 'E14 91G',
        spaces: [
            {
                appliance_categories: [],
                appliances: [],
                default: true,
                hubs: [
                    {
                        id: '1aa197d0-1f9a-4f0d-9c44-757e3b090012',
                        name: null,
                        online: false,
                        type: 'hub',
                        wifi_rssi: -44
                    }
                ],
                id: 'c16dfb11-df1d-4656-8044-b0005d830f63',
                name: 'Main room in home'
            },
            {
                appliance_categories: [
                    {
                        icon_url: 'tap',
                        id: 100,
                        name: 'Tap',
                        parent: null,
                        parent_id: null
                    },
                    {
                        icon_url: 'w-machine',
                        id: 200,
                        name: 'Washing Machine',
                        parent: null,
                        parent_id: null
                    },
                    {
                        icon_url: 'fridge',
                        id: 220,
                        name: 'Refrigerator',
                        parent: null,
                        parent_id: null
                    }
                ],
                appliances: [
                    {
                        appliance_category_icon: 'tap',
                        appliance_category_id: 100,
                        appliance_category_name: 'Tap',
                        brand: null,
                        id: 'a58442b9-cdb8-45a9-8b09-0b82ad10d989',
                        model: null,
                        name: 'Kitchen Sink',
                        property_id: 'f7b44f5c-apt-6-property',
                        space_id: '1ca4b5db-f3e7-4853-8540-234c310df5a6',
                        space_name: 'Kitchen'
                    },
                    {
                        appliance_category_icon: 'w-machine',
                        appliance_category_id: 200,
                        appliance_category_name: 'Washing Machine',
                        brand: null,
                        id: '991e5df3-757e-45aa-bd9a-911264d5b507',
                        model: null,
                        name: null,
                        property_id: 'f7b44f5c-apt-6-property',
                        space_id: '1ca4b5db-f3e7-4853-8540-234c310df5a6',
                        space_name: 'Kitchen'
                    },
                    {
                        appliance_category_icon: 'fridge',
                        appliance_category_id: 220,
                        appliance_category_name: 'Refrigerator',
                        brand: null,
                        id: '9578124d-ac55-4be2-b9eb-f2757b7f16b6',
                        model: null,
                        name: 'Fridge',
                        property_id: 'f7b44f5c-apt-6-property',
                        space_id: '1ca4b5db-f3e7-4853-8540-234c310df5a6',
                        space_name: 'Kitchen'
                    }
                ],
                default: false,
                hubs: [],
                id: '1ca4b5db-f3e7-4853-8540-234c310df5a6',
                name: 'Main room'
            }
        ],
        uprn: null
    }, {
        active: true,
        address_1: '14 George Street',
        address_2: null,
        address_3: null,
        city: 'London',
        country: 'United Kingdom',
        created_at: '2021-05-11T09:22:48.829088Z',
        id: 'f7b44f5c-apt-7-property',
        lat: 51.5021546,
        lng: -0.0153349,
        name: 'Fifth London flat',
        postcode: 'E14 91G',
        spaces: [
            {
                appliance_categories: [],
                appliances: [],
                default: true,
                hubs: [
                    {
                        id: '1aa197d0-1f9a-4f0d-9c44-757e3b090011',
                        name: null,
                        online: true,
                        type: 'hub',
                        wifi_rssi: -44
                    }
                ],
                id: 'c16dfb11-df1d-4656-8044-b0005d830f61',
                name: 'Main room in home'
            },
            {
                appliance_categories: [
                    {
                        icon_url: 'tap',
                        id: 100,
                        name: 'Tap',
                        parent: null,
                        parent_id: null
                    },
                    {
                        icon_url: 'w-machine',
                        id: 200,
                        name: 'Washing Machine',
                        parent: null,
                        parent_id: null
                    },
                    {
                        icon_url: 'fridge',
                        id: 220,
                        name: 'Refrigerator',
                        parent: null,
                        parent_id: null
                    },
                    {
                        icon_url: 'dishwasher',
                        id: 210,
                        name: 'Dishwasher',
                        parent: null,
                        parent_id: null
                    }
                ],
                appliances: [
                    {
                        appliance_category_icon: 'tap',
                        appliance_category_id: 100,
                        appliance_category_name: 'Tap',
                        brand: null,
                        id: 'a58442b9-cdb8-45a9-8b09-0b82ad10d989',
                        model: null,
                        name: 'Kitchen Sink',
                        property_id: 'f7b44f5c-apt-7-property',
                        space_id: '1ca4b5db-f3e7-9853-8560-234c310df5a6',
                        space_name: 'Main room'
                    },
                    {
                        appliance_category_icon: 'w-machine',
                        appliance_category_id: 200,
                        appliance_category_name: 'Washing Machine',
                        brand: null,
                        id: '991e5df3-757e-45aa-bd9a-911264d5b507',
                        model: null,
                        name: null,
                        property_id: 'f7b44f5c-apt-7-property',
                        space_id: '1ca4b5db-f3e7-9853-8560-234c310df5a6',
                        space_name: 'Main room'
                    },
                    {
                        appliance_category_icon: 'fridge',
                        appliance_category_id: 220,
                        appliance_category_name: 'Refrigerator',
                        brand: null,
                        id: '9578124d-ac55-4be2-b9eb-f2757b7f16b6',
                        model: null,
                        name: 'Fridge',
                        property_id: 'f7b44f5c-apt-7-property',
                        space_id: '1ca4b5db-f3e7-9853-8560-234c310df5a6',
                        space_name: 'Main room'
                    }
                ],
                default: false,
                hubs: [],
                id: '1ca4b5db-f3e7-9853-8560-234c310df5a6',
                name: 'Main room'
            }
        ],
        uprn: null
    }, {
        active: false,
        address_1: 'Częstochowska 38/52 lok.106/107',
        address_2: null,
        address_3: null,
        city: 'Łódź',
        country: 'Poland',
        created_at: '2021-05-10T08:59:48.829088Z',
        id: 'f7b44f5c-apt-3-property',
        lat: 51.7506531,
        lng: 19.481256,
        name: 'Summer residence',
        postcode: '93-121',
        spaces: [
            {
                appliance_categories: [],
                appliances: [],
                default: true,
                hubs: [
                    {
                        id: '1aa197d0-1f9a-4f0d-9c44-757e3b090010',
                        name: null,
                        online: true,
                        type: 'hub',
                        wifi_rssi: -44
                    }
                ],
                id: 'c16dfb11-df1d-4656-8044-b0005d830f60',
                name: 'Room for entire home'
            },
            {
                appliance_categories: [
                    {
                        icon_url: 'tap',
                        id: 100,
                        name: 'Tap',
                        parent: null,
                        parent_id: null
                    },
                    {
                        icon_url: 'w-machine',
                        id: 200,
                        name: 'Washing Machine',
                        parent: null,
                        parent_id: null
                    },
                    {
                        icon_url: 'dishwasher',
                        id: 210,
                        name: 'Dishwasher',
                        parent: null,
                        parent_id: null
                    },
                    {
                        icon_url: 'fridge',
                        id: 220,
                        name: 'Refrigerator',
                        parent: null,
                        parent_id: null
                    }
                ],
                appliances: [
                    {
                        appliance_category_icon: 'tap',
                        appliance_category_id: 100,
                        appliance_category_name: 'Tap',
                        brand: null,
                        id: 'a58442b9-cdb8-45a9-8b09-0b82ad10d989',
                        model: null,
                        name: 'Kitchen Sink',
                        property_id: 'f7b44f5c-apt-3-property',
                        space_id: '1ca4b5db-f3e7-4853-8560-234c310df5a5',
                        space_name: 'Kitchen'
                    },
                    {
                        appliance_category_icon: 'dishwasher',
                        appliance_category_id: 210,
                        appliance_category_name: 'Dishwasher',
                        brand: null,
                        id: '773e117a-49b6-4548-be0f-3d38aec1e865',
                        model: null,
                        name: null,
                        property_id: 'f7b44f5c-apt-3-property',
                        space_id: '1ca4b5db-f3e7-4853-8560-234c310df5a5',
                        space_name: 'Kitchen'
                    },
                    {
                        appliance_category_icon: 'w-machine',
                        appliance_category_id: 200,
                        appliance_category_name: 'Washing Machine',
                        brand: null,
                        id: '991e5df3-757e-45aa-bd9a-911264d5b507',
                        model: null,
                        name: null,
                        property_id: 'f7b44f5c-apt-3-property',
                        space_id: '1ca4b5db-f3e7-4853-8560-234c310df5a5',
                        space_name: 'Kitchen'
                    },
                    {
                        appliance_category_icon: 'fridge',
                        appliance_category_id: 220,
                        appliance_category_name: 'Refrigerator',
                        brand: null,
                        id: '9578124d-ac55-4be2-b9eb-f2757b7f16b6',
                        model: null,
                        name: 'Fridge',
                        property_id: 'f7b44f5c-apt-3-property',
                        space_id: '1ca4b5db-f3e7-4853-8560-234c310df5a5',
                        space_name: 'Kitchen'
                    }
                ],
                default: false,
                hubs: [],
                id: '1ca4b5db-f3e7-4853-8560-234c310df5a5',
                name: 'Kitchen'
            }
        ],
        uprn: null
    }, {
        active: true,
        address_1: 'Częstochowska 38/52 lok.105/107',
        address_2: null,
        address_3: null,
        city: 'Łódź',
        country: 'Poland',
        created_at: '2021-05-10T08:59:48.829088Z',
        id: 'f7b44f5c-apt-8-property',
        lat: 51.7506531,
        lng: 19.481256,
        name: 'Łódz flat',
        postcode: '93-121',
        spaces: [
            {
                appliance_categories: [],
                appliances: [],
                default: true,
                hubs: [{
                    id: '57b9008a-25bd-42f5-adbc-92b8cdd20021',
                    name: null,
                    online: false,
                    type: 'signal_in_sonic_wifi',
                    wifi_rssi: null
                }],
                id: 'c16dfb11-df1d-4656-8044-b0005d830f60',
                name: 'Room for entire home'
            },
            {
                appliance_categories: [
                    {
                        icon_url: 'tap',
                        id: 100,
                        name: 'Tap',
                        parent: null,
                        parent_id: null
                    },
                    {
                        icon_url: 'w-machine',
                        id: 200,
                        name: 'Washing Machine',
                        parent: null,
                        parent_id: null
                    }
                ],
                appliances: [
                    {
                        appliance_category_icon: 'tap',
                        appliance_category_id: 100,
                        appliance_category_name: 'Tap',
                        brand: null,
                        id: 'a58442b9-cdb8-45a9-8b09-0b82ad10d989',
                        model: null,
                        name: 'Kitchen Sink',
                        property_id: 'f7b44f5c-apt-8-property',
                        space_id: '1ca4b5db-f3e7-4853-8560-234c310df5a9',
                        space_name: 'Restroom'
                    },
                    {
                        appliance_category_icon: 'w-machine',
                        appliance_category_id: 200,
                        appliance_category_name: 'Washing Machine',
                        brand: null,
                        id: '991e5df3-757e-45aa-bd9a-911264d5b507',
                        model: null,
                        name: null,
                        property_id: 'f7b44f5c-apt-8-property',
                        space_id: '1ca4b5db-f3e7-4853-8560-234c310df5a9',
                        space_name: 'Restroom'
                    }
                ],
                default: false,
                hubs: [],
                id: '1ca4b5db-f3e7-4853-8560-234c310df5a9',
                name: 'Restroom'
            }
        ],
        uprn: null
    }, {
        active: true,
        address_1: 'Częstochowska 38/52 lok.100/107',
        address_2: null,
        address_3: null,
        city: 'Łódź',
        country: 'Poland',
        created_at: '2021-05-10T08:59:48.829088Z',
        id: 'f7b44f5c-apt-9-property',
        lat: 51.7506531,
        lng: 19.481256,
        name: 'Łódz office',
        postcode: '93-121',
        spaces: [
            {
                appliance_categories: [],
                appliances: [],
                default: true,
                hubs: [{
                    id: '57b9008a-25bd-42f5-adbc-92b8cdd20020',
                    name: null,
                    online: false,
                    type: 'signal_in_sonic_wifi',
                    wifi_rssi: null
                }],
                id: 'c16dfb11-df1d-4656-8044-b0005d830f61',
                name: 'Office'
            },
            {
                appliance_categories: [
                    {
                        icon_url: 'tap',
                        id: 100,
                        name: 'Tap',
                        parent: null,
                        parent_id: null
                    },
                    {
                        icon_url: 'toilet',
                        id: 110,
                        name: 'Toilet',
                        parent: null,
                        parent_id: null
                    }
                ],
                appliances: [
                    {
                        appliance_category_icon: 'tap',
                        appliance_category_id: 100,
                        appliance_category_name: 'Tap',
                        brand: null,
                        id: 'a58442b9-cdb8-45a9-8b09-0b82ad10d989',
                        model: null,
                        name: 'Kitchen Sink',
                        property_id: 'f7b44f5c-apt-8-property',
                        space_id: '1ca4b5db-f3e7-4853-8560-234c310df510',
                        space_name: 'Restroom'
                    },
                    {
                        appliance_category_icon: 'w-machine',
                        appliance_category_id: 200,
                        appliance_category_name: 'Washing Machine',
                        brand: null,
                        id: '991e5df3-757e-45aa-bd9a-911264d5b507',
                        model: null,
                        name: null,
                        property_id: 'f7b44f5c-apt-8-property',
                        space_id: '1ca4b5db-f3e7-4853-8560-234c310df510',
                        space_name: 'Restroom'
                    }
                ],
                default: false,
                hubs: [],
                id: '1ca4b5db-f3e7-4853-8560-234c310df510',
                name: 'Office'
            }
        ],
        uprn: null
    }
];

export const propertySettings = {
    'aef65e22-apt-1-property': {
        adults_count: 2,
        appliance_logging: false,
        auto_shut_off: true,
        children_count: 0,
        currency: 'pounds',
        dont_disturb_mode: false,
        dont_disturb_mode_until: null,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        mode: 'home',
        pets: false,
        pressure_tests_enabled: true,
        pressure_tests_schedule: '03:00:00',
        timezone: 'Europe/London',
        water_cost_per_litre: 0.003
    },
    '1aa197d0-apt-2-property': {
        adults_count: 2,
        appliance_logging: false,
        auto_shut_off: true,
        children_count: 0,
        currency: 'pounds',
        dont_disturb_mode: false,
        dont_disturb_mode_until: null,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        mode: 'home',
        pets: false,
        pressure_tests_enabled: true,
        pressure_tests_schedule: '03:00:00',
        timezone: 'Europe/London',
        water_cost_per_litre: 0.003
    },
    'f7b44f5c-apt-3-property': {
        adults_count: 2,
        appliance_logging: false,
        auto_shut_off: true,
        children_count: 0,
        currency: 'pounds',
        dont_disturb_mode: false,
        dont_disturb_mode_until: null,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        mode: 'home',
        pets: false,
        pressure_tests_enabled: true,
        pressure_tests_schedule: '03:00:00',
        timezone: 'Europe/London',
        water_cost_per_litre: 0.003
    },
    'z7b79fsc-apt-4-property': {
        adults_count: 2,
        appliance_logging: false,
        auto_shut_off: true,
        children_count: 0,
        currency: 'pounds',
        dont_disturb_mode: false,
        dont_disturb_mode_until: null,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        mode: 'home',
        pets: false,
        pressure_tests_enabled: true,
        pressure_tests_schedule: '03:00:00',
        timezone: 'Europe/London',
        water_cost_per_litre: 0.003
    },
    'f7b44f5c-apt-5-property': {
        adults_count: 2,
        appliance_logging: false,
        auto_shut_off: true,
        children_count: 0,
        currency: 'pounds',
        dont_disturb_mode: false,
        dont_disturb_mode_until: null,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        mode: 'home',
        pets: false,
        pressure_tests_enabled: true,
        pressure_tests_schedule: '03:00:00',
        timezone: 'Europe/London',
        water_cost_per_litre: 0.003
    },
    'f7b44f5c-apt-6-property': {
        adults_count: 2,
        appliance_logging: false,
        auto_shut_off: true,
        children_count: 0,
        currency: 'pounds',
        dont_disturb_mode: false,
        dont_disturb_mode_until: null,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        mode: 'home',
        pets: false,
        pressure_tests_enabled: true,
        pressure_tests_schedule: '03:00:00',
        timezone: 'Europe/London',
        water_cost_per_litre: 0.003
    },
    'f7b44f5c-apt-7-property': {
        adults_count: 2,
        appliance_logging: false,
        auto_shut_off: true,
        children_count: 0,
        currency: 'pounds',
        dont_disturb_mode: false,
        dont_disturb_mode_until: null,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        mode: 'home',
        pets: false,
        pressure_tests_enabled: true,
        pressure_tests_schedule: '03:00:00',
        timezone: 'Europe/London',
        water_cost_per_litre: 0.003
    },
    'f7b44f5c-apt-8-property': {
        adults_count: 2,
        appliance_logging: false,
        auto_shut_off: true,
        children_count: 0,
        currency: 'pounds',
        dont_disturb_mode: false,
        dont_disturb_mode_until: null,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        mode: 'home',
        pets: false,
        pressure_tests_enabled: true,
        pressure_tests_schedule: '04:00:00',
        timezone: 'Europe/London',
        water_cost_per_litre: 0.003
    },
    'f7b44f5c-apt-9-property': {
        adults_count: 2,
        appliance_logging: false,
        auto_shut_off: true,
        children_count: 0,
        currency: 'pounds',
        dont_disturb_mode: false,
        dont_disturb_mode_until: null,
        local_api_enabled: false,
        local_api_password: null,
        local_api_username: null,
        mode: 'home',
        pets: false,
        pressure_tests_enabled: true,
        pressure_tests_schedule: '04:00:00',
        timezone: 'Europe/London',
        water_cost_per_litre: 0.003
    }
};

export const notificationSettings = {
    'aef65e22-apt-1-property': {
        cloud_disconnection: true,
        device_handle_moved: true,
        health_check_failed: true,
        high_volume_threshold_litres: 400,
        long_flow_notification_delay_mins: 60,
        low_battery_level: true, low_water_temperature: true,
        pressure_test_failed: true,
        pressure_test_skipped: true, backup_battery_usage: true,
        radio_disconnection: true,
        legionella_risk: true
    },
    '1aa197d0-apt-2-property': {
        cloud_disconnection: true,
        device_handle_moved: true,
        health_check_failed: true,
        high_volume_threshold_litres: 300,
        long_flow_notification_delay_mins: 60,
        low_battery_level: true, low_water_temperature: true,
        pressure_test_failed: true,
        pressure_test_skipped: true, backup_battery_usage: true,
        radio_disconnection: true,
        legionella_risk: true
    },
    'f7b44f5c-apt-3-property': {
        cloud_disconnection: true,
        device_handle_moved: true,
        health_check_failed: true,
        high_volume_threshold_litres: 300,
        long_flow_notification_delay_mins: 60,
        low_battery_level: true, low_water_temperature: true,
        pressure_test_failed: true,
        pressure_test_skipped: true, backup_battery_usage: true,
        radio_disconnection: true,
        legionella_risk: true
    },
    'z7b79fsc-apt-4-property': {
        cloud_disconnection: true,
        device_handle_moved: true,
        health_check_failed: true,
        high_volume_threshold_litres: 300,
        long_flow_notification_delay_mins: 60,
        low_battery_level: true, low_water_temperature: true,
        pressure_test_failed: true,
        pressure_test_skipped: true, backup_battery_usage: true,
        radio_disconnection: true,
        legionella_risk: true
    },
    'f7b44f5c-apt-5-property': {
        cloud_disconnection: true,
        device_handle_moved: true,
        health_check_failed: true,
        high_volume_threshold_litres: 200,
        long_flow_notification_delay_mins: 55,
        low_battery_level: true, low_water_temperature: true,
        pressure_test_failed: true,
        pressure_test_skipped: true, backup_battery_usage: true,
        radio_disconnection: true,
        legionella_risk: true
    },
    'f7b44f5c-apt-6-property': {
        cloud_disconnection: true,
        device_handle_moved: true,
        health_check_failed: true,
        high_volume_threshold_litres: 300,
        long_flow_notification_delay_mins: 60,
        low_battery_level: true, low_water_temperature: true,
        pressure_test_failed: true,
        pressure_test_skipped: true, backup_battery_usage: true,
        radio_disconnection: true,
        legionella_risk: false
    },
    'f7b44f5c-apt-7-property': {
        cloud_disconnection: true,
        device_handle_moved: true,
        health_check_failed: true,
        high_volume_threshold_litres: 300,
        long_flow_notification_delay_mins: 60,
        low_battery_level: true, low_water_temperature: true,
        pressure_test_failed: true,
        pressure_test_skipped: true, backup_battery_usage: true,
        radio_disconnection: true,
        legionella_risk: true
    },
    'f7b44f5c-apt-8-property': {
        cloud_disconnection: true,
        device_handle_moved: true,
        health_check_failed: true,
        high_volume_threshold_litres: 300,
        long_flow_notification_delay_mins: 60,
        low_battery_level: true, low_water_temperature: true,
        pressure_test_failed: true,
        pressure_test_skipped: true, backup_battery_usage: true,
        radio_disconnection: true,
        legionella_risk: true
    },
    'f7b44f5c-apt-9-property': {
        cloud_disconnection: true,
        device_handle_moved: true,
        health_check_failed: true,
        high_volume_threshold_litres: 300,
        long_flow_notification_delay_mins: 60,
        low_battery_level: true, low_water_temperature: true,
        pressure_test_failed: true,
        pressure_test_skipped: true, backup_battery_usage: true,
        radio_disconnection: true,
        legionella_risk: true
    }
};

