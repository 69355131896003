export const userDetailsAguilar = {
    id: '700c8cc7-9fe7-48b6-9113-05f4bf831932',
    email: 'user@hero-labs.com',
    first_name: 'John',
    last_name: 'Doe',
    full_name: 'John Doe',
    language: 'en',
    phone: '555666555',
    roles: [{
        id: '46689b65-f8fd-4bbb-b7e9-4f66b4ebe038',
        name: 'user',
        default: true,
        created_at: '2018-10-12T13:01:18.672Z',
        updated_at: '2018-10-12T13:01:18.691Z'
    }, {
        id: 'd6402128-6cb5-46b5-b5ed-d5706349a8bf',
        name: 'customer',
        default: true,
        created_at: '2018-10-12T13:01:18.681Z',
        updated_at: '2018-10-12T13:01:18.698Z'
    }, {
        id: 'bfcb3bcd-d599-44f8-b44e-a0a66179ca86',
        name: 'admin',
        default: false,
        created_at: '2018-10-12T13:01:18.664Z',
        updated_at: '2018-10-12T13:01:18.664Z'
    }, {
        id: '7601a0b5-1eaf-4e27-80c2-748889d6d82a',
        name: 'root',
        default: false,
        created_at: '2019-08-07T10:02:24.968Z',
        updated_at: '2019-08-07T10:02:24.968Z'
    }, {
        id: '7601a0b5-1eaf-4e27-80c2-748889d6d82a',
        name: 'B2B',
        default: false,
        created_at: '2019-08-07T10:02:24.968Z',
        updated_at: '2019-08-07T10:02:24.968Z'
    }],
    organizations: [{id: '68193f60-796a-451a-bc64-bfd0abe18a57', name: '', active: true}]
};
