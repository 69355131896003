import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {UserService} from './user.service';
import {skipWhile, tap} from 'rxjs/operators';
import {UserResource} from '../resources/user.resource';
import {StorageParser} from '../../common/storage/StorageParser';
import {RefreshTokenResponse, User} from '../../models/user';
import {LoginResponse} from '../../models/login.response';
import {Router} from '@angular/router';
import {PasswordResource} from '../resources/password.resources';
import {DemoService} from './demo.service';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root',
})

export class AuthenticationService {
    public authenticationState = new BehaviorSubject(null);
    private readonly TOKEN_KEY = 'auth-token';

    private readonly USER_KEY = 'user';
    private userRoles = [];
    constructor(private userResource: UserResource,
                private passwordResource: PasswordResource,
                private router: Router,
                private demoService: DemoService,
                private userService: UserService) {
    }

    public checkToken() {
        const user = StorageParser.storageGet<User>(this.USER_KEY);
        const token = localStorage.getItem(this.TOKEN_KEY);
        if (user!==null && token) {
            this.userService.user = user;
            this.refreshToken().subscribe();
        } else {
            this.authenticationState.next(false);
        }
    }

    public refreshToken(): Observable<RefreshTokenResponse> {
        return this.userResource.refreshUserToken()
            .pipe(
                tap((response: RefreshTokenResponse) => {
                    localStorage.setItem(this.TOKEN_KEY, response.token_details);
                    this.processLogin(response.token_details, this.userService.user);
                }, (error) => {
                    this.authenticationState.next(false);
                })
            );
    }

    public signIn(email: string, password: string): Observable<LoginResponse> {
        return this.userService.signInUser(email, password).pipe(
            tap((response) => {
                this.processLogin(response.token_details, response.user_details);
            })
        );
    }


    public getToken(): string {
        return localStorage.getItem(this.TOKEN_KEY);
    }

    public processLogin(token: string, user_details: User) {
        _.find(user_details.roles, (r) => {
            this.userRoles.push(r.name);
        });

        if (_.difference(['B2B', 'customer'], this.userRoles).length) {
            throw Error('Permission');
        }
        this.userService.token = token;
        this.userService.user = user_details;
        this.userService.userId = this.userService.user.id;
        this.userRoles = [];
        localStorage.setItem(this.USER_KEY, JSON.stringify(this.userService.user));
        localStorage.setItem(this.TOKEN_KEY, this.userService.token);
        this.authenticationState.next(true);
    }

    public signOut() {
        this.destroySession();
    }

    public destroySession() {
        this.userResource.signOutUser().subscribe(
            (res) => {
                this.clearSessionData();
                this.router.navigate(['login']);
            },
            (err) => {
                console.log(`Couldn't destroy the session ${err}`);
                this.demoService.toggleDemo(false);
            }
        );
    }

    public isAuthenticated() {
        return this.authenticationState.value;
    }

    public clearSessionData() {
        localStorage.removeItem(this.USER_KEY);
        localStorage.removeItem(this.TOKEN_KEY);
        this.authenticationState.next(false);
    }

    public getAuthStateStream(): Observable<boolean> {
        return this.authenticationState.asObservable()
            .pipe(skipWhile((val) => val===null));
    }

    public requestPasswordReset(email: string): Observable<string> {
        return this.passwordResource.requestPasswordReset(email);
    }
}
