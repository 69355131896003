import {AuthenticationService} from './authenthication.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {map, skipWhile} from 'rxjs/operators';

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(public authService: AuthenticationService,
                public router: Router) {
    }

    public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.authService.authenticationState.asObservable()
                .pipe(
                        skipWhile((state) => state === null),
                        map((state) => {
                            if (!state) {
                                this.router.navigate(['login'])
                                        .then(() => console.warn('Can not activate route: ' + route.url));
                            }
                            return state;
                        })
                );
    }
}
