import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {TableComponent} from './table/table.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {SearchFormComponent} from './search-form/search-form.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSelectModule} from '@angular/material/select';
import {SystemStatusComponent} from './system-status/system-status.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {HumanizeDurationComponent} from './humanize-duration/humanize-duration.component';
import {MomentModule} from 'ngx-moment';
import {EditAccountComponent} from './edit-account-dialog/edit-account.component';
import {MatRippleModule} from '@angular/material/core';
import {NotificationSettingsComponent} from './notification-settings/notification-settings.component';
import {PropertiesListComponent} from './properties-list/properties-list.component';
import {
    EditNotificationSettingsDialogComponent
} from './edit-notification-settings-dialog/edit-notification-settings-dialog.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {BatteryLevelComponent} from './battery-level/battery-level.component';
import {WifiStrengthComponent} from './wifi-strength/wifi-strength.component';
import {SonicConnectionStatusComponent} from './sonic-connection-status/sonic-connection-status.component';
import {SonicStateComponent} from './sonic-state/sonic-state.component';
import {PhoneInputModule} from '../../common/phone-input/phone-input.module';
import {EventsListComponent} from './events-list/events-list.component';
import {PipesModule} from '../../pipes/pipes.module';
import {
    NotificationSettingsPageComponent
} from '../settings/notification-settings-page/notification-settings-page.component';
import {
    EditPropertySettingsDialogComponent
} from './edit-property-settings-dialog/edit-property-settings-dialog.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {MassEditDialogComponent} from './mass-edit-dialog/mass-edit-dialog.component';


@NgModule({
    declarations: [
        ConfirmDialogComponent,
        SearchFormComponent,
        TableComponent,
        SystemStatusComponent,
        HumanizeDurationComponent,
        EditAccountComponent,
        NotificationSettingsComponent,
        PropertiesListComponent,
        EditNotificationSettingsDialogComponent,
        BatteryLevelComponent,
        WifiStrengthComponent,
        SonicConnectionStatusComponent,
        SonicStateComponent,
        EventsListComponent,
        NotificationSettingsPageComponent,
        EditPropertySettingsDialogComponent,
        MassEditDialogComponent,
    ],
    exports: [
        SearchFormComponent,
        TableComponent,
        SystemStatusComponent,
        HumanizeDurationComponent,
        EditAccountComponent,
        PropertiesListComponent,
        NotificationSettingsComponent,
        EditNotificationSettingsDialogComponent,
        BatteryLevelComponent,
        WifiStrengthComponent,
        SonicConnectionStatusComponent,
        SonicStateComponent,
        EventsListComponent,
        NotificationSettingsPageComponent,
    ],
    entryComponents: [
        EditAccountComponent,
        EditNotificationSettingsDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatTooltipModule,
        MatIconModule,
        NgxDatatableModule,
        MatDatepickerModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MomentModule,
        MatRippleModule,
        MatSlideToggleModule,
        PhoneInputModule,
        PipesModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatDatepickerModule,
        MatDatepickerModule,
        MatInputModule,
        MatInputModule,
        MatDatepickerModule,
        MatDatepickerModule,
        NgxMaterialTimepickerModule
    ]
})
export class PartsModule {
}
