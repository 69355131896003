import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutsModule} from './layouts/layouts.module';
import {AuthModule} from './auth/auth.module';
import {DashboardComponent} from './dashboard/dashboard.component';
import {PropertiesWidgetComponent} from './dashboard/properties-widget/properties-widget.component';
import {PropertyComponent} from './property/property.component';
import {RouterModule} from '@angular/router';
import {ThingComponent} from './thing/thing.component';
import {IncidentsComponent} from './incidents/incidents.component';
import {IncidentsListComponent} from './incidents/incidents-list/incidents-list.component';
import {MatListModule} from '@angular/material/list';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {PipesModule} from '../pipes/pipes.module';
import {MatTabsModule} from '@angular/material/tabs';
import {MatButtonModule} from '@angular/material/button';
import {IncidentDetailsDialogComponent} from './incidents/incident-details/incident-details-dialog.component';
import {SnoozeModalComponent} from './incidents/snooze-modal/snooze-modal.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import {FormsModule} from '@angular/forms';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {PropertiesComponent} from './properties/properties.component';
import {PartsModule} from './parts/parts.module';
import {PressureTestsComponent} from './pressure-tests/pressure-tests.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {PressureTestDetailsComponent} from './pressure-test-details/pressure-test-details.component';
import {ChartsModule} from 'ng2-charts';
import {ThingsSummaryComponent} from './things-summary/things-summary.component';
import {SettingsComponent} from './settings/settings.component';
import {MatRippleModule} from '@angular/material/core';
import {IncidentsPageComponent} from './incidents/incidents-page/incidents-page.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {IncidentPageComponent} from './incidents/incident-page/incident-page.component';
import {IncidentsTableComponent} from './incidents/incidents-page/incident-table/incidents-table.component';
import {StatsComponent} from './stats/stats.component';
import {WidgetsModule} from './stats/widgets/widgets.module';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {SettingsTableComponent} from './settings/table/settings-table.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {MatIconModule} from '@angular/material/icon';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatInputModule} from '@angular/material/input';


@NgModule({
    declarations: [
        DashboardComponent,
        PropertiesWidgetComponent,
        PropertyComponent,
        ThingComponent,
        IncidentsComponent,
        IncidentDetailsDialogComponent,
        IncidentsListComponent,
        SnoozeModalComponent,
        PropertiesWidgetComponent,
        PropertiesComponent,
        PressureTestsComponent,
        PressureTestDetailsComponent,
        ThingsSummaryComponent,
        SettingsComponent,
        IncidentsPageComponent,
        IncidentPageComponent,
        IncidentsTableComponent,
        StatsComponent,
        SettingsTableComponent,
    ],
    imports: [
        AuthModule,
        CommonModule,
        LayoutsModule,
        RouterModule,
        MatListModule,
        MatProgressSpinnerModule,
        PipesModule,
        MatTabsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatSelectModule,
        MatDialogModule,
        FormsModule,
        MatProgressBarModule,
        PartsModule,
        MatButtonToggleModule,
        MatDatepickerModule,
        ChartsModule,
        MatRippleModule,
        NgxDatatableModule,
        MatButtonModule,
        WidgetsModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        NgxMaterialTimepickerModule,
        MatIconModule,
        TooltipModule,
        MatTooltipModule,
        MatInputModule,

    ],
    entryComponents: [
        IncidentDetailsDialogComponent,
        SnoozeModalComponent
    ]
})
export class ModulesModule {
}
