import {RadioConnection} from '../../../models/thing';
import {ThingState} from '../../../models/pusher';
import * as moment from 'moment-timezone';

export const thingsDataAguilar = {
    'aef65e22-apt-1-property': [{
        battery_level: 'external_power_supply',
        cached_telemetry: {
            ambient_temp: 17.375,
            device_id: 'aef65e22-apt-1-thing',
            influx_time: 1631190271559,
            message_type: 'telemetry',
            pressure: 3068,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 17.37
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: 'aef65e22-apt-1-thing',
        hub_id: 'aef65e22-apt-1-hub',
        name: 'HERO SONIC - Main home',
        radio_connection: 'connected' as RadioConnection,
        radio_rssi: 0,
        state: 'open' as ThingState,
        status: 'active',
        type: 'smart_valve',
        wifi_rssi: -29,
        created_at: moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
        property_id: 'aef65e22-apt-1-property'
    }],
    '1aa197d0-apt-2-property': [{
        battery_level: 'high',
        cached_telemetry: {
            ambient_temp: 17.375,
            device_id: '1aa197d0-apt-2-thing',
            influx_time: 1631190271559,
            message_type: 'telemetry',
            pressure: 3068,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 17.37
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: '1aa197d0-apt-2-thing',
        hub_id: 'aef65e22-apt-2-hub',
        name: 'HERO SONIC - London flat',
        radio_connection: 'disconnected' as RadioConnection,
        radio_rssi: 0,
        state: 'closed' as ThingState,
        status: 'active',
        type: 'smart_valve',
        wifi_rssi: -29,
        created_at: moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
        property_id: '1aa197d0-apt-2-property'
    }],
    'f7b44f5c-apt-3-property': [{
        battery_level: 'low',
        cached_telemetry: {
            ambient_temp: 17.375,
            device_id: 'f7b44f5c-apt-3-thing',
            influx_time: 1631190271559,
            message_type: 'telemetry',
            pressure: 3068,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 17.37
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: 'f7b44f5c-apt-3-thing',
        hub_id: 'aef65e22-apt-3-hub',
        name: 'HERO SONIC - Summer residence',
        radio_connection: 'connected' as RadioConnection,
        radio_rssi: 0,
        state: 'open' as ThingState,
        status: 'active',
        type: 'smart_valve',
        wifi_rssi: -29,
        created_at: moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
        property_id: 'f7b44f5c-apt-3-property'
    }],
    'aef65e22-apt-4-property': [{
        battery_level: 'high',
        cached_telemetry: {
            ambient_temp: 19.41,
            device_id: 'aef65e22-apt-4-thing',
            influx_time: 1631190271559,
            message_type: 'telemetry',
            pressure: 3053,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 12.24
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: 'aef65e22-apt-4-thing',
        hub_id: 'aef65e22-apt-4-hub',
        name: 'HERO SONIC - Otherside',
        radio_connection: 'connected' as RadioConnection,
        radio_rssi: 0,
        state: 'open' as ThingState,
        status: 'active',
        type: 'smart_valve',
        wifi_rssi: -21,
        created_at: '2022-01-01T00:00:00.000Z',
        property_id: 'aef65e22-apt-4-property'
    }],
    'aef65e22-apt-5-property': [{
        battery_level: 'high',
        cached_telemetry: {
            ambient_temp: 15.51,
            device_id: 'aef65e22-apt-5-thing',
            influx_time: 1631190271559,
            message_type: 'telemetry',
            pressure: 3002,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 12.75
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: 'aef65e22-apt-5-thing',
        hub_id: 'aef65e22-apt-5-hub',
        name: 'HERO SONIC - Rodeology',
        radio_connection: 'connected' as RadioConnection,
        radio_rssi: 0,
        state: 'closed' as ThingState,
        status: 'active',
        type: 'smart_valve',
        wifi_rssi: -58,
        created_at: '2022-01-01T00:00:00.000Z',
        property_id: 'aef65e22-apt-5-property'
    }],
    'aef65e22-apt-6-property': [{
        battery_level: 'high',
        cached_telemetry: {
            ambient_temp: 21.75,
            device_id: 'aef65e22-apt-6-thing',
            influx_time: 1631190271559,
            message_type: 'telemetry',
            pressure: 3056,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 16.63
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: 'aef65e22-apt-6-thing',
        hub_id: 'aef65e22-apt-6-hub',
        name: 'HERO SONIC - Daido',
        radio_connection: 'connected' as RadioConnection,
        radio_rssi: 0,
        state: 'open' as ThingState,
        status: 'active',
        type: 'smart_valve',
        wifi_rssi: -82,
        created_at: '2022-01-01T00:00:00.000Z',
        property_id: 'aef65e22-apt-6-property'
    }],
    'aef65e22-apt-7-property': [{
        battery_level: 'external_power_supply',
        cached_telemetry: {
            ambient_temp: 17.82,
            device_id: 'aef65e22-apt-7-thing',
            influx_time: 1631190271559,
            message_type: 'telemetry',
            pressure: 3046,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 15.73
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: 'aef65e22-apt-7-thing',
        hub_id: 'aef65e22-apt-7-hub',
        name: 'HERO SONIC - Egypto',
        radio_connection: 'connected' as RadioConnection,
        radio_rssi: 0,
        state: 'open' as ThingState,
        status: 'active',
        type: 'smart_valve',
        wifi_rssi: -67,
        created_at: '2022-01-01T00:00:00.000Z',
        property_id: 'aef65e22-apt-7-property'
    }],
    'aef65e22-apt-8-property': [{
        battery_level: 'low',
        cached_telemetry: {
            ambient_temp: 15.43,
            device_id: 'aef65e22-apt-8-thing',
            influx_time: 1631190271559,
            message_type: 'telemetry',
            pressure: 3000,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 15.43
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: 'aef65e22-apt-8-thing',
        hub_id: 'aef65e22-apt-8-hub',
        name: 'HERO SONIC - Anocha',
        radio_connection: 'connected' as RadioConnection,
        radio_rssi: 0,
        state: 'open' as ThingState,
        status: 'active',
        type: 'smart_valve',
        wifi_rssi: -69,
        created_at: '2022-01-01T00:00:00.000Z',
        property_id: 'aef65e22-apt-8-property'
    }],
    'aef65e22-apt-9-property': [{
        battery_level: 'low',
        cached_telemetry: {
            ambient_temp: 20.67,
            device_id: 'aef65e22-apt-9-thing',
            influx_time: 1631190271559,
            message_type: 'telemetry',
            pressure: 3028,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 19.17
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: 'aef65e22-apt-9-thing',
        hub_id: 'aef65e22-apt-9-hub',
        name: 'HERO SONIC - Hairport',
        radio_connection: 'connected' as RadioConnection,
        radio_rssi: 0,
        state: 'open' as ThingState,
        status: 'active',
        type: 'smart_valve',
        wifi_rssi: -21,
        created_at: '2022-01-01T00:00:00.000Z',
        property_id: 'aef65e22-apt-9-property'
    }],
    'aef65e22-apt-10-property': [{
        battery_level: 'high',
        cached_telemetry: {
            ambient_temp: 16.75,
            device_id: 'aef65e22-apt-10-thing',
            influx_time: 1631190271559,
            message_type: 'telemetry',
            pressure: 3033,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 12.33
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: 'aef65e22-apt-10-thing',
        hub_id: 'aef65e22-apt-10-hub',
        name: 'HERO SONIC - Cosmetex',
        radio_connection: 'connected' as RadioConnection,
        radio_rssi: 0,
        state: 'closed' as ThingState,
        status: 'active',
        type: 'smart_valve',
        wifi_rssi: -42,
        created_at: '2022-01-01T00:00:00.000Z',
        property_id: 'aef65e22-apt-10-property'
    }],
    'aef65e22-apt-11-property': [{
        battery_level: 'low',
        cached_telemetry: {
            ambient_temp: 17.12,
            device_id: 'aef65e22-apt-11-thing',
            influx_time: 1631190271559,
            message_type: 'telemetry',
            pressure: 3061,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 13.22
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: 'aef65e22-apt-11-thing',
        hub_id: 'aef65e22-apt-11-hub',
        name: 'HERO SONIC - Zounds',
        radio_connection: 'connected' as RadioConnection,
        radio_rssi: 0,
        state: 'open' as ThingState,
        status: 'active',
        type: 'smart_valve',
        wifi_rssi: -26,
        created_at: '2022-01-01T00:00:00.000Z',
        property_id: 'aef65e22-apt-11-property'
    }],
    'aef65e22-apt-12-property': [{
        battery_level: 'high',
        cached_telemetry: {
            ambient_temp: 20.17,
            device_id: 'aef65e22-apt-12-thing',
            influx_time: 1631190271559,
            message_type: 'telemetry',
            pressure: 3009,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 13.13
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: 'aef65e22-apt-12-thing',
        hub_id: 'aef65e22-apt-12-hub',
        name: 'HERO SONIC - Portalis',
        radio_connection: 'connected' as RadioConnection,
        radio_rssi: 0,
        state: 'open' as ThingState,
        status: 'active',
        type: 'smart_valve',
        wifi_rssi: -38,
        created_at: '2022-01-01T00:00:00.000Z',
        property_id: 'aef65e22-apt-12-property'
    }],
    'aef65e22-apt-13-property': [{
        battery_level: 'high',
        cached_telemetry: {
            ambient_temp: 21.62,
            device_id: 'aef65e22-apt-13-thing',
            influx_time: 1631190271559,
            message_type: 'telemetry',
            pressure: 3026,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 15.75
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: 'aef65e22-apt-13-thing',
        hub_id: 'aef65e22-apt-13-hub',
        name: 'HERO SONIC - Ginkle',
        radio_connection: 'connected' as RadioConnection,
        radio_rssi: 0,
        state: 'open' as ThingState,
        status: 'active',
        type: 'smart_valve',
        wifi_rssi: -43,
        created_at: '2022-01-01T00:00:00.000Z',
        property_id: 'aef65e22-apt-13-property'
    }],
    'aef65e22-apt-14-property': [{
        battery_level: 'high',
        cached_telemetry: {
            ambient_temp: 18.04,
            device_id: 'aef65e22-apt-14-thing',
            influx_time: 1631190271559,
            message_type: 'telemetry',
            pressure: 3063,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 17.79
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: 'aef65e22-apt-14-thing',
        hub_id: 'aef65e22-apt-14-hub',
        name: 'HERO SONIC - Sustenza',
        radio_connection: 'connected' as RadioConnection,
        radio_rssi: 0,
        state: 'open' as ThingState,
        status: 'active',
        type: 'smart_valve',
        wifi_rssi: -68,
        created_at: '2022-01-01T00:00:00.000Z',
        property_id: 'aef65e22-apt-14-property'
    }],
    'aef65e22-apt-15-property': [{
        battery_level: 'high',
        cached_telemetry: {
            ambient_temp: 16.44,
            device_id: 'aef65e22-apt-15-thing',
            influx_time: 1631190271559,
            message_type: 'telemetry',
            pressure: 3046,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 13.41
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: 'aef65e22-apt-15-thing',
        hub_id: 'aef65e22-apt-15-hub',
        name: 'HERO SONIC - Codax',
        radio_connection: 'connected' as RadioConnection,
        radio_rssi: 0,
        state: 'open' as ThingState,
        status: 'active',
        type: 'smart_valve',
        wifi_rssi: -89,
        created_at: '2022-01-01T00:00:00.000Z',
        property_id: 'aef65e22-apt-15-property'
    }],
    'aef65e22-apt-16-property': [{
        battery_level: 'external_power_supply',
        cached_telemetry: {
            ambient_temp: 16.19,
            device_id: 'aef65e22-apt-16-thing',
            influx_time: 1631190271559,
            message_type: 'telemetry',
            pressure: 3070,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 19.69
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: 'aef65e22-apt-16-thing',
        hub_id: 'aef65e22-apt-16-hub',
        name: 'HERO SONIC - Quotezart',
        radio_connection: 'connected' as RadioConnection,
        radio_rssi: 0,
        state: 'open' as ThingState,
        status: 'active',
        type: 'smart_valve',
        wifi_rssi: -80,
        created_at: '2022-01-01T00:00:00.000Z',
        property_id: 'aef65e22-apt-16-property'
    }],
    'aef65e22-apt-17-property': [{
        battery_level: 'low',
        cached_telemetry: {
            ambient_temp: 21.92,
            device_id: 'aef65e22-apt-17-thing',
            influx_time: 1631190271559,
            message_type: 'telemetry',
            pressure: 3017,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 14.54
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: 'aef65e22-apt-17-thing',
        hub_id: 'aef65e22-apt-17-hub',
        name: 'HERO SONIC - Combot',
        radio_connection: 'connected' as RadioConnection,
        radio_rssi: 0,
        state: 'open' as ThingState,
        status: 'active',
        type: 'smart_valve',
        wifi_rssi: -24,
        created_at: '2022-01-01T00:00:00.000Z',
        property_id: 'aef65e22-apt-17-property'
    }],
    'aef65e22-apt-18-property': [{
        battery_level: 'high',
        cached_telemetry: {
            ambient_temp: 17.34,
            device_id: 'aef65e22-apt-18-thing',
            influx_time: 1631190271559,
            message_type: 'telemetry',
            pressure: 3039,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 18.75
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: 'aef65e22-apt-18-thing',
        hub_id: 'aef65e22-apt-18-hub',
        name: 'HERO SONIC - Plasmox',
        radio_connection: 'connected' as RadioConnection,
        radio_rssi: 0,
        state: 'open' as ThingState,
        status: 'active',
        type: 'smart_valve',
        wifi_rssi: -74,
        created_at: '2022-01-01T00:00:00.000Z',
        property_id: 'aef65e22-apt-18-property'
    }],
    'aef65e22-apt-19-property': [{
        battery_level: 'mid',
        cached_telemetry: {
            ambient_temp: 21.15,
            device_id: 'aef65e22-apt-19-thing',
            influx_time: 1631190271559,
            message_type: 'telemetry',
            pressure: 3038,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 13.37
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: 'aef65e22-apt-19-thing',
        hub_id: 'aef65e22-apt-19-hub',
        name: 'HERO SONIC - Insurity',
        radio_connection: 'connected' as RadioConnection,
        radio_rssi: 0,
        state: 'open' as ThingState,
        status: 'active',
        type: 'smart_valve',
        wifi_rssi: -90,
        created_at: '2022-01-01T00:00:00.000Z',
        property_id: 'aef65e22-apt-19-property'
    }],
    'aef65e22-apt-20-property': [{
        battery_level: 'high',
        cached_telemetry: {
            ambient_temp: 15.66,
            device_id: 'aef65e22-apt-20-thing',
            influx_time: 1631190271559,
            message_type: 'telemetry',
            pressure: 3042,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 19.05
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: 'aef65e22-apt-20-thing',
        hub_id: 'aef65e22-apt-20-hub',
        name: 'HERO SONIC - Pearlesex',
        radio_connection: 'connected' as RadioConnection,
        radio_rssi: 0,
        state: 'open' as ThingState,
        status: 'active',
        type: 'smart_valve',
        wifi_rssi: -24,
        created_at: '2022-01-01T00:00:00.000Z',
        property_id: 'aef65e22-apt-20-property'
    }],
    'aef65e22-apt-21-property': [{
        battery_level: 'mid',
        cached_telemetry: {
            ambient_temp: 19.23,
            device_id: 'aef65e22-apt-21-thing',
            influx_time: 1631190271559,
            message_type: 'telemetry',
            pressure: 3021,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 17.48
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: 'aef65e22-apt-21-thing',
        hub_id: 'aef65e22-apt-21-hub',
        name: 'HERO SONIC - Insurety',
        radio_connection: 'connected' as RadioConnection,
        radio_rssi: 0,
        state: 'open' as ThingState,
        status: 'active',
        type: 'smart_valve',
        wifi_rssi: -49,
        created_at: '2022-01-01T00:00:00.000Z',
        property_id: 'aef65e22-apt-21-property'
    }],
    'aef65e22-apt-22-property': [{
        battery_level: 'high',
        cached_telemetry: {
            ambient_temp: 15.39,
            device_id: 'aef65e22-apt-22-thing',
            influx_time: 1631190271559,
            message_type: 'telemetry',
            pressure: 3066,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 18.47
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: 'aef65e22-apt-22-thing',
        hub_id: 'aef65e22-apt-22-hub',
        name: 'HERO SONIC - Unisure',
        radio_connection: 'connected' as RadioConnection,
        radio_rssi: 0,
        state: 'open' as ThingState,
        status: 'active',
        type: 'smart_valve',
        wifi_rssi: -47,
        created_at: '2022-01-01T00:00:00.000Z',
        property_id: 'aef65e22-apt-22-property'
    }],
};
