import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UserResource} from '../resources/user.resource';
import {PasswordResource} from '../resources/password.resources';
import {EmailExistsResponse, User, UserProfile, UserResponse} from '../../models/user';
import {LoginResponse} from '../../models/login.response';
import {ValidateTokenResponse} from '../../models/validateToken.response';
import {UserDemoResource} from '../demo-resources/user.demo.resource';


@Injectable()
export class UserService {
    public user: any;
    private resource: UserResource | UserDemoResource;
    private _userId: string;
    private _token: string;
    private _device_token: string;
    private readonly USER_KEY = 'user';


    constructor(private userResource: UserResource,
                private userDemoResource: UserDemoResource,
                private passwordResource: PasswordResource) {
        this.resource = this.userResource;
    }

    /* eslint-disable @typescript-eslint/member-ordering */
    get userId(): string {
        return this._userId;
    }

    set userId(value: string) {
        this._userId = value;
    }

    get token(): string {
        return this._token;
    }

    set token(value: string) {
        this._token = value;
    }

    get device_token(): string {
        return this._device_token;
    }

    set device_token(value: string) {
        this._device_token = value;
    }

    /* eslint-enable @typescript-eslint/member-ordering */

    public toggleDemo(state: boolean): void {
        this.resource = state ? this.userDemoResource:this.userResource;
    }

    public activateUser(code: string): Observable<UserResponse> {
        return this.resource.activateUser(code);
    }

    public signInUser(email: string, password: string): Observable<LoginResponse> {
        return this.resource.signInUser(email, password);
    }

    public signUpUser(email: string, password: string, firstName: string, lastName: string, phone: string): Observable<UserResponse> {
        return this.resource.signUpUser(email, password, firstName, lastName, phone);
    }


    public requestPasswordReset(email: string): Observable<string> {
        return this.passwordResource.requestPasswordReset(email);
    }

    public requestResendActivationEmail(email: string): Observable<User> {
        return this.resource.requestResendActivationEmail(email);
    }

    public requestCancelAccount(): Observable<string> {
        return this.resource.requestCancelAccount();
    }

    public checkIfEmailExists(email: string): Observable<EmailExistsResponse> {
        return this.resource.checkIfEmailExists(email);
    }

    public signInUserWithFacebook(accessToken: string): Observable<LoginResponse> {
        return this.resource.signInUserWithFacebook(accessToken);
    }

    public signInUserWithApple(firstName: string, lastName: string, jwt: string, userIdentity: string): Observable<LoginResponse> {
        return this.resource.signInUserWithApple(firstName, lastName, jwt, userIdentity);
    }

    public signInUserWithGoogle(accessToken: string): Observable<LoginResponse> {
        return this.resource.signInUserWithGoogle(accessToken);
    }

    public updateUser(userId: string, payload: UserProfile): Observable<User> {
        return this.resource.updateUser(userId, payload);
    }

    public getUserProfile(): UserProfile {
        return {
            first_name: this.user.first_name,
            last_name: this.user.last_name,
            email: this.user.email,
            phone: this.user.phone,
        };
    }

    public setUserProfile(data: UserProfile): void {
        this.user.first_name = data.first_name;
        this.user.last_name = data.last_name;
        this.user.email = data.email;
        this.user.phone = data.phone;
        localStorage.setItem(this.USER_KEY, JSON.stringify(this.user));
    }


    public validateToken(): Observable<ValidateTokenResponse> {
        return this.resource.validateToken();
    }
}
