<app-layout-app>
    <div class="main-content">
        <!-- HEADER -->
        <div class="header pb-5">
            <div class="container-fluid">
                <!-- Body -->
                <div class="header-body">
                    <div class="row align-items-end">
                        <div class="col">
                            <h1 class="m-0 py-4">
                                Property {{ property?.name }}
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid ">
            <div class="row">
                <div class="col-12 col-xl-4 u-sticky">
                    <div class="card sticky-top">
                        <div class="card-header">
                            <!-- Title -->
                            <h4 class="card-header-title">
                                Thing
                            </h4>
                        </div>
                        <div class="card-body">
                            <app-thing [propertyId]="propertyId"></app-thing>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-xl-4 u-sticky">
                    <div class="card">
                        <div class="card-header">
                            <!-- Title -->
                            <h4 class="card-header-title">
                                Events
                            </h4>
                        </div>
                        <div class="card-body" *ngIf="thing && propertySettings">
                            <app-events-list [thingId]="thing.id"
                                             [timezone]="propertySettings.timezone"></app-events-list>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-xl-4 u-sticky">
                    <div class=" sticky-top">
                        <div class="card">
                            <div class="card-header">
                                <!-- Title -->
                                <h4 class="card-header-title">
                                    Incidents
                                </h4>
                            </div>
                            <div class="card-body">
                                <app-incidents [propertyId]="propertyId"></app-incidents>
                            </div>
                        </div>

                        <app-notification-settings *ngIf="property"
                                                   [selectedProperty]="property"></app-notification-settings>

                        <div class="card">
                            <div class="card-header">
                                <!-- Title -->
                                <h4 class="card-header-title">
                                    Settings
                                </h4>
                            </div>
                            <div class="card-body"
                                 *ngIf="propertySettings">
                                <dl>
                                    <dt>Timezone</dt>
                                    <dd>{{ propertySettings.timezone }}</dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-layout-app>
