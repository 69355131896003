<app-layout-app>
    <div class="main-content">
        <!-- HEADER -->
        <div class="header pb-5">
            <div class="container-fluid">
                <!-- Body -->
                <div class="header-body">
                    <div class="row align-items-end">
                        <div class="col">
                            <h1 class="m-0 py-4">
                                Properties
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid ">
            <div class="row">
                <div class="col-12">

                    <div class="card">
                        <div class="card-body">

                            <mat-form-field class="w-100">
                                <mat-label>Search</mat-label>
                                <input matInput type="text"
                                       (keydown)="triggerSearch()"
                                       [(ngModel)]="search"
                                       placeholder="Search by property name or address">
                                <button *ngIf="search" matSuffix mat-icon-button aria-label="Clear"
                                        (click)="clearSearch()">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </mat-form-field>

                            <app-table [rows]="rows"
                                       [externalPaging]="true"
                                       [externalSorting]="false"
                                       [columns]="columns"
                                       [sorts]="[]"
                                       [tableSettings]="tableSettings"
                                       [selectionEnabled]="false"
                                       (fetchPage)="fetchPage($event)">
                            </app-table>
                            <ng-template #addressColumn
                                         ngx-datatable-cell-template
                                         let-row="row"
                                         let-value="value">
                                {{ row.address_1 }} {{ row.address_2 }} {{ row.address_3 }}
                            </ng-template>
                            <ng-template #actionsColumn
                                         ngx-datatable-cell-template
                                         let-row="row"
                                         let-value="value">
                                <a routerLink="/property/{{row.id}}"
                                   class="btn btn-primary btn-md px-5 ml-auto">See details</a>

                            </ng-template>
                            <ng-template #statusColumn
                                         ngx-datatable-cell-template
                                         let-row="row"
                                         let-value="value">
                                <app-system-status [propertyId]="row.id"></app-system-status>
                            </ng-template>

                            <ng-template #systemColumn
                                         ngx-datatable-cell-template
                                         let-row="row"
                                         let-value="value">
                                {{ printSystemStatus(row) }}
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-layout-app>
