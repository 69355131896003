import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Injectable, OnInit} from '@angular/core';
import {Property} from '../../models/property';
import {Thing, ThingsResponse} from '../../models/thing';
import * as _ from 'lodash';
import {PropertyService} from '../../providers/services/property.service';
import {ThingService} from '../../providers/services/thing.service';
import {Observable, Subscription} from 'rxjs';
import {PropertySettings} from '../../models/property.settings';
import {switchMap, tap} from 'rxjs/operators';
import {StatsService} from '../../providers/services/stats.service';
import {StatsRange} from '../../models/aggregated-stats';
import {StatsRanges} from './stats-config';
import {TimeRange} from '../../models/stats';
import {
    DateRange,
    MAT_DATE_RANGE_SELECTION_STRATEGY,
    MatDateRangeSelectionStrategy
} from '@angular/material/datepicker';
import {DateAdapter} from '@angular/material/core';
import * as moment from 'moment-timezone';
import {DurationInputArg2} from 'moment-timezone';
import StartOf = moment.unitOfTime.StartOf;

@Injectable()
export class CustomRangeSelectionStrategy<D> implements MatDateRangeSelectionStrategy<D> {
    public range: StatsRange;

    constructor(private _dateAdapter: DateAdapter<D>) {
    }

    selectionFinished(date: D | null, currentRange: DateRange<D>): DateRange<D> {
        switch (this.range?.value) {
            case 'year':
                return this._createRange(date, 'year');

            case 'month':
                return this._createRange(date, 'month');

            case 'week':
                return this._createRange(date, 'isoWeek');


        }


        return new DateRange<D>(date, date);
    }

    createPreview(date: D | null, currentRange: DateRange<D>): DateRange<D> {
        switch (this.range?.value) {
            case 'year':
                return this._createRange(date, 'year');
            case 'month':
                return this._createRange(date, 'month');
            case 'week':
                return this._createRange(date, 'isoWeek');
        }

        return new DateRange<D>(date, date);
    }

    private _createRange(date: D | null, momentRange: StartOf): DateRange<D> {
        if (date) {
            const format = 'YYYY-MM-DD';
            const start = this._dateAdapter.parse(moment(date).startOf(momentRange).format(format), format);
            const end = this._dateAdapter.parse(moment(date).endOf(momentRange).format(format), format);
            return new DateRange<D>(start, end);
        }

        return new DateRange<D>(null, null);
    }
}


@Component({
    selector: 'app-stats',
    templateUrl: './stats.component.html',
    styleUrls: ['./stats.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
            useClass: CustomRangeSelectionStrategy,
        },
    ]
})
export class StatsComponent implements OnInit {

    public selectedProperty: string;
    public thing: Thing;
    public notThing: boolean;
    public minTemp = 0;
    public selectedDate = new Date();
    public selectedEndDate = new Date();
    public freezeWaterTempAlert = 4;
    public ranges = StatsRanges;
    public calendarMinDate: Date;
    public maxDate = new Date();

    public currentRange: StatsRange = _.head(StatsRanges);

    public waterTempAverageChartData: number[] = [];
    public waterTempAverageChartLabels: string[] = [];

    public waterUsageChartData: number[] = [];
    public waterUsageZerosChartData: number[] = [];
    public waterUsageChartLabels: string[] = [];

    public waterPressureAverageChartData: number[] = [];
    public waterPressureAverageChartLabels: string[] = [];

    private getAggregatedStatsApiSub: Subscription;
    private timezone: string;

    constructor(private propertyService: PropertyService,
                @Inject(MAT_DATE_RANGE_SELECTION_STRATEGY)
                private rg: CustomRangeSelectionStrategy<any>,
                private thingService: ThingService,
                private statsService: StatsService,
                private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
    }


    public selectProperty(properties: Property[]) {
        const property = _.head(properties);
        this.selectedProperty = property.id;
        if (!property) {
            return;
        }
        this.loadSettings()
            .pipe(
                tap((settings) => {
                    this.timezone = settings.timezone;
                    this.statsService.setTimezone(settings.timezone);
                }),
                switchMap(() => this.propertyService.getThingsByPropertyId(this.selectedProperty))
            )
            .subscribe((response) => this.handleThings(response));
    }

    public prevDate() {
        this.selectedDate = moment(this.selectedDate).tz(this.timezone).subtract(1, this.getMomentPeriod()).toDate();
        this.loadHourStats();
    }

    public nextDate() {
        this.selectedDate = moment(this.selectedDate).tz(this.timezone).add(1, this.getMomentPeriod()).toDate();
        this.loadHourStats();
    }

    public onCalendarDateSelected() {
        this.rg.range = this.currentRange;
        this.loadHourStats();
    }

    private loadSettings(): Observable<PropertySettings> {
        return this.propertyService.getSettings(this.selectedProperty);
    }


    private handleThings(thingsResponse: ThingsResponse): void {
        this.thing = _.head(thingsResponse.things);

        if (this.thing) {
            this.calendarMinDate = this.thing.created_at as Date;
            this.notThing = false;
            this.loadHourStats();
        } else {
            this.notThing = true;
        }

        this.cdRef.detectChanges();
    }

    private loadHourStats() {
        const {start, end} = this.getDateRangeAsTimestamp();
        if (this.getAggregatedStatsApiSub) {
            this.getAggregatedStatsApiSub.unsubscribe();
        }
        this.getAggregatedStatsApiSub = this.thingService.getAggregatedStats(this.thing.id, start, end)
            .subscribe(response => {

                const {
                    labels,
                    data
                } = this.statsService.processData(response, this.currentRange, 'water_usage', start, end);


                this.waterUsageChartData = data;
                this.waterUsageZerosChartData = _.map(data, (item) => {
                    if (item===null) {
                        return 0.1;
                    }
                    return null;
                });
                this.waterUsageChartLabels = labels;

                this.waterTempAverageChartData = this.statsService.processData(response, this.currentRange, 'temp_average', start, end).data;
                this.waterTempAverageChartLabels = labels;

                this.waterPressureAverageChartData = this.statsService.processData(response, this.currentRange, 'pressure_average', start, end).data;
                this.waterPressureAverageChartLabels = labels;

                const minElem = _.minBy(response, 'temp_min');
                this.minTemp = minElem ? minElem.temp_min:null;


                this.cdRef.detectChanges();
            });
    }

    private getDateRangeAsTimestamp(): TimeRange {
        const {start, end} = this.getDateRange();
        this.selectedDate = start.toDate();
        this.selectedEndDate = end.toDate();
        return {start: Math.round(start.valueOf() / 1000) + 1, end: Math.round(end.valueOf() / 1000)};
    }


    private getMomentPeriod(): DurationInputArg2 {
        switch (this.currentRange.value) {
            case 'month':
                return 'month';
            case 'week':
                return 'week';
            case 'year':
                return 'year';
            case 'day':
            default:
                return 'day';
        }
    }

    private getDateRange() {
        return this.statsService.getDateRange(this.selectedDate, this.currentRange);
    }
}
