import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApiConfig} from './ApiConfig';

@Injectable()
export class PasswordResource {

    constructor(private http: HttpClient) {
    }

    public requestPasswordReset(email: string): Observable<string> {
        const url = `${ApiConfig.PASSWORDS_URL}/reset_password`;

        const data = {
            email,
        };

        return this.http.post<string>(url, data);
    }
}
