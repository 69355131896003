import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {OptionalHttpParams} from '../../../providers/resources/thing.resource';
import {BehaviorSubject, Observable} from 'rxjs';
import {ApplianceAwareEvent, ThingEvent} from '../../../models/thing';
import {ThingService} from '../../../providers/services/thing.service';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventsListComponent implements OnInit {

  @Input() thingId: string;
  @Input() timezone = 'Europe/London';

  public timeFormat = 'HH:mm:ss';
  public durationDisplayThreshold = 5000; // in milliliters
  public selectedDay = new Date();
  public showLoadMoreBt = true;

  private eventsPerPage = 50;
  private eventsSubject = new BehaviorSubject<ApplianceAwareEvent[]>(null);

  constructor(private thingService: ThingService,
              private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.updateStream();
  }

  public getStream(): Observable<ApplianceAwareEvent[]> {
    return this.eventsSubject.asObservable();
  }


  public getEvents(thingId: string, params?: OptionalHttpParams): Observable<ThingEvent[]> {
    return this.thingService.getEvents(thingId, params);
  }

  public getEventIcon(event: ApplianceAwareEvent): string {
    return event.applianceCategory ? event.applianceCategory.icon_url : 'question-mark';
  }

  public loadData(): void {
    const currentEvents = this.eventsSubject.value;
    const dayMoment = moment(this.selectedDay);
    const maxDate = (moment(_.last(currentEvents).event_start).valueOf() - 1).toString();
    const minDate = dayMoment.startOf('day').valueOf().toString();

    this.thingService.getApplianceAwareEvents(this.thingId, {from: minDate, to: maxDate, asc: 'false', limit: this.eventsPerPage.toString()})
      .subscribe((todayEvents) => {
        if (_.size(todayEvents) === 0) {
          this.showLoadMoreBt = false;
        }
        const newEvents = _.concat(currentEvents, todayEvents);
        this.eventsSubject.next(newEvents);
        this.cdRef.detectChanges();
      });
  }

  public updateStream() {
    const dayMoment = moment(this.selectedDay);
    const maxDate = dayMoment.endOf('day').valueOf().toString();
    const minDate = dayMoment.startOf('day').valueOf().toString();
    this.showLoadMoreBt = true;

    this.thingService.getApplianceAwareEvents(this.thingId, {from: minDate, to: maxDate, asc: 'false', limit: this.eventsPerPage.toString()})
      .subscribe((todayEvents) => {
        this.eventsSubject.next(todayEvents);
        this.cdRef.detectChanges();
      });
  }

}
