import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {PropertiesResponse, PropertyResponse} from '../../models/property';
import {ThingsResponse} from '../../models/thing';
import {PropertySettings} from '../../models/property.settings';
import {NotificationSettings} from '../../models/notification-settings';
import {delay} from 'rxjs/operators';
import * as _ from 'lodash';
import {notificationSettings, properties, propertySettings} from './data/properties.fixture';
import { notificationSettingsAguilar, propertiesAguilar, propertySettingsAguilar } from './aquilarData/properties.fixture';
import {thingsData} from './data/things.fixture';
import { thingsDataAguilar } from './aquilarData/things.fixture';
import {environment} from '../../../environments/environment';

@Injectable()
export class PropertyDemoResource {

    public things = !environment.aguilar ? thingsData : thingsDataAguilar;
    private properties = !environment.aguilar ? properties : propertiesAguilar;
    private propertySettings = !environment.aguilar ? propertySettings : propertySettingsAguilar;
    private notificationSettings: { [key: string]: NotificationSettings } = !environment.aguilar ? notificationSettings : notificationSettingsAguilar;

    constructor() {
    }

    public getPropertiesList(): Observable<PropertiesResponse> {

        const response: PropertiesResponse = {
            properties: this.properties
        };
        return of(response).pipe(delay(200));
    }

    public getThingsByPropertyId(propertyId: string): Observable<ThingsResponse> {

        const response: ThingsResponse = {
            things: _.get(this.things, propertyId)
        };
        return of(response);
    }

    public getSettings(propertyId: string): Observable<PropertySettings> {
        return of(_.get(this.propertySettings, propertyId)).pipe(delay(200));
    }

    public getProperty(propertyId: string): Observable<PropertyResponse> {

        const response: PropertyResponse = {property: _.find(this.properties, (item) => item.id === propertyId)};
        return of(response).pipe(delay(200));
    }

    public getNotificationSettings(propertyId: string): Observable<NotificationSettings> {

        return of(_.get(this.notificationSettings, propertyId)).pipe(delay(200));
    }

    public updateNotificationSettings(propertyId: string, payload: NotificationSettings): Observable<NotificationSettings> {
        _.set(this.notificationSettings, propertyId, payload);
        return this.getNotificationSettings(propertyId);
    }
}
