import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {ColumnMode, DatatableComponent, TableColumn} from '@swimlane/ngx-datatable';
import {HttpParams} from '@angular/common/http';
import * as _ from 'lodash';
import {FilterField, FilterOption, TableSettings} from '../../../models/table-settings';
import {OptionalHttpParams} from '../../../providers/resources/thing.resource';
import {PaginatedResponse} from '../../../models/Pageable';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent implements OnInit {

    @Input() columns: TableColumn[];
    @Input() rows: any[];
    @Input() sorts: any[];
    @Input() selectionEnabled = true;
    @Input() externalSorting = true;
    @Input() externalPaging = true;
    @Input() tableSettings: TableSettings;
    @Input() rowHeight: number | 'auto';

    @Input() filterFields: FilterField[];
    @Input() startValues: FilterOption[];

    @Output() fetchPage = new EventEmitter<HttpParams | OptionalHttpParams>();
    @Output() selected = new EventEmitter<any>();
    @ViewChild('codeColumn') codeColumn: TemplateRef<any>;
    @ViewChild('dateColumn') dateColumn: TemplateRef<any>;
    @ViewChild('table', { static: true }) table: DatatableComponent;


    public ColumnMode = ColumnMode;
    public offset = 0;
    public isLoading: boolean;
    private showLoader = true;
    private filterValues = {};

    constructor(private cdRef: ChangeDetectorRef) {

    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
      this.table.recalculate();
      this.table.recalculateColumns();
      this.table.onWindowResize();
      this.cdRef.detectChanges();
    }

    public ngOnInit(): void {
        const startPage = Number(window.location.hash.substr(1));
        if (startPage) {
            _.set(this.tableSettings, 'page', startPage);
        }
        if (_.size(this.startValues) === 0) {
            this.fetchNewPage();
        }
    }

    public onSort(event: any): void {
        if (!this.externalSorting) {
            return;
        }
        const sort = event.sorts[0];
        _.set(this.tableSettings, 'order_strategy', sort.dir);
        _.set(this.tableSettings, 'order_by', sort.prop);

        this.fetchNewPage();
    }

    public onRowSelect({selected}): void {
        this.selected.emit(selected[0]);
    }

    public setPage(pageInfo): void {
        _.set(this.tableSettings, 'page', pageInfo.offset + 1);
        window.location.hash = this.tableSettings.page.toString();
        this.fetchNewPage();
    }

    public onSearch(params: OptionalHttpParams): void {
        this.filterValues = params;
        _.set(this.tableSettings, 'page', 1);
        this.fetchNewPage();
    }

    public setPageParams(result: PaginatedResponse): void {
        _.set(this.tableSettings, 'page', +result.page_number);
        _.set(this.tableSettings, 'page_size', +result.page_size);
        _.set(this.tableSettings, 'total_elements', +result.total_entries);
        _.set(this.tableSettings, 'total_pages', +result.total_pages);
    }

    public toNum(value: string | number): number {
        return value as number;
    }

    public refresh(showLoader = true): void {
        this.showLoader = showLoader;
        this.fetchNewPage();
    }

    private fetchNewPage(): void {
        if (this.showLoader) {
            this.isLoading = true;
        }

        const params = this.filterValues;
        _.set(params, 'page', this.tableSettings.page);
        _.set(params, 'page_size', this.tableSettings.page_size);
        if (_.size(_.filter(this.columns, (column) => column.sortable))) {
            _.set(params, 'order_by', this.tableSettings.order_by);
            _.set(params, 'order_strategy', this.tableSettings.order_strategy);
        }
        this.fetchPage.emit(params);
        this.showLoader = true;
    }
}
