import { PressureTest } from 'src/app/models/pressure-test';

export const lastPressure: PressureTest = {
    avg_pressure: 3063.222222222222,
    created_at: '2021-10-26T07:58:45.591665Z',
    finished_at: '2021-10-26T08:03:46.160000Z',
    id: '35429a37-c792-4a8c-a65d-b000fc21b091',
    measurements: [{
      pressure: 3117,
      probed_at: 1635235159962,
      state: 'okay'
    }, {pressure: 3102, probed_at: 1635235191645, state: 'okay'}, {
      pressure: 3102,
      probed_at: 1635235223328,
      state: 'okay'
    }, {pressure: 3102, probed_at: 1635235255010, state: 'okay'}, {
      pressure: 3102,
      probed_at: 1635235286694,
      state: 'okay'
    }, {pressure: 3101, probed_at: 1635235318375, state: 'okay'}, {
      pressure: 3101,
      probed_at: 1635235350060,
      state: 'okay'
    }, {pressure: 3101, probed_at: 1635235381744, state: 'okay'}, {
      pressure: 2741,
      probed_at: 1635235413427,
      state: 'abandon_water_run'
    }],
    passed: false,
    percentage_tendency: -12.062880975296759,
    started_at: '2021-10-26T07:59:06.987000Z',
    state: 'finished',
    status: 'abandon_water_run',
    tendency_eligible_for_fail: -15,
    thing_id: 'f864c03e-b2e1-44fc-964b-e16905a80011'
  };

  export const pressureTestNotPassedMeasurements =
    [{
      pressure: 3133,
      probe_state: 'in_progress',
      probed_at: 1631159974889,
      state: 'okay'
    }, {pressure: 2855, probe_state: 'in_progress', probed_at: 1631160006576, state: 'okay'}, {
      pressure: 2678,
      probe_state: 'in_progress',
      probed_at: 1631160038302,
      state: 'okay'
    }, {pressure: 2515, probe_state: 'in_progress', probed_at: 1631160070016, state: 'okay'}, {
      pressure: 2364,
      probe_state: 'in_progress',
      probed_at: 1631160101701,
      state: 'okay'
    }, {pressure: 2223, probe_state: 'in_progress', probed_at: 1631160133404, state: 'okay'}, {
      pressure: 2092,
      probe_state: 'in_progress',
      probed_at: 1631160165087,
      state: 'okay'
    }, {pressure: 1969, probe_state: 'in_progress', probed_at: 1631160196796, state: 'okay'}, {
      pressure: 1855,
      probe_state: 'in_progress',
      probed_at: 1631160228480,
      state: 'okay'
    }, {pressure: 1747, probe_state: 'in_progress', probed_at: 1631160260186, state: 'okay'}, {
      pressure: 1647,
      probe_state: 'in_progress',
      probed_at: 1631160291872,
      state: 'okay'
    }, {pressure: 1553, probe_state: 'in_progress', probed_at: 1631160323574, state: 'okay'}, {
      pressure: 1465,
      probe_state: 'in_progress',
      probed_at: 1631160355261,
      state: 'okay'
    }, {pressure: 1382, probe_state: 'in_progress', probed_at: 1631160386963, state: 'okay'}, {
      pressure: 1305,
      probe_state: 'in_progress',
      probed_at: 1631160418651,
      state: 'okay'
    }, {pressure: 1231, probe_state: 'in_progress', probed_at: 1631160450352, state: 'okay'}, {
      pressure: 1161,
      probe_state: 'in_progress',
      probed_at: 1631160482037,
      state: 'okay'
    }, {pressure: 1095, probe_state: 'in_progress', probed_at: 1631160513742, state: 'okay'}, {
      pressure: 1033,
      probe_state: 'in_progress',
      probed_at: 1631160545426,
      state: 'okay'
    }, {pressure: 974, probe_state: 'in_progress', probed_at: 1631160577132, state: 'okay'}];

export const pressureTestPassedMeasurements = [{
    pressure: 3248,
    probe_state: 'in_progress',
    probed_at: 1631851168245,
    state: 'okay'
  }, {
    pressure: 3243,
    probe_state: 'in_progress',
    probed_at: 1631851199928,
    state: 'okay'
  }, {pressure: 3243, probe_state: 'in_progress', probed_at: 1631851231633, state: 'okay'}, {
    pressure: 3243,
    probe_state: 'in_progress',
    probed_at: 1631851263316,
    state: 'okay'
  }, {pressure: 3243, probe_state: 'in_progress', probed_at: 1631851295023, state: 'okay'}, {
    pressure: 3243,
    probe_state: 'in_progress',
    probed_at: 1631851326727,
    state: 'okay'
  }, {pressure: 3243, probe_state: 'in_progress', probed_at: 1631851358412, state: 'okay'}, {
    pressure: 3243,
    probe_state: 'in_progress',
    probed_at: 1631851390115,
    state: 'okay'
  }, {pressure: 3243, probe_state: 'in_progress', probed_at: 1631851421799, state: 'okay'}, {
    pressure: 3243,
    probe_state: 'in_progress',
    probed_at: 1631851453505,
    state: 'okay'
  }, {pressure: 3243, probe_state: 'in_progress', probed_at: 1631851485188, state: 'okay'}, {
    pressure: 3243,
    probe_state: 'in_progress',
    probed_at: 1631851516893,
    state: 'okay'
  }, {pressure: 3243, probe_state: 'in_progress', probed_at: 1631851548577, state: 'okay'}, {
    pressure: 3243,
    probe_state: 'in_progress',
    probed_at: 1631851580282,
    state: 'okay'
  }, {pressure: 3243, probe_state: 'in_progress', probed_at: 1631851611965, state: 'okay'}, {
    pressure: 3243,
    probe_state: 'in_progress',
    probed_at: 1631851643671,
    state: 'okay'
  }, {pressure: 3243, probe_state: 'in_progress', probed_at: 1631851675355, state: 'okay'}, {
    pressure: 3243,
    probe_state: 'in_progress',
    probed_at: 1631851707062,
    state: 'okay'
  }, {pressure: 3243, probe_state: 'in_progress', probed_at: 1631851738747, state: 'okay'}, {
    pressure: 3243,
    probe_state: 'in_progress',
    probed_at: 1631851770452,
    state: 'okay'
  }];

  export const pressureTestsCalendars = {

    'aef65e22-apt-1-thing': {
      '2021-10-08': {}
    },
    '1aa197d0-apt-2-thing': {
      '2021-10-09': {}
    },
    'f7b44f5c-apt-3-thing': {
      '2021-10-20': {},
    },
    'f7b44f5c-apt-4-thing': {
      '2021-10-09': {},
    },
    'f7b44f5c-apt-5-thing': {
      '2021-10-10': {},
    },
    'f7b44f5c-apt-6-thing': {
      '2021-10-11': {},
    },
    'f7b44f5c-apt-7-thing': {
      '2021-10-12': {},
    },
    'f7b44f5c-apt-8-wifi': {
      '2021-10-12': {},
    },
    'f7b44f5c-apt-9-wifi': {
      '2021-10-15': {},
    }
  };


  export const totalUsages = {
    'aef65e22-apt-1-thing': {
      day: 165755,
      year: 142121000
    },
    '1aa197d0-apt-2-thing': {
      day: 1,
      year: 217121000
    },
    'f7b44f5c-apt-3-thing': {
      day: 214412,
      year: 512125000
    },
    'f7b44f5c-apt-4-thing': {
      day: 3,
      year: 512125000
    },
    'f7b44f5c-apt-5-thing': {
      day: 4,
      year: 512125000
    },
    'f7b44f5c-apt-6-thing': {
      day: 5,
      year: 512125000
    },
    'f7b44f5c-apt-7-thing': {
      day: 7,
      year: 512125000
    },
    'f7b44f5c-apt-8-wifi': {
      day: 13,
      year: 555125000
    },
    'f7b44f5c-apt-9-wifi': {
      day: 12,
      year: 500125000
    }
  };
