import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WaterUsageWidget} from './water-usage-widget/water-usage-widget.component';
import {WaterTempWidgetComponent} from './water-temp-widget/water-temp-widget.component';
import {WaterPressureWidgetComponent} from './water-pressure-widget/water-pressure-widget.component';
import {LineChartComponent} from './line-chart/line-chart.component';
import {WidgetFrameComponent} from './widget-frame/widget-frame.component';
import {RouterModule} from '@angular/router';
import {NgxEchartsModule} from 'ngx-echarts';


@NgModule({
    declarations: [
        WaterUsageWidget,
        WaterTempWidgetComponent,
        WaterPressureWidgetComponent,
        LineChartComponent,
        WidgetFrameComponent,
    ],
    exports: [
        WaterUsageWidget,
        WaterTempWidgetComponent,
        WaterPressureWidgetComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        NgxEchartsModule,
    ]
})
export class WidgetsModule {
}
