<mat-form-field appearance="outline"
>
  <mat-label>Choose a date</mat-label>
  <input matInput
         [matDatepicker]="picker"
         [(ngModel)]="selectedDay"
         (dateChange)="updateStream()">
  <mat-datepicker-toggle matSuffix
                         dataformatas=""
                         [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
<ng-container *ngIf="getStream() | async as todayEvents">
  <ng-container *ngIf="todayEvents.length>0; else noEvents">
    <ul class="list-group">
      <ng-container *ngFor="let event of todayEvents; index as i; let last = last"
                    class="list-group-item d-flex justify-content-between align-items-center">
        <li class="list-group-item d-flex justify-content-between align-items-center">
          <div class="c-icon-box">
            <i [class]="'icon icon-' + getEventIcon(event)"
               *ngIf="event.appliance_category_ids.length <= 1; else complex"></i>
            <ng-template #complex>
              <span class="complex-event">{{event.appliance_category_ids.length}}</span>
            </ng-template>
          </div>
          <div class="mr-auto">
            <h3>
              {{event.applianceCategory ? event.applianceCategory.name : ('Unknown Event')}}
            </h3>
            <h4 class="event-date">
              {{event.event_start | utcLocal: {format: timeFormat, timezone}  }}
              - {{event.event_end | utcLocal: {format: timeFormat, timezone} }}
            </h4>
            <p class="m-0">
              <app-humanize-duration [duration]="event.event_duration"
                                     [durationDisplayThreshold]="durationDisplayThreshold"></app-humanize-duration>
            </p>
          </div>
          <span class="badge badge-primary badge-pill">
         <span [ngClass]="{'grey-7': !!event.applianceCategory}">
                {{event.water_consumed | liters: {hasMax: true, digits: null} }}
            </span> <small>l</small>
      </span>
        </li>
      </ng-container>
    </ul>
    <button class="btn btn-primary mt-3"
            *ngIf="showLoadMoreBt"
            (click)="loadData()">
      Load more
    </button>
  </ng-container>
  <ng-template #noEvents>
    <p class="text-center">No events</p>
  </ng-template>
</ng-container>
