import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Property} from '../../../models/property';
import {PropertyService} from '../../../providers/services/property.service';
import {NotificationSettings} from '../../../models/notification-settings';
import {BehaviorSubject, Observable} from 'rxjs';
import * as _ from 'lodash';
import {take} from 'rxjs/operators';
import {EditNotificationSettingsDialogComponent} from '../edit-notification-settings-dialog/edit-notification-settings-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {incidentsBoolProperties} from '../../incidents/config';

@Component({
    selector: 'app-notification-settings',
    templateUrl: './notification-settings.component.html',
    styleUrls: ['./notification-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationSettingsComponent implements OnInit {

    @Input() selectedProperty: Property;
    public showBack = true;
    public boolProperties = incidentsBoolProperties;
    private notificationSettingsSubject: BehaviorSubject<NotificationSettings> = new BehaviorSubject(null);

    constructor(private cdRef: ChangeDetectorRef,
                private matDialog: MatDialog,
                private propertyService: PropertyService) {
    }

    ngOnInit(): void {
        if (this.selectedProperty) {
            this.showBack = false;
            this.fetchNotificationSettings();
        }
    }

    public selectProperty(properties: Property[]) {
        this.selectedProperty = properties[0];
        this.cdRef.detectChanges();
        this.fetchNotificationSettings();
    }

    public getNotificationSettingsStream(): Observable<NotificationSettings> {
        return this.notificationSettingsSubject.asObservable();
    }

    public clearPropertySelection(): void {
        this.selectedProperty = null;
        this.notificationSettingsSubject.next(null);
        this.cdRef.detectChanges();
    }

    public openEditNotificationDialog() {
        const ref = this.matDialog.open(EditNotificationSettingsDialogComponent, {
            data: {
                notificationSettings: this.notificationSettingsSubject.getValue(),
                propertyData: [this.selectedProperty],
                boolProperties: this.boolProperties
            },
            width: '100%',
            maxWidth: '500px',
            panelClass: ['mx-auto', 'overflow-visible'],
        });
        ref.afterClosed()
            .pipe(take(1))
            .subscribe((response: boolean) => {
                if (response) {
                    this.fetchNotificationSettings();
                    this.cdRef.detectChanges();
                }
            });
    }

    private fetchNotificationSettings(): void {
        this.propertyService.getNotificationSettings(this.selectedProperty.id)
            .subscribe((settings) => {
                this.notificationSettingsSubject.next(_.cloneDeep(settings));
                this.cdRef.detectChanges();
            });
    }
}
