<div class="card-body">
    <div class="row align-items-start">
        <div class="col-lg-4">
            <div class="header-with-buttons mb-3">
                <h4>Select at least one property</h4>
                <div class="buttons-row">
                    <button (click)="clearPropertySelection()"
                            *ngIf="selectedProperties.length"
                            class="ml-auto d-block btn btn-outline-primary btn-sm">
                        Clear
                    </button>
                    <button (click)="selectAllProperties()"
                            class="ml-auto d-block btn btn-outline-primary btn-sm">
                        Select all
                    </button>
                </div>
            </div>
            <app-properties-list [multiPropertySelectDisabled]="false"
                                 [selectedProperties]="selectedProperties"
                                 (propertySelect)="selectProperty($event)"
                                 (propertiesLoaded)="getPropertiesList($event)"></app-properties-list>
        </div>
        <div class="col-lg-4 u-sticky">
            <ng-container *ngIf="selectedProperty">
                <div class="row align-items-center mb-3">
                    <div class="col-lg">
                        <h4>Auto shut-off settings</h4></div>
                    <div class="col-auto">
                        <button class="ml-auto d-block btn btn-outline-primary btn-sm"
                                mat-ripple
                                *ngIf="selectedProperty"
                                (click)="openEditSettingsDialog('shutoff');">
                            <i class="fe fe-edit-2"></i> Edit
                        </button>
                    </div>
                </div>

                <table class="table table-striped"
                       *ngIf="getPropertySettingsStream() | async as propertySettings">
                    <tr>
                        <td>Auto shut-off enabled:</td>
                        <td>
                            <span
                                [ngClass]="{'text-success':  propertySettings.auto_shut_off ,'text-warning':!propertySettings.auto_shut_off}">{{ propertySettings.auto_shut_off ? 'On':'Off' }}</span>
                        </td>
                    </tr>

                </table>

                <div class="row align-items-center mb-3">
                    <div class="col-lg">
                        <h4>Pressure test settings</h4></div>
                    <div class="col-auto">
                        <button class="ml-auto d-block btn btn-outline-primary btn-sm"
                                mat-ripple
                                *ngIf="selectedProperty"
                                (click)="openEditSettingsDialog('pressure');">
                            <i class="fe fe-edit-2"></i> Edit
                        </button>
                    </div>
                </div>
                <table class="table table-striped"
                       *ngIf="getPropertySettingsStream() | async as propertySettings">
                    <tr>
                        <td>Pressure test:</td>
                        <td>
                            <span
                                [ngClass]="{'text-success':  propertySettings.pressure_tests_enabled ,'text-warning':!propertySettings.pressure_tests_enabled}">{{ propertySettings.pressure_tests_enabled ? 'On':'Off' }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>Pressure test time:</td>
                        <td>
                            {{ formatTime(propertySettings.pressure_tests_schedule) }}
                        </td>
                    </tr>

                </table>
            </ng-container>
        </div>
        <div class="col-lg-4 u-sticky">

            <ng-container *ngIf="selectedProperty">

                <div class="row align-items-center mb-3">
                    <div class="col-lg">
                        <h4>Notification settings</h4></div>
                    <div class="col-auto">
                        <button class="ml-auto d-block btn btn-outline-primary btn-sm"
                                mat-ripple
                                *ngIf="selectedProperty"
                                (click)="openEditNotificationDialog();">
                            <i class="fe fe-edit-2"></i> Edit
                        </button>
                    </div>
                </div>
                <table class="table table-striped"
                       *ngIf="getNotificationSettingsStream() | async as notificationSettings">
                    <tr *ngFor="let prop of boolProperties">
                        <td>{{ prop.name }}:</td>
                        <td>
                            <span
                                [ngClass]="{'text-success': notificationSettings[prop.key],'text-warning':!notificationSettings[prop.key]}">{{ notificationSettings[prop.key] ? 'On':'Off' }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>High volume threshold:</td>
                        <td>
                            {{ notificationSettings.high_volume_threshold_litres }} l
                        </td>
                    </tr>
                    <tr>
                        <td>Long flow delay:</td>
                        <td>
                            {{ notificationSettings.long_flow_notification_delay_mins }} min
                        </td>
                    </tr>
                </table>
            </ng-container>
        </div>
    </div>
</div>
