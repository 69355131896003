import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Pageable, PageableRequestParams} from '../../models/Pageable';
import {IncidentsService} from '../../providers/services/incidents.service';
import {IncidentIndex} from '../../models/incident';
import {MatTabGroup} from '@angular/material/tabs';
import * as _ from 'lodash';
import {PropertyService} from '../../providers/services/property.service';

enum IncidentTabsIndex {
    ACTIVE,
    CLOSED
}

@Component({
    selector: 'app-incidents',
    templateUrl: './incidents.component.html',
    styleUrls: ['./incidents.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IncidentsComponent implements OnInit {

    @Input() propertyId: string;
    @ViewChild('mainTabGroup', {static: false}) mainTabGroup: MatTabGroup;
    public selectedType: string;
    public incidentPageable: Pageable<IncidentIndex>;
    public closedIncidentsPageable: Pageable<IncidentIndex>;
    public timezone: string;

    constructor(private cdRef: ChangeDetectorRef,
                private propertyService: PropertyService,
                private incidentsService: IncidentsService) {
    }

    ngOnInit(): void {
        this.loadSettings();
        this.loadIncidents();
    }

    public loadIncidents(pageRequest?: PageableRequestParams,
                         status: any = 'active',
                         type?: string,
                         done?: () => void) {
        const pageableRequest = pageRequest || {page_size: 20, page: 1};
        this.selectedType = type ? type : null;
        this.incidentsService.getIncidentByStatus(this.propertyId, pageableRequest, status, type)
                .subscribe((res) => {
                    this.mainTabGroup.realignInkBar();
                    switch (status) {
                        case 'active' :
                            this.incidentPageable = _.cloneDeep(res);
                            break;
                        case 'closed':
                            this.closedIncidentsPageable = _.cloneDeep(res);
                            break;
                    }
                    if (done) {
                        done();
                    }
                    this.cdRef.markForCheck();
                });
    }


    public onNextPage(event, status, type = null) {
        this.loadIncidents(event, status, type);
    }

    public onTabChange(index: IncidentTabsIndex): void {
        this.closedIncidentsPageable = null;
        if (IncidentTabsIndex.ACTIVE === index) {
            this.loadIncidents();
        } else if (IncidentTabsIndex.CLOSED === index) {
            this.loadIncidents(null, 'closed');
        }
    }

    private loadSettings(): void {
        this.propertyService.getSettings(this.propertyId)
                .subscribe((settings) => {
                    this.timezone = settings.timezone;
                    this.cdRef.detectChanges();
                });
    }
}
