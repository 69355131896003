<app-widget-frame>
    <div class="c-water-usage-widget">
        <div class="c-water-usage-widget__header">
            <div class="c-water-usage-widget__title">
                Water usage
            </div>

            <div class="c-water-usage-widget__usage">
                <div class="c-water-usage-widget__trend"
                     *ngIf="limit"
                     [ngClass]="{'is-bad':sum > getUsageLimit()}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M2.3115 15.6405C0.774504 13.9506 0.354504 11.8125 0.541504 9.90255C0.747504 7.79555 1.7085 5.77455 2.9595 4.73155C4.6315 3.33855 6.3875 2.76655 7.9115 2.48655C8.65363 2.3592 9.40103 2.26477 10.1515 2.20355C10.5555 2.16555 10.9645 2.12755 11.3575 2.02955C12.1855 1.82255 13.0025 1.48655 13.6755 0.952549C13.9835 0.707549 14.2955 0.459549 14.7105 0.505549C14.8597 0.522146 15.0032 0.572112 15.1305 0.651744C15.2577 0.731375 15.3654 0.838628 15.4455 0.965549C18.6455 6.03255 18.1625 11.4115 15.4895 14.7995C14.1545 16.4905 12.2795 17.6705 10.0925 18.0196C8.1845 18.3225 6.0995 17.9836 3.9985 16.8836C3.81393 17.4573 3.6768 18.0453 3.5885 18.6416C3.55098 18.9041 3.41068 19.141 3.19848 19.3001C2.98629 19.4593 2.71957 19.5276 2.457 19.49C2.19444 19.4525 1.95754 19.3122 1.79841 19.1C1.63929 18.8878 1.57098 18.6211 1.6085 18.3585C1.7325 17.4935 1.9685 16.5725 2.3115 15.6405ZM8.2715 4.45355C8.9755 4.32455 9.6365 4.26255 10.2785 4.19955C10.8025 4.14955 11.3305 4.09755 11.8425 3.96955C12.7049 3.75867 13.5308 3.41956 14.2925 2.96355C16.4705 7.08055 15.8625 11.0975 13.9195 13.5615C12.8715 14.8895 11.4305 15.7815 9.7795 16.0435C8.2965 16.2795 6.5795 16.0195 4.7495 15.0175C5.8615 12.7505 7.6795 10.5775 10.0465 9.39455C10.2839 9.276 10.4644 9.06801 10.5484 8.81633C10.6325 8.56466 10.6131 8.28992 10.4945 8.05255C10.376 7.81518 10.168 7.63463 9.91629 7.55061C9.66461 7.4666 9.38987 7.486 9.1525 7.60455C6.5445 8.90855 4.5465 11.1635 3.2475 13.5685C2.6065 12.5185 2.4145 11.2975 2.5325 10.0965C2.7015 8.36855 3.4925 6.89155 4.2395 6.26755C5.5675 5.16055 6.9675 4.69355 8.2725 4.45355H8.2715Z"
                        />
                    </svg>
                </div>
                <div class="c-water-usage-widget__sum">
                    {{sum | number : '1.1-1' }} <span class="c-water-usage-widget__unit">l</span>
                </div>
                <div class="c-water-usage-widget__usage-divider" *ngIf="limit">
                    &nbsp;/&nbsp;
                </div>
                <div class="c-water-usage-widget__limit" *ngIf="limit">
                    {{getUsageLimit()  | number : '1.1-1'}} <span class="c-water-usage-widget__unit">l</span>
                </div>
            </div>
        </div>
        <div echarts [options]="chartOptions" class="c-bar-chart"></div>
    </div>
</app-widget-frame>
