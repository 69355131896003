import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { ThingState } from 'src/app/models/pusher';
import {Thing} from '../../../models/thing';
import {ValveStateService} from '../../../providers/services/valve-state.service';
@Component({
    selector: 'app-sonic-state',
    templateUrl: './sonic-state.component.html',
    styleUrls: ['./sonic-state.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SonicStateComponent  {
    @Input() thing: Thing;

    constructor(private valveStateService: ValveStateService) {
    }

    public returnValveStateBadge(thing: Thing) {
        return this.valveStateService.returnValveState(thing, true);
    }

    public isStateDanger(state: ThingState): boolean{
        return this.valveStateService.stateDanger(state);
    }
    public isStateSucces(state: ThingState): boolean{
        return this.valveStateService.stateSucces(state);
    }

    public isStateSecondary(state: ThingState): boolean{
        return this.valveStateService.stateSecondary(state);
    }
}
