import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {AggregatedStats, AllowedAggregatedStatProps, ProcessedData, StatsRange} from '../../models/aggregated-stats';
import * as moment from 'moment-timezone';
import {ProcessedAggregatedUsage, TimeRangeMoment} from '../../models/stats';
import numbro from 'numbro';
import StartOf = moment.unitOfTime.StartOf;


@Injectable({
    providedIn: 'root'
})
export class StatsService {
    private timezone: string;

    constructor() {
    }

    public setTimezone(timezone: string) {
        this.timezone = timezone;
    }

    public getLabels(items: AggregatedStats[] | ProcessedAggregatedUsage[], currentRange: StatsRange): string[] {
        return _.map(items, (item: AggregatedStats | ProcessedAggregatedUsage) => this.timestampToChartLabel(item.agr_date, currentRange));
    }

    public formatUsage(input: number): string {
        return numbro(input).format({
            average: input > 100000,
            thousandSeparated: true,
            mantissa: 0
        });
    }

    public formatTooltipContent(item, unit: string, range: StatsRange, showTime = true): string {
        if (item.data===undefined || (item.data!==null && _.isNaN(+item.data))) {
            return;
        }

        const value = item.data!==null ? numbro(item.data).format({
            average: item.data > 100000,
            thousandSeparated: true,
            mantissa: 1,
            optionalMantissa: true,
        }):item.data;

        let topLine = `<span class="chart-tooltip-value">${value}</span>`;

        if (item.data===null) {
            topLine = `<span class="chart-tooltip-no-data"><img src="assets/images/no-data-icon.svg" alt=""> No data</span>`;
            unit = '';
        }
        let topRange = '';
        if (range?.value==='day') {
            topRange = ` - ${this.getTopHour(item.axisValue)}`;
        }
        let timeLine = '';
        if (showTime) {
            timeLine = `<br> <span class="chart-tooltip-range">${item.axisValue}${topRange}</span>`;
        }

        return `${topLine}<span class="chart-tooltip-unit"> ${unit}</span>${timeLine}`;
    }

    public getTopHour(label: string): string {
        return moment(`1970-01-01 ${label}`).add(1, 'hour').format('HH:mm');
    }

    public formatXaxisLabel(label: string, index: number, size: number): string {
        switch (true) {
            case index===0:
            case size < 9:
            case index===size - 1:
            case size > 2 && index===_.floor(size / 2):
                return label;

        }
        return '';

    }

    public timestampToChartLabel(item: number, currentRange: StatsRange): string {
        if (_.isNaN(+item)) {
            return '';
        }
        let hoursToSubtract = 0;
        let labelFormat = 'HH:mm';
        switch (currentRange?.value) {
            case 'week':
                labelFormat = 'ddd';
                break;
            case 'month':
                labelFormat = 'DD MMM';
                break;
            case 'year':
                labelFormat = 'MMM';
                break;
            default:
                hoursToSubtract = 1;
                break;
        }
        return moment.unix(item).tz(this.timezone, false).subtract(hoursToSubtract, 'hour').format(labelFormat);
    }

    public getDateRange(date: Date, range: StatsRange): TimeRangeMoment {

        const selected = moment(date).tz(this.timezone, false);
        const start = selected.clone().startOf(this.getMomentStartOf(range));
        const end = selected.clone().endOf(this.getMomentStartOf(range));

        return {start, end};
    }


    public processData(inputData: AggregatedStats[], range: StatsRange, param: AllowedAggregatedStatProps, start: number, end: number): ProcessedData {
        const processedData = this.getAggregatedData(inputData, range);
        const filledData = this.fillEmptyRows(processedData, start, end, range);
        const labels = this.getLabels(filledData, range);
        const data = _.map(filledData, (item) => this.getMappedValue(item, param));

        return {labels, data};
    }


    private getMomentStartOf(range: StatsRange): StartOf {
        switch (range.value) {
            case 'month':
                return 'month';
            case 'week':
                return 'isoWeek';
            case 'year':
                return 'year';
            case 'day':
            default:
                return 'day';
        }
    }


    private getAggregatedData(inputData: AggregatedStats[], range: StatsRange) {
        switch (range.value) {
            case 'week':
            case 'month':
                return this.aggregateData(inputData, 'day');

            case 'year':
                return this.aggregateData(inputData, 'month');

        }
        return inputData;
    }

    private getMappedValue(item: AggregatedStats, param: AllowedAggregatedStatProps): number {
        const value = _.get(item, param);
        if (value===null) {
            return null;
        }
        switch (param) {
            case 'water_usage':
                return Math.round(item.water_usage / 1000);
            case 'pressure_average':
                return Math.round(item.pressure_average / 100) / 10;
        }
        return value;
    }

    private aggregateData(inputData: AggregatedStats[], aggregatePeriod: 'day' | 'month') {
        const result = [];
        _.forEach(inputData, (item) => {

            const newKey = moment.unix(item.agr_date).tz(this.timezone, false).startOf(aggregatePeriod).valueOf() / 1000;
            const foundItem = _.find(result, (resultItem) => resultItem.agr_date===newKey);
            item.agr_date = newKey;
            if (foundItem) {
                this.mergeStatItem(foundItem, item);
            } else {
                result.push(item);
            }
        });
        return result;
    }

    private mergeStatItem(item1: AggregatedStats, item2: AggregatedStats) {
        item1.temp_min = _.min([item1.temp_min, item2.temp_min]);
        item1.temp_max = _.max([item1.temp_max, item2.temp_max]);
        item1.temp_average = _.round(_.mean([item1.temp_average, item2.temp_average]), 1);

        item1.pressure_min = _.min([item1.pressure_min, item2.pressure_min]);
        item1.pressure_max = _.max([item1.pressure_max, item2.pressure_max]);
        item1.pressure_average = _.round(_.mean([item1.pressure_average, item2.pressure_average]), 2);
        item1.water_usage = _.sum([item1.water_usage, item2.water_usage]);
    }

    private fillEmptyRows(inputData: AggregatedStats[], start: number, end: number, range: StatsRange) {
        const daysInMonth = moment.unix(start).daysInMonth();
        const hourInSeconds = 3600;
        const dayInSeconds = hourInSeconds * 24;
        const monthInSeconds = dayInSeconds * daysInMonth;
        let offset = hourInSeconds;
        let startOfLoop = end;
        let endOfLoop = start;
        switch (range.value) {
            case 'week':
            case 'month':
                offset = dayInSeconds;
                endOfLoop = start - 1;
                startOfLoop = end - offset;
                break;
            case 'year':
                offset = monthInSeconds;
                startOfLoop = end - dayInSeconds;
                break;
        }
        if (range.value!=='day') {
            inputData = _.filter(inputData, (item) => item.agr_date!==end);
        }
        for (let i = startOfLoop; i >= endOfLoop; i = i - offset) {
            const key = this.getKeyToLookFor(range, i);
            const item = _.find(inputData, (aggregatedStat) => aggregatedStat.agr_date===key);
            // if (!item && (key * 1000) <= moment().valueOf()) {
            if (!item) {
                const valueToMock = (key * 1000) <= moment().valueOf() ? null:undefined;

                inputData.push({
                    agr_date: key,
                    device_id: '',
                    water_usage: valueToMock,
                    temp_average: valueToMock,
                    temp_min: valueToMock,
                    temp_max: valueToMock,
                    pressure_min: valueToMock,
                    pressure_max: valueToMock,
                    pressure_average: valueToMock
                });
            }

        }
        return _.sortBy(inputData, 'agr_date');
    }


    private getKeyToLookFor(range: StatsRange, initialValue: number) {
        let startOfUnit: StartOf = 'day';
        switch (range.value) {
            case 'day':
                return initialValue;
            case 'year':
                startOfUnit = 'month';
                break;
        }
        return moment(initialValue * 1000).tz(this.timezone).startOf(startOfUnit).valueOf() / 1000;
    }

}
