export const predefinedHighVolumeOptions = [
    {
        value: 100,
        label: '100 litres'
    },
    {
        value: 150,
        label: '150 litres'
    },
    {
        value: 200,
        label: '200 litres'
    },
    {
        value: 250,
        label: '250 litres'
    },
    {
        value: 300,
        label: '300 litres'
    },
    {
        value: 400,
        label: '400 litres'
    },
    {
        value: 500,
        label: '500 litres'
    },
    {
        value: 600,
        label: '600 litres'
    },
    {
        value: 800,
        label: '800 litres'
    },
    {
        value: 1000,
        label: '1000 litres'
    },
];
export const predefinedLongFlowOptions = [
    {
        value: 30,
        label: '1/2 h'
    },
    {
        value: 60,
        label: '1 h'
    },
    {
        value: 120,
        label: '2 h'
    },
    {
        value: 180,
        label: '3 h'
    },
    {
        value: 240,
        label: '4 h'
    },
    {
        value: 300,
        label: '5 h'
    },
    {
        value: 360,
        label: '6 h'
    },
];
