<h2 mat-dialog-title
    class="text-center">Mass edit</h2>
<mat-dialog-content>
    <p>You are about to update <i>{{ data.label }}</i> for {{ getNumberOfSelectedProperties() }} properties.</p>
    <div [ngSwitch]="data.field">
        <ng-container *ngSwitchCase="'pressure_tests_schedule'">
            You are setting UTC time
            <label>
                <input [(ngModel)]="timeOfPressureTests" placeholder="24hr format" aria-label="24hr format"
                       [ngxTimepicker]="fullTime" [format]="24"
                       class="form-control"
                       readonly>
                <ngx-material-timepicker [theme]="timePickerTheme"
                                         (timeSet)="updateTime($event)"
                                         #fullTime></ngx-material-timepicker>
            </label>
        </ng-container>
        <ng-container *ngSwitchCase="'high_volume_threshold_litres'">
            <label class="w-100">
                <select [(ngModel)]="value"
                        class="form-control">
                    <option *ngFor="let option of predefinedHighVolumeOptions"
                            [value]="option.value">{{ option.label }}
                    </option>
                </select>
            </label>
        </ng-container>
        <ng-container *ngSwitchCase="'long_flow_notification_delay_mins'">
            <label class="w-100">
                <select [(ngModel)]="value"
                        class="form-control">
                    <option *ngFor="let option of predefinedLongFlowOptions"
                            [value]="option.value">{{ option.label }}
                    </option>
                </select>
            </label>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <label>
                <mat-slide-toggle color="primary"
                                  class="mr-3"
                                  [(ngModel)]="value"></mat-slide-toggle>

            </label>
        </ng-container>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-ripple
            class="alert-cancel-bt btn btn-outline-secondary mr-3"
            mat-dialog-close><span>Cancel</span></button>
    <button mat-ripple
            class="btn btn-primary"
            (click)="save()">
        <span>Save</span>
    </button>
</mat-dialog-actions>
