import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {LoginResponse} from '../../models/login.response';
import {ValidateTokenResponse} from '../../models/validateToken.response';
import {EmailExistsResponse, User, UserProfile, UserResponse} from '../../models/user';
import { userDetails } from './data/userAndAppliance';
import { userDetailsAguilar } from './aquilarData/userAndAppliance';
import {delay} from 'rxjs/operators';
import * as _ from 'lodash';
import {environment} from '../../../environments/environment';

@Injectable()
export class UserDemoResource {
    private userDetails = !environment.aguilar ? userDetails : userDetailsAguilar;

    constructor() {
    }

    public signInUser(email: string, password: string): Observable<LoginResponse> {
        const response: LoginResponse = {
            user_details: this.userDetails,
            token_details: 'token-faked'
        };
        return of(response);
    }

    public signInUserWithFacebook(accessToken: string): Observable<LoginResponse> {
        const response: LoginResponse = {
            user_details: this.userDetails,
            token_details: 'token-faked'
        };
        return of(response);
    }


    public signInUserWithApple(firstName: string, lastName: string, jwt: string, userIdentity: string): Observable<LoginResponse> {
        const response: LoginResponse = {
            user_details: this.userDetails,
            token_details: 'token-faked'
        };
        return of(response);
    }

    public signInUserWithGoogle(accessToken: string): Observable<LoginResponse> {
        const response: LoginResponse = {
            user_details: this.userDetails,
            token_details: 'token-faked'
        };
        return of(response);
    }

    public signOutUser(): Observable<any> {
        return of(true);
    }


    public validateToken(): Observable<ValidateTokenResponse> {
        const response: ValidateTokenResponse = {token_details: 'aaa', user_details: this.userDetails};
        return of(response).pipe(delay(200));
    }

    public updateUser(userId: string, payload: UserProfile): Observable<User> {

        _.set(this.userDetails, 'email', payload.email);
        _.set(this.userDetails, 'first_name', payload.first_name);
        _.set(this.userDetails, 'last_name', payload.last_name);
        _.set(this.userDetails, 'phone', payload.phone);
        return of(this.userDetails).pipe(delay(200));
    }


    public refreshUserToken(oldToken = null): Observable<any> {

        return of({token_details: 'eXp4VXJuSzA5dExFZmdUcGtJZmw2UXJESnJ6WU1MZDBpWStYL0puQURIUDFZSWhjT2RDUS96UGR3bDRY' +
                'eUMrUWI2Q3hIbWJoZ3F6a08zakViL3dMeVJJblNOeHNCWFg3RkJ0RmdJL0NRM1k9LS1WSWdiRmtvUnhkMFhUU1JwSjZTTzlRPT0=' +
                '--c40f3ff729b39ee4e88e6abc479e15b6fc97efd7'});
    }


    public signUpUser(email: string, password: string, firstName: string, lastName: string, phone: string): Observable<UserResponse> {

        return of(null).pipe(delay(200));
    }

    public activateUser(code: string): Observable<UserResponse> {

        return of(null).pipe(delay(200));
    }

    public requestResendActivationEmail(email: string): Observable<User> {

        return of(null).pipe(delay(200));
    }

    public checkIfEmailExists(email: string): Observable<EmailExistsResponse> {

        return of(null).pipe(delay(200));
    }

    public requestCancelAccount(): Observable<string> {

        return of(null).pipe(delay(200));
    }
}
