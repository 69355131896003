export const recentTypes = {
    water_presence_detected: {
        icon: 'siryn-flood.svg',
    },
    high_temperature: {
        icon: 'siryn-high-temp.svg',
    },
    low_temperature: {
        icon: 'siryn-low-temp.svg',
    },
    high_humidity: {
        icon: 'siryn-high-humidity.svg',
    },
    long_flow_alert: {
        icon: 'long-usage.svg',
    },
    high_volume_alert: {
        icon: 'high-usage.svg',
    },
    device_handle_moved: {
        icon: 'moved-handle.svg',
    },
    radio_disconnection: {
        icon: 'radio-disconnect.svg',
    },
    cloud_disconnection: {
        icon: 'cloud-disconnect.svg',
    },
    pressure_test_failed: {
        icon: 'pressure-failed.svg',
    },
    pressure_test_skipped: {
        icon: 'pressure-skipped.svg',
    },
    health_check_failed: {
        icon: 'hardware-check-01.svg',
    },
    low_battery_level: {
        icon: 'low-battery-01.svg',
    },
    low_water_temperature: {
        icon: 'low-water-temperature.svg',
    },
    legionella_risk: {
        icon: 'legionella.svg',
    },
    legionella_high_risk: {
        icon: 'legionella.svg',
    },
    battery_too_low_to_move_handle: {
        icon: 'handle-battery-01.svg',
    },
    backup_battery_usage: {
        icon: 'backup-battery-usage.svg',
    },
    advanced_valve_misalignment: {
        icon: 'moved-handle.svg',
    },
};

export const incidentColor = {
    high: 'red',
    low: 'yellow',
};

export const incidentsBoolProperties =
    [
        {
            name: 'Radio disconnection',
            key: 'radio_disconnection'
        },
        {
            name: 'Pressure test skipped',
            key: 'pressure_test_skipped'
        },
        {
            name: 'Pressure test failed',
            key: 'pressure_test_failed'
        },
        {
            name: 'Low battery',
            key: 'low_battery_level'
        },
        {
            key: 'backup_battery_usage',
            name: 'Mains Power Disconnected'
        },
        {
            name: 'Low temperature',
            key: 'low_water_temperature'
        },
        {
            name: 'Sonic sensor status',
            key: 'health_check_failed'
        },
        {
            name: 'Valve position',
            key: 'device_handle_moved'
        },
        {
            name: 'Handle couldn\'t be moved due to low batteries',
            key: 'battery_too_low_to_move_handle'
        },
        {
            name: 'Cloud (wifi) disconnection',
            key: 'cloud_disconnection'
        },
        {
            name: 'Legionella check',
            key: 'legionella_risk'
        }
    ];
