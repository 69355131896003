import {EChartsOption} from 'echarts';
import * as _ from 'lodash';

export const chartDefaultOptions: EChartsOption = {
    // backgroundColor: 'black', // for dark mode
    // textStyle: {
    //     fontFamily: 'Nunito Sans'
    // },
    legend: {show: false},
    animationEasing: 'elasticOut',
    animationDelayUpdate: idx => idx * 5,
    title: {
        show: false,
    },
    xAxis: {
        axisTick: {
            show: false,
            alignWithLabel: false,
        },
        axisLabel: {
            interval: 0,
            showMaxLabel: true,
            hideOverlap: false,
        },
    },
    yAxis: {
        // show: false,
        position: 'right',
        scale: true,
        splitLine: {
            show: true,
            lineStyle: {
                type: 'dashed',
            },
        },
    },
    tooltip: {
        borderRadius: 8,
        position: (point, params, dom) => {
            const top = -1 * _.get(dom, 'offsetHeight', 0) + 10;
            const tooltipWidth = _.get(dom, 'offsetWidth', 0);
            let left = point[0] - tooltipWidth / 2;
            if (left < -25) {
                left = -25;
            }
            const windowWidth = _.get(window, 'innerWidth', 0);
            const chartMargins = 32 + 10; // margin and padding right + additionalOffset
            const maxLeftBeforeHittingRightBorder = windowWidth - chartMargins - tooltipWidth;

            if (left > maxLeftBeforeHittingRightBorder) {
                left = maxLeftBeforeHittingRightBorder;
            }
            return [left, top];
        }
    }
};
