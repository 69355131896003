<table class="table tableFixHead table-striped table-responsive">
    <thead>
    <tr>
        <th class=" " colspan="2">

            <mat-checkbox color="primary"
                          (change)="toggleAll()" [(ngModel)]="allChecked">Select all properties
            </mat-checkbox>
        </th>
        <th>
            <div class="vertical-text px-3">
                Auto shut-off
            </div>
            <button mat-icon-button (click)="massUpdateSettings('auto_shut_off','Auto shut-off')"
                    [matTooltip]="tooltipText">
                <mat-icon>mode_edit</mat-icon>
            </button>
        </th>
        <th>
            <div class="vertical-text px-3">
                High volume threshold
            </div>
            <button mat-icon-button
                    (click)="massUpdateNotification('high_volume_threshold_litres','High volume threshold')"
                    [matTooltip]="tooltipText">
                <mat-icon>mode_edit</mat-icon>
            </button>
        </th>
        <th>


            <div class="vertical-text px-3">
                Long usage threshold
            </div>
            <button mat-icon-button
                    (click)="massUpdateNotification('long_flow_notification_delay_mins','Long usage threshold')"
                    [matTooltip]="tooltipText">
                <mat-icon>mode_edit</mat-icon>
            </button>

        </th>
        <th *ngFor="let prop of boolProperties">
            <div class="vertical-text px-3">
                {{ prop.name }}
            </div>
            <button mat-icon-button (click)="massUpdateNotification(prop.key,prop.name)"
                    [matTooltip]="tooltipText">
                <mat-icon>mode_edit</mat-icon>
            </button>

        </th>
        <th>
            <div class="vertical-text px-3">
                Check-up
            </div>
            <button mat-icon-button (click)="massUpdateSettings('pressure_tests_enabled','Pressure tests enabled')"
                    [matTooltip]="tooltipText">
                <mat-icon>mode_edit</mat-icon>
            </button>

        </th>
        <th>
            <div class="vertical-text px-3">
                Check-up time
            </div>
            <button mat-icon-button (click)="massUpdateSettings('pressure_tests_schedule','Pressure tests time')"
                    [matTooltip]="tooltipText">
                <mat-icon>mode_edit</mat-icon>
            </button>

        </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let propertyObj of propertyData">
        <th>
            <mat-checkbox color="primary"
                          [(ngModel)]="propertyObj.isChecked">
            </mat-checkbox>
        </th>
        <td>{{ propertyObj.property.name }}</td>
        <td>
            <label>
                <mat-slide-toggle color="primary"
                                  class="mr-3"
                                  (change)="updateSettings(propertyObj)"
                                  [(ngModel)]="propertyObj.settings.auto_shut_off"></mat-slide-toggle>
            </label>
        </td>
        <td>
            <select [(ngModel)]="propertyObj.notification.high_volume_threshold_litres"
                    (change)="updateNotification(propertyObj)"
                    style="min-width: 120px;"
                    class="form-control">
                <option *ngFor="let option of predefinedHighVolumeOptions"
                        [value]="option.value">{{ option.label }}
                </option>
            </select>
        </td>
        <td><select [(ngModel)]="propertyObj.notification.long_flow_notification_delay_mins"
                    (change)="updateNotification(propertyObj)"
                    style="min-width: 120px;"
                    class="form-control">
            <option *ngFor="let option of predefinedLongFlowOptions"
                    [value]="option.value">{{ option.label }}
            </option>
        </select>
        </td>
        <td *ngFor="let prop of boolProperties">
            <label>
                <mat-slide-toggle color="primary"
                                  class="mr-3"
                                  (change)="updateNotification(propertyObj)"
                                  [(ngModel)]="propertyObj.notification[prop.key]"></mat-slide-toggle>
            </label>
        </td>
        <td>
            <label>
                <mat-slide-toggle color="primary"
                                  class="mr-3"
                                  (change)="updateSettings(propertyObj)"
                                  [(ngModel)]="propertyObj.settings.pressure_tests_enabled"></mat-slide-toggle>
            </label>
        </td>
        <td>

            <label>
                <input [ngModel]="getTimeOfPressureTest(propertyObj)" placeholder="24hr format" aria-label="24hr format"
                       [ngxTimepicker]="fullTime" [format]="24"
                       class="form-control"
                       style="min-width: 80px;"
                       readonly>
                <ngx-material-timepicker [theme]="timePickerTheme"
                                         (timeSet)="updateTime($event,propertyObj)"
                                         #fullTime></ngx-material-timepicker>
            </label>
        </td>
    </tr>

    </tbody>
</table>
