import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../providers/services/user.service';
import {RegexMatcher} from '../../../common/regex/RegexMatcher';
import {User, UserProfile} from '../../../models/user';
import * as _ from 'lodash';

@Component({
  selector: 'app-edit-account',
  templateUrl: './edit-account.component.html',
  styleUrls: ['./edit-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditAccountComponent implements OnInit {

  public userForm: FormGroup;
  public apiError: string;

  private user: User;

  constructor(private fb: FormBuilder,
              private cdRef: ChangeDetectorRef,
              private userService: UserService,
              private dialogRef: MatDialogRef<EditAccountComponent>) {
  }

  ngOnInit(): void {
    this.user = this.userService.user;
    this.initForm();
  }

  public close(): void {
    this.dialogRef.close(this.user);
  }

  public save() {
    const payload = _.pick(this.userForm.value, ['first_name', 'last_name', 'email', 'phone']);
    const number = _.get(payload, 'phone.e164Number');
    if (_.get(payload, 'phone.e164Number')) {
      _.set(payload, 'phone', number);
    }
    this.saveUser(payload);
  }

  private initForm(): void {
    this.userForm = this.fb.group({
      first_name: [this.user.first_name, Validators.compose([Validators.required])],
      last_name: [this.user.last_name, Validators.compose([Validators.required])],
      email: [this.user.email, Validators.compose([Validators.required, Validators.pattern(RegexMatcher.EMAIL_REGEX)])],
      phone: [this.user.phone, Validators.compose([Validators.required])],
    });
  }

  private saveUser(payload: UserProfile): void {
    this.apiError = '';
    this.userService.updateUser(this.userService.userId, payload)
      .subscribe((response) => {
        this.user = response;
        this.userService.setUserProfile(response);
        this.close();
      }, (error) => this.handleSaveUserError(error.status));
  }

  private handleSaveUserError(status: number): void {
    switch (status) {
      case 422:
        this.userForm.controls.phone.setErrors({incorrect: true});
        break;
      case 500:
        this.apiError = 'Server error';
        break;
      default:
        this.apiError = 'Unknown error';
        break;
    }
    this.cdRef.detectChanges();
  }
}
