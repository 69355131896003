import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit} from '@angular/core';
import {Thing, ThingsResponse} from '../../../models/thing';
import * as _ from 'lodash';
import {PropertyService} from '../../../providers/services/property.service';
import {ThingService} from '../../../providers/services/thing.service';

@Component({
    selector: 'app-system-status',
    templateUrl: './system-status.component.html',
    styleUrls: ['./system-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SystemStatusComponent implements OnInit, OnChanges {

    @Input() propertyId: string;

    public thing: Thing;

    constructor(private propertyService: PropertyService,
                private thingService: ThingService,
                private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.fetchThings();
    }

    ngOnChanges(): void {
        this.thing = undefined;
        this.fetchThings();
    }

    private fetchThings(): void {
        this.propertyService.getThingsByPropertyId(this.propertyId)
                .subscribe((thingsResponse: ThingsResponse) => {
                    if (_.size(thingsResponse.things) > 0) {
                        this.thing = _.head(thingsResponse.things);
                    } else {
                        this.thing = null;
                    }
                    this.cdRef.detectChanges();
                });
    }


}
