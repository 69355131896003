import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'app-widget-frame',
    templateUrl: './widget-frame.component.html',
    styleUrls: ['./widget-frame.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetFrameComponent {
    @Input() image: string;
    @Input() title: string;
    @Input() value: string;
    @Input() unit: string;
    @Input() class: string;
    @Input() isCollapsable = false;
    @Input() link: { label: string; path: string };

    public isOpen = true;


    public toggle() {
        if (!this.isCollapsable) {
            return;
        }
        this.isOpen = !this.isOpen;
    }

    public getToggleIcon() {
        if (this.isOpen) {
            return 'chevron-up-outline';
        }
        return 'chevron-down-outline';
    }
}
