import {StatsRange} from '../../models/aggregated-stats';

export const ApplianceCategoriesColors = {
    100: '#EC3C86',
    110: '#B1D0F3',
    120: '#FF6B00',
    130: '#4E6AFF',
    200: '#16C7B1',
    210: '#BF41DE',
    220: '#F6D004',
    300: '#1AAE29',
    900: '#9BCA4E',
    1: '#929292', // unclassified
};

export const StatsRanges: StatsRange[] = [
    {
        label: 'Day',
        value: 'day',
        previous: 'yesterday',
        previousLabel: 'day before',
    },
    {
        label: 'Week',
        value: 'week',
        previous: 'lastweek',
        previousLabel: 'week before',
    },
    {
        label: 'Month',
        value: 'month',
        previous: 'lastmonth',
        previousLabel: 'month before',
    },
    {
        label: 'Year',
        value: 'year',
        previous: 'lastyear',
        previousLabel: 'year before',
    },
];

export declare type StatsRangeValue = 'day' | 'week' | 'month' | 'year';
