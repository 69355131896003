<div class="card-header"
	 *ngIf="filterFields">
	<app-search-form [filterFields]="filterFields"
					 [startValues]="startValues"
					 (search)="onSearch($event)"></app-search-form>
</div>

<div class="table-wrapper">
	<div class="table-loader"
		 *ngIf="isLoading">
		<div class="spinner spinner-border my-5 mx-auto d-block"
			 role="status">
			<span class="sr-only">Loading...</span>
		</div>
	</div>
	<div class="table-box"
		 [ngClass]="{'selection-enabled':selectionEnabled}">
		<ngx-datatable
        #table
				class="material w-100"
				[loadingIndicator]="isLoading"
				[rows]="rows"
				[sorts]="sorts"
				(page)='setPage($event)'
				[headerHeight]="50"
				[rowHeight]="rowHeight || undefined"
				[columnMode]="ColumnMode.flex"
				[footerHeight]="50"
				[scrollbarH]="true"
				[externalPaging]="externalPaging"
				[count]="tableSettings.total_elements"
				[offset]="toNum(tableSettings.page) - 1"
				[limit]="tableSettings.page_size"
				[externalSorting]="externalSorting"
				[selectionType]="selectionEnabled?'single':null"
				(sort)="onSort($event)"
				(select)="onRowSelect($event)"
				[columns]="columns">

		</ngx-datatable>
		<ng-template #codeColumn
					 ngx-datatable-cell-template
					 let-row="row"
					 let-value="value">
			<pre>{{value}}</pre>
		</ng-template>
		<ng-template #dateColumn
					 ngx-datatable-cell-template
					 let-row="row"
					 let-value="value">
			{{value | date: 'yyyy-MM-dd HH:mm:ss'}}
		</ng-template>
	</div>
</div>
