<ng-container *ngIf="pressureTest.state !== 'finished'; else testFinished">
    <div class="test-result h-100">
        <div class="row h-100 blue-gradient-bg">
            <ng-container [ngSwitch]="pressureTest.state">
                <div class="col-10 m-auto text-center"
                     *ngSwitchCase="'skipped'">
                    <img src="assets/images/skipped_test.svg"
                         alt=""/>
                    <h2>SKIPPED</h2>
                    <div class="date mb-3">

                        {{ utcToPropertyLocalTime(pressureTest.created_at, 'DD MMM yyyy') }}
                    </div>
                    <p>Your test has been skipped.</p>
                </div>
                <div class="col-10 m-auto text-center"
                     *ngSwitchCase="'requested'">
                    <h2>Check Up is being prepared...</h2>
                </div>
                <div class="col-10 m-auto text-center"
                     *ngSwitchCase="'error'">
                    <h2>Check Up error. Aborting...</h2>
                </div>
                <div class="col-10 m-auto text-center"
                     *ngSwitchDefault>
                    <h2>Check Up in progress...</h2>
                    <div class="date mb-3">
                        {{ utcToPropertyLocalTime(pressureTest.created_at, 'DD MMM yyyy') }}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>
<ng-template #testFinished>
    <div class="test-result mt-4">
        <div class="status">
            <ng-container *ngIf="pressureTest.passed; else notPassed">
                <span>PASSED</span>
            </ng-container>
            <ng-template #notPassed>
                <ng-container [ngSwitch]="pressureTest.status">
                    <div *ngSwitchCase="'skipped'">
                        <span class="skipped">Pressure test skipped</span></div>
                    <div *ngSwitchCase="'okay'">
                        <span class="alert">Pressure alert</span></div>
                    <div *ngSwitchDefault>
                        <span class="incomplete">Incomplete test</span></div>
                </ng-container>
            </ng-template>
        </div>
        <ng-container *ngIf="pressureTest.started_at">
            <div class="time">
                {{ utcToPropertyLocalTime(pressureTest.started_at) }}
                <ng-container *ngIf="pressureTest.finished_at">
                    - {{ utcToPropertyLocalTime(pressureTest.finished_at) }}</ng-container>
            </div>
            <div class="date">
                {{ utcToPropertyLocalTime(pressureTest.started_at, 'DD MMM yyyy') }}
            </div>
        </ng-container>
        <div class="chart mt-3"
             *ngIf="pressureTest.measurements.length>15">
            <div class="chart-wrapper"
                 #chartWrapper>
                <canvas baseChart
                        [datasets]="chartDataSets"
                        [colors]="lineChartColors"
                        [labels]="mapLabels(pressureTest.measurements)"
                        [options]="chartOptions"
                        [legend]="false"
                        [chartType]="'line'">
                </canvas>
                <div class="chart-overlay"></div>
            </div>
            <div class="chart-legend my-5">
                <div class="row align-items-center ">
                    <div class="col-auto ml-auto">
                        <div class="actual-line"></div>
                    </div>
                    <div class="col-auto p-0">
                        <span>ACTUAL PRESSURE</span>
                    </div>
                    <div class="col-auto">
                        <div class="decay-line"></div>
                    </div>
                    <div class="col-auto mr-auto p-0">
                        <span>MAX DECAY</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="!isNaN(pressureTest.avg_pressure); else error">
        <div class="row my-4 pl-3">
            <div class="col-12">
                <h3 class="m-0">
                    Test details
                </h3>
            </div>
        </div>
        <dl>
            <dt>Average pressure:</dt>
            <dd>{{ pressureTest.avg_pressure/1000 | number: '1.2-2'}} bar</dd>
            <dt>Pressure change:</dt>
            <dd><ng-container *ngIf="pressureTest.percentage_tendency>0">+</ng-container>{{ pressureTest.percentage_tendency | number: '1.0-2'}} %</dd>
        </dl>
    </ng-container>
    <ng-template #error>
        <div class="row my-4 pl-3">
            <div class="col-10 m-auto text-center">
                <ng-container [ngSwitch]="pressureTest.status">
                    <p *ngSwitchCase="'abandon_water_run'">
                        Check-Up test has been abandoned due to flowing water
                    </p>
                    <p *ngSwitchCase="'abandon_handle_movement'">
                        Check-Up has been abandoned due to handle movement
                    </p>
                    <p *ngSwitchCase="'pending'">
                        Check-Up is being prepared...
                    </p>
                    <p *ngSwitchCase="'closed_due_to_inactivity'">
                        Check-Up was not completed because no response was received from Sonic.
                    </p>
                    <p *ngSwitchCase="'pressure_drop'">
                        Check-Up has been abandoned due to pressure drop.
                    </p>
                    <p *ngSwitchCase="'low_pressure'">
                        Check-Up has been abandoned due to low pressure.
                    </p>
                    <p *ngSwitchCase="'drag_out'">
                        Check-Up was not completed because no response was received from Sonic.
                    </p>
                    <p *ngSwitchDefault>
                        Check-Up has been abandoned due internal error.
                    </p>
                </ng-container>
            </div>
        </div>
    </ng-template>
</ng-template>
