import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {
    IncidentDetailsResponse,
    IncidentIndex,
    IncidentSeverity,
    IncidentsStatusCount,
    IncidentState,
    IncidentType,
    MostRecentIncident
} from '../../../models/incident';
import {Pageable} from '../../../models/Pageable';
import {OptionalHttpParams} from '../../resources/thing.resource';
import {delay} from 'rxjs/operators';
import * as _ from 'lodash';
import {PropertyDemoResource} from '../property.demo.resource';
import * as moment from 'moment-timezone';

@Injectable()
export class IncidentDemoResource {

    private incidents: IncidentIndex[] = [
        {
            detected_at: moment().subtract(1, 'hour').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
            id: 'aef65e22-29a9-496c-9c47-e38fcb072f22',
            payload: {
                water_temp: 3
            },
            severity: 'low',
            state: 'contacting',
            status: 'active',
            thing_id: 'aef65e22-apt-1-thing',
            title: 'Low water temperature',
            type: 'low_water_temperature'
        }, {
            detected_at: moment().subtract(1, 'day').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
            id: 'aef65e22-29a9-496c-9c47-e38fcb072f98',
            payload: {},
            severity: 'low',
            state: 'resolved',
            status: 'closed',
            thing_id: 'aef65e22-apt-1-thing',
            title: 'Signal Disconnected',
            type: 'cloud_disconnection'
        }, {
            detected_at: moment().subtract(1, 'day').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
            id: '2c7bb842-b1f6-4a8a-a9a8-971a61bbab27',
            payload: {},
            severity: 'low',
            state: 'contacting',
            status: 'closed',
            thing_id: 'f7b44f5c-apt-3-thing',
            title: 'Check-Up Skipped',
            type: 'pressure_test_skipped'
        }, {
            detected_at: moment().subtract(1, 'day').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
            id: 'ceb0e4ff-2a30-4fbe-b78b-8447969b23e8',
            payload: {avg_pressure: 1937.5, percentage_tendency: -66.98113207547169},
            severity: 'high',
            state: 'dismissed',
            status: 'closed',
            thing_id: 'aef65e22-apt-1-thing',
            title: 'Check-Up Alert',
            type: 'pressure_test_failed'
        }, {
            detected_at: moment().subtract(4, 'day').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
            id: '57e5b66f-7574-48c6-afdf-f6fe9c313239',
            payload: {duration: 3817589, max: 345.703125, min: 0, volume: 10.426310221354168},
            severity: 'high',
            state: 'resolved',
            status: 'closed',
            thing_id: 'f7b44f5c-apt-3-thing',
            title: 'Long Usage Behaviour',
            type: 'long_flow_alert'
        }, {
            detected_at: moment().subtract(5, 'day').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
            id: '8fcbc768-75fc-41eb-b335-79a175adf048',
            payload: {},
            severity: 'low',
            state: 'contacting',
            status: 'active',
            thing_id: '1aa197d0-apt-2-thing',
            title: 'Sonic Disconnected',
            type: 'radio_disconnection'
        }, {
            detected_at: moment().subtract(6, 'day').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
            id: '5ac9e0a5-fe04-4f60-9ef5-cfd0dd3129a1',
            payload: {},
            severity: 'low',
            state: 'resolved',
            status: 'closed',
            thing_id: '1aa197d0-apt-2-thing',
            title: 'The water supply is only partially shut off.',
            type: 'device_handle_moved'
        }, {
            detected_at: moment().subtract(7, 'day').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
            id: '5672a028-8a07-413c-ab6e-3aa1b37fe7d2',
            payload: {test: 'tomas-test'},
            severity: 'low',
            state: 'resolved',
            status: 'active',
            thing_id: 'f7b44f5c-apt-3-thing',
            title: 'Low Battery',
            type: 'low_battery_level'
        }, {
            detected_at: moment().subtract(8, 'day').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
            id: '0a31574f-7cc3-4f8a-b487-7f75f03fa83c',
            payload: {test: 'tomas-test'},
            severity: 'low',
            state: 'resolved',
            status: 'closed',
            thing_id: 'f7b44f5c-apt-3-thing',
            title: 'High Volume Behaviour',
            type: 'high_volume_alert'
        }, {
            detected_at: moment().subtract(9, 'day').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
            id: 'fdab2450-6035-4460-b1de-bfe03e408cf0',
            payload: {test: 'tomas-test'},
            severity: 'high',
            state: 'resolved',
            status: 'closed',
            thing_id: 'f7b44f5c-apt-3-thing',
            title: 'There\'s a problem with one of Sonic\'s sensors.',
            type: 'health_check_failed'
        }, {
            detected_at: moment().subtract(7, 'day').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
            id: '5672h643-6035-413c-9ef5-3ft1b37fe7d3',
            payload: {duration: 3817539, max: 345.103125, min: 0, volume: 9.426310221354168},
            severity: 'high',
            state: 'resolved',
            status: 'closed',
            thing_id: 'f7b44f5c-apt-4-thing',
            title: 'Long Usage Behaviour',
            type: 'long_flow_alert'
        }, {
            detected_at: moment().subtract(7, 'day').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
            id: '1256a028-830z-233c-ab6e-64hi9ytfe7d2',
            payload: {test: 'tomas-test'},
            severity: 'low',
            state: 'resolved',
            status: 'active',
            thing_id: 'f7b44f5c-apt-5-thing',
            title: 'Low Battery',
            type: 'low_battery_level'
        }, {
            detected_at: moment().subtract(7, 'day').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
            id: '5d72hj82-8a07-413c-ko8z-3aa1b37fe7d2',
            payload: {duration: 3817539, max: 345.103125, min: 0, volume: 9.426310221354168},
            severity: 'high',
            state: 'resolved',
            status: 'closed',
            thing_id: 'f7b44f5c-apt-6-thing',
            title: 'Long Usage Behaviour',
            type: 'long_flow_alert'
        }, {
            detected_at: moment().subtract(7, 'day').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
            id: '2jk2a028-7ui87-23k0c-ab6e-9hfdj238qwd',
            payload: {test: 'tomas-test'},
            severity: 'high',
            state: 'contacting',
            status: 'active',
            thing_id: 'f7b44f5c-apt-6-thing',
            title: 'Long Usage Behaviour',
            type: 'health_check_failed'
        }, {
            detected_at: moment().subtract(7, 'day').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
            id: '5e82a028-2pok-413c-ab7e-3aa1b37fe7d2',
            payload: {duration: 3817539, max: 345.103125, min: 0, volume: 9.426310221354168},
            severity: 'low',
            state: 'contacting',
            status: 'closed',
            thing_id: 'f7b44f5c-apt-7-thing',
            title: 'Long Usage Behaviour',
            type: 'pressure_test_skipped'
        }, {
            detected_at: moment().subtract(1, 'day').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
            id: '2c7bb842-b1f6-4a8a-a9a8-971a61bbab27',
            payload: {},
            severity: 'low',
            state: 'contacting',
            status: 'closed',
            thing_id: 'f7b44f5c-apt-8-wifi',
            title: 'Check-Up Skipped',
            type: 'pressure_test_skipped'
        }, {
            detected_at: moment().subtract(7, 'day').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
            id: '5e82a028-2pok-413c-ab7e-3aa1b37fe7d9',
            payload: {duration: 3617539, max: 344.103125, min: 0, volume: 9.126310221354168},
            severity: 'low',
            state: 'contacting',
            status: 'closed',
            thing_id: 'f7b44f5c-apt-9-wifi',
            title: 'Long Usage Behaviour',
            type: 'pressure_test_skipped'
        },{
            detected_at: moment().subtract(1, 'hour').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
            id: 'aef65e22-29a9-496c-9c47-e38fcb072f12',
            payload: {
                water_temp: 3
            },
            severity: 'low',
            state: 'contacting',
            status: 'active',
            thing_id: 'f7b44f5c-apt-9-wifi',
            title: 'Low water temperature',
            type: 'low_water_temperature'
        }];

    private incidentDescriptions = {
        device_handle_moved: 'Sonic\'s shut-off valve is partially closed. Please close (or open) it the rest of the way.\n',
        cloud_disconnection: 'We\'re having some trouble connecting to your Signal.\n' +
            'While Signal is offline, you won\'t be able to view usage or manually control the incoming water supply.\n' +
            'Here are some things to check:\n- Has someone unplugged the Signal or turned it off at the wall?\n' +
            '- Is your WiFi network on and operating normally?\n- Have you changed your WiFi password recently?\n' +
            '- Can you connect to the internet using other devices?\nIf you\'re having trouble, you can chat with the support team using the chat icon below.\n',
        long_flow_alert: 'Water has been running in your property for a long time and we aren\'t sure why.\n\n' +
            'Here are a few things to check:\n- Check to see if anyone in the house is using lots of water, such as watering the garden or washing a car.\n' +
            '- Take a look at your sinks, toilets and appliances - see if any of them have been left on.\n' +
            '- Check any water storage tanks in the loft or airing cupboard for leaks.\n- Try turning the water back on to see if there is still water flowing.\n\n' +
            'If you cannot locate the source, we recommend turning the water off and calling a plumber as you may have a leak.\n',
        radio_disconnection: 'We\'re having some trouble connecting to Sonic. While Sonic is offline, you won\'t be able to view your usage or control the water supply.\n' +
            'Here are some things to try:\n- Press the button on top of Sonic. If no lights appear, check the power supply or replace the batteries.\n' +
            '- Have you recently moved your Signal to a different location? If so, Sonic may no longer be in range.\n' +
            'If you have any trouble, you can chat with the support team using the chat icon below.\n',
        pressure_test_skipped: 'You\'ve enabled CheckUps, but the last three scheduled CheckUps didn\'t run correctly.\n' +
            'Skipped Check-Ups usually happen because somebody used the water while the test was in progress.' +
            ' We recommend adjusting scheduled Check-Ups to a time when the water isn\'t usually needed, such as the middle of the night.\n' +
            'If you need any help, you can chat to the support team using the chat icon below.\n',
        pressure_test_failed: 'A recent Check-Up alert. This could mean you have a slow leak somewhere, or it could just be a dripping tap.\n' +
            'Here are some steps you can take to investigate:\n- Dripping taps are a common cause of a failed Check-Up, so check this first.\n' +
            '- Take a look at your sinks, toilets and appliances - see if any of them have been left on.\n' +
            '- Check any water storage tanks in the loft or airing cupboard for leaks.\n' +
            '- If you cannot locate the source of the usage, we recommend calling a plumber as you may have a leak.\n',
        high_volume_alert: 'We\'ve detected an unusually high rate of flow in your property.\n' +
            'This can happen for a number of reasons, but it could mean there\'s a problem.\nHere are some steps you can take to investigate:\n' +
            '- Check around and underneath sinks, toilets and appliances for signs of leakage.\n' +
            '- Check your boiler and water storage tank, if you have one. Storage tanks may be in loft areas.\n- Check outside taps and hose pipes for damage.\n' +
            '- Try turning the water back on and see if there\'s still an issue.\n' +
            'If you cannot locate the source, we recommend you turn the water supply off and call a plumber as you may have a leak.\n',
        low_battery_level: 'The batteries in Sonic are running low. Please replace them with 4 x AA Lithium batteries.\n',
        health_check_failed: 'Sonic has detected an issue with one its sensors or components. Would you like to chat to the support team?\n',
        low_water_temperature: 'Sonic has detected that the water temperature dropped below 4°C.' +
            ' If the water freezes there is a risk that pipes will burst.'


    };

    private singleIncident = {
        confirmed: null,
        description: 'We\'re having some trouble connecting to your Signal.\n' +
            'While Signal is offline, you won\'t be able to view usage or manually control the incoming water supply.\nHere are some things to check:\n' +
            '- Has someone unplugged the Signal or turned it off at the wall?\n- Is your WiFi network on and operating normally?\n' +
            '- Have you changed your WiFi password recently?\n- Can you connect to the internet using other devices?\n' +
            'If you\'re having trouble, you can chat with the support team using the chat icon below.\n',
        detected_at: '2021-10-22T11:40:14.828217Z',
        id: 'cea8bbdd-b5bf-4ca3-b473-5ca18b0cfc40',
        open: true,
        possible_actions: [],
        property_id: null,
        seen: false,
        severity: 'low' as IncidentSeverity,
        state: 'contacting' as IncidentState,
        thing_id: '45c28e92-0415-48f3-8076-d695ba190011',
        title: 'Signal Disconnected',
        type: 'cloud_disconnection' as IncidentType
    };

    constructor(private propertyDemoResource: PropertyDemoResource) {
    }

    public getIncidentIndex(propertyId: string, indexParams?: IncidentIndexFilters): Observable<Pageable<IncidentIndex>> {
        const thingId = (_.get(this.propertyDemoResource.things, propertyId)[0] as IncidentIndex).id;

        let incidents: IncidentIndex[] = _.filter(this.incidents, (item) => item.thing_id===thingId);
        if (indexParams.status) {
            incidents = _.filter(incidents, (item) => item.status===indexParams.status);
        }
        const response: Pageable<IncidentIndex> = {
            content: incidents,
            page_number: 1,
            page_size: 20,
            total_entries: incidents.length,
            total_pages: 1
        };
        return of(response).pipe(delay(200));
    }

    public getAllIncidents(params?: OptionalHttpParams): Observable<Pageable<IncidentIndex>> {

        const response: Pageable<IncidentIndex> = {
            content: this.incidents,
            page_number: 1,
            page_size: 30,
            total_entries: this.incidents.length,
            total_pages: 1
        };
        return of(response).pipe(delay(200));
    }

    public getIncidentsCount(propertyId: string): Observable<IncidentsStatusCount> {

        return of(null).pipe(delay(200));
    }

    public findById(propertyId: string, incidentId: string): Observable<IncidentDetailsResponse> {

        const tempIncident = _.find(this.incidents, (item) => item.id===incidentId);
        _.set(this.singleIncident, 'payload', tempIncident.payload);
        _.set(this.singleIncident, 'type', tempIncident.type);
        _.set(this.singleIncident, 'detected_at', tempIncident.detected_at);
        _.set(this.singleIncident, 'state', tempIncident.state);
        _.set(this.singleIncident, 'icon', tempIncident.icon);
        _.set(this.singleIncident, 'title', tempIncident.title);
        _.set(this.singleIncident, 'description', _.get(this.incidentDescriptions, tempIncident.type));

        const response: IncidentDetailsResponse = {
            incident: this.singleIncident
        };
        return of(response).pipe(delay(200));
    }


    public executeAction(propertyId: string,
                         incidentId: string,
                         action: string): Observable<IncidentDetailsResponse> {
        return of(null).pipe(delay(200));
    }

    public snooze(propertyId: string,
                  incidentId: string,
                  snoozedUntil: string): Observable<IncidentDetailsResponse> {
        return of(null).pipe(delay(200));
    }

    public theMostRecentClosedIncidents(propertyId: string): Observable<MostRecentIncident[]> {
        return of(null).pipe(delay(200));
    }

    public getCopiesByType(type: string): Observable<{ title: string; description: string }> {
        return of(null).pipe(delay(200));
    }
}

export interface IncidentIndexFilters {
    // initialising, contacting, resolved, waiting_for_user, dismissed;
    state?: IncidentState[];
    seen?: boolean;
    open?: boolean;
    confirmed?: boolean;
    type?: IncidentType;
    severity?: string;
    escalation_level?: number;
    page?: number;
    status?: 'active' | 'snoozed' | 'closed';
    page_size?: number;
}
