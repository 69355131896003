import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RegexMatcher} from '../../../common/regex/RegexMatcher';
import {Router} from '@angular/router';
import * as _ from 'lodash';
import {AuthenticationService} from '../../../providers/services/authenthication.service';
import {DemoService} from '../../../providers/services/demo.service';
import * as data from '../../../../../package.json';
import {environment} from 'src/environments/environment';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {

    public logo = !environment.aguilar ? 'assets/images/logo.svg' : 'assets/images/aquilar-logo.jpeg';
    public logoClass = environment.aguilar;
    public version;
    public passwordFieldType = 'password';
    public error = '';
    public loginForm: FormGroup;
    private EMAIL_REGEXP: RegExp = RegexMatcher.EMAIL_REGEX;

    constructor(private fb: FormBuilder,
                private cdRef: ChangeDetectorRef,
                private demoService: DemoService,
                private authService: AuthenticationService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.buildForm();
        const appInfo = data;
        this.version = appInfo.version;
    }

    public onSubmit(): void {
        const loginMode = this.sandboxModeCheck(this.loginForm.value.email, this.loginForm.value.password);
        if (this.loginForm.valid && loginMode) {
            this.switchToDemo();
        } else if (this.loginForm.valid && !loginMode) {
            this.signInUser();
        } else {
            this.error = 'Provided inputs are incorrect';
        }
    }

    public switchToDemo(): void {
        this.demoService.toggleDemo(true);
        this.loginForm.controls.email.setValue('demo@demo.com');
        this.loginForm.controls.password.setValue('demo@demo.com');
        this.onSubmit();
    }

    public changePasswordType() {
        this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
        this.cdRef.detectChanges();
    }

    private sandboxModeCheck(email: string, password: string): boolean {
        const sandboxEmailCheck = email === 'sandbox@hero-labs.com';
        const sandboxPasswordCheck = password === 'ImaH3ro!';
        return sandboxEmailCheck && sandboxPasswordCheck;
    }

    private signInUser() {
        this.authService.signIn(this.loginForm.value.email, this.loginForm.value.password)
                .subscribe(
                        () => this.router.navigate(['dashboard']),
                        (err) => this.handleSignInError(err)
                );
    }

    private buildForm() {
        this.loginForm = this.fb.group({
            email: ['', Validators.compose([Validators.required, Validators.pattern(this.EMAIL_REGEXP)])],
            password: ['', Validators.compose([Validators.minLength(6), Validators.required])]
        });
    }

    private handleSignInError(err: string): void {
        switch (err.toString()) {
            case 'Unauthorized':
                this.error = 'Invalid Email or Password. Please check the credentials and try again.';
                break;
            case 'Error: Permission':
                this.error = 'You do not have sufficient permission to access the dashboard.';
                break;
            default:
                this.error = _.get(err, 'error.message');
        }
        this.cdRef.detectChanges();
    }

}
