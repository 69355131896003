<app-layout-auth>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-5 col-xl-4 my-5">
        <a class=" mb-5 text-center"
           routerLink="/">
          <img alt="..."
               [ngClass]="logoClass === true ? 'logo-AQUILAR' : 'logo'"
               [src]="logo">
        </a>

        <div *ngIf="error !== ''"
             class="alert alert-danger"
             role="alert">
          {{error}}
        </div>

        <!-- Form -->
        <form (submit)="onSubmit()"
              *ngIf="loginForm"
              [formGroup]="loginForm">

          <!-- Email address -->
          <div class="form-group">

            <!-- Label -->
            <label>Email Address</label>

            <!-- Input -->
            <input class="form-control"
                   formControlName="email"
                   placeholder="name@address.com"
                   type="email">

          </div>

          <!-- Password -->
          <div class="form-group">

            <div class="row">
              <div class="col">

                <!-- Label -->
                <label>Password</label>

              </div>
            </div> <!-- / .row -->

            <!-- Input group -->
            <div class="input-group input-group-merge">

              <!-- Input -->
              <input [type]="passwordFieldType"
                     class="form-control form-control-appended"
                     formControlName="password"
                     placeholder="Enter your password">

              <!-- Icon -->
              <div class="input-group-append">
                                <span (click)="changePasswordType()"
                                      class="input-group-text">
                                    <i class="fe fe-eye"></i>
                                    </span>
              </div>

            </div>
            <div class="row">
              <div class="col text-right">
                <!-- Help text -->
                <a class="form-text small text-muted"
                   routerLink="/auth/forgot-password">
                  Forgot password?
                </a>
              </div>
            </div> <!-- / .row -->
          </div>

          <!-- Submit -->
          <button class="btn btn-lg btn-block btn-primary mb-3"
                  type="submit">
            Sign in
          </button>

        </form>
      </div>
    </div> <!-- / .row -->
    <div class="app-version">{{version}}</div>
  </div> <!-- / .container -->
</app-layout-auth>
