<ng-container *ngIf="thing===null; else hasSonic">
	No sonic
</ng-container>
<ng-template #hasSonic>
	<ng-container *ngIf="thing; else loading">
		Battery:
        <app-battery-level [batteryLevel]="thing.battery_level"></app-battery-level>

        <br/>
		WiFi:
		<app-wifi-strength [thing]="thing"></app-wifi-strength>
		<br/>
    <div *ngIf="thing.type !== 'sonic_wifi'">
      Sonic radio:
      <app-sonic-connection-status [thing]="thing"></app-sonic-connection-status>
    </div>
		Valve state:
		<app-sonic-state [thing]="thing"></app-sonic-state>
	</ng-container>
	<ng-template #loading>
		<mat-spinner [diameter]="22"></mat-spinner>
	</ng-template>
</ng-template>
