<h2 mat-dialog-title
    class="text-center">Edit notification settings</h2>
<mat-dialog-content  >
    <h3>Receive incident notifications from the list below</h3>
    <div *ngFor="let prop of boolProperties">
        <label>
            <mat-slide-toggle color="primary"
                              class="mr-3"
                              [(ngModel)]="notificationSettings[prop.key]"></mat-slide-toggle>
            {{ prop.name }}
        </label>
    </div>

    <div class="form-group">
        <label class="w-100">
            High volume threshold
            <select [(ngModel)]="notificationSettings.high_volume_threshold_litres"
                    class="form-control">
                <option *ngFor="let option of predefinedHighVolumeOptions"
                        [value]="option.value">{{ option.label }}
                </option>
            </select>
        </label>
    </div>

    <div class="form-group">
        <label class="w-100">
            Long usage threshold
            <select [(ngModel)]="notificationSettings.long_flow_notification_delay_mins"
                    class="form-control">
                <option *ngFor="let option of predefinedLongFlowOptions"
                        [value]="option.value">{{ option.label }}
                </option>
            </select>
        </label>
    </div>
    <button class="w-100" (click)="restoreToDefault();" mat-stroked-button>Restore thresholds to default values</button>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-ripple
            class="alert-cancel-bt btn btn-outline-secondary mr-3"
            mat-dialog-close><span>Cancel</span></button>
    <button mat-ripple
            class="btn btn-primary"
            (click)="save()">
        <span>Save</span>
    </button>
</mat-dialog-actions>
