import {ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import * as _ from 'lodash';
import {TableComponent} from '../../parts/table/table.component';
import {tableSettings} from 'src/app/models/table-settings';
import {incidentColor, recentTypes} from '../config';

@Component({
    selector: 'app-incidents-page',
    templateUrl: './incidents-page.component.html',
    styleUrls: ['./incidents-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IncidentsPageComponent implements OnInit {
    @ViewChild(TableComponent) table: TableComponent;
    @ViewChild('titleColumn') titleColumn: TemplateRef<any>;
    @ViewChild('statusColumn') statusColumn: TemplateRef<any>;
    @ViewChild('severityColumn') severityColumn: TemplateRef<any>;
    @ViewChild('additionalColumn') additionalColumn: TemplateRef<any>;
    @ViewChild('actionsColumn') actionsColumn: TemplateRef<any>;

    public columns = [];
    public tableSettings = _.cloneDeep(tableSettings);
    public incidentColor = incidentColor;
    public incidentsTypes = recentTypes;
    public thingsInProperties = [];

    constructor() {
    }

    ngOnInit(): void {
    }

}
