import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {OptionalHttpParams, ThingResource} from '../resources/thing.resource';
import {ApplianceAwareEvent, Thing, ThingEvent, ThingResponse} from '../../models/thing';
import {TotalUsage, TotalUsageParams} from '../../models/total.usage';
import {Hub} from '../../models/hub';
import {HubService} from './hub.service';
import {CalendarPressureTests, PressureTestsResponse} from '../../models/pressure-test';
import {ThingDemoResource} from '../demo-resources/thing.demo.resource';
import {map, switchMap} from 'rxjs/operators';
import {ApplianceCategoryService} from './appliance-category.service';
import * as _ from 'lodash';
import {AggregatedStats} from '../../models/aggregated-stats';

@Injectable()
export class ThingService {
    private resource: ThingResource | ThingDemoResource;

    constructor(private thingResource: ThingResource,
                private readonly applianceCategoryService: ApplianceCategoryService,
                private thingDemoResource: ThingDemoResource,
                private hubService: HubService) {
        this.resource = this.thingResource;
    }

    public toggleDemo(state: boolean): void {
        this.resource = state ? this.thingDemoResource : this.thingResource;
    }

    public changeThingState(thingId: string, newState: string): Observable<ThingResponse> {
        return this.resource.changeThingState(thingId, newState);
    }

    public getThing(thingId: string): Observable<ThingResponse> {
        return this.resource.getThing(thingId);
    }

    public totalUsage(thingId: string, query: TotalUsageParams): Observable<TotalUsage> {
        return this.resource.getTotalUsage(thingId, query);
    }

    public getBatteryLevel(batteryLevel: string | number): { label: string; css: string } {
        let key = '';
        let css = '';
        if (!batteryLevel) {
            return {label: key, css};
        }

        switch (batteryLevel) {
            case 'low':
                key = 'Very low batteries';
                css = 'text-danger';
                break;
            case 'mid':
                key = 'Medium batteries';
                css = 'text-warning';
                break;
            case 'high':
                key = 'Very good batteries';
                css = 'text-success';
                break;
            case 'external_power_supply':
                key = 'Power supply';
                break;
        }

        return {label: key, css};
    }

    public getThingConnectionStatus(thing: Thing): string {
        if (thing.cloud_connection && thing.radio_rssi) {
            return `${thing.radio_connection} ${thing.radio_rssi.toString()}`;
        } else if (thing.radio_connection) {
            return thing.radio_connection.toString();
        }
        return 'disconnected';
    }

    public getWifiStrength(thing: Thing | Hub): { label: string; css: string } {
        const wifiStatus = this.hubService.getWifiStatus(thing);
        let key = 'THINGS_SETTINGS_SIGNAL_CONNECTION_FALSE';
        let css = '';
        switch (wifiStatus) {
            case 'excellent':
                css = 'text-success';
                key = 'good';
                break;
            case 'good':
                css = 'text-success';
                key = 'medium';
                break;
            case 'fair':
                css = 'text-warning';
                key = 'medium';
                break;
            case 'weak':
                css = 'text-danger';
                key = 'low';
                break;
            case 'no_signal':
                css = 'text-danger fe-wifi-off';
                key = 'disconnected';
                break;
        }
        return {label: key, css};
    }


    public getPropertyPressureTests(thingId: string, params?: { day?: string; last?: boolean }): Observable<PressureTestsResponse> {
        return this.resource.getPropertyPressureTests(thingId, params);
    }

    public getPressureCalendar(thingId: string, params: { from: string; to: string }): Observable<CalendarPressureTests> {
        return this.resource.getCalendarPressureTests(thingId, params);
    }

    public getEvents(thingId: string, params?: OptionalHttpParams): Observable<ThingEvent[]> {
        return this.resource.getEvents(thingId, params)
            .pipe(map((res) => res.events));
    }

    public getApplianceAwareEvents(thingId: string, params?: OptionalHttpParams): Observable<ApplianceAwareEvent[]> {
        return this.applianceCategoryService.getAllCategories()
            .pipe(
                switchMap((cats) => this.getEvents(thingId, params)
                    .pipe(
                        map((res) => {
                            const applianceCatAwareEvents = _.map(res, (event: ThingEvent) => {
                                const categoryIds = event.appliance_category_ids;

                                if (_.size(categoryIds)) {
                                    _.set(event, 'applianceCategory', _.find(cats, {id: _.head(categoryIds)}));
                                }

                                return event;
                            });
                            return _.filter(applianceCatAwareEvents, (e: ApplianceAwareEvent) => e.water_consumed >= 100);
                        }),
                    )),
            );
    }

    public getAggregatedStats(thingId: string, from: number, to: number): Observable<AggregatedStats[]> {
        return this.thingResource.getAggregatedStats(thingId, from, to);
    }

}

