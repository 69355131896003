<app-layout-app>
	<div class="main-content">
		<!-- HEADER -->
		<div class="header pb-5">
			<div class="container-fluid">
				<!-- Body -->
				<div class="header-body">
					<div class="row align-items-end">
						<div class="col">
							<h1 class="m-0 py-4">
								Pressure tests
							</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid ">
			<div class="row">
				<div class="col-12">
					<div class="card">
						<div class="card-body">
                            <div class="row align-items-start">
								<div [ngClass]="{'col-xl-12': !selectedProperty, 'col-xl-3':selectedProperty}">
									<h2>Select property</h2>


									<app-properties-list
                                        [autoSelectFirstProperty]="true"
                                        (propertySelect)="selectProperty($event)">
                                    </app-properties-list>

								</div>
								<ng-container *ngIf="selectedProperty && thing; ">
                                    <div class="col-xl-3 mx-xl-auto u-sticky">
										<h2>Select date</h2>
										<ng-container *ngIf="readCalendarStream() | async as pressureTests; else loading">

											<mat-calendar (selectedChange)="onCalendarDateSelected($event)"
														  [maxDate]="maxDate"
														  [minDate]="calendarMinDate"
														  [dateClass]="getDateClass(pressureTests)"></mat-calendar>
										</ng-container>
									</div>
                                    <div class="col-xl-4 u-sticky">
										<ng-container *ngIf="currentPressureTest && timezone">
											<app-pressure-test-details [pressureTest]="currentPressureTest"
																	   [timezone]="timezone"></app-pressure-test-details>
										</ng-container>
									</div>
								</ng-container>
								<ng-container *ngIf="notThing">
									<ng-container *ngIf="selectedProperty">
										<div class="col-xl-3 offset-xl-1">
											<h2>There is no Sonic</h2>
											<img src="assets/images/sonic-not-found.svg"
												 alt=""/>
										</div>
									</ng-container>
								</ng-container>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<ng-template #loading>
		<div class="row py-4">
			<div class="mx-auto col-auto">
				<mat-spinner [diameter]="50"></mat-spinner>
			</div>
		</div>
	</ng-template>
</app-layout-app>
