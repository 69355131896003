import {NgModule} from '@angular/core';
import {LitersPipe} from './consumption/LitersPipe';
import {DecimalPipe} from '@angular/common';
import {UtcToLocalPipe} from './consumption/UtcToLocalPipe';
import {MarkdownPipe} from './MarkdownPipe';

const pipes = [
    LitersPipe,
    UtcToLocalPipe,
    MarkdownPipe
];

@NgModule({
    declarations: [
        pipes
    ],
    imports: [],
    exports: [...pipes],
    providers: [DecimalPipe]
})
export class PipesModule {
}
