export interface TableSettings {
    [param: string]: string | number;
}

export const tableSettings: TableSettings = {
    // The number of elements in the page
    page_size: 30,
    // The total number of elements
    total_elements: 0,
    // The total number of pages
    total_pages: 0,
    // The current page number
    page: 1,
    // The order by element
    order_by: 'inserted_at',
    // The order strategy
    order_strategy: 'desc',
};

export interface FilterField {
    type?: string;
    name: string;
    label: string;
    options: FilterOption[];
    multiple?: boolean;
}

export interface FilterOption {
    value: string;
    label: string;
}
