import {RadioConnection} from '../../../models/thing';
import {ThingState} from '../../../models/pusher';
import * as moment from 'moment-timezone';

export const thingsData = {
    'aef65e22-apt-1-property': [{
        battery_level: 'external_power_supply',
        cached_telemetry: {
            ambient_temp: 17.375,
            device_id: 'aef65e22-apt-1-thing',
            influx_time: 1631190271559,
            message_type: 'telemetry',
            pressure: 3068,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 17.37
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: 'aef65e22-apt-1-thing',
        hub_id: 'aef65e22-apt-1-hub',
        name: 'HERO SONIC - Main home',
        radio_connection: 'connected' as RadioConnection,
        radio_rssi: 0,
        state: 'open' as ThingState,
        status: 'active',
        type: 'smart_valve',
        wifi_rssi: -29,
        created_at: moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
        property_id: 'aef65e22-apt-1-property'
    }],
    '1aa197d0-apt-2-property': [{
        battery_level: 'high',
        cached_telemetry: {
            ambient_temp: 17.375,
            device_id: '1aa197d0-apt-2-thing',
            influx_time: 1631190271559,
            message_type: 'telemetry',
            pressure: 3068,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 17.37
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: '1aa197d0-apt-2-thing',
        hub_id: 'aef65e22-apt-2-hub',
        name: 'HERO SONIC - London flat',
        radio_connection: 'disconnected' as RadioConnection,
        radio_rssi: 0,
        state: 'closed' as ThingState,
        status: 'active',
        type: 'smart_valve',
        wifi_rssi: -29,
        created_at: moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
        property_id: '1aa197d0-apt-2-property'
    }],
    'f7b44f5c-apt-3-property': [{
        battery_level: 'low',
        cached_telemetry: {
            ambient_temp: 17.375,
            device_id: 'f7b44f5c-apt-3-thing',
            influx_time: 1631190271559,
            message_type: 'telemetry',
            pressure: 3068,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 17.37
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: 'f7b44f5c-apt-3-thing',
        hub_id: 'aef65e22-apt-3-hub',
        name: 'HERO SONIC - Summer residence',
        radio_connection: 'connected' as RadioConnection,
        radio_rssi: 0,
        state: 'open' as ThingState,
        status: 'active',
        type: 'smart_valve',
        wifi_rssi: -29,
        created_at: moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
        property_id: 'f7b44f5c-apt-3-property'
    }],
    'z7b79fsc-apt-4-property': [{
        battery_level: 'external_power_supply',
        cached_telemetry: {
            ambient_temp: 17.375,
            device_id: 'f7b44f5c-apt-4-thing',
            influx_time: 1631190271559,
            message_type: 'telemetry',
            pressure: 3068,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 17.37
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: 'f7b44f5c-apt-4-thing',
        hub_id: 'aef65e22-apt-4-hub',
        name: '11 George Street',
        radio_connection: 'connected' as RadioConnection,
        radio_rssi: 0,
        state: 'open' as ThingState,
        status: 'active',
        type: 'smart_valve',
        wifi_rssi: -29,
        created_at: moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
        property_id: 'z7b79fsc-apt-4-property'
    }],
    'f7b44f5c-apt-5-property': [{
        battery_level: 'high',
        cached_telemetry: {
            ambient_temp: 17.375,
            device_id: 'f7b44f5c-apt-5-thing',
            influx_time: 1631190271559,
            message_type: 'telemetry',
            pressure: 3068,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 17.37
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: 'f7b44f5c-apt-5-thing',
        hub_id: 'aef65e22-apt-5-hub',
        name: '12 George Street',
        radio_connection: 'connected' as RadioConnection,
        radio_rssi: 0,
        state: 'open' as ThingState,
        status: 'active',
        type: 'smart_valve',
        wifi_rssi: -29,
        created_at: moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
        property_id: 'f7b44f5c-apt-5-property'
    }],
    'f7b44f5c-apt-6-property': [{
        battery_level: 'low',
        cached_telemetry: {
            ambient_temp: 17.375,
            device_id: 'f7b44f5c-apt-6-thing',
            influx_time: 1631190271559,
            message_type: 'telemetry',
            pressure: 3068,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 17.37
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: 'f7b44f5c-apt-6-thing',
        hub_id: 'aef65e22-apt-6-hub',
        name: '13 George Street',
        radio_connection: 'connected' as RadioConnection,
        radio_rssi: 0,
        state: 'open' as ThingState,
        status: 'active',
        type: 'smart_valve',
        wifi_rssi: -29,
        created_at: moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
        property_id: 'f7b44f5c-apt-6-property'
    }],
    'f7b44f5c-apt-7-property': [{
        battery_level: 'high',
        cached_telemetry: {
            ambient_temp: 17.375,
            device_id: 'f7b44f5c-apt-7-thing',
            influx_time: 1631190271559,
            message_type: 'telemetry',
            pressure: 3068,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 17.37
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: 'f7b44f5c-apt-7-thing',
        hub_id: 'aef65e22-apt-7-hub',
        name: '14 George Street',
        radio_connection: 'connected' as RadioConnection,
        radio_rssi: 0,
        state: 'open' as ThingState,
        status: 'active',
        type: 'smart_valve',
        wifi_rssi: -29,
        created_at: moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
        property_id: 'f7b44f5c-apt-7-property'
    }],
    'f7b44f5c-apt-8-property': [{
        battery_level: 'high',
        cached_telemetry: {
            ambient_temp: 17.375,
            device_id: 'f7b44f5c-apt-8-wifi',
            influx_time: 1631190272559,
            message_type: 'telemetry',
            pressure: 3068,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 17.37
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: 'f7b44f5c-apt-8-wifi',
        hub_id: 'aef65e22-apt-8-hub',
        name: 'Łódz flat',
        radio_connection: null,
        radio_rssi: null,
        state: 'open' as ThingState,
        status: 'active',
        type: 'sonic_wifi',
        wifi_rssi: -79,
        created_at: moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
        property_id: 'f7b44f5c-apt-8-property'
    }],
    'f7b44f5c-apt-9-property': [{
        battery_level: 'low',
        cached_telemetry: {
            ambient_temp: 17.375,
            device_id: 'f7b44f5c-apt-9-wifi',
            influx_time: 1631190272559,
            message_type: 'telemetry',
            pressure: 3068,
            probed_at: 1631190271559,
            received_at: 1631190272664,
            water_flow: 0,
            water_temp: 17.37
        },
        cloud_connection: true,
        health: {
            ambient_temp_sensor: 'ok',
            flash: 'ok',
            i2c: 'ok',
            pressure_sensor: 'ok',
            radio: 'ok',
            spi: 'ok',
            ultrasound_sensor: 'ok',
            water_temp_sensor: 'ok'
        },
        id: 'f7b44f5c-apt-9-wifi',
        hub_id: 'aef65e22-apt-9-hub',
        name: 'Łódz office',
        radio_connection: null,
        radio_rssi: null,
        state: 'open' as ThingState,
        status: 'active',
        type: 'sonic_wifi',
        wifi_rssi: -79,
        created_at: moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
        property_id: 'f7b44f5c-apt-8-property'
    }]
};
