import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {ApplianceCategoriesResponse} from 'src/app/models/appliance';
import {OptionalHttpParams} from '../resources/thing.resource';

@Injectable()
export class ApplianceDemoResource {

  private applianceCategoriesData = {
    appliance_categories: [
      {icon_url: 'tap', id: 100, name: 'Tap', parent: null, parent_id: null},
      {icon_url: 'toilet', id: 110, name: 'Toilet', parent: null, parent_id: null},
      {
        icon_url: 'shower',
        id: 120,
        name: 'Shower',
        parent: null,
        parent_id: null
      },
      {icon_url: 'bathtub', id: 130, name: 'Bathtub', parent: null, parent_id: null},
      {icon_url: 'w-machine', id: 200, name: 'Washing Machine', parent: null, parent_id: null}, {
        icon_url: 'dishwasher',
        id: 210,
        name: 'Dishwasher',
        parent: null,
        parent_id: null
      },
      {icon_url: 'irrigation', id: 300, name: 'Irrigation System', parent: null, parent_id: null},
      {icon_url: 'fridge', id: 220, name: 'Refrigerator', parent: null, parent_id: null}, {
        icon_url: 'other',
        id: 900,
        name: 'Other',
        parent: null,
        parent_id: null
      }]
  };

  constructor(private http: HttpClient) {
  }

  public getCategories(thingId: string, params?: OptionalHttpParams): Observable<ApplianceCategoriesResponse> {
    return of(this.applianceCategoriesData);
  }

  public getAllCategories(): Observable<ApplianceCategoriesResponse> {
    return of(this.applianceCategoriesData);
  }

}
