import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {PropertyService} from '../../../providers/services/property.service';
import {Property, PropertyObj} from '../../../models/property';
import * as _ from 'lodash';
import {switchMap, take} from 'rxjs/operators';
import * as moment from 'moment-timezone';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgxMaterialTimepickerTheme} from 'ngx-material-timepicker';
import {MatDialog} from '@angular/material/dialog';
import {predefinedHighVolumeOptions, predefinedLongFlowOptions} from '../settings.config';
import {MassEditDialogComponent} from '../../parts/mass-edit-dialog/mass-edit-dialog.component';
import {incidentsBoolProperties} from '../../incidents/config';

@Component({
    selector: 'app-settings-table',
    templateUrl: './settings-table.component.html',
    styleUrls: ['./settings-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsTableComponent implements OnInit {
    public predefinedHighVolumeOptions = predefinedHighVolumeOptions;
    public predefinedLongFlowOptions = predefinedLongFlowOptions;
    public propertyData: PropertyObj[] = [];
    public allChecked = false;
    public boolProperties = incidentsBoolProperties;
    public timePickerTheme: NgxMaterialTimepickerTheme = {
        container: {
            buttonColor: '#2C7BE5'
        },
        dial: {
            dialBackgroundColor: '#2C7BE5',
        },
        clockFace: {
            clockHandColor: '#2C7BE5',
        }
    };
    public tooltipText = 'Change for selected properties';

    constructor(private snackBar: MatSnackBar,
                private matDialog: MatDialog,
                private propertyService: PropertyService,
                private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.fetchData();
    }


    public toggleAll() {
        _.forEach(this.propertyData, (item) => {
            item.isChecked = this.allChecked;
        });
    }

    public getTimeOfPressureTest(propertyObj: PropertyObj) {
        return moment.utc(propertyObj.settings.pressure_tests_schedule, 'H:mm:ss').tz(propertyObj.settings.timezone)
            .format('H:mm');
    }

    public updateTime(event: string, propertyObj: PropertyObj): void {
        _.set(propertyObj.settings, 'pressure_tests_schedule', moment.tz(event, 'h:mm A', propertyObj.settings.timezone).utc().format(moment.HTML5_FMT.TIME_MS));
        this.updateSettings(propertyObj);
    }

    public updateNotification(propertyObj: PropertyObj) {

        this.propertyService.updateNotificationSettings(propertyObj.property.id, propertyObj.notification)
            .subscribe({
                next: (settings) => {
                    propertyObj.notification = settings;
                    this.snackBar.open('Data saved', null, {
                        verticalPosition: 'top',
                        horizontalPosition: 'right',
                        duration: 5000
                    });
                },
                error: () => {
                    this.snackBar.open('Error occurred', null, {
                        verticalPosition: 'top',
                        horizontalPosition: 'right',
                        duration: 5000
                    });
                }
            });
    }

    public updateSettings(propertyObj: PropertyObj) {

        this.propertyService.updateSettings(propertyObj.property.id, propertyObj.settings)
            .subscribe({
                next: (settings) => {
                    propertyObj.settings = settings;
                    this.snackBar.open('Data saved', null, {
                        verticalPosition: 'top',
                        horizontalPosition: 'right',
                        duration: 5000
                    });
                },
                error: () => {
                    this.snackBar.open('Error occurred', null, {
                        verticalPosition: 'top',
                        horizontalPosition: 'right',
                        duration: 5000
                    });
                }
            });
    }

    public massUpdateNotification(field: string, label: string) {
        this.openEditSettingsDialog(field, label, 'notification');
    }

    public massUpdateSettings(field: string, label: string) {
        this.openEditSettingsDialog(field, label, 'settings');
    }

    public openEditSettingsDialog(field: string, label: string, mode: string): void {
        const ref = this.matDialog.open(MassEditDialogComponent, {
            data: {
                propertyData: this.propertyData,
                field,
                label,
                mode
            },
            width: '100%',
            maxWidth: '500px',
            panelClass: ['mx-auto', 'overflow-visible'],
        });
        ref.afterClosed()
            .pipe(take(1))
            .subscribe((response: boolean) => {
                if (response) {
                    this.fetchData();
                }
            });
    }

    private fetchData() {
        this.propertyData = [];
        this.propertyService.getPropertiesList()
            .subscribe(properties => {
                _.forEach(properties.properties, (property) => {
                    this.fetchSettings(property);
                });
            });
    }

    private fetchSettings(property: Property) {
        const data = {
            property,
            settings: null,
            isChecked: false,
            notification: null
        };
        this.propertyService.getSettings(property.id)
            .pipe(switchMap((settings) => {
                data.settings = settings;
                return this.propertyService.getNotificationSettings(property.id);
            }))
            .subscribe(notificationSettings => {
                data.notification = notificationSettings;
                this.propertyData.push(data);
                this.propertyData = _.sortBy(this.propertyData, (item) => item.property.name);
                this.cdRef.detectChanges();
            });
    }

}
