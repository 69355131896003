import {Injectable} from '@angular/core';
import {Hub} from '../../models/hub';
import {Thing} from '../../models/thing';

@Injectable()
export class HubService {

    constructor() {
    }

    public getWifiStatus(resource: Hub | Thing): 'excellent' | 'good' | 'fair' | 'weak' | 'no_signal' {
        const rssi = resource.wifi_rssi;

        if(!(resource as Thing).cloud_connection){
            return 'no_signal';
        }

        switch (true) {
            case (rssi <= -90):
                return 'no_signal';
            case (rssi <= -75 && rssi > -90):
                return 'weak';
            case (rssi <= -70 && rssi > -75):
                return 'fair';
            case (rssi <= -60 && rssi > -70):
                return 'good';
            case (rssi > -60):
                return 'excellent';
        }
    }
}
