import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ThingService} from '../../../providers/services/thing.service';

@Component({
    selector: 'app-battery-level',
    templateUrl: './battery-level.component.html',
    styleUrls: ['./battery-level.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BatteryLevelComponent {


    @Input() batteryLevel: string | number;

    constructor(private thingService: ThingService) {
    }

    public getBatteryLevel(): { label: string; css: string } {
        return this.thingService.getBatteryLevel(this.batteryLevel);
    }
}
