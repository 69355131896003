import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UserService} from '../../providers/services/user.service';
import {User} from '../../models/user';
import {MatDialog} from '@angular/material/dialog';
import {EditAccountComponent} from '../parts/edit-account-dialog/edit-account.component';
import {take} from 'rxjs/operators';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsComponent implements OnInit {

    public user: User;

    constructor(private userService: UserService,
                private cdRef: ChangeDetectorRef,
                private matDialog: MatDialog) {
    }

    ngOnInit(): void {
        this.userService.validateToken()
                .subscribe((response) => {
                    this.userService.setUserProfile(response.user_details);
                    this.user = this.userService.user;
                    this.cdRef.detectChanges();
                });
    }

    public openUserEditDialog(): void {
        const ref = this.matDialog.open(EditAccountComponent, {
            data: {},
            width: '100%',
            maxWidth: '500px',
            panelClass: ['mx-auto', 'overflow-visible'],
        });
        ref.afterClosed()
                .pipe(take(1))
                .subscribe((response: User) => {
                    if (response) {
                        this.user = response;
                        this.cdRef.detectChanges();
                    }
                });
    }
}
