import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import * as _ from 'lodash';
import {StatsRange} from '../../../../models/aggregated-stats';

@Component({
    selector: 'app-water-temp-widget',
    templateUrl: './water-temp-widget.component.html',
    styleUrls: ['./water-temp-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WaterTempWidgetComponent {

    @Input() seriesData: number[];
    @Input() labels: string[];
    @Input() range: StatsRange;
    @Input() freezeWaterTempAlert: number;
    @Input() chartXMin: number;
    @Input() chartXMax: number;
    public unit = '°C';

    public getAverageTemp() {
        const filteredData = this.filterOutNotNumbers();
        if (filteredData.length === 0) {
            return null;
        }
        return _.round(_.mean(filteredData), 1).toString();
    }

    private filterOutNotNumbers(): number[] {
        return _.filter(this.seriesData, item => !_.isNaN(item) && !!item);
    }
}
