import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {IncidentsService} from '../../../providers/services/incidents.service';

import * as moment from 'moment-timezone';
// @ts-ignore
import * as momentDurationFormatSetup from 'moment-duration-format';
import {PusherService} from '../../../common/pusher/pusher.service';
import {Observable} from 'rxjs';
import {
    BatteryPayload,
    HealthCheckPayload,
    IncidentAction,
    IncidentDetails,
    IncidentUsagePayload
} from '../../../models/incident';
import {TelemetryMessage} from '../../../models/pusher';
import * as _ from 'lodash';
import {ConfirmDialogComponent} from '../../parts/confirm-dialog/confirm-dialog.component';
import {SnoozeModalComponent} from '../snooze-modal/snooze-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {PropertyService} from '../../../providers/services/property.service';
import {recentTypes} from '../config';

@Component({
    selector: 'app-incident-page',
    templateUrl: './incident-page.component.html',
    styleUrls: ['./incident-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IncidentPageComponent implements OnInit, OnDestroy {


    public propertyId: string;
    public incidentId: string;
    public incidentDetailsStream: Observable<IncidentDetails>;
    public recentTypes = recentTypes;

    private pusherAlreadyInitialised = true;
    private timezone: string;


    constructor(private route: ActivatedRoute,
                private pusherService: PusherService,
                private matDialog: MatDialog,
                private cdRef: ChangeDetectorRef,
                private propertyService: PropertyService,
                private incidentsService: IncidentsService) {
    }

    ngOnInit(): void {
        momentDurationFormatSetup(moment);
        this.route.params.subscribe(params => {
            this.propertyId = params.propertyId;
            this.incidentId = params.incidentId;
            this.fetchPropertySettings();
            this.fetchDetails();
            this.setupPusher();
        });
    }

    public ngOnDestroy(): void {
        if (!this.pusherAlreadyInitialised) {
            this.pusherService.destroyEvents();
        }
    }

    public convertDate(detected_at: string): string {
        const timezone = this.timezone || 'Europe/London';
        return moment.tz(detected_at, timezone).format('ll HH:mm');
    }

    public checkIfHasPayload(payload: TelemetryMessage | IncidentUsagePayload | HealthCheckPayload | BatteryPayload): boolean {
        return !(!payload || _.isEmpty(payload));
    }

    public formatDuration(duration: number): string {
        return moment.duration(duration, 'millisecond').format('m');
    }

    public sortActionButtons(buttonActions: IncidentAction[]): IncidentAction[] {
        return _.sortBy(buttonActions, [(o) => o.button_class.indexOf('btn-primary') >= 0 ? 0:1]);
    }

    public handleAction(action: IncidentAction): void {
        if (action.name==='snooze') {
            this.showSnoozeModal();
        } else if (action.confirmation_required) {

            this.matDialog.open(ConfirmDialogComponent, {
                data: {
                    title: action.confirmation_required.title,
                    description: action.confirmation_required.body,
                    yesClass: ['btn-danger'],
                    yesAction: () => {
                        this.executeIncidentAction(action);
                    },
                    noAction: () => {
                    }
                }
            });
        } else {
            this.executeIncidentAction(action);
        }
    }

    private executeIncidentAction(action: IncidentAction) {
        this.incidentDetailsStream = this.incidentsService.executeAction(this.propertyId, this.incidentId, action.name);
        this.cdRef.detectChanges();
    }

    private fetchDetails(): void {
        this.incidentDetailsStream = this.incidentsService.getDetails(this.propertyId, this.incidentId);
        this.cdRef.detectChanges();
    }

    private showSnoozeModal(): void {
        const ref = this.matDialog.open(SnoozeModalComponent, {
            data: {},
            width: '100%',
            maxWidth: '80vw',
            panelClass: 'mx-auto',
        });
        ref.afterClosed().subscribe((res: number) => {
            if (res) {
                const snoozedUntil = res > 0 ? moment().utc().add(res, 'm').format('YYYY-MM-DD HH:mm:ss'):null;

                this.incidentsService.snooze(this.propertyId, this.incidentId, snoozedUntil)
                    .subscribe(() => {
                        this.fetchDetails();
                        this.cdRef.detectChanges();
                    });
            }
        });
    }

    private fetchPropertySettings(): void {
        this.propertyService.getSettings(this.propertyId)
            .subscribe(response => {
                this.timezone = response.timezone;
                this.cdRef.detectChanges();
            });
    }

    private setupPusher(): void {
        if (!this.pusherService.getChannel()) {
            this.pusherAlreadyInitialised = false;
            this.pusherService.initPropertyChannel(this.propertyId);
        }
        this.pusherService.onIncidentChange((message) => {
            if (this.incidentId===message.message.incident_id) {
                this.fetchDetails();
            }
        });
    }
}
