<mat-tab-group mat-align-tabs="center"
			   [selectedIndex]="0"
			   (selectedIndexChange)="onTabChange($event)"
			   #mainTabGroup>
	<mat-tab label='active'>
		<ng-template matTabContent>
			<div class="tab-inner">
				<app-incidents-list [incidentIndex]="incidentPageable"
									[propertyId]="propertyId"
									[timezone]="timezone"
									(nextPage)="onNextPage($event, 'active')"
									(refresh)="loadIncidents(null, 'active')"
									*ngIf="incidentPageable && timezone">
				</app-incidents-list>
			</div>
		</ng-template>
	</mat-tab>
	<mat-tab label='closed'>
		<ng-template matTabContent>
			<div class="tab-inner">
				<ng-container *ngIf="closedIncidentsPageable && timezone">
					<app-incidents-list [incidentIndex]="closedIncidentsPageable"
										[propertyId]="propertyId"
										[timezone]="timezone"
										(refresh)="loadIncidents(null, 'closed', selectedType)"
										(nextPage)="onNextPage($event, 'closed',selectedType)">
					</app-incidents-list>

				</ng-container>
			</div>
		</ng-template>
	</mat-tab>
</mat-tab-group>
