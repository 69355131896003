import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit} from '@angular/core';
import {EChartsOption, graphic} from 'echarts';
import {MarkAreaOption} from 'echarts/types/dist/shared';
import * as _ from 'lodash';
import {chartDefaultOptions} from '../chart-defaults';
import {StatsService} from '../../../../providers/services/stats.service';
import {StatsRange} from '../../../../models/aggregated-stats';

@Component({
    selector: 'app-line-chart',
    templateUrl: './line-chart.component.html',
    styleUrls: ['./line-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineChartComponent implements OnInit, OnChanges {

    @Input() seriesData: number[];
    @Input() labels: string[];
    @Input() range: StatsRange;
    @Input() warnValue: number;
    @Input() unit: string;
    @Input() yLabelMinInterval = 2;
    @Input() chartXMin: number;
    @Input() chartXMax: number;

    public chartOptions: EChartsOption;

    constructor(private cdRef: ChangeDetectorRef,
                private statsService: StatsService) {
    }

    ngOnInit() {
        this.setupOptions();
    }

    ngOnChanges() {

        this.setupOptions();
    }

    private hasAtLeastOneValue() {
        return _.size(_.filter(this.seriesData, (item) => !_.isNaN(item) && !_.isNull(item) && item!==undefined)) > 0;
    }


    private getMarkArea(): MarkAreaOption {
        if (!this.warnValue || this.warnValue < _.min(this.seriesData) || !this.hasAtLeastOneValue()) {
            return null;
        }

        return {
            itemStyle: {
                color: new graphic.LinearGradient(0, 0, 0, 1, [
                    {
                        offset: 0,
                        color: 'rgba(255,176,165,0.5)'
                    },
                    {
                        offset: 0.5,
                        color: 'rgba(255,176,165,0)'
                    }
                ]),
            },
            label: {
                position: 'insideTopLeft'
            },
            data: [
                [
                    {
                        // name: 'Possible freeze',
                        yAxis: 0
                    },
                    {
                        yAxis: this.warnValue
                    }
                ],

            ]

        };

    }


    private setupOptions() {
        const markArea = this.getMarkArea();

        this.chartOptions = _.merge(_.cloneDeep(chartDefaultOptions), {
            grid: {
                left: '5%',
                top: '5%',
                right: '14%',
                bottom: '10%',
            },
            tooltip: {
                trigger: 'axis',
                borderWidth: 0,
                backgroundColor: '#191A19',
                textStyle: {
                    color: '#D9D8D8'
                },
                formatter: (item) => this.statsService.formatTooltipContent(item[0], this.unit, this.range)
            },
            xAxis: {
                data: this.labels,
                // silent: false,
                // scale: true,
                // type: 'time',
                splitNumber: 3,
                // splitLine: {
                //     show: false,
                // },
                min: this.chartXMin,
                max: this.chartXMax,
                axisTick: {
                    show: false,
                    alignWithLabel: true,
                },
                axisLabel: {
                    color: '#A2A1A1',
                    showMaxLabel: true,
                    hideOverlap: true,
                    formatter: (item, index) => this.statsService.formatXaxisLabel(item, index, this.seriesData.length)
                },
                axisLine: {
                    lineStyle: {
                        color: '#e0e6f1'
                        // type: [1, 10],
                    },
                    // show: false
                },
            },
            yAxis: {

                axisLabel: {
                    color: '#A2A1A1',
                    formatter: (item) => `${item}${this.unit}`
                },
                minInterval: this.yLabelMinInterval,
                // splitNumber: 3,
            },
            series: [
                {
                    name: 'line',
                    type: 'line',
                    connectNulls: true,
                    data: this.seriesData,
                    smooth: true,
                    // showSymbol: false,
                    lineStyle: {
                        color: '#5D8DC0'
                    },
                    animationDelay: idx => idx * 10,
                    // markLine,
                    markArea,
                },

            ]
        });
        this.cdRef.detectChanges();
    }

}
