import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import * as _ from 'lodash';

@Component({
    selector: 'app-expandable-menu',
    templateUrl: './expandable-menu.component.html',
    styleUrls: ['./expandable-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpandableMenuComponent implements OnInit {

    @Input() iconsName: string;
    @Input() menuName: string;
    @Input() routerActiveSlug: string;
    @Input() active: boolean;

    public opened = false;

    constructor(private router: Router) {
    }

    ngOnInit(): void {
        if (_.includes(this.router.url, this.routerActiveSlug)) {
            this.active = true;
        }
    }

}
