import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {PropertyService} from '../../providers/services/property.service';
import {Property} from '../../models/property';
import * as _ from 'lodash';
import {Hub} from '../../models/hub';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent implements OnInit {

    public properties: Property[];
    public numberOfIncidents = {active: 0};

    constructor(private propertyService: PropertyService,
                private cdRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.fetchProperties();
    }


    public getNumberOfAllHubs(properties: Property[]) {
        return _.size(this.getAllHubs(properties));
    }

    public getNumberOfConnectedHubs(properties: Property[]) {
        return _.size(_.filter(this.getAllHubs(properties), (hub) => hub.online));
    }


    private fetchProperties(): void {
        this.propertyService.getPropertiesList(true)
            .subscribe((response) => {
                this.properties = response.properties;
                this.loadAllIncidents();
                this.cdRef.detectChanges();
            });
    }

    private getAllHubs(properties: Property[]): Hub[] {
        return _.flatMapDeep(properties, (item) => _.flatMapDeep(item.spaces, (space) => space.hubs));
    }

    private loadAllIncidents() {
        this.properties.forEach((property) => {
            this.numberOfIncidents['active'] += (property.active_incidents.high_count + property.active_incidents.low_count);
        });
    }


}
