<app-layout-app>
    <div class="main-content">
        <!-- HEADER -->
        <div class="header pb-5">
            <div class="container-fluid">
                <!-- Body -->
                <div class="header-body">
                    <div class="row align-items-end">
                        <div class="col">
                            <h1 class="m-0 py-4">
                                Stats
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid ">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row align-items-start">
                                <div [ngClass]="{'col-xl-12': !selectedProperty, 'col-xl-3':selectedProperty}">
                                    <h2>Select property</h2>


                                    <app-properties-list [autoSelectFirstProperty]="true"
                                                         (propertySelect)="selectProperty($event)">
                                    </app-properties-list>

                                </div>
                                <ng-container *ngIf="selectedProperty && thing; ">
                                    <div class="col-xl-3 mx-xl-auto u-sticky">
                                        <h2>Select range</h2>

                                        <mat-button-toggle-group [(ngModel)]="currentRange"
                                                                 class="w-100"
                                                                 (change)="onCalendarDateSelected()"
                                                                 aria-label="Date range">
                                            <mat-button-toggle *ngFor="let range of ranges"
                                                               class="w-100"
                                                               [value]="range">{{ range.label }}
                                            </mat-button-toggle>
                                        </mat-button-toggle-group>
                                        <br>
                                        <div class="row pt-3">
                                            <div class="col-lg">
                                                <button mat-stroked-button (click)="prevDate()"
                                                        class="w-100">
                                                    Previous range
                                                </button>
                                            </div>
                                            <div class="col-lg">
                                                <button mat-stroked-button (click)="nextDate()"
                                                        class="w-100">
                                                    Next range
                                                </button>
                                            </div>
                                        </div>

                                        <h2 class="mt-4">Select date</h2>

                                        <mat-form-field class="w-100">
                                            <mat-date-range-input [rangePicker]="picker">
                                                <input matStartDate (dateChange)="onCalendarDateSelected()"
                                                       (click)="picker.open()"
                                                       [(ngModel)]="selectedDate">
                                                <input matEndDate
                                                       (click)="picker.open()"
                                                       [(ngModel)]="selectedEndDate">
                                            </mat-date-range-input>
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-date-range-picker disabled="false" #picker></mat-date-range-picker>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-xl-4 u-sticky">
                                        <h2>Charts</h2>
                                        <app-water-usage-widget [series1Data]="waterUsageChartData"
                                                                [series2Data]="waterUsageZerosChartData"
                                                                [labels]="waterUsageChartLabels"
                                                                [range]="currentRange"></app-water-usage-widget>

                                        <app-water-temp-widget [seriesData]="waterTempAverageChartData"
                                                               [freezeWaterTempAlert]="freezeWaterTempAlert"
                                                               [range]="currentRange"
                                                               [labels]="waterTempAverageChartLabels"></app-water-temp-widget>

                                        <app-water-pressure-widget [seriesData]="waterPressureAverageChartData"
                                                                   [range]="currentRange"
                                                                   [labels]="waterPressureAverageChartLabels"></app-water-pressure-widget>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="notThing">
                                    <ng-container *ngIf="selectedProperty">
                                        <div class="col-xl-3 offset-xl-1">
                                            <h2>There is no Sonic</h2>
                                            <img src="assets/images/sonic-not-found.svg"
                                                 alt=""/>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #loading>
        <div class="row py-4">
            <div class="mx-auto col-auto">
                <mat-spinner [diameter]="50"></mat-spinner>
            </div>
        </div>
    </ng-template>
</app-layout-app>
